import { SEARCH_SET_QUERY, SEARCH_CLEAR_QUERY, SET_ACTIVE_ADVANCE_SEARCH, 
  SEARCH_RESULTS, 
  CLEAR_ADVANCE_SEARCH,
  SAVE_ADVANCE_FORM_VALUES
} from '../actionTypes';

const initialState = {
  smallBusiness: {},
  primes: {},
  calendar: {},
  rfpRfq: {},
  lookAhead: {},
  association: {},
  'message-center': {},
  activeAdvanceSearch: null,
  searchResults: [],
  clearAdvanceSearch: false,
  formValues: {}
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SET_QUERY:
      return {
        ...state,
        [action.payload.component]: {
          keyword: action.payload.keyword,
          fields: [...action.payload.fields]
        }
      };
    case SET_ACTIVE_ADVANCE_SEARCH: 
      return {
        ...state,
        activeAdvanceSearch: action.payload
      }
    case SEARCH_CLEAR_QUERY:
      return { ...state, [action.payload.component]: {} };
    case SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };
    case SAVE_ADVANCE_FORM_VALUES:
      return { ...state, formValues: action.payload };
    case CLEAR_ADVANCE_SEARCH:
      return { ...state, clearAdvanceSearch: action.payload.clearAdvanceSearch, searchResults: [] };
    default:
      return { ...state };
  }
};
