import connection from '../utils/api';

export const getVerificationSteps = query => {
    let queryString = '';
    // if (!query) {
    //   queryString = '';
    // }
    // let queryParams = [];
    // if (query.page) {
    //   queryParams.push(`page=${query.page}`);
    // }
    // if (query.limit) {
    //   queryParams.push(`limit=${query.limit}`);
    // }
    // queryString = `?` + queryParams.join('&');
    return connection.get(`verification-step${queryString}`);
};

export const showVerificationStep = id =>
connection.get(`verification-step/${id}`);

export const createVerificationStep = data =>
connection.post('verification-step', data);

export const updateVerificationStep = (id, data) =>
connection.put(`verification-step/${id}`, data);

export const deleteVerificationStep = id =>
connection.delete(`verification-step/${id}`);
