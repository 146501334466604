import React, { useEffect, useState } from 'react';
import { Form, Button, Spin, Input } from 'antd';
import { onCreateVideo } from '../../redux/actions/videos';
import { useDispatch } from 'react-redux';
import { objectToFormData } from '../../utils/utils';
import { useRouteMatch } from 'react-router-dom';
import { getVideoDetails } from '../../api/videos';

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 24
  }
};

const VideoCreate = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const match = useRouteMatch();
  let video_id = match.params.id;

  const getVideoInfo = async (id) => {
    try {
      let result = await getVideoDetails(id);
      let data = result.data?.data
      setFormValues(data || {});
    } catch (error) {
      
    }
  }
  
  useEffect(()=>{
    if(video_id){
      form.resetFields();
      setFormValues({})
      getVideoInfo(video_id)
    }
  }, [video_id])

  const onFinish = values => {
    setLoading(true)
    dispatch(onCreateVideo(objectToFormData({ ...values, is_deleted: false}), () => {
      form.resetFields();
    }));
  };

  return (
    <div>
      {video_id && !formValues.title ? <Spin /> :
      <Form
        form={form}
        {...layout}
        initialValues={formValues}
        layout="vertical"
        onFinish={onFinish}
      >
        <h1>Video Create Form</h1>
        <Form.Item name="title" label="Video Title">
          <Input placeholder="Video Title" />
        </Form.Item>
        <Form.Item name="url" label="Video Url">
          <Input placeholder="Video Url" />
        </Form.Item>
        <Form.Item name="description" label="Video Description">
          <Input placeholder="Video Description" />
        </Form.Item>

        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>}
    </div>
  );
};

export default VideoCreate;
