import {
  UI_LOADING_SHOW,
  UI_LOADING_HIDE,
  UI_SHOW_MODAL,
  UI_HIDE_MODAL,
  UI_SET_MODAL_TITLE,
  UI_SHOW_SIDEBAR
} from '../actionTypes';

export const showLoading = () => ({
  type: UI_LOADING_SHOW
});

export const hideLoading = () => ({
  type: UI_LOADING_HIDE
});

export const showModal = () => ({ type: UI_SHOW_MODAL });

export const hideModal = () => ({ type: UI_HIDE_MODAL });

export const closeDrawer = (payload) => ({ type: UI_SHOW_SIDEBAR, payload });

export const setModalHeader = title => ({
  type: UI_SET_MODAL_TITLE,
  payload: title
});
