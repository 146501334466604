import React, { useEffect, useState } from 'react';
import { array, bool, func, string } from 'prop-types';
import { Checkbox, Col, Row } from 'antd';

import { getDropdownOptions } from '../../../../api/formOptions';

const Checkboxes = ({ valueProps, keyName, parentHandler, disabled }) => {
  const [options, setOptions] = useState(null);
  const innerWidth = window.innerWidth;

  useEffect(() => {
    getDropdownOptions(keyName)
      .then(({ data: { data: options } }) => {
        const optionsArray = options[keyName];
        let optionsList = [];
        optionsList = optionsArray.map(option => {
          let node = null;
          for (const [key, value] of Object.entries(option)) {
            node = { label: value, value: key };
          }
          return node;
        });
        setOptions([...optionsList]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [keyName]);

  const onCheckedValues = values => {
    const selectedValues = [];
    options.forEach(item => {
      if (values.includes(item['value'])) {
        selectedValues.push(item['label']);
      }
    });
    parentHandler(keyName, { [keyName]: [...selectedValues] });
  };

  return (
    <>
      {options ? (
        <Checkbox.Group
          style={{ border: '1px solid gray', width: '100%', textAlign: 'left' }}
          onChange={onCheckedValues}
          disabled={disabled}
          defaultValue={
            valueProps && valueProps.length
              ? options
                  .filter(item => valueProps.includes(item['label']))
                  .map(item => item['value'])
              : []
          }
        >
          <Row>
            {options.map((option) => (
              <Col
                span={innerWidth > 556 ? 6 : 12}
                key={option.value}
                style={{
                  borderBottom: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  padding: '10px'
                }}
              >
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      ) : null}
    </>
  );
};

Checkboxes.propTypes = {
  keyName: string,
  parentHandler: func,
  valueProps: array,
  disabled: bool
};

export default Checkboxes;
