import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import VideoPlayer from './videoPlayer';
import ModalComponent from '../../components/ui/ModalComponent';
import { showModal } from '../../redux/actions/ui';
import { getVideos } from '../../api/videos';
import { useDispatch } from 'react-redux';
import { handleNotifications } from '../../utils/notifications';

const Videos = () => {
  const [activeUrl, setActiveUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const response = await getVideos();
        const list = response.data?.data?.data;
        setVideoList(list.filter(item => !item.is_deleted));
        setLoading(false)
      } catch (err) {
        setLoading(false)
        handleNotifications('error', 'Error', 'Something bad happened!')
      }
    })();
  }, []);

  const handleClick = url => {
    dispatch(showModal());
    setActiveUrl(url);
  };

  const handleCloseVideo = () => {
    setActiveUrl('');
  };

  return (
    <div className="video-container">
      <ModalComponent onCloseModalSuccess={handleCloseVideo}>
        <VideoPlayer src={activeUrl} />
      </ModalComponent>
      <Row gutter={{ xs: 8, sm: 16 }}>
        {Array.isArray(videoList) && videoList.length ? (
          videoList.map(video => (
            <Col className="gutter-row" lg={6} md={12} sm={24}>
              <div className="video-item">
                <video
                  poster={video.thumbnail_url || "https://i.picsum.photos/id/490/200/300.jpg?hmac=8hYDsOwzzMCthBMYMN9bM6POtrJfVAmsvamM2oOEiF4"}
                  onClick={() =>
                    handleClick(video.url)
                  }
                >
                  <source src={video.url} type="video/mp4" />
                </video>
                <div className="details">
                  <img
                    alt=""
                    src={video.thumbnail_url || "https://i.picsum.photos/id/490/200/300.jpg?hmac=8hYDsOwzzMCthBMYMN9bM6POtrJfVAmsvamM2oOEiF4"}
                    width="30"
                    height="30"
                  />
                  <span>{video.title}</span>
                </div>
              </div>
            </Col>
          ))
        ) : (
          !loading && <h2>No videos present :(</h2>
        )}
      </Row>
    </div>
  );
};

export default Videos;
