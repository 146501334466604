import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  Spin
} from 'antd';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';

import { getMessageCenter, getMessagingEntities } from '../../api/messageCenter';
// import { handleNotifications } from '../../utils/notifications';
import { objectToFormData } from '../../utils/utils';
import { getUser } from '../../utils/checkLogin';
import { onUpdateCenter } from '../../redux/actions/messageCenter';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Update = ({ role, onUpdate }) => {

  const [loading, setLoading] = useState(true);
  const [showAssociation, setShowAssociation] = useState(false);
  const [blockReason, setBlockReason] = useState(false);
  const [showPrimes, setShowPrimes] = useState(false)
  const [showSmalles, setShowSmalles] = useState(false);
  const [primeList, setPrimeList] = useState([]);
  const [sbList, setSbList] = useState([]);
  const [center, setCenter] = useState({});
  const [associationList,setAssociationList] = useState([]);

  const { centerId } = useParams();

  useEffect(() => {
    console.log(role)
    switch(role) {
      case 'admin':
        setShowPrimes(true);
        setShowSmalles(true);
        setShowAssociation(true);
        break;
      case 'prime':
        setShowPrimes(true);
        setShowSmalles(false);
        setShowAssociation(true);
        break;
      case 'small-business':
      case 'association':
        setShowPrimes(false);
        setShowSmalles(true);
        setShowAssociation(true);
        break;
      default:
        setShowPrimes(false);
        setShowSmalles(true);
        setShowAssociation(true);
    }

    if (!centerId) {
      return;
    }

    getMessageCenter(centerId)
      .then(({ data }) => {
        if(data['success']) {
          setCenter(data['data']);
          setBlockReason(data['data']['block_status']);
          getMessagingEntities()
            .then(({ data }) => {
              if (data['success']) {
                setPrimeList(data['data']['primes']);
                setSbList(data['data']['small_businesses']);
                setAssociationList(data['data']['associations']);
                setLoading(false);
              }
            }).catch(error => {
              console.log(error);
            });
        }
      }).catch(error => {
        console.log(error);
      });

    // async function fetchCenter() {
    //   let res = await getMessageCenter(centerId);
    //   if (res.data['success']) {
    //     setCenter(res.data['data']);
    //     setBlockReason(res.data['data']['block_status']);
    //   } else {
    //     handleNotifications('error', 'Could not fetch', res.data.message);
    //   }
    // }

    // async function fetchEntities() {
    //   let res = await getMessagingEntities();
    //   if (res.data['success']) {
    //     setPrimeList(res.data['data']['primes']);
    //     setSbList(res.data['data']['small_businesses']);
    //   } else {
    //     handleNotifications('error', 'Could not fetch', res.data.message);
    //   }
    // }

    // fetchCenter();
    // fetchEntities();
    
      // eslint-disable-next-line
  }, [centerId]);

  const onFinish = (values) => {
    values.initiator_id = getUser().user_id;
    console.log('Success:', values);
    onUpdate(center.id, objectToFormData(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const toggleBlockReason = (e) => {
    setBlockReason(e.target.checked);
  }

  return (
    <>
    { loading ? <Spin /> :
      (
        <Form
          {...layout}
          layout="vertical"
          initialValues={{
            title: center['title'],
            smalles: center['participants'].filter(e => sbList.map(m => m.userid).includes(e)),
            primes: center['participants'].filter(e => primeList.map(m => m.userid).includes(e)),
            associations: center['participants'].filter(e => associationList.map(m => m.userid).includes(e)),
            block_status: center['block_status'],
            block_reason: center['block_reason'],
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="title" label="Title">
            <Input />
          </Form.Item>

            
        {showPrimes ? (
          <Form.Item name="primes" label="Corporations">
            <Select
              showSearch
              mode={role === 'admin' ? 'tags' : false}
              options={primeList.map(e => ({
                label: e.company_name,
                value: e.userid
              }))}

            >
            </Select>
          </Form.Item>
        ) : null}

        {showSmalles ? (
          <Form.Item name="smalles" label="Small Businesses">
            <Select
              showSearch
              mode={role === 'admin' ? 'tags' : false}
              options={sbList.map(e => ({
                label: e.company_name,
                value: e.userid
              }))}
            >
            </Select>
          </Form.Item>
        ) : null}

      {showAssociation && associationList.length > 0 ? (
        <Form.Item name="associations" label="Associations">
          <Select
            showSearch
            mode={role === 'admin' ? 'tags' : false}
            options={associationList.map(e => ({
              label: e.company_name,
              value: e.userid
            }))}
          >
          </Select>
        </Form.Item>
      ) : null}

          <Form.Item name="block_status" valuePropName="checked">
            <Checkbox onChange={toggleBlockReason}>Block</Checkbox>
          </Form.Item>

          <Form.Item name="block_reason" label="Block Reason" hidden={!blockReason}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onUpdate: (id, payload) => dispatch(onUpdateCenter(id, payload))
});

Update.propTypes = { config: array, onUpdate: func };

export default connect(null, mapDispatchToProps)(Update);