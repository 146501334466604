import React, { useEffect } from 'react';
import { Image, Space } from 'antd';
import { string } from 'prop-types';
import brandLogo from '../../../assets/images/brand-logo-light-no-tagline.png';
import { MenuOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../../redux/actions/ui';
import { getCurrentPageFromLocation } from '../../../utils/utils';
import { accessRules } from '../../../utils/accessRules';
import { useLocation } from 'react-router-dom';
import { setSteps } from '../../../redux/actions/verificationSteps';
import { getVerificationSteps } from '../../../api/verificationSteps';

const Branding = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    fetchVerificationSteps();
  }, []);

  const fetchVerificationSteps = async () => {
    try {
      const result = await getVerificationSteps();
      const data = result.data?.data;
      dispatch(setSteps(data));
    } catch (error) {
      console.log(error)
    }
  }

  const role = useSelector(({auth})=>auth.role);

  const show = () => {
      dispatch(closeDrawer(true))
  }

  return (
    <Space direction="horizontal">
      {role && accessRules?.[role]?.[getCurrentPageFromLocation(pathname)]?.['searchSidebar'] ?
        <MenuOutlined onClick={show} /> :
        null
      }
      <Image
        src={brandLogo}
        alt="BZ Connects Logo"
        preview={false}
        width="200px"
        style={{ paddingTop: '35px' }}
      />
    </Space>
  )
}

Branding.propTypes = { width: string, height: string };

export default Branding;
