import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, number, string } from 'prop-types';
import { Space, Typography } from 'antd';
import {
  // BellOutlined,
  EditOutlined,
  LogoutOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { onLogout } from '../../../redux/actions/auth';
import { showModal } from '../../../redux/actions/ui';
// import ModalComponent from '../../ui/ModalComponent';

const { Title } = Typography;

const UserControls = ({
  username,
  role,
  free_user,
  company_admin,
  businessId,
  onLogout,
  onShowModal
}) => {
  // const [activeNotification, setActiveNotification] = useState('');
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/change-password">Change Password</Link>
      </Menu.Item>
      {role === 'admin' && !free_user && (<><Menu.Item key="2">
        <Link to="/general-announcements">General Announcements</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/general-industry-news">Industry News</Link>
      </Menu.Item></>)}
      {!free_user && <Menu.Item key="4">
        <Link to="/site-settings">Settings</Link>
      </Menu.Item>}
      {role !== 'admin' ? (
        <React.Fragment>
          {!free_user && <Menu.Item key="5">
            <Link to="/announcements">Announcements</Link>
          </Menu.Item>}
          {company_admin && !free_user && <Menu.Item>
            <Link to="/add-users">Add Users</Link>
          </Menu.Item>}
          <Menu.Item key="6">
            <Link to="/payments">Payments {'&'} Plans</Link>
          </Menu.Item>
          {!free_user && <Menu.Item key="7">
            <Link
              onClick={onShowModal}
              to={`${
                role === 'prime' ? '/primes' : role === 'small-business' ? '/small-business' : 'association'
              }/print-view/${businessId}`}
            >
              Print Profile
            </Link>
          </Menu.Item>}
          <Menu.Item key="8">
            <Link to="/my-business">
              <EditOutlined /> Edit
            </Link>
          </Menu.Item>
        </React.Fragment>
      ) : null}
      <Menu.Item key="9" onClick={() => onLogout()}>
        <LogoutOutlined size="50px" /> Logout
      </Menu.Item>
    </Menu>
  );

  // const viewNotification = (e, item) => {
  //   e && e.stopPropagation();
  //   setActiveNotification(`${item} first Notification list fdas dlfjasld df asldfj lasdf asdf
  //   asldf jlasd fasd fajs dfajsldf`);
  //   onShowModal();
  // }

  // const notifications = () => {
  //   return (
  //     <Menu>
  //       {[1,2,3,4,5].map(item => (<Menu.Item key={item}>
  //         <div
  //           style={{
  //             width: 250,
  //             textOverflow: 'ellipsis',
  //             whiteSpace: 'nowrap',
  //             overflow: 'hidden'
  //           }}
  //           onClick={(e) => viewNotification(e, item)}
  //         >
  //           {item}{' '}first Notification list fdas dlfjasld df asldfj lasdf asdf
  //           asldf jlasd fasd fajs dfajsldf{' '}
  //         </div>
  //       </Menu.Item>))}
  //     </Menu>
  //   );
  // };

  return (
    <Space direction="horizontal">
      {/* {activeNotification && <ModalComponent>
        <div>{activeNotification}</div>
      </ModalComponent>} */}
      <Title level={3} style={{ paddingTop: '20px', color: '#fff' }}>
        {username ? username : ''}
      </Title>
      {/* <Space style={{ paddingTop: '10px' }}>
        <Dropdown overlay={notifications}>
          <BellOutlined  onClick={e => e.stopPropagation()} />
        </Dropdown>
      </Space> */}
      <Space style={{ paddingTop: '10px' }}>
        <Dropdown overlay={menu} trigger={['click']}>
          <SettingOutlined onClick={e => e.stopPropagation()} />
        </Dropdown>
      </Space>
    </Space>
  );
};

const mapStateToProps = state => ({
  username: state.auth?.user?.name,
  businessId: state.auth?.user?.businessId,
  role: state.auth.role,
  free_user: state.auth.free_user,
  company_admin: state.auth.company_admin
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(onLogout()),
  onShowModal: () => dispatch(showModal())
});

UserControls.propTypes = {
  role: string,
  username: string,
  businessId: number,
  onLogout: func,
  onShowModal: func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserControls));
