import React, {useEffect} from 'react';
import { Typography } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { redirect } from '../../redux/actions/redirect';
import { useDispatch } from 'react-redux';

const Thankyou = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(redirect(null));
    setTimeout(()=>{
      history.push('/');
    },3000)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="success-page">
      <Typography.Title level={2}>Thank You!</Typography.Title>
      <LikeOutlined />
      <Typography.Title level={4}>
        Your Subscription has been successfully created. <br />
        You can access priviledged sections now.
      </Typography.Title>
    </div>
  );
};

export default Thankyou;
