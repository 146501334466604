import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import dayjs from 'dayjs';
import { Button, Row, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { exportEvents } from '../../api/rfpRfq';
import { hideLoading, showLoading } from '../../redux/actions/ui';
import { setRfpRfqRefresh } from '../../redux/actions/rfpRfq';
// import DateInput from '../../../../components/common/Form/DateInput';

const Export = ({ onShowLoading, onHideLoading }) => {
  // const [dateRange, setDateRange] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  // const dateSelectHandler = (key, range) => {
  //   setDateRange(range);
  //   setDownloadUrl(null);
  // };

  const getDownload = () => {
    // const range = {
    //   start_date: dateRange['export_events_start'],
    //   end_date: dateRange['export_events_end']
    // };
    const offset = dayjs().utcOffset();

    onShowLoading();
    exportEvents(/* range,  */ offset)
      .then(({ data }) => {
        onHideLoading();
        const blob = new Blob([data], {
          encoding: 'UTF-8',
          type: 'text/csv;charset=UTF-8'
        });
        const blobUrl = window.URL.createObjectURL(blob);
        setDownloadUrl(blobUrl);
      })
      .catch(error => {
        onHideLoading();
        console.log(error);
      });
  };

  return (
    <>
      <Row justify="center">
        <h2>Export RFP/RFQ/RFI/IFB Events</h2>
      </Row>
      <Row justify="center">
        <Space direction="vertical" align="center">
          {/* <DateInput
            keyName="export_events"
            nestedHandler={dateSelectHandler}
            limit={90}
          /> */}
          <Space direction="horizontal">
            {!downloadUrl ? (
              <Button
                type="primary"
                onClick={getDownload}
                // disabled={!dateRange}
              >
                Generate CSV
              </Button>
            ) : (
              <a href={downloadUrl} download="rfp-rfq-events.csv">
                {<DownloadOutlined />} Download
              </a>
            )}
          </Space>
        </Space>
      </Row>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading()),
  onSetRefresh: payload => dispatch(setRfpRfqRefresh(payload))
});

Export.propTypes = {
  onSetRefresh: func,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(null, mapDispatchToProps)(Export);
