import React, { useEffect, useState } from 'react';
import { array, bool, func, object } from 'prop-types';
import { Button, Form, Space } from 'antd';
import { handleNotifications } from '../../../utils/notifications';
import FormElement from './FormElement';
import dayjs from 'dayjs';
import moment from 'moment';

const PluggableForm = ({
  config,
  formConfig,
  editMode,
  valueProps,
  microApiHandler,
  onFinish,
  onCancel,
  reset
}) => {
  const [submitValues, setSubmitValues] = useState(null);
  const [details, setDetails] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    if (editMode) {
      setDetails({ ...valueProps });
    }
    // eslint-disable-next-line
  }, [valueProps]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [reset]);

  const parentFormHandler = (key, value) => {
    form.setFieldsValue({ [key]: value });
    form.validateFields([key]);
    setSubmitValues({ ...submitValues, [key]: value });
  };

  const onSubmitFormGood = values => {
    if (editMode) {
      onFinish(values);
    } else {
      onFinish(submitValues);
    }
  };

  const onSubmitFormBad = errors => {
    console.log('Pluggable Form | Form Bad', errors);
    handleNotifications(
      'warning',
      'Warning',
      'Some fields require your attention.'
    );
  };

  const formElements = [];
  config.forEach((item, index) => {
    formElements.push(
      <Form.Item
        key={`${item.key}_${index}`}
        name={item.key}
        label={item.label}
        rules={item.rules}
        hasFeedback
      >
        <FormElement
          config={item}
          editMode={editMode}
          valueProps={editMode ? details?.[item.key] : ''}
          microApiHandler={item.useMicroApi ? microApiHandler : null}
          parentFormHandler={parentFormHandler}
        />
      </Form.Item>
    );
  });

  return !editMode || (editMode && details) ? (
    <Form
      form={form}
      layout={formConfig.layout}
      name={formConfig.name}
      onFinish={onSubmitFormGood}
      onFinishFailed={onSubmitFormBad}
      labelCol={
        formConfig && formConfig.labelCol
          ? formConfig.labelCol
          : { md: { offset: 4 }, sm: { offset: 0 } }
      }
      wrapperCol={
        formConfig && formConfig.wrapperCol
          ? formConfig.wrapperCol
          : { md: { offset: 4 }, sm: { offset: 0 } }
      }
      initialValues={{ ...details }}
    >
      {formElements}
      <Form.Item>
        <Space direction="horizontal">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          {onCancel ? (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          ) : null}
        </Space>
      </Form.Item>
      {details && details['created_on'] && (
        <Form.Item>
          <>
            <strong>Created On:</strong>{' '}
            {moment
              .utc(details['created_on'])
              .utcOffset(dayjs().utcOffset())
              .format('MM/DD/YYYY')}
          </>
        </Form.Item>
      )}
      {details && details['updated_on'] && (
        <Form.Item>
          <>
            <strong>Updated On:</strong>{' '}
            {moment
              .utc(details['updated_on'])
              .utcOffset(dayjs().utcOffset())
              .format('MM/DD/YYYY')}
          </>
        </Form.Item>
      )}
    </Form>
  ) : null;
};

PluggableForm.propTypes = {
  config: array,
  formConfig: object,
  editMode: bool,
  valueProps: object,
  microApiHandler: object,
  onFinish: func,
  onFail: func,
  onCancel: func
};

export default PluggableForm;
