import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Image,
  Form,
  Input,
  Row,
  Space,
  Typography
} from 'antd';

import { getPrime } from '../../api/primes';
import { handleNotifications } from '../../utils/notifications';
import dayjs from 'dayjs';
import brandLogo from '../../assets/images/brand-logo-light-no-tagline1.png';
import { getcurrentDateTime } from '../../utils/dateTimeHelper';
import { PrinterFilled } from '@ant-design/icons';
import UpcomingProjects from './upcomingProjects';

const { Title } = Typography;
const { TextArea } = Input;

const styles = {
  title: {
    padding: '5px',
    border: '0.1px solid gray',
    // height: '35px'
  },
  headerStyle: {
    border: '1px solid gray',
    marginBottom: '0px',
    padding: '7px',
    backgroundColor: '#E8E8E8',
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px'
  },
  company_name: {marginTop: 0, fontWeight: 500}
}

const PrimesPrintView = () => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const role = useSelector(({ auth }) => auth.role);
  const { primeId } = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    if (primeId) {
      setLoading(true);
      getPrime(primeId)
        .then(({ data }) => {
          if (data['success']) {
            setDetails(data['data']);
            getFormValuesFromDetails(data['data']);
          } else {
            setDetails(null);
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          setLoading(false);
        })
        .catch(error => {
          setDetails(null);
          setLoading(false);
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primeId]);

  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: '',
    phone: '',
    company_website: '',
    unspsc_codes: [],
    address: [],
    company_contacts: [],
    company_profile: '',
    specialization: [],
    certification: [],
    soq: null,
    linkedin_profile: '',
    updated_on: ''
  });

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      address,
      certificates,
      company_contacts: companyContacts,
      company_name: companyName,
      company_profile: companyProfile,
      company_specialization: companySpecialization,
      linkedin_profile: linkedinProfile,
      logo_url: logoUrl,
      logo_name: logoName,
      phone,
      unspsc_codes,
      soq_url: soqUrl,
      soq_name: soqName,
      website_info: websiteInfo,
      updated_on: updatedOn,
      projects
    } = details;

    if (logoUrl) {
      newFormValues['logo'] = {
        url: logoUrl,
        name: logoName
      };
    }
    if (websiteInfo) {
      newFormValues['company_website'] = websiteInfo;
    }
    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['street'],
        state: item['state'],
        county: item['county'],
        city: item['city']
      }));
    }
    if (unspsc_codes?.length) {
      newFormValues['unspsc_codes'] = unspsc_codes.map(item => ({
        id: item['id'],
        codes: item['unspsc_codes'],
        description: item['description']
      }));
    }
    if (companyContacts?.length) {
      newFormValues['company_contacts'] = companyContacts.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }

    if (companySpecialization?.length) {
      newFormValues['specialization'] = [...companySpecialization];
    }
    if (certificates) {
      newFormValues['certification'] = certificates.map(item => ({
        id: item['id'],
        certification_name: item['certification'],
        certification_agency: item['certification_agency'],
        certification_proof: {
          name: item['proof_name'],
          url: item['proof_url']
        },
        expiration_date: item['expiration_date']
      }));
    }
    if (soqUrl) {
      newFormValues['soq'] = { url: soqUrl, name: soqName };
    }

    setFormValues({
      ...newFormValues,
      companyName,
      phone,
      companyProfile,
      linkedinProfile,
      updatedOn,
      projects
    });
  };
  return (
    <Card loading={loading}>
      {details ? (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Title level={3} style={{ color: '#4A71A1', fontWeight: 'bold' }}>
                  BZ One Company Profile
                </Title>
                <Title level={2} style={styles.company_name}>
                  {formValues.companyName}
                </Title>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Space direction="vertical">
                  <Button
                    type="primary"
                    htmlType="button"
                    size="small"
                    icon={<PrinterFilled />}
                    onClick={() => window.print()}
                  >
                    Print
                  </Button>
                  <Image
                    src={
                      formValues['logo']?.['url']
                        ? formValues['logo']['url']
                        : ''
                    }
                    preview={false}
                    width="100px"
                    height="100px"
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Business Main Address</Title>
                <Title level={5} style={{ marginTop: 0, fontSize: '12px' }}>
                  (Street Address, City)
                </Title>
              </Col>
              <Col span={18} style={styles.title}>
                <ul>
                  {formValues?.['address']?.map((item, i) => (
                    <li key={i}>{`${item.street || ''} ${item.state || ''} ${
                      item.zipcode || ''
                    }`}</li>
                  ))}
                </ul>
              </Col>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Business Phone</Title>
              </Col>
              <Col span={6} style={styles.title}>
                {role === 'small-business' ? '**********' : (formValues['phone'] || '')}
              </Col>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Website</Title>
              </Col>
              <Col span={6} style={styles.title}>
                {formValues['company_website'] || ''}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="ant-row" style={styles.headerStyle}>
                  Contact:
                </div>
                <div>
                  {formValues?.['company_contacts']?.map((item, i) => (
                    <Row key={i}>
                      <Col span={6} style={styles.headerStyle}>
                        Key Contact Name:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {item.name}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Title:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {item.title}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Contact Phone:
                      </Col>
                      <Col span={6} style={styles.title}>
                        {item.phone}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Email:
                      </Col>
                      <Col span={6} style={styles.title}>
                        {item.email}
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ border: '0.1px solid' }}>
                {/* UNSPSC Code */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  UNSPSC Codes:
                </div>
                <div>
                  <ul>
                    {formValues?.['unspsc_codes']?.map((item) => (
                      <li key={item.id}>{item.description}</li>
                    ))}
                  </ul>
                </div>
              </Col>

              <Col span={24} style={{ border: '0.1px solid' }}>
                {/* Company Specializations */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Company Specialization:
                </div>
                <div>
                  <ul>
                    {formValues?.['specialization']?.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
            
            {/* Upcoming Projects */}
            <Row>
              <Col>
                <Form
                  form={form}
                  name="add-small-business"
                  layout="horizontal"
                  labelCol={{ /* offset: 4, */ span: 4 }}
                  wrapperCol={{ /* offset: 4,  */ span: 20 }}
                  labelAlign="left"
                  initialValues={{ ...formValues }}
                >
                  <Form.List name="projects">
                    {fields => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            wrapperCol={{ md: { offset: 4, span: 20 }, sm: { span: 24 }}}
                            key={field.key}
                            style={{
                              border: '1px solid gray',
                              marginBottom: '0px',
                              paddingLeft: '7px',
                              backgroundColor: '#E8E8E8'
                            }}
                            label={index === 0 ? 'Project Details' : ''}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={['onChange', 'onBlur']}
                              name={[field.name, 'projects']}
                              style={{ marginLeft: 7 }}
                              noStyle
                            >
                              <UpcomingProjects
                                index={index}
                                valueProps={
                                  formValues['projects']
                                    ? formValues['projects'][index]
                                    : null
                                }
                                disabled={true}
                                isPrint={true}
                              />
                            </Form.Item>
                          </Form.Item>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {/* Brief Company Profile */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Brief Company Profile: (max 300 words)
                </div>
                <TextArea
                  rows={3}
                  disabled
                  defaultValue={formValues.companyProfile}
                />
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                Printed On:{' '}
                {dayjs(getcurrentDateTime()).format('MM/DD/YYYY hh:mm A').toString()}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Image
                  src={brandLogo}
                  preview={false}
                  width="150px"
                  height="70px"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

export default PrimesPrintView;
