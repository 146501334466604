import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  Typography,
  Radio
} from 'antd';

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { addField } from '../../api/smallBusinessFields';
import { getSmallBusiness } from '../../api/smallBusiness';
import { hideModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';
import Address from '../common/Form/FormInputs/Address';
import Dropdowns from '../common/Form/FormInputs/Dropdowns';
import dayjs from 'dayjs';
import moment from 'moment';
import RadioComponent from '../common/Form/FormInputs/Radio';

const { Title, Link: TextLink, Text } = Typography;
const { TextArea } = Input;

const SmallBusinessView = ({ onCancel, companyVerificationSteps }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const { smallBusinessId } = useParams();

  useEffect(() => {
    if (smallBusinessId) {
      setLoading(true);
      getSmallBusiness(smallBusinessId)
        .then(({ data }) => {
          if (data['success']) {
            setDetails(data['data']);
            getFormValuesFromDetails(data['data']);
          } else {
            setDetails(null);
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          setLoading(false);
        })
        .catch(error => {
          setDetails(null);
          setLoading(false);
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallBusinessId]);

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: '',
    phone: '',
    company_website: '',
    address: [],
    company_contacts: [],
    company_profile: '',
    naics_codes: [],
    unspsc_codes: [],
    service: [],
    specialization: [],
    agencies: [],
    revenue: null,
    no_of_employees: null,
    certification: [],
    due_diligence: null,
    soq: null,
    linkedin_profile: '',
    owner_demographic: '',
    liability_insurance: null,
    compensation_insurance: null,
    disability_insurance: null,
    updated_on: '',
    verification_step_id: null,
    si: null,
  });

  const dueDiligenceOptions = [
    { VERIFIED_CLEAR: 'Verified - Clear' },
    { VERIFIED_RECORD_FOUND: 'Verified - Record Found' },
    { NOT_VERIFIED: 'Not Verified' }
  ];

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      logo_url: logoUrl,
      logo_name: logoName,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      address,
      company_contacts: companyContacts,
      company_profile: companyProfile,
      unspsc_codes,
      naics_codes: naicsCodes,
      company_services: companyServices,
      industry_specialization: industrySpecialization,
      agencies,
      previous_year_revenue: previousYearRevenue,
      no_of_employees: noOfEmployees,
      certification,
      soq_name: soqName,
      soq_url: soqUrl,
      linkedin_profile: linkedinProfile,
      owner_demographic: ownerDemographic,
      liability_insurance_proof_name: liabilityInsuranceProofName,
      liability_insurance_proof_url: liabilityInsuranceProofUrl,
      worker_compensation_insurance_proof_name:
        workerCompensationInsuranceProofName,
      worker_compensation_insurance_proof_url:
        workerCompensationInsuranceProofUrl,
      disability_insurance_proof_name: disabilityInsuranceProofName,
      disability_insurance_proof_url: disabilityInsuranceProofUrl,
      updated_on: updatedOn,
      due_diligence,
      verification_step_id,
      si_name: siName,
      si_url: siUrl
    } = details;
    if (logoUrl) {
      newFormValues['logo'] = {
        url: logoUrl,
        name: logoName
      };
    }
    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['address'],
        state: item['state'],
        county: item['county'],
        zipcode: item['zip_code'],
        city: item['city']
      }));
    }
    if (unspsc_codes?.length) {
      newFormValues['unspsc_codes'] = unspsc_codes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyContacts?.length) {
      newFormValues['company_contacts'] = companyContacts.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }
    if (naicsCodes?.length) {
      newFormValues['naics_codes'] = naicsCodes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyServices?.length) {
      newFormValues['service'] = [...companyServices];
    }
    if (industrySpecialization?.length) {
      newFormValues['specialization'] = [...industrySpecialization];
    }
    if (previousYearRevenue) {
      newFormValues['revenue'] = {
        id: previousYearRevenue['id'],
        first_year_revenue: previousYearRevenue['first_year_revenue'],
        second_year_revenue: previousYearRevenue['second_year_revenue'],
        third_year_revenue: previousYearRevenue['third_year_revenue']
      };
    }
    if (noOfEmployees) {
      newFormValues['no_of_employees'] = noOfEmployees;
    }
    if (certification) {
      newFormValues['certification'] = certification.map(item => ({
        id: item['id'],
        certifications_name: item['certification'],
        certification_agency: item['certification_agency'],
        certification_no: item['certification_no'],
        certification_proof: {
          name: item['proof_name'],
          url: item['proof_url']
        },
        expiration_date: item['expiration_date']
      }));
    }
    if (soqUrl) {
      newFormValues['soq'] = { url: soqUrl, name: soqName };
    }
    if (liabilityInsuranceProofUrl) {
      newFormValues['liability_insurance'] = {
        url: liabilityInsuranceProofUrl,
        name: liabilityInsuranceProofName
      };
    }
    if (workerCompensationInsuranceProofUrl) {
      newFormValues['compensation_insurance'] = {
        url: workerCompensationInsuranceProofUrl,
        name: workerCompensationInsuranceProofName
      };
    }
    if (disabilityInsuranceProofUrl) {
      newFormValues['disability_insurance'] = {
        url: disabilityInsuranceProofUrl,
        name: disabilityInsuranceProofName
      };
    }
    setFormValues({
      ...newFormValues,
      company_name: companyName,
      phone,
      due_diligence,
      company_website: companyWebsite,
      company_profile: companyProfile,
      agencies: agencies || [],
      linkedin_profile: linkedinProfile,
      owner_demographic: ownerDemographic,
      updated_on: updatedOn,
      verification_step_id,
    });
  };
  return (
    <Card loading={loading}>
      {details ? (
        <Row align="middle" justify="center" className="small-business-form">
          <Col span={24}>
            <Title level={2} className="heading-title">
              {formValues.company_name || "Small Business Profile"}
            </Title>
            <Form
              form={form}
              name="add-small-business"
              layout="horizontal"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              labelAlign="left"
              initialValues={{ ...formValues }}
            >
              {/* Company Logo */}
              <Form.Item
                key="logo"
                name="logo"
                hasFeedback
                style={{ textAlign: 'right' }}
                wrapperCol={{ md: { offset: 4 }, sm: { offset: 0 } }}
              >
                <Image
                  src={
                    formValues['logo']?.['url'] ? formValues['logo']['url'] : ''
                  }
                  preview={false}
                  width="120px"
                  height="120px"
                />
              </Form.Item>

              {/* Business Name */}
              <Form.Item
                key="company_name"
                name="company_name"
                label="Business Name"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
              >
                <Input
                  name="company_name"
                  placeholder="Business Name"
                  type="text"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  disabled
                />
              </Form.Item>

              {/* Business Phone */}
              <Form.Item
                key="phone"
                name="phone"
                label="Business Phone"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
              >
                <Input
                  name="phone"
                  placeholder="Business Phone (10-digit)"
                  type="tel"
                  maxLength={10}
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  disabled
                />
              </Form.Item>

              {/* Website */}
              <Form.Item
                key="company_website"
                name="company_website"
                label="Website"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
              >
                <Input
                  name="company_website"
                  placeholder="Company Website"
                  type="url"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  disabled
                />
              </Form.Item>

              {/* Business Addresses */}
              <Form.List name="address">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={field.key}
                        label={index === 0 ? 'Business Address' : ''}
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                      >
                        <Address
                          keyName="address"
                          microApi={{ add: addField }}
                          hasCity={true}
                          hasZip={true}
                          disabled={true}
                          viewMode={true}
                          valueProps={formValues['address'][index]}
                        />
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form.List>

              {/* Company Contacts */}
              <Form.List name="company_contacts">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={`${field.key}_${index}`}
                        label={index === 0 ? 'Company Contacts' : ''}
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          key={`name_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Name"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'title']}
                          key={`title_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Title"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'phone']}
                          key={`phone_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Phone"
                            type="tel"
                            maxLength={10}
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          key={`email_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Email (Eg. john@domain.com)"
                            type="email"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form.List>

              {/* UNSPSC Code */}
              <Form.List name="unspsc_codes">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        key={`${field.key}_${index}`}
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'UNSPSC Codes' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'codes']}
                          key={`codes_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Code"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          key={`description_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Description"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form.List>

              {/* Brief Company Profile */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Brief Company Profile: (max 300 words)
                </div>
              </React.Fragment>
              <TextArea
                name="company_profile"
                placeholder="Brief Company Profile (max 300 words)"
                rows={3}
                maxLength={300}
                defaultValue={formValues['company_profile']}
                disabled
              />

              {/* NAICS Code */}
              <Form.List name="naics_codes">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        key={`${field.key}_${index}`}
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'NAICS Codes' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'codes']}
                          key={`codes_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="NAICS Code"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          key={`description_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="NAICS Description"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form.List>

              {/* Company Specializations */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Company Specialization:
                </div>
              </React.Fragment>
              <Dropdowns
                keyName="specializations"
                label="specializations"
                multiSelect={true}
                valueProps={formValues['specialization']}
                disabled={true}
              />

              {/* Agency Experience */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Agency Experience:
                </div>
              </React.Fragment>
              <Dropdowns
                keyName="agencies"
                label="agencies"
                multiSelect={true}
                valueProps={formValues['agencies']}
                disabled={true}
              />
              {/*Due Deligence */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Due Diligence:
                </div>
              </React.Fragment>

              {/*Due Deligence */}
              <Form.Item
                key="due_diligence"
                name="due_diligence"
                label="Structural Integrity"
                hasFeedback
                labelCol={6}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <Text
                  style={{ marginLeft: '-7px', width: '101%' }}
                >
                  {dueDiligenceOptions.map(item => {
                    for (const [key, value] of Object.entries(item)) {
                      return key === formValues['due_diligence'] && value
                    }
                  })}
                </Text>
              </Form.Item>

              {/* Previous Annual Revenue */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Previous Years Revenue:
                </div>
              </React.Fragment>
              <Form.Item
                key="first_year_revenue"
                name="first_year_revenue"
                label="Last Year"
                style={{
                  border: '1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px'
                }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <RadioComponent
                  keyName="first_year_revenue"
                  valueProps={
                    formValues['revenue']?.['first_year_revenue']
                      ? formValues['revenue']?.['first_year_revenue']
                      : []
                  }
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                key="second_year_revenue"
                name="second_year_revenue"
                label="2 Years Ago"
                style={{
                  border: '1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px'
                }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <RadioComponent
                  keyName="second_year_revenue"
                  valueProps={
                    formValues['revenue']?.['second_year_revenue']
                      ? formValues['revenue']['second_year_revenue']
                      : []
                  }
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                key="third_year_revenue"
                name="third_year_revenue"
                label="3 Years Ago"
                style={{
                  border: '1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px'
                }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <RadioComponent
                  keyName="third_year_revenue"
                  valueProps={
                    formValues['revenue']?.['third_year_revenue']
                      ? formValues['revenue']?.['third_year_revenue']
                      : []
                  }
                  disabled={true}
                />
              </Form.Item>

              {/* Number of Employees */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Number of Employees:
                </div>
              </React.Fragment>
              <RadioComponent
                keyName="no_of_employees"
                valueProps={formValues['no_of_employees']}
                disabled={true}
              />

              {/* Certifications */}
              <Form.List name="certification">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={`${field.key}_${index}`}
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={
                          index === 0 ? (
                            <span>
                              Certifications{' '}
                              {false ? (
                                <CheckCircleOutlined
                                  style={{ color: 'green' }}
                                />
                              ) : (
                                <ExclamationCircleOutlined
                                  style={{ color: 'orange' }}
                                />
                              )}
                            </span>
                          ) : (
                            ''
                          )
                        }
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'certification_name']}
                          key={`certification_name_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Dropdowns
                            keyName="certifications"
                            label="Certification"
                            multiSelect={false}
                            valueProps={
                              formValues['certification'][index]?.[
                                'certifications_name'
                              ]
                            }
                            disabled={true}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'certification_agency']}
                          key={`certification_agency_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input placeholder="Certification Agency" disabled />
                        </Form.Item>
                        {/* <Form.Item
                          {...field}
                          name={[field.name, 'certification_no']}
                          key={`certification_no_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Certification Number"
                            disabled
                          />
                        </Form.Item> */}
                        <Form.Item
                          {...field}
                          name={[field.name, 'expiration_date']}
                          key={`expiration_date_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Expiration Date (MM/YYYY)"
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'certification_proof']}
                          key={`certification_proof_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <div>
                            <TextLink
                              href={
                                formValues['certification'][index]?.[
                                  'certification_proof'
                                ]['url']
                              }
                              target="_blank"
                              download
                            >
                              {
                                formValues['certification'][index]?.[
                                  'certification_proof'
                                ]['name']
                              }
                            </TextLink>
                          </div>
                        </Form.Item>
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form.List>

              {/* Additional Information */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    fontWeight: 600
                  }}
                >
                  Additional Information:
                </div>
              </React.Fragment>

              {/* Statement of Qualification */}
              <Form.Item
                key="soq"
                name="soq"
                label="Statement of Qualification"
                hasFeedback
                labelCol={6}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <TextLink
                  style={{ marginLeft: '-7px', width: '101%' }}
                  href={formValues['soq']?.['url']}
                  target="_blank"
                  download
                >
                  {formValues['soq']?.['name']}
                </TextLink>
              </Form.Item>

              {/*  Company LinkedIn Profile*/}
              <Form.Item
                key="linkedin_profile"
                name="linkedin_profile"
                label="Company LinkedIn Profile"
                labelCol={6}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <Input
                  name="linkedin_profile"
                  placeholder="LinkedIn Profile"
                  style={{ marginLeft: '-7px', width: '101%' }}
                  type="text"
                  disabled
                />
              </Form.Item>

              {/* Optional Information */}
              <div
                className="ant-row ant-form-item"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  padding: '7px',
                  backgroundColor: 'lightgray',
                  color: 'rgba(0, 0, 0, 0.85)',
                  fontSize: '14px',
                  fontWeight: 600
                }}
              >
                Optional Information:
              </div>
              {/* Owner Demographic */}
              <Form.Item
                key="owner_demographic"
                name="owner_demographic"
                label="Owner Demographic"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <Dropdowns
                  keyName="demographics"
                  label="Owner Demographic"
                  multiSelect={false}
                  valueProps={formValues['owner_demographic']}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                key="liability_insurance"
                name="liability_insurance"
                label="Liability Insurance"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <TextLink
                  href={formValues['liability_insurance']?.['url']}
                  target="_blank"
                  download
                >
                  {formValues['liability_insurance']?.['name']}
                </TextLink>
              </Form.Item>
              <Form.Item
                key="compensation_insurance"
                name="compensation_insurance"
                label="Compensation Insurance"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <TextLink
                  href={formValues['compensation_insurance']?.['url']}
                  target="_blank"
                  download
                >
                  {formValues['compensation_insurance']?.['name']}
                </TextLink>
              </Form.Item>
              <Form.Item
                key="disability_insurance"
                name="disability_insurance"
                label="Disablity Insurance"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <TextLink
                  href={formValues['disability_insurance']?.['url']}
                  target="_blank"
                  download
                >
                  {formValues['disability_insurance']?.['name']}
                </TextLink>
              </Form.Item>

              <Form.Item
                key="verification_step_id"
                name="verification_step_id"
                label="Verification Step"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <Text
                >
                  {formValues['verification_step_id'] ? companyVerificationSteps.find(o => o.id === formValues['verification_step_id'])?.name : "Not Verified"}
                  <br />
                  <InfoCircleOutlined title="description" /> {companyVerificationSteps.find(o => o.id === formValues['verification_step_id'])?.description}
                </Text>
              </Form.Item>

              {/* Profile Last Updated */}
              <strong>Profile Created : </strong>
              <Text>
                {moment
                  .utc(details['created_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text>
              <br />
              <strong>Profile Last Updated : </strong>
              <Text>
                {moment
                  .utc(details['updated_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text>
              {/* Action Buttons */}
              <Form.Item
                wrapperCol={{ md: { offset: 6, span: 12 }, sm: { span: 24 } }}
                style={{ textAlign: 'center' }}
              >
                <Space direction="horizontal" align="center">
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    Close
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

SmallBusinessView.propTypes = {
  onCancel: func
};

const mapStateToProps = state => ({
  companyVerificationSteps: state.verificationSteps.verificationSteps,
});

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(SmallBusinessView);
