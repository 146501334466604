import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { string, boolean } from 'prop-types';

import { getMenuItems } from '../../configs/menu/menuBarItems';
import NavMenu from './NavMenu';

const MenuBar = ({ role, free_user }) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (role) {
      const menuItems = getMenuItems(role, free_user);
      setMenu(menuItems);
    }
  }, [role]);

  return <NavMenu items={menu ? menu : []} />;
};

const mapStateToProps = state => ({
  role: state.auth.role,
  free_user: state.auth.free_user
});

MenuBar.propTypes = {
  role: string,
  free_user: boolean
};

export default connect(mapStateToProps)(MenuBar);
