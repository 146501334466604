import connection from '../utils/api';
import { primes } from '../utils/apiStrings.json';

/* Add API for field */
export const addField = (key, payload) =>
  connection.post(`primes/${primes[key]}`, payload);

/* Update API for field */
export const updateField = (key, id, payload) =>
  connection.put(`primes/${primes[key]}/${id}`, payload);

/* Delete API for field */
export const deleteField = (key, id) =>
  connection.delete(`primes/${primes[key]}/${id}`);