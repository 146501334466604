import React, { useState } from 'react';
import { func, object, string } from 'prop-types';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { fileFormats } from '../../../../utils/constants';

const FileUpload = ({ keyName, label, styleObj, parentHandler, disabled }) => {
  const [fileList, setFileList] = useState([]);

  const fileHandler = file => {
    parentHandler(keyName, file ? { [keyName]: file } : null);
  };

  const onRemove = file => {
    const originalFileList = [...fileList];
    const indexOfFileToRemove = fileList.indexOf(file);
    let newFileList = [];
    if (indexOfFileToRemove > -1) {
      newFileList = originalFileList.slice();
      newFileList.splice(indexOfFileToRemove, 1);
    }
    setFileList([...newFileList]);
    fileHandler(null);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      // console.log(file);
      fileHandler(file);
      onSuccess('ok');
    }, 0);
  };

  return (
    <Upload
      accept={fileFormats[keyName]}
      customRequest={dummyRequest}
      onRemove={onRemove}
    >
      <Button disabled={disabled} icon={<UploadOutlined />} style={styleObj}>
        {label ? label : 'Upload'}
      </Button>
    </Upload>
  );
};

FileUpload.propTypes = {
  keyName: string,
  label: string,
  styleObj: object,
  parentHandler: func
};

export default FileUpload;
