import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ProjectContact from './projectContact';
import { handleNotifications } from '../../utils/notifications';
import { addField, updateField } from '../../api/primesFields';
import { objectToFormData } from '../../utils/utils';

const { Option } = Select;

const styles = {
  border: {
    borderBottom: '1px solid gray',
    borderLeft: '1px solid gray',
    marginBottom: '0px',
    backgroundColor: 'white'
  }
};

const UpcomingProjects = ({
  disabled,
  valueProps,
  parentHandler,
  index,
  isPrint
}) => {
  const [projectDetails, setProjectDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (valueProps) {
      setProjectDetails({ ...valueProps });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (value, key) => {
    setProjectDetails(prev => ({ ...prev, [key]: value }));
  };

  const handleContact = (value, index) => {
    let arr = projectDetails['contacts'] || [];
    arr[index] = value;
    setProjectDetails(prev => ({ ...prev, contacts: arr }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let {
      scope_of_project,
      specialization_requested,
      agencies,
      type_of_business,
      id
    } = projectDetails;
    let contacts = projectDetails['contacts'] ? projectDetails['contacts'] : [];
    let payload = {
      scope_of_project,
      specialization_requested,
      agencies,
      type_of_business,
      contacts
    };
    if (payload['contacts']) {
      payload['contacts'] = JSON.stringify(
        payload['contacts'].map(item => item.id || item)
      );
    }
    payload['type_of_business'] = JSON.stringify(payload['type_of_business']);

    setLoading(true);
    try {
      let res;
      if (id) {
        res = await updateField('project', id, objectToFormData(payload));
      } else {
        res = await addField('project', objectToFormData(payload));
      }
      if (res.data && res.data.success) {
        handleNotifications(
          'success',
          'success',
          `Project successfully ${id ? 'updated' : 'added'}.`
        );
        parentHandler &&
          parentHandler('projects', res.data.data?.id || id, index);
      } else {
        handleNotifications('error', 'error', 'Project error occured !!');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleNotifications('error', 'error', 'Project error occured !!');
    }
  };

  return (
    <React.Fragment>
      <Input
        name="scope_of_project"
        placeholder="Scope of Project"
        type="text"
        style={styles.border}
        onChange={e => {
          e.persist();
          handleChange(e.target.value, 'scope_of_project');
        }}
        disabled={disabled}
        defaultValue={valueProps?.['scope_of_project']}
      />
      <Input
        // name="street_address"
        placeholder="Specialization Requested"
        type="text"
        style={styles.border}
        onChange={e => {
          e.persist();
          handleChange(e.target.value, 'specialization_requested');
        }}
        disabled={disabled}
        defaultValue={valueProps?.['specialization_requested']}
      />
      <Input
        // name="street_address"
        placeholder="Agency Experience Seeking"
        type="text"
        style={styles.border}
        onChange={e => {
          e.persist();
          handleChange(e.target.value, 'agencies');
        }}
        disabled={disabled}
        defaultValue={valueProps?.['agencies']}
      />
      <Select
        placeholder="Type of Business"
        style={{
          borderBottom: '1px solid gray',
          borderLeft: '1px solid gray',
          marginBottom: '0px'
        }}
        onChange={value => {
          handleChange(value, 'type_of_business');
        }}
        mode="multiple"
        disabled={disabled}
        defaultValue={valueProps?.['type_of_business']}
      >
        <Option key={-1} value="" disabled>
          Select Business Type
        </Option>
        {['SBE', 'DBE', 'DVBE'].map((item, index) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))}
      </Select>

      {/* Causing form as a descendent of form error */}
      <Form
        form={form}
        name="add-small-business"
        layout="horizontal"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="left"
        // onFinish={submit}
        // onFinishFailed={submitFail}
        initialValues={{ ...valueProps }}
      >
        <Form.List
          name="contacts"
          // rules={[
          //   {
          //     validator: async (_, project) => {
          //       if (!address || address.length < 1) {
          //         return Promise.reject(
          //           new Error('At least 1 project is required.')
          //         );
          //       }
          //     }
          //   }
          // ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  wrapperCol={{ offset: index === 0 ? 2 : 4, span: 20 }}
                  key={field.key}
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    paddingLeft: '7px',
                    backgroundColor: `${isPrint ? '#E8E8E8' : 'lightgray'}`
                  }}
                  label={index === 0 ? 'Contact Info' : ''}
                >
                  <ProjectContact
                    parentHandler={handleContact}
                    index={index}
                    valueProps={
                      valueProps 
                      ? (valueProps['contacts'] ? valueProps['contacts'][index]: null) 
                      : null
                    }
                    disabled={disabled}
                  />
                  {!disabled ? (
                    <Button
                      type="warning"
                      style={{
                        marginTop: '0px',
                        backgroundColor: '#D26A31',
                        color: 'white',
                        float: 'right'
                      }}
                      onClick={() => {
                        // formValues['company_contacts'].splice(index, 1);
                        return remove(field.name);
                      }}
                    >
                      Remove
                    </Button>
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item
                style={{
                  border: '1px dashed gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  float: 'left'
                }}
              >
                {!disabled && fields.length < 5 ? (
                  <Button
                    type="primary"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Contacts
                  </Button>
                ) : null}

                {!disabled ? <Form.ErrorList errors={errors} /> : null}
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
      {!disabled ? (
        <Button
          type="warning"
          style={{
            float: 'right',
            marginRight: '0px',
            marginTop: '0px',
            backgroundColor: '#82CF8F',
            color: 'white'
          }}
          disabled={disabled || loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      ) : null}
    </React.Fragment>
  );
};

export default UpcomingProjects;
