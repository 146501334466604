import {
  importEvents,
  addEvent,
  deleteEvent,
  updateEvent
} from '../../api/rfpRfq';
import { handleNotifications } from '../../utils/notifications';
import { RFP_RFQ_REFRESH, DELETE_RFPRFQ_SUCCESS, IMPORT_RFPRFQ_SUCCESS } from '../actionTypes';
import { redirect } from './redirect';
import { hideLoading, hideModal, showLoading } from './ui';

export const setRfpRfqRefresh = payload => ({ type: RFP_RFQ_REFRESH, payload });

export const deleteRfprfqSuccess = () => ({
  type: DELETE_RFPRFQ_SUCCESS
});

export const successImport = () => ({
  type: IMPORT_RFPRFQ_SUCCESS
});

export const onAddEvent = payload => dispatch => {
  dispatch(showLoading());
  addEvent(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/rfp-rfq'));
        dispatch(setRfpRfqRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateEvent = (eventId, payload) => dispatch => {
  dispatch(showLoading());
  updateEvent(eventId, payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        handleNotifications('success', 'Success', data.message);
        dispatch(redirect('/rfp-rfq'));
        dispatch(setRfpRfqRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteEvent = eventId => dispatch => {
  dispatch(showLoading());
  deleteEvent(eventId)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        handleNotifications('success', 'Success', data.message);
        dispatch(redirect('/rfp-rfq'));
        dispatch(setRfpRfqRefresh(true));
        dispatch(deleteRfprfqSuccess());
      } else {
        dispatch(hideLoading());
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onImportEvents = importPayload => dispatch => {
  dispatch(showLoading());
  importEvents(importPayload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/rfp-rfq'));
        dispatch(setRfpRfqRefresh(true));
        dispatch(successImport());
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};
