import React, { useEffect, useState } from 'react';
import { any, func, string } from 'prop-types';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const { RangePicker } = TimePicker;

const TimeInput = ({ name, keyName, valueProps, timeMode, nestedHandler }) => {
  const [timeValue, setTimeValue] = useState(null);
  const [mode, setMode] = useState(null);

  // useEffect(() => {
  //   console.log('value', value);
  //   const newValue = value ? value.format('hh:mma') : null;
  //   // setTimeValue(newValue);
  // }, [value]);

  useEffect(() => {
    if (timeMode) {
      setMode(timeMode);
    }
  }, [timeMode]);

  useEffect(() => {
    console.log('timeinputprop', valueProps);
    setTimeValue(valueProps)
  }, [valueProps]);

  const onSelectTime = time => {
    let selectedTime = timeValue;
    if (timeMode === 'range') {
      selectedTime = {
        [`${keyName}_start`]: time ? time[0].format('hh:mm a') : null,
        [`${keyName}_end`]: time ? time[1].format('hh:mm a') : null,
        time_offset: dayjs().utcOffset()
      };
    } else {
      selectedTime = {
        [keyName]: time ? time.format('hh:mm a') : null,
        time_offset: dayjs().utcOffset()
      };
    }
    setTimeValue(time);
    nestedHandler(keyName, selectedTime);
  };

  return (
    <>
      {mode ? (
        mode === 'single' ? (
          <TimePicker
            use12Hours
            format="hh:mm a"
            value={timeValue}
            onChange={onSelectTime}
          />
        ) : mode === 'range' ? (
          valueProps === '' ? 
          <RangePicker use12Hours format="hh:mm a" onChange={onSelectTime} /> :
          <RangePicker use12Hours format="hh:mm a" value={valueProps} onChange={onSelectTime} />
        ) : null
      ) : null}
    </>
  );
};

TimeInput.propTypes = {
  name: string,
  valueProps: any,
  timeMode: string,
  keyName: string,
  nestedHandler: func
};

export default TimeInput;
