import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { addField } from '../../api/primesFields';
import { hideModal } from '../../redux/actions/ui';
import { onAddPrime } from '../../redux/actions/primes';
import { debounce, objectToFormData } from '../../utils/utils';
import { phoneUSPattern, websiteUrlPattern } from '../../utils/constants';
import ImageUpload from '../common/Form/FormInputs/ImageUpload';
import Address from '../common/Form/FormInputs/Address';
import FileUpload from '../common/Form/FormInputs/FileUpload';
import UpcomingProjects from './upcomingProjects';
import Dropdowns from '../common/Form/FormInputs/Dropdowns';

const { Title } = Typography;
const { TextArea } = Input;

const PrimesAdd = ({ addPrime, onCancel }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: null,
    phone: null,
    address: [],
    company_contacts: [],
    company_profile: null,
    company_specialization: [],
    // certificates: [],
    unspsc_codes: [],
    soq: null,
    projects: [],
    linkedin_profile: null
  });

  // const [certifications, setCertifications] = useState([]);

  // const certificationsHandler = (key, value, index) => {
  //   const newCertifications = [...certifications];
  //   if (newCertifications[index]) {
  //     newCertifications[index][key] = value;
  //   } else {
  //     newCertifications.push({ [key]: value });
  //   }
  //   setCertifications([...newCertifications]);
  // };

  const inputChangedHandler = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const debouncedInput = debounce((e, key) => {
    inputChangedHandler(key, e.target.value);
  }, 500);

  const microApiResponseHandler = (key, value, index) => {
    let arr = formValues[key] ? [...formValues[key]] : [];
    arr[index] = value;
    setFormValues({ ...formValues, [key]: arr });
  };

  const submit = () => {
    let payload = { ...formValues };
    Object.keys(payload).forEach(key=>{
      if(['address', 'company_specialization', 'projects', 'company_contacts', 'unspsc_codes'].includes(key)){
        payload[key] = JSON.stringify(payload[key]);
      }
    })
    addPrime(objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
  };

  return (
    <Row align="middle" justify="center">
      <Col span={24}>
        <Title level={2} className="heading-title">Corporation Profile</Title>
        <Form
          form={form}
          name="add-small-business"
          layout="horizontal"
          labelCol={{ /* offset: 4, */ span: 4 }}
          wrapperCol={{ /* offset: 4,  */ span: 20 }}
          labelAlign="left"
          onFinish={submit}
          onFinishFailed={submitFail}
        >
          {/* Company Logo */}
          <Form.Item
            key="logo"
            name="logo"
            hasFeedback
            style={{ textAlign: 'right' }}
            wrapperCol={{ md: { offset: 4 }, sm: { offset: 0 } }}
          >
            <ImageUpload
              keyName="logo"
              label="Company Logo"
              max={1}
              nestedHandler={(key, value) => {
                inputChangedHandler(key, value[key]);
              }}
            />
          </Form.Item>

          {/* Business Name */}
          <Form.Item
            key="company_name"
            name="company_name"
            style={{
              border: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            label="Business Name"
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Company name is required.'
              }
            ]}
          >
            <Input
              name="company_name"
              placeholder="Business Name"
              type="text"
              bordered={false}
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'company_name');
              }}
            />
          </Form.Item>

          {/* Business Phone */}
          <Form.Item
            key="phone"
            name="phone"
            label="Business Phone"
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Company Phone is required.'
              },
              {
                pattern: phoneUSPattern,
                message: 'Please enter a valid phone number.'
              }
            ]}
          >
            <Input
              name="phone"
              placeholder="Business Phone (10-digit)"
              type="tel"
              maxLength={10}
              bordered={false}
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'phone');
              }}
            />
          </Form.Item>

          {/* Website */}
          <Form.Item
            key="website_info"
            name="website_info"
            label="Website"
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '1px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Company Website is required.'
              },
              {
                pattern: websiteUrlPattern,
                message: 'Please enter a valid web url.'
              }
            ]}  
          >
            <Input
              name="website_info"
              placeholder="Company Website"
              // type="text"
              bordered={false}
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'website_info');
              }}
            />
          </Form.Item>

          {/* Business Addresses */}
          <Form.List
            name="address"
            rules={[
              {
                validator: async (_, address) => {
                  if (!address || address.length < 1) {
                    return Promise.reject(
                      new Error('At least 1 address is required.')
                    );
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                    required={true}
                    // required={false}
                    key={field.key}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                    label={index === 0 ? 'Business Address' : ''}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          validator: async (_, address) => {
                            if (!formValues['address'][index]) {
                              return Promise.reject(
                                new Error(
                                  'Please input details and Save or delete this field.'
                                )
                              );
                            }
                          }
                        }
                      ]}
                      noStyle
                    >
                      <Address
                        keyName="address"
                        microApi={{ add: addField }}
                        parentHandler={microApiResponseHandler}
                        hasCity={true}
                        valueProps={formValues['address'][index]}
                        index={index}
                        hasZip={true}
                      />
                    </Form.Item>
                    <Button
                      type="warning"
                      style={{
                        float: 'right',
                        marginRight: '0px',
                        backgroundColor: '#D26A31',
                        color: 'white'
                      }}
                      onClick={() => {
                        formValues['address'].splice(index, 1);
                        return remove(field.name);
                      }}
                    >
                      Remove
                    </Button>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '0px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 2 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Address
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Company Contacts */}
          <Form.List name="company_contacts">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                    required={false}
                    key={`${field.key}_${index}`}
                    label={index === 0 ? 'Company Contacts' : ''}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`name_${index}`}
                    >
                      <Input
                        placeholder="Name"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'title']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`title_${index}`}
                    >
                      <Input
                        placeholder="Title"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'phone']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`phone_${index}`}
                    >
                      <Input
                        placeholder="Phone"
                        type="tel"
                        maxLength={10}
                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`email_${index}`}
                    >
                      <Input
                        placeholder="Email"
                        type="email"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>

                    <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['company_contacts'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                      {!formValues['company_contacts'][index] ? (
                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '0px',
                            marginTop: '0px',
                            backgroundColor: '#82CF8F',
                            color: 'white'
                          }}
                          onClick={() => {
                            let ind = index;
                            addField(
                              'company_contacts',
                              objectToFormData(
                                form.getFieldValue('company_contacts')[index]
                              )
                            )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'company_contacts',
                                    data['data']['id'],
                                    ind
                                  );
                                } else {
                                  // microApiResponseHandler(
                                  //   'company_contacts',
                                  //   null
                                  // );
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                          }}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '1px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 2 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Company Contact
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Brief Company Profile */}

          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px'
              }}
            >
              Brief Company Profile: (max 300 words)
            </div>
          </React.Fragment>
          <TextArea
            name="company_profile"
            placeholder="Brief Company Profile (max 300 words)"
            rows={3}
            maxLength={300}
            onChange={e => {
              e.persist();
              debouncedInput(e, 'company_profile');
            }}
          />

          {/* NAICS Code */}
          <Form.List name="unspsc_codes">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                    key={`${field.key}_${index}`}
                    label={index === 0 ? 'UNSPSC Codes' : ''}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'codes']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`codes_${index}`}
                    >
                      <Input
                        placeholder="UNSPSC Code"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      name={[field.name, 'description']}
                      key={`description_${index}`}
                    >
                      <Input
                        placeholder="UNSPSC Description"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>

                    <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginRight: '0px',
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['unspsc_codes'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                      {!formValues['unspsc_codes'][index] ? (
                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '0px',
                            marginTop: '0px',
                            backgroundColor: '#82CF8F',
                            color: 'white'
                          }}
                          onClick={() => {
                            let ind = index;
                            addField(
                              'unspsc',
                              objectToFormData(
                                form.getFieldValue('unspsc_codes')[index]
                              )
                            )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'unspsc_codes',
                                    data['data']['id'],
                                    ind
                                  );
                                } else {
                                  // microApiResponseHandler('naics_codes', null);
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                          }}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '1px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 2 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add UNSPSC Code
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Company Specializations */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px'
              }}
            >
              Company Specialization: (Select all that applies)
            </div>
          </React.Fragment>

          <div>
            <Dropdowns
              keyName="specializations"
              label="specializations"
              multiSelect={true}
              parentHandler={(key, value) => {
                inputChangedHandler('company_specialization', [...value[key]]);
              }}
            />
          </div>

          {/* Upcoming Projects */}
          <Form.List
                name="projects"
                // rules={[
                //   {
                //     validator: async (_, project) => {
                //       if (!address || address.length < 1) {
                //         return Promise.reject(
                //           new Error('At least 1 project is required.')
                //         );
                //       }
                //     }
                //   }
                // ]}
              >
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                        key={field.key}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'Project Details' : ''}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          name={[field.name, 'projects']}
                          style={{ marginLeft: 7 }}
                          // rules={[
                          //   {
                          //     validator: async (_, address) => {
                          //       if (!formValues['address'][index]) {
                          //         return Promise.reject(
                          //           new Error(
                          //             'Please input details and Save or delete this field.'
                          //           )
                          //         );
                          //       }
                          //     }
                          //   }
                          // ]}
                          noStyle
                        >
                          <UpcomingProjects
                            parentHandler={microApiResponseHandler}
                            index={index}
                            valueProps={
                              formValues['projects']
                              ? formValues['projects'][index]
                             : null
                            }
                          />
                        </Form.Item>

                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '3px',
                            marginTop: '0px',
                            backgroundColor: '#D26A31',
                            color: 'white'
                          }}
                          onClick={() => {
                            formValues['upcoming_project'] && formValues['upcoming_project'].splice(index, 1);
                          }}
                        >
                          Remove
                        </Button>
                      </Form.Item>
                    ))}
                    
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Upcoming Teaming Opportunities
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

          {/* Additional Information */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px',
                fontWeight: 600
              }}
            >
              Additional Information:
            </div>
          </React.Fragment>
          {/* Statement of Qualification */}
          <Form.Item
            key="soq"
            name="soq"
            label="Statement of Qualification"
            labelCol={{ span: 6 }}
            style={{
              border: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
          >
            <FileUpload
              keyName="soq"
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              styleObj={{ marginLeft: '0px' }}
              label="Upload"
              parentHandler={(key, value) => {
                inputChangedHandler(key, value[key]);
              }}
            />
          </Form.Item>

          {/*  Company LinkedIn Profile*/}
          <Form.Item
            key="linkedin_profile"
            name="linkedin_profile"
            label="Company LinkedIn Profile"
            labelCol={{ span: 6 }}
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '30px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
          >
            <Input
              name="linkedin_profile"
              placeholder="LinkedIn Profile"
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              type="text"
              onChange={e => {
                e.persist();
                debouncedInput(e, 'linkedin_profile');
              }}
            />
          </Form.Item>

          {/* Action Buttons */}
          <Form.Item
            wrapperCol={{ md: { offset: 6, span: 12 }, sm: { span: 24 }}}
            style={{ textAlign: 'center' }}
          >
            <Space direction="horizontal" align="center">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

PrimesAdd.propTypes = {
  addPrime: func,
  onCancel: func
};

const mapDispatchToProps = dispatch => ({
  addPrime: payload => dispatch(onAddPrime(payload)),
  onCancel: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(PrimesAdd);
