/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';
import { Space, Typography, Tooltip } from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { getEvents } from '../../api/rfpRfq';
import { columns } from '../../components/configs/tables/rfprfq.json';
import TableComponent from '../../components/ui/TableComponent';
import { setRfpRfqRefresh } from '../../redux/actions/rfpRfq';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { accessRules } from '../../utils/accessRules';
import {
  stringToDateObject,
  dateTimeUtcToLocal
} from '../../utils/dateTimeHelper';
import { handleNotifications } from '../../utils/notifications';
import { getCurrentPageFromLocation, HeaderRenderer } from '../../utils/utils';
import { GlobalOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Link: TextLink } = Typography;

const List = ({
  refresh,
  onDeleteSuccess,
  importSuccess,
  role,
  searchParams,
  onSetRefresh,
  onShowLoading,
  onHideLoading,
  onShowModal
}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [events, setEvents] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentTableProps, setCurrentTableProps] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });
  const innerWidth = window.innerWidth;

  useEffect(() => {
    if (refresh) {
      onSetRefresh(false);
      setFiltersFromSearchParams(searchParams);
      fetchRfpEvents({ currentTableProps, filters: [...currentFilters] });
    }
  }, [refresh]);

  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  useDeepCompareEffect(() => {
    fetchRfpEvents({ currentTableProps, filters: [...currentFilters] });
  }, [currentFilters, currentTableProps.pagination.current, onDeleteSuccess, importSuccess]);

  useEffect(
    () => () => {
      setCurrentFilters([]);
      onSetRefresh(true);
    },
    []
  );

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: searchParams['keyword']
        }))
      : [];
    setCurrentFilters(searchFilters);
  };

  /* Render Site Link Column */
  // const renderSiteLink = (text, record) => (
  //   <Tooltip title="Visit Site">
  //     <TextLink href={`${record.site_link}`} target="_blank"> <GlobalOutlined /> </TextLink>
  //   </Tooltip>
  // );

  /* Render Actions Column */
  const renderActions = (text, record) => {
    return (
      <Space size="small">
        <Tooltip title="Visit Site">
          <TextLink onClick={(e) => e.stopPropagation()} style={{ marginRight: '5px' }} href={`${record.site_link}`} target="_blank"> 
            <GlobalOutlined style={{ fontSize: '18px' }} /> 
          </TextLink>
        </Tooltip>
        {/* <Button type="link">{renderSiteLink(text, record)}</Button> */}
        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'edit'
        ] ? (
            <Tooltip 
              onClick={(e) => {
                e.stopPropagation();
                onShowModal();
              }}
              title="Edit"
            >
              <Link style={{ marginRight: '5px' }} to={`${match.path}/edit/${record.id}`}>
                <EditOutlined style={{ fontSize: '18px' }} />
              </Link>
            </Tooltip>
        ) : null}
        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'delete'
        ] ? (
            <Tooltip 
              onClick={(e) => {
                e.stopPropagation();
                onShowModal();
              }}
              title="Delete"
            >
              <Link
                style={{ marginRight: '5px' }}
                to={{
                  pathname: `${match.path}/delete/${record.id}`,
                  eventNumber: record.number
                }}
              >
                < DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
              </Link>
            </Tooltip>
        ) : null}
      </Space>
    );
  };

  const tableColumns = HeaderRenderer(columns, innerWidth)

  // tableColumns.push({
  //   title: 'Site Link',
  //   key: 'site_link',
  //   displayName: 'site_link',
  //   render: renderSiteLink
  // });

  tableColumns.push({
    title: 'Actions',
    key: 'action',
    displayName: 'action',
    render: renderActions
  });

  const fetchRfpEvents = ({ currentTableProps, filters }) => {
    const query = {};
    if (currentTableProps && currentTableProps.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = currentTableProps.pagination.size;
    }
    if (filters) {
      query['filters'] = filters;
    }

    onShowLoading();
    getEvents(query)
      .then(({ data }) => {
        if (data.success) {
          setEvents(
            data.data.map(item => {
              return {
                ...item,
                key: item['id'],
                issue_date: item.issue_date
                  ? stringToDateObject(item.issue_date).format('MMMM DD, YYYY')
                  : '',
                due_date: item.due_date_time
                  ? dateTimeUtcToLocal(item.due_date_time).format(
                      'MMMM DD, YYYY'
                    )
                  : '',
                due_time: item.due_date_time
                  ? dateTimeUtcToLocal(item.due_date_time).format('hh:mma')
                  : ''
              };
            })
          );
          setCurrentTableProps({
            ...currentTableProps,
            pagination: {
              ...currentTableProps.pagination,
              total: data.pagination.total
            }
          });
        } else {
          setEvents([]);
          handleNotifications('error', 'Failed', data['message']);
        }
        onHideLoading();
      })
      .catch(error => {
        onHideLoading();
        setEvents([]);
        handleNotifications('error', 'Failed', error['message']);
        console.log(error);
      });
  };

  const manageDataSource = ({ current, pageSize }) => {
    let tableProps = { ...currentTableProps };
    if (current) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, current }
      };
    }
    if (pageSize) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, size: pageSize }
      };
    }
    // if (filters) {
    //   tableProps = { ...tableProps };
    // }
    setCurrentTableProps({ ...tableProps });
  };

  const onClickRow = (record) => {
    return {
      onClick: () => {
        if(accessRules[role][getCurrentPageFromLocation(match.path)]['actions']['view']){
          onShowModal();
          history.push(`${match.path}/view/${record.id}`);
        }
      },
      onMouseEnter: () => {

      }
    };
  }

  return (
    <TableComponent
      className="table-hoverable"
      onRow={onClickRow}
      columns={tableColumns}
      tableData={events}
      pagination={{
        position: ['bottomCenter'],
        hideOnSinglePage: true,
        ...currentTableProps.pagination
      }}
      manageDataSource={manageDataSource}
    />
  );
};

const mapStateToProps = state => ({
  refresh: state.rfpRfq.refresh,
  onDeleteSuccess: state.rfpRfq.deleteSuccess,
  importSuccess: state.rfpRfq.importSuccess,
  role: state.auth.role,
  searchParams: state.search.rfpRfq
});

const mapDispatchToProps = dispatch => ({
  onHideLoading: () => dispatch(hideLoading()),
  onSetRefresh: payload => dispatch(setRfpRfqRefresh(payload)),
  onShowLoading: () => dispatch(showLoading()),
  onShowModal: () => dispatch(showModal())
});

List.propTypes = {
  refresh: bool,
  onDeleteSuccess: bool,
  importSuccess: bool,
  role: string,
  searchParams: object,
  onSetRefresh: func,
  onShowModal: func,
  onHideLoading: func,
  onShowLoading: func
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
