import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Spin, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { objectToFormData } from '../../utils/utils';
import { showVerificationStep } from '../../api/verificationSteps';
import { handleNotifications } from '../../utils/notifications';
import { onUpdateStep } from '../../redux/actions/verificationSteps';

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 24
  }
};

const VerificationStepUpdate = ({ onUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState({});
  const [form] = Form.useForm();

  const { stepId } = useParams();

  useEffect(() => {
    if (!stepId) {
      return;
    }

    async function fetchStep(id) {
      let res = await showVerificationStep(id);
      if (res.data['success']) {
        setStep(res.data['data']);
        setLoading(false);
      } else {
        setLoading(false);
        handleNotifications('error', 'Could not fetch', res.data.message);
      }
    }

    fetchStep(stepId);

    // eslint-disable-next-line
  }, [stepId]);

  const onFinish = values => {
    onUpdate(step.id, objectToFormData(values));
    setStep({});
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          {...layout}
          initialValues={{
            name: step.name,
            description: step.description
          }}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item key="name" name="name" hasFeedback>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item key="description" name="description" hasFeedback>
            <TextArea placeholder="Description" />
          </Form.Item>
          <Form.Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onUpdate: (id, payload) => dispatch(onUpdateStep(id, payload))
});

VerificationStepUpdate.propTypes = { onUpdate: func };

export default connect(null, mapDispatchToProps)(VerificationStepUpdate);
