import connection from '../../utils/api';
import { handleNotifications } from '../../utils/notifications';
import { 
  SEARCH_CLEAR_QUERY, 
  SEARCH_RESULTS, 
  SEARCH_SET_QUERY, 
  SET_ACTIVE_ADVANCE_SEARCH, 
  CLEAR_ADVANCE_SEARCH,
  SAVE_ADVANCE_FORM_VALUES
} from '../actionTypes';
import { hideLoading, hideModal, showLoading } from './ui';

export const setSearchQuery = (component, keyword, fields) => ({
  type: SEARCH_SET_QUERY,
  payload: { component, keyword, fields }
});

export const setActiveAdvanceSearch = (payload) => ({
  type: SET_ACTIVE_ADVANCE_SEARCH,
  payload
});

export const clearSearchQuery = component => ({
  type: SEARCH_CLEAR_QUERY,
  payload: { component }
});

export const resetSearchResults = (payload) => ({
  type: CLEAR_ADVANCE_SEARCH,
  payload
});

export const saveAdvanceFormValues = (payload) => ({
  type: SAVE_ADVANCE_FORM_VALUES,
  payload
});

export const onSearch = payload => async dispatch => {
  dispatch(showLoading());
  connection.post(`small_business/adv_search`, payload)
  .then(({ data }) => {
    dispatch(hideLoading());
    if (data.success && data.data?.length) {
      dispatch({type: SEARCH_RESULTS, payload: data.data})
      handleNotifications('success', 'Success', data.message);
      dispatch(hideModal());
      dispatch(setActiveAdvanceSearch(null))
    } else {
      handleNotifications('error', 'Warning', 'No Record Found! ');
    }
  })
  .catch(error => {
    dispatch(hideLoading());
    handleNotifications('error', 'Failed', error.message);
    console.log(error);
  });
}