import { RFP_RFQ_REFRESH, DELETE_RFPRFQ_SUCCESS, IMPORT_RFPRFQ_SUCCESS } from '../actionTypes';

const initialState = { refresh: false, deleteSuccess: false, importSuccess: false };

export const rfpRfqReducer = (state = initialState, action) => {
  switch (action.type) {
    case RFP_RFQ_REFRESH:
      return { ...state, refresh: action.payload };
    case DELETE_RFPRFQ_SUCCESS:
      return { ...state, deleteSuccess: true };
    case IMPORT_RFPRFQ_SUCCESS:
      return { ...state, importSuccess: true };
    default:
      return { ...state };
  }
};
