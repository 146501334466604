import React /* , { useEffect } */ from 'react';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';

import PluggableForm from '../../components/common/Form/PluggableForm';
import { onAddLookAhead } from '../../redux/actions/lookAhead';
import { objectToFormData } from '../../utils/utils';

const Add = ({ config, addLookAhead }) => {
  const onSubmit = values => {
    values = { ...values, address: values.address.address };
    const payload = Object.keys(values).reduce(
      (accumulator, item) => ({ ...accumulator, ...values[item] }),
      {}
    );
    Object.keys(payload).forEach(key=>{
      if(['street', 'proof', 'city', 'zipcode', 'id'].includes(key) || !payload[key]){
        delete payload[key];
      }
    })
    addLookAhead(objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
  };
  
  return (
    <>
      <h1 className="heading-title">Add Look Ahead</h1>
      <PluggableForm
        config={config}
        formConfig={{ layout: 'vertical', name: 'add-look-ahead' }}
        onFinish={onSubmit}
        onFinishFailed={submitFail}
        editMode={false}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  addLookAhead: payload => dispatch(onAddLookAhead(payload))
});

Add.propTypes = { config: array, addLookAhead: func };

export default connect(null, mapDispatchToProps)(Add);
