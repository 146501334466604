import { Space, Button } from 'antd';
import { func } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { onDeleteContact } from '../../redux/actions/contact';
import { hideModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';

const Delete = ({ onDelete, onHideModal}) => {
  const params = useParams();
  const history = useHistory();

  const onClickDelete = () => {
    if(!params && !params['conatctId']) {
      handleNotifications('error', 'Failed', 'Did not receive contact id');
      return;
    }
    onDelete(params['contactId']);
  };

  const onClickCancel = () => {
    onHideModal();
    history.goBack();
  };

  return (
    <Space direction="vertical" align="center" style={{width:'100%'}}>
      <Space direction="horizontal" align="center">
        <h1> Delete contact data ?</h1>
        <br />
      </Space>
      <Space direction="horizontal" align="center">
        <Button type="primary" danger htmlType="button" onClick={onClickDelete}>
          Delete
        </Button>
        <Button type="primary" htmlType="button" onClick={onClickCancel}>
          Cancel
        </Button>
      </Space>
    </Space>
  );
};

const mapDispatchToProps = dispatch  => ({
  onDelete: contactId => dispatch(onDeleteContact(contactId)),
  onHideModal: () => dispatch(hideModal())
});

Delete.propTypes = {
  onDelete: func,
  onHideModal: func
}

export default connect(null, mapDispatchToProps)(Delete);