import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';
import { Card } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { getEvent } from '../../api/calendar';
import { handleNotifications } from '../../utils/notifications';
import PluggableForm from '../../components/common/Form/PluggableForm';
// import { utcToLocalString } from '../../utils/calendarHelper';
import { hideModal } from '../../redux/actions/ui';
import { objectToFormData } from '../../utils/utils';
import { onUpdateEvent } from '../../redux/actions/calendar';
import moment from 'moment';

dayjs.extend(customParseFormat);

const Edit = ({ config, onUpdate }) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const { eventId } = useParams();

  useEffect(() => {
    if (!eventId) {
      return;
    }
    setLoading(true);
    getEvent(eventId)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          setEventDetails(mapValuesToFields(data['data']));
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }, [eventId]);

  const mapValuesToFields = values => {
    const date = {};
    if (values.date_start === values.date_end) {
      date['mode'] = 'single';
      date['value'] = moment.utc(values.date_start).utcOffset((dayjs().utcOffset()));
    } else {
      date['mode'] = 'multi-day';
      date['value'] = [
        moment.utc(values.date_start).utcOffset((dayjs().utcOffset())),
        moment.utc(values.date_end).utcOffset((dayjs().utcOffset()))
      ];
    }

    // const time = [
    //   dayjs(utcToLocalString(values.time_start), 'hh:mm a'),
    //   dayjs(utcToLocalString(values.time_end), 'hh:mm a')
    // ];
    return {
      title: values.title,
      agency: values.agency,
      date: date,
      // time: time,
      description: values.description,
      info_link: values.info_link,
      location: values.location,
      type_event: values.type_event,
      updated_on: values.updated_on,
      created_on: values.created_on,
      // time_start: utcToLocalString(values.time_start),
      // time_end: utcToLocalString(values.time_end),
    };
  };

  const onSubmit = values => {
    const { date } = values;
    const payload = Object.keys(values).reduce(
      (accumulator, keys) => { 
        return typeof values[keys] === 'object' ? { ...accumulator, ...values[keys] } : { ...accumulator, [keys]:values[keys] }
      },
      {}
    );

    if (date['mode'] === 'single') {
      payload['date_start'] = date.date || date['value'].format('MM/DD/YYYY');
      payload['date_end'] = date.date || date['value'].format('MM/DD/YYYY');
    } else {
      payload['date_start'] =
        date['date_start'] || date['value']?.[0].format('MM/DD/YYYY') || '';
      payload['date_end'] =
        date['date_end'] || date['value']?.[1].format('MM/DD/YYYY') || '';
    }

    console.log('final', payload);
    onUpdate(eventId, objectToFormData(payload));
  };

  return (
    <>
      <h1 className="heading-title">Edit Event Details</h1>
      <Card loading={loading}>
        {eventDetails ? (
          <PluggableForm
            config={config}
            formConfig={{ layout: 'vertical', name: 'update-event' }}
            valueProps={eventDetails}
            onFinish={onSubmit}
            editMode={true}
          />
        ) : null}
      </Card>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onUpdate: (eventId, payload) => dispatch(onUpdateEvent(eventId, payload)),
  onCancel: () => dispatch(hideModal())
});

Edit.propTypes = { config: array, onUpdate: func, onCancel: func };

export default connect(null, mapDispatchToProps)(Edit);
