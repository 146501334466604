import React, { useState, useEffect } from 'react';
import { Row, Col, Layout, Button } from 'antd';
import { showModal } from '../../redux/actions/ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  lookAhead,
  rfprfq,
  recent_bookmarks,
  dummy
} from '../../components/configs/tables/dashboard.json';
import TableComponent from '../../components/ui/TableComponent';
import Announcement from './announcement';
import IndustryNews from './industryNews';
import {fetchCalendarEvents, setActiveComponents, fetchLookAhead, fetchPrimes, fetchRfpEvents, fetchSmallBusinesses} from '../../redux/actions/dashboard';
import lookaheadLogo from '../../assets/images/LookAhead.png';
import newMembersLogo from '../../assets/images/NewMembers.png';
import rfpLogo from '../../assets/images/RFP_Bid.png';
import activityLogo from '../../assets/images/Activity.png';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

const NewCompany = ({item, index, showPrimes}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="company-container">
      {index === 0 && item['naics_codes'] && <h3>Small Business</h3>}
      {showPrimes && !item['naics_codes'] && <h3>Corporation</h3>}
      <div className="company-details">
        <span>
          <p id="title">{item.company_name || '-'}</p>
          <p><strong>Industry : </strong>{item.industry_specialization || item.company_specialization || '-'}</p>
        </span>
        <Button size="small" onClick={()=>{dispatch(showModal()); history.push(`/${item['naics_codes'] ? 'small-business' : 'primes'}/view/${item.id}`, { goBack: true})}}>View</Button>
      </div>
    </div>
  )
}

const LookAheadDash = ({item}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="lookahead-dash-container">
      <div>
        <strong>{dayjs(item.proposed_advertising_date).format('MMM, YYYY') || 'N/A'}</strong>{' - '}
        <span className="title">{item.agency}</span><br />
        <span className="para"><strong><small>Type : </small></strong>{item.type_of_work}</span><br />
        <span className="para"><strong><small>Scope : </small></strong>{item.scope}</span>
      </div>
      <Button size="small" onClick={()=>{dispatch(showModal()); history.push(`look-ahead/view/${item.id}`, { goBack: true})}}>View</Button>
    </div>
  )
}

const BookMarkDash = ({item}) =>{
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <p className="bookmark_dash">
      <span id="content"><strong>{item.date_end ? dayjs(item.date_end).format('MM/DD/YYYY') : '-'} :</strong> {item.title || ''}{' '}{item.agency || ''}</span>
      <Button size="small" onClick={()=>{dispatch(showModal()); history.push(`/calendar/view/${item.id}`, { goBack: true})}}>View</Button>
    </p>
  )
}

const UserDashboard = () => {
  const [currentTableProps] = useState({
    pagination: { current: 1, total: 0 }
  });

  const {activeComps, lookAheads, rfp_rfqs, calendars, smallBusiness, primes} = useSelector(({dashboard}) => ({
    activeComps: dashboard.activeComps,
    lookAheads: dashboard.lookAheads,
    rfp_rfqs: dashboard.rfp_rfqs,
    calendars: dashboard.calendars,
    smallBusiness: dashboard.smallBusiness,
    primes: dashboard.primes
  }));
  const { businessId } = useSelector(({auth}) => ({businessId: auth.user?.businessId}));
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let start = dayjs().format('YYYY-MM-DD');
    let rfpend= dayjs().add(10, 'day').format('YYYY-MM-DD');
    let endDateMonth = dayjs().add(30, 'day').format('YYYY-MM-DD');
    if(businessId){
      dispatch(setActiveComponents());
    }
    !lookAheads?.length && dispatch(fetchLookAhead({currentTableProps, filters: [{proposed_advertising_date: endDateMonth}]}));
    !calendars?.length && dispatch(fetchCalendarEvents({currentTableProps, range: {start_date: start, end_date: endDateMonth}}));
    !primes?.length && dispatch(fetchPrimes({currentTableProps, filters: [{order_by: "-id"}]}));
    !smallBusiness?.length && dispatch(fetchSmallBusinesses({currentTableProps, filters: [{order_by: "-id"}]}));
    !rfp_rfqs?.length && dispatch(fetchRfpEvents({currentTableProps, filters: [{due_date_time: rfpend}]}));
    // eslint-disable-next-line
  }, []);
  
  const renderActions = (text, record) => (
    <Button size="small" onClick={()=>{dispatch(showModal()); history.push(`/rfp-rfq/view/${record?.id}`, { goBack: true})}}>View</Button>
  )

  useEffect(()=>{
    rfprfq.columns[3] = {
      title: 'Action',
      key: 'action',
      displayName: 'action',
      render: renderActions
    }
    // eslint-disable-next-line
  },[])

  const getColumns = type => {
    switch (type) {
      case 'RFP/Bid Closing Soon':
        return rfprfq.columns.map(item=>({...item, ellipsis: true}));
      case "Look Ahead Calendar":
      case 'Present Month Look Ahead':
        return lookAhead.columns.map(item=>({...item}));
      case 'Calendar Events':
      case 'Recent Companies Viewed':
        return recent_bookmarks.columns.map(item=>({...item, ellipsis: true}));
      default:
        return dummy.columns.map(item=>({...item, width: 160 }));
    }
  };

  const getImage = (type) => {
    switch (type) {
      case 'RFP/Bid Closing Soon':
        return rfpLogo
      case "Look Ahead Calendar":
        return lookaheadLogo
      case "Calendar Events":
        return activityLogo
      default:
        return newMembersLogo
    }
  }

  const getTableData = (type) => {
    switch (type) {
      case 'RFP/Bid Closing Soon':
        return rfp_rfqs?.map(item=> ({id: item.id, agency: item.agency || '-', description: item.scope || '-', due_date: dayjs(item.due_date).format('MM/DD/YYYY') }))
      case "Look Ahead Calendar":
        return lookAheads?.map(item=> ({nofield: <LookAheadDash item={item} /> }))
      case "Calendar Events":
        return calendars?.map(item=> ({bookMark_desc: <BookMarkDash item={item} /> }))
      default:
        return smallBusiness?.slice(0, 5).concat(primes?.slice(0, 5)).map((item, i)=> ({name: <NewCompany item={item} index={i} showPrimes={primes?.[0]?.id === item.id} /> }))
    }
  }

  return (
    <Layout className="dashboard-container">
      <Row>
        <Col xs={24} md={12}>
          <Announcement />
        </Col>
        <Col xs={24} md={12}>
          <IndustryNews />
        </Col>
      </Row>
      <Row className="tables-container">
        {activeComps?.map((item, i) => (
          <Col key={i} xs={24} sm={24} md={activeComps.length === 1 ? 24 : 12} xl={24/activeComps.length}>
            <div style={{ padding: 10 }}>
              <div className="heading-block">
                <img src={getImage(item.name)} alt="icon" width="35" height="30" />
                <h3>{item.name}</h3>
              </div>
              <div className="custom-scroll" style={{ height: 400, background: 'white', overflowY: 'auto' }}>
                <div id="ghost-bar"></div>
                <TableComponent
                  columns={getColumns(item.name)}
                  tableData={getTableData(item.name)}
                  size="small"
                  className={`${['Look Ahead Calendar', 'Welcome New Members'].includes(item.name) ? 'no-header' : ''}`}
                  pagination={false}
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Layout>
  );
};

export default UserDashboard;
