import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { getNews } from '../../redux/actions/generalIndustryNews';
import TableComponent from '../../components/ui/TableComponent';

const Msgs = ({item}) => {
  return(
    <div className="msg-container">
      <span className="para">{item.title}</span>
      <br />
      <span>{item.description}</span>
      <br />
      <span>{item.source}</span>
    </div>
  )
}

const Announcement = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const getData = async() => {
    const data = await dispatch(getNews())
    setList(data);
  }
  useEffect(()=>{
    getData();
    // eslint-disable-next-line
  },[])

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      searchFilter: true,
      sortable: false
    },
    {
      title: 'Industry News',
      dataIndex: 'industryNews',
      key: 'industryNews',
      searchFilter: true,
      sortable: false
    }
  ];

  return (
    <div style={{ padding: 10 }}>
      <div className="announcement-list">
        <TableComponent
          columns={tableColumns}
          tableData={list?.map(item => ({
            index: item,
            date: dayjs(item.created_on).format('MM/DD'),
            industryNews: <Msgs item={item}
             />
          }))}
          // manageDataSource={manageDataSource}
        />
      </div>
    </div>
  );
};

export default Announcement;
