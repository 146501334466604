/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { bool, func, number, object, string } from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Space, Tooltip } from 'antd';

import { getSmallBusinesses } from '../../api/smallBusiness';
import { columns } from '../../components/configs/tables/smallBusiness.json';
import { setSmallBusinessRefresh } from '../../redux/actions/smallBusiness';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';
import TableComponent from '../../components/ui/TableComponent';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation, HeaderRenderer } from '../../utils/utils';
import { dueDiligenceOptions } from '../../utils/constants';
import { PrinterOutlined, CheckCircleOutlined, EditOutlined, DeleteOutlined, MessageOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { resetSearchResults } from '../../redux/actions/search';

const List = ({
  refresh,
  onDeleteSuccess,
  clearAdvanceSearch,
  role,
  activeTab,
  searchParams,
  searchResults,
  onSetRefresh,
  onShowLoading,
  onHideLoading,
  onShowModal,
  companyVerificationSteps
}) => {
  const [smallBusinesses, setSmallBusinesses] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentTableProps, setCurrentTableProps] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });
  const innerWidth = window.innerWidth;

  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (refresh) {
      setFiltersFromSearchParams(searchParams);
    }
  }, [refresh]);

  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  useEffect(() => {
    if(onDeleteSuccess || clearAdvanceSearch){
      fetchSmallBusinesses({ currentTableProps, filters: [...currentFilters] });
      dispatch(resetSearchResults({ clearAdvanceSearch: false }))
    }
  }, [onDeleteSuccess, clearAdvanceSearch]);

  // commented this part out as it was responsible for calling the get api twice
  // useEffect(() => {
  //   fetchSmallBusinesses({ currentTableProps, filters: [...currentFilters] });
  // }, [activeTab]);

  useDeepCompareEffect(() => {
    fetchSmallBusinesses({ currentTableProps, filters: [...currentFilters] });
  }, [currentFilters, currentTableProps.pagination.current]);

  useEffect(() => () => {
    setCurrentFilters([]);
    onSetRefresh(true);
  }, []);

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: searchParams['keyword']
        }))
      : [];
    setCurrentFilters(searchFilters);
  };

  const filterData = (arr) => {
    arr = arr
    .map(item => ({
      ...item,
      key: item['id'],
      naics_codes: item['naics_codes']?.length ? item['naics_codes'].map(subItem => subItem['codes']).join(', ') : '-',
      county: item['address']?.length ? item['address'].map(subItem => subItem['county']).join(', ') : '-',
      state: item['address']?.length ? item['address'].map(subItem => subItem['state']).join(', ') : '-',
      verification_step_id: item['verification_step_id'] ? companyVerificationSteps.find(x => x.id === item['verification_step_id'])?.name : 'Not Verified',
      due_diligence: item['due_diligence'] ? dueDiligenceOptions[item['due_diligence']] : '-',
      industry_specialization: item['industry_specialization']?.length ? item['industry_specialization'].join(', ') : '-',
    }));
    return arr;
  }

  useDeepCompareEffect(()=> {
    if(searchResults && searchResults.length){
      let arr = filterData(searchResults)
      setSmallBusinesses(arr)
      setCurrentTableProps({
        ...currentTableProps,
        pagination: {
          ...currentTableProps.pagination,
          total: arr.length
        }
      });
    }
  }, [searchResults])

  /* Render Actions Column */
  const renderActions = (text, record) => {
    return (
      <Space size="small">
        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'chat'
        ] ? (
          <Tooltip
            title="Chat"
            onClick={(e) => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link style={{ marginRight: '5px' }} to={`${match.path}/chat/${record.id}/SmallBusiness`}>
              <MessageOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
        ) : null}

        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'print-view'
        ] ? (
          <Tooltip
            title="Print Profile"
            onClick={(e) => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link style={{ marginRight: '5px' }} to={`${match.path}/print-view/${record.id}`}>
              <PrinterOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
        ) : null}

        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'edit'
        ] ? (
          <Tooltip
            title="Edit"
            onClick={(e) => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link style={{ marginRight: '5px' }} to={`${match.path}/edit/${record.id}`}>
              <EditOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
        ) : null}
        
        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'delete'
        ] ? (
          <Tooltip
            title="Delete"
            onClick={(e) => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link
              style={{ marginRight: '5px' }}
              to={{
                pathname: `${match.path}/delete/${record.id}`,
                businessName: record['company_name']
              }}
            >
              <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }}/>
            </Link>
          </Tooltip>
        ) : null}
      </Space>
    );
  };

  const tableColumns = HeaderRenderer(columns, innerWidth)

  tableColumns.push({
    title: 'Action',
    key: 'action',
    displayName: 'action',
    render: renderActions
  });

  const fetchSmallBusinesses = ({ currentTableProps, filters }) => {
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = currentTableProps.pagination.size;
    }
    if (filters) {
      query['filters'] = filters;
    }

    query['vetted'] = activeTab === "vetted" ? 'True' : undefined

    onShowLoading();
    getSmallBusinesses(query)
      .then(({ data }) => {
        if (data.success) {
          let arr = filterData(data.data);
          setSmallBusinesses(arr);
          setCurrentTableProps({
            ...currentTableProps,
            pagination: {
              ...currentTableProps.pagination,
              total: data?.pagination.total
            }
          });
          onHideLoading();
        } else {
          setSmallBusinesses([]);
          onHideLoading();
          handleNotifications('error', 'Failed', data.message);
        }
      })
      .catch(error => {
        console.log(error);
        setSmallBusinesses([]);
        onHideLoading();
        handleNotifications('error', 'Failed', error.message);
      });
  };

  const manageDataSource = ({ current, pageSize }, filters) => {
    let tableProps = { ...currentTableProps };
    if (current) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, current }
      };
    }
    if (pageSize) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, size: pageSize }
      };
    }
    setCurrentTableProps({ ...tableProps });
  };

  const onClickRow = (record) => {
    return {
      onClick: () => {
        if(accessRules[role][getCurrentPageFromLocation(match.path)]['actions']['view']){
          onShowModal();
          history.push(`${match.path}/view/${record.id}`);
        }
      }
    };
  }

  return (
    <TableComponent
      className="table-hoverable"
      columns={tableColumns}
      tableData={smallBusinesses}
      onRow={onClickRow}
      pagination={{
        position: ['bottomCenter'],
        hideOnSinglePage: true,
        ...currentTableProps.pagination
      }}
      manageDataSource={manageDataSource}
    />
  );
};

const mapStateToProps = state => ({
  refresh: state.smallBusiness.refresh,
  onDeleteSuccess: state.smallBusiness.deleteSuccess,
  clearAdvanceSearch: state.search.clearAdvanceSearch,
  role: state.auth.role,
  businessId: state.auth?.user?.businessId, 
  searchParams: state.search.smallBusiness,
  searchResults: state.search.searchResults,
  companyVerificationSteps: state.verificationSteps.verificationSteps
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onHideLoading: () => dispatch(hideLoading()),
  onSetRefresh: payload => dispatch(setSmallBusinessRefresh(payload)),
  onShowLoading: () => dispatch(showLoading())
});

List.propTypes = {
  refresh: bool,
  onDeleteSuccess: bool,
  role: string,
  businessId: number,
  searchParams: object,
  onSetRefresh: func,
  onShowModal: func,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
