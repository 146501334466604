import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tag, Button, Tooltip } from 'antd';
import TableComponent from '../../components/ui/TableComponent';
import { PlusCircleFilled } from '@ant-design/icons';
import { getUsers, getAdditionalUsers } from '../../api/addUsers';
import { handleNotifications } from '../../utils/notifications';
import PaymentForm from '../../components/payments/paymentForm';
import ModalComponent from '../../components/ui/ModalComponent';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import AddUserForm from './addUserForm';

const tableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    searchFilter: true,
    sortable: false
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    searchFilter: true,
    sortable: false
  }
];

const UsersList = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    quantity: 0,
    amount: 0,
    order_desciptioin: 'Association Package'
  });
  const [users, setUsers] = useState([]);
  const [activeContent, setActiveContent] = useState(0);
  const [additionalUser, setAdditionalUser] = useState({additional_users: 0, standard_users: 0});
  const loginData = useSelector(state => state.auth);

  const getData = async () => {
    let promises = [];
    let param = `company_type=${
      loginData?.role === 'prime' ? 'Primes' : 'SmallBusiness'
    }&company_id=${loginData?.user?.businessId}`;
    promises.push(getUsers(param));
    promises.push(getAdditionalUsers(param));
    try {
      let [res1, res2] = await Promise.all(promises);
      if(res1.data.success && res2.data.success){
        setUsers(
          res1.data.data.map(item => ({
            ...item,
            name: `${item.firstname} ${item.lastname}`
          }))
        );
        res2.data.data?.extra_user_price && setAdditionalUser(res2.data.data);
        dispatch(hideLoading());
      }
    } catch (error) {
      handleNotifications(
        'error',
        'BZ CONNECT!!',
        error.response?.data?.message || 'Something Bad Happened!'
      );
    }
  }

  useEffect(() => {
    dispatch(showLoading());
    getData();
    // eslint-disable-next-line
  }, []);

  const handleAddUser = e => {
    e.stopPropagation();
    if (!data.quantity) {
      handleNotifications( 'warning', 'BZ CONNECT!!', 'Quantity can not be less than 1' );
      return;
    }
    setActiveContent(1);
    dispatch(showModal());
  };

  const handleAddUserForm = (e) => {
    e.stopPropagation();
    setActiveContent(2);
    dispatch(showModal());
  }

  const handleChange = () => {
    setData(prev => ({
      ...prev,
      quantity: prev.quantity + 1,
      amount: parseFloat(additionalUser.extra_user_price) * (prev.quantity + 1)
    }));
  };

  return (
    <Row>
      <Col span="24">
        <ModalComponent>
          {activeContent === 1 && <PaymentForm data={data} addUsers={true} />}
          {activeContent === 2 && <AddUserForm getData={getData}/>}
        </ModalComponent>
        <div style={{ float: 'right', marginBottom: 10 }}>
          <span>
            Team Size :{' '}
            {console.log(parseInt(additionalUser?.additional_users), parseInt(additionalUser?.standard_users), parseInt(additionalUser?.additional_users) + parseInt(additionalUser?.standard_users))}
            <Tag color="warning" style={{padding: 5}}>{parseInt(additionalUser?.additional_users) + parseInt(additionalUser?.standard_users)}</Tag>
          </span>
          <span>
            Price Per User :{' '}
            <Tag color="success" style={{padding: 5}}>$ {additionalUser?.extra_user_price}</Tag>
          </span>
          <span>
            <Button name="quantity" onClick={handleChange}>
              {data.quantity} +
            </Button>
            <Button onClick={handleAddUser}>Add Team</Button>
          </span>
        </div>
        <Tooltip title="add user">
          <PlusCircleFilled style={{
            position: 'absolute',
            right: 10,
            top: 50,
            zIndex: 1,
            fontSize: 25,
            color: '#ffffff'
          }} onClick={handleAddUserForm}/>
        </Tooltip>
        <TableComponent columns={tableColumns} tableData={users} />
      </Col>
    </Row>
  );
};

export default UsersList;
