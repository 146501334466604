import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { array, func, object } from 'prop-types';
import { Card } from 'antd';

import { getEvent } from '../../api/rfpRfq';
import { hideLoading, showLoading } from '../../redux/actions/ui';
import { onUpdateEvent } from '../../redux/actions/rfpRfq';
import { handleNotifications } from '../../utils/notifications';
import PluggableForm from '../../components/common/Form/PluggableForm';
import { objectToFormData } from '../../utils/utils';
import { dateTimeUtcToLocal } from '../../utils/dateTimeHelper';
import dayjs from 'dayjs';

const Edit = ({ config, updateEvent, onShowLoading, onHideLoading }) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const { eventId } = useParams();

  useEffect(() => {
    if (!eventId) {
      return;
    } else {
      setLoading(true);
      onShowLoading();
      getEvent(eventId)
        .then(({ data }) => {
          setLoading(false);
          onHideLoading();
          if (data.success) {
            setEventDetails({
              ...data.data,
              address: {
                street: data.data.street,
                county: data.data.county,
                state: data.data.state
              },
              issue_date: data.data.issue_date
                ? dateTimeUtcToLocal(data.data.issue_date).format(
                    'MMMM DD, YYYY'
                  )
                : null,
              due_date: data.data.due_date_time
                ? dateTimeUtcToLocal(data.data.due_date_time).format(
                    'MMMM DD, YYYY'
                  )
                : null,
              due_time: data.data.due_date_time
                ? dateTimeUtcToLocal(data.data.due_date_time)
                : // .format('hh:mma')
                  null,
              created_on: data.data.created_on
                ? dateTimeUtcToLocal(data.data.created_on).format('MM/DD/YYYY hh:mm A')
                : null,
              updated_on: data.data.updated_on
                ? dateTimeUtcToLocal(data.data.updated_on).format('MM/DD/YYYY hh:mm A')
                : null
            });
          }
        })
        .catch(error => {
          setLoading(false);
          onHideLoading();
          handleNotifications('error', 'Failes', error.message);
        });
    }
    // eslint-disable-next-line
  }, [eventId]);

  const onSubmit = values => {
    let payload = Object.keys(values).reduce(
      (accumulator, keys) => {
        return typeof(values[keys]) === "object" && keys !== 'due_time' ? 
        { ...accumulator, ...values[keys] } :
        { ...accumulator, [keys]: values[keys]}
      },
      {}
    );
    payload['time_offset'] = dayjs().utcOffset();
    payload['due_time'] = payload['due_time']?.['due_time'] ? payload['due_time']['due_time'] : dayjs(payload['due_time']).format('hh:mm A')
    payload['due_date'] = dayjs(payload['due_date']).format('MM/DD/YYYY')
    payload['issue_date'] = dayjs(payload['issue_date']).format('MM/DD/YYYY')
    payload = { ...payload, ...payload['address'] };
    delete payload['address'];

    updateEvent(eventId, objectToFormData(payload));
  };

  return (
    <>
      <h1 className="heading-title">Edit RFP/RFQ/RFI/IFB Page</h1>
      <Card loading={loading}>
        {eventDetails ? (
          <PluggableForm
            config={config}
            formConfig={{ layout: 'vertical', name: 'edit-rfp-rfq' }}
            valueProps={eventDetails}
            onFinish={onSubmit}
            editMode={true}
          />
        ) : null}
      </Card>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading()),
  updateEvent: (eventId, payload) => dispatch(onUpdateEvent(eventId, payload))
});

Edit.propTypes = {
  config: array,
  updateEvent: func,
  match: object,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(null, mapDispatchToProps)(Edit);
