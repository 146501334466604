import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { Button, Row, Space } from 'antd';

import { onDeletePrime } from '../../redux/actions/primes';
import { hideModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';

const Delete = ({ onDelete, onHideModal }) => {
  const param = useParams();
  const history = useHistory();
  const location = useLocation();

  const onClickDelete = () => {
    if (!param || !param.primeId) {
      handleNotifications('error', 'Failed', 'Did not receive prime id');
      return;
    }
    onDelete(param['primeId']);
  };

  const onClickCancel = () => {
    onHideModal();
    history.goBack();
  };

  return (
    <>
      <h1>Delete Prime: {location.primeId}</h1>
      <Row style={{ justifyContent: 'center' }}>
        <Space direction="horizontal" align="center">
          <Button
            type="primary"
            danger
            htmlType="button"
            onClick={onClickDelete}
          >
            Delete
          </Button>
          <Button type="primary" htmlType="button" onClick={onClickCancel}>
            Cancel
          </Button>
        </Space>
      </Row>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onDelete: primeId => dispatch(onDeletePrime(primeId)),
  onHideModal: () => dispatch(hideModal())
});

Delete.propTypes = {
  onDelete: func,
  onHideModal: func
};

export default connect(null, mapDispatchToProps)(Delete);
