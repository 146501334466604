import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export const timeStringToMinutes = timeString => {
  const [hours, minutes] = timeString.split(':');
  return +hours * 60 + +minutes;
};

export const eventsArrayToJson = eventsArray => {
  if (!eventsArray || !eventsArray.length) {
    return {};
  }
  const eventsJson = {};
  eventsArray.forEach(eventItem => {
    /* Single Day Case*/
    if (eventItem.date_start === eventItem.date_end) {
      const dateObject = dayjs.utc(eventItem.date_start, 'YYYY-MM-DD');
      const currentYear = dateObject.get('year');
      const currentMonth = dateObject.get('month');
      const currentDate = dateObject.get('date');
      createEventListNode(
        eventsJson,
        currentYear,
        currentMonth,
        currentDate,
        eventItem
      );
    } else {
      /* Multi-Day Case */
      const startDateObject = dayjs(eventItem.date_start, 'YYYY-MM-DD');
      const endDateObject = dayjs(eventItem.date_end);
      const tempDateObject = startDateObject;

      let i = 0;
      while (tempDateObject.add(i, 'day').isSameOrBefore(endDateObject)) {
        const currentYear = tempDateObject.add(i, 'day').get('year');
        const currentMonth = tempDateObject.add(i, 'day').get('month');
        const currentDate = tempDateObject.add(i, 'day').get('date');
        createEventListNode(
          eventsJson,
          currentYear,
          currentMonth,
          currentDate,
          eventItem
        );
        i++;
      }
    }
    /* Multple Day Case??? */
  });
  return eventsJson;
};

const createEventListNode = (
  eventsJson,
  currentYear,
  currentMonth,
  currentDate,
  eventItem
) => {
  if (!eventsJson[currentYear]) {
    eventsJson[currentYear] = {};
  }
  if (!eventsJson[currentYear][currentMonth]) {
    eventsJson[currentYear][currentMonth] = {};
  }
  if (!eventsJson[currentYear][currentMonth][currentDate]) {
    eventsJson[currentYear][currentMonth][currentDate] = [];
  }
  eventsJson[currentYear][currentMonth][currentDate].push({
    ...eventItem,
    name: eventItem.title,
    time_start: utcToLocalString(eventItem.time_start),
    time_end: utcToLocalString(eventItem.time_end)
  });
};

export const utcToLocalString = utcTime => {
  if (!utcTime) {
    return '';
  }

  const offset = dayjs().utcOffset();
  const utcMinutes =
    +utcTime.split(':')[0] * 60 + +utcTime.split(':')[1] + offset;
  // console.log(utcMinutes / 60);
  const localHours = Math.floor(utcMinutes / 60);
  const localMinutes = (utcMinutes % 60).toFixed(0);

  const localTime =
    (localHours > 12 ? `${localHours % 12}` : `${localHours}`) +
    ':' +
    (localMinutes < 10 ? `0${localMinutes}` : `${localMinutes}`) +
    (localHours >= 12 ? ` pm` : ` am`);
  return localTime;
};
