import React from 'react';
import { string } from 'prop-types';
import Search from '../../searchForms/Search';

const SearchSidebar = ({ pathname }) => {
  switch (pathname) {
    case 'association':
      return <Search name="association" />;
    case 'small-business':
      return <Search name="smallBusiness" />;
    case 'primes':
      return <Search name="primes" />;
    case 'calendar':
      return <Search name="calendar" />;
    case 'rfp-rfq':
      return <Search name="rfpRfq" />;
    case 'look-ahead':
      return <Search name="lookAhead" />;
    case 'message-center':
        return <Search name="message-center" />;
    default:
      return null;
  }
};

SearchSidebar.propTypes = { pathname: string };

export default SearchSidebar;
