import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { instanceOf, object, string } from 'prop-types';
import { Image, Typography } from 'antd';

import RegisterForm from '../../components/auth/RegisterForm';
import brandLogo from '../../assets/images/brand-logo.png';
import bg from '../../assets/images/background.png'

const Register = ({ redirect, registerData }) => {
  const history = useHistory();

  useEffect(() => {
    if (redirect.path) {
      history.push({
        pathname: redirect.path,
        state: registerData
      });
    }
  }, [redirect.path, history, registerData]);

  return (
    <>
      <div className="register-container" style={{backgroundImage: `url(${bg})`}}>
        <div className="form-container">
          <Image 
            src={brandLogo}
            alt="BZConnects Logo"
            preview={false}
          />
          <div className="form-head">
            <Typography.Title>Register</Typography.Title>
            <small>You will receive an email notification to verify your email</small>
          </div>
          <RegisterForm />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  redirect: state.redirect,
  registerData: state.auth.registerData
});

Register.propTypes = {
  redirect: string,
  history: object,
  registerData: instanceOf(Object)
};

export default connect(mapStateToProps)(Register);
