import connection from '../utils/api';

/* GET RESET PASSWORD */
export const resetPassword = email =>
  connection.get(encodeURI(`reset-password?email=${email}`));

/* POST UPDATE PASSWORD */
export const updatePassword = payload =>
  connection.post(`reset-password`, payload);

/* POST UPDATE PASSWORD */
export const changePassword = payload =>
  connection.post(`change-password`, payload);
