import React from 'react';
import { Col, Row } from 'antd';
import UsersList from './usersList';

const AddUsers = () => {

  return (
    <Row align="middle" justify="center" className="addUsers-container">
      <Col span={24}>
        <UsersList />
      </Col>
    </Row>
  );
};

export default AddUsers;
