import { 
  SET_CONTACT_REQUESTS,
  ADD_CONTACT_REQUEST,
  UPDATE_CONTACT_REQUESTS,
  FILTER_CONTACT_REQUESTS,
} from '../actionTypes';

const initialState = {
  contact_requests: []
};

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_REQUESTS:
      return { ...state, contact_requests: [...action.payload]};
    case ADD_CONTACT_REQUEST:
      return { ...state, contact_requests: [...state.contact_requests, ...action.payload] };
    case UPDATE_CONTACT_REQUESTS:
      let index = state.contact_requests.findIndex(e => e.id === action.payload.id );
      state.contact_requests[index] = action.payload;
      return { ...state, contact_requests: state.contact_requests };
    case FILTER_CONTACT_REQUESTS:
        return { ...state, contact_requests: state.contact_requests.filter(c => c.id !== action.contactId) };
    default:
      return { ...state };
  }
};
