import React from 'react';
import { connect } from 'react-redux';
import { bool, element } from 'prop-types';
import { Layout } from 'antd';

import Spinner from '../components/ui/Spinner';
import PageHeader from '../components/ui/PageHeader';
import PageFooter from '../components/ui/PageFooter';

const { Footer, Header } = Layout;

const PageLayout = ({ loading, loggedIn, children }) => (
  <>
    {loading ? <Spinner /> : null}
    <Layout style={{ height: '100%', width: '100%' }}>
      {loggedIn ? (
        <Header>
          <PageHeader />
        </Header>
      ) : null}
      <Layout style={{ width: '100%' }}>{children}</Layout>
      <Footer>
        <PageFooter />
      </Footer>
    </Layout>
  </>
);

const mapStateToProps = state => ({
  loading: state.ui.loading,
  loggedIn: state.auth.loggedIn
});

PageLayout.propTypes = {
  loading: bool,
  loggedIn: bool,
  children: element
};

export default connect(mapStateToProps)(PageLayout);
