import { RESET_DASHBOARD, SET_ACTIVE_COMP } from '../actionTypes';
import { stringToDateObject, dateTimeUtcToLocal } from '../../utils/dateTimeHelper';
import { getFromLocalStorage } from '../../utils/localStorage';

const initialState = {
  activeComps: [
    {name: 'Look Ahead Calendar', codename: 'look_ahead_schedule_perm'},
    {name: 'RFP/Bid Closing Soon', codename: 'rfp_perm'},
    {name: 'Calendar Events', codename: 'calendar_perm'},
    {name: 'Welcome New Members', codename: 'small_business_perm'}
  ],
  lookAheads: [],
  rfp_rfqs: [],
  calendars: [],
  smallBusiness: [],
  primes: []
};

export const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_COMP:
      return { ...state, activeComps: componentsFilter(payload || state.activeComps)};
    case 'GET_DASHBOARD_LOOK_AHEAD':
      return { ...state, lookAheads: dataParser("lookAheads", payload) };
    case 'GET_DASHBOARD_RFP_RFQ':
      return { ...state, rfp_rfqs: dataParser("rfp_rfq", payload) };
    case 'GET_DASHBOARD_CALENDAR':
      return { ...state, calendars: dataParser("calendar", payload) };
    case 'GET_DASHBOARD_SMALL_BUSINESS':
      return { ...state, smallBusiness: dataParser("smallbusiness", payload) };
    case 'GET_DASHBOARD_PRIMES':
      return { ...state, primes: dataParser("primes", payload) };
    case RESET_DASHBOARD: 
      return { ...initialState }
    default:
      return { ...state };
  }
};

const componentsFilter = (comps) => {
  const permissions = getFromLocalStorage('permissions');
  return comps.filter(item=> permissions[item.codename] ? true : false);
}

const dataParser = (type, data) => {
  switch(type){
    case "lookAheads":
      return data.map(item => ({
            ...item,
            estimated_cost: item['estimated_cost'] ? `$ ${item['estimated_cost']}` : '-',
            key: item['id'],
            proposed_advertising_date: item['proposed_advertising_date']
              ? stringToDateObject(item?.['proposed_advertising_date']).format('MMM DD, YYYY')
              : ''
          }))
    case "rfp_rfq": {
      return data.map(item => ({
              ...item,
              key: item['id'],
              issue_date: item.issue_date
                ? stringToDateObject(item.issue_date).format('MMMM DD, YYYY')
                : '',
              due_date: item.due_date_time
                ? dateTimeUtcToLocal(item.due_date_time).format(
                    'MMMM DD, YYYY'
                  )
                : '',
              due_time: item.due_date_time
                ? dateTimeUtcToLocal(item.due_date_time).format('hh:mma')
                : ''
            }))
    }
    case "calendar":{
      return data.length ? data : []
    }
    case "smallbusiness":{
      return data.map(item => ({
        ...item,
        key: item['id'],
        industry_specialization: item['industry_specialization']?.length ? item['industry_specialization'].join(', ') : '-'
      }));
    }
    case "primes":{
      return data.map(item => ({
        ...item,
        key: item.id,
        company_specialization: item.company_specialization || '-',
        key_projects: item?.key_projects?.length ? item['key_projects'].map(project => project.title).join(', ') : '-'
      }))
    }
    default:
      return []
  }
}