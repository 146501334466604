import connection from '../utils/api';

/* GET fetch contact information */
export const getContacts = query => {
    let queryString = '';
    if(!query){
        queryString = '';
    }
    let queryParams = [];
    if(query.page){
        queryParams.push(`page=${query.page}`);
    }
    if(query.limit){
        queryParams.push(`limit=${query.limit}`);
    }
    queryString = `?` + queryParams.join('&');
    return connection.get(`contact${queryString}`);
}


export const getContact = id => connection.get(`contact/${id}`)
export const addContact = data => connection.post(`contact`, data);
export const deleteContact = id => connection.delete(`contact/${id}`);

