import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import TableComponent from '../../components/ui/TableComponent';
import { columns } from '../../components/configs/tables/videos.json';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import { accessRules } from '../../utils/accessRules';
import {
  getCurrentPageFromLocation,
  HeaderRenderer
} from '../../utils/utils';
import { Link } from 'react-router-dom';
import { getVideos } from '../../api/videos';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { onDeleteVideo, refreshVideos } from '../../redux/actions/videos';

const List = ({
  role,
  refresh,
  onSetRefresh,
  onShowModal,
  DeleteVideo
}) => {
  const match = useRouteMatch();
  const innerWidth = window.innerWidth;
  const [videos, setVideos] = useState([]);

  const [currentTableProps, setCurrentTableProps] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });

  const fetchVideos = async () => {
    try {
      const response = await getVideos();
      const list = response.data?.data?.data;
      setVideos(list.filter(item=> !item.is_deleted));
    } catch (error) {
      handleNotifications('error', 'Could not fetch', error.message);
    }
  };

  useEffect(() => {
    if (!refresh) {
      return;
    }
    fetchVideos();
    onSetRefresh(false);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    fetchVideos();
    // eslint-disable-next-line
  }, [currentTableProps.pagination.current]);

  const onDelete = (id) => {
    DeleteVideo(id);
  }

  /* Render action column */
  const renderMethod = (text, record) => {
    return (
      <>
        <Space size="small">
          <Tooltip
            title="Edit"
            onClick={() => {
              onShowModal();
            }}
          >
            <Link style={{ marginRight: '5px' }} to={`${match.path}/video-edit/${record.id}`}><EditOutlined style={{ fontSize: '18px' }} /></Link>
          </Tooltip>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Popconfirm
              title="Are you sure to delete this video?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <span style={{ marginRight: '5px' }}>
                  <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
                </span>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      </>
    );
  };

  const tableColumns = HeaderRenderer(columns, innerWidth);

  tableColumns.push({
    title: 'Action',
    key: 'action',
    displayName: 'action',
    render: renderMethod
  });

  const manageDataSource = ({ current, pageSize }, filters, sorter) => {
    let tableProps = { ...currentTableProps };
    if (current) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, current }
      };
    }
    if (pageSize) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, size: pageSize }
      };
    }
    if (filters) {
      tableProps = {
        ...tableProps,
        filters: { ...tableProps.filters, ...filters }
      };
    }
    setCurrentTableProps({ ...tableProps });
  };

  return (
    <>
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          display: 'flex',
          padding: '8px',
          flexDirection: 'row',
          justifyContent: 'space-between'
          // alignItems: 'center'
        }}
      >
        <Space style={{ alignSelf: 'flex-start' }}>My Videos</Space>

        <Space style={{ alignSelf: 'flex-end' }}>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                onShowModal();
              }}
            >
              <Link to={`${match.path}/video-create`}>Create New</Link>
            </Button>
          ) : null}
        </Space>
      </Space>
      <div className="divider"></div>

      <TableComponent
        columns={tableColumns}
        tableData={videos}
        pagination={{
          position: ['bottomCenter'],
          current: currentTableProps.pagination.current,
          pageSize: currentTableProps.pagination.size,
          total: currentTableProps.pagination.total,
          hideOnSinglePage: true
        }}
        manageDataSource={manageDataSource}
      />
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  refresh: state.userAgreement.refreshVideos
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading()),
  DeleteVideo: (id) => dispatch(onDeleteVideo(id)),
  onSetRefresh: (payload) => dispatch(refreshVideos(payload))
});

List.prototype = {
  role: string,
  refresh: bool,
  onSetRefresh: func,
  onShowModal: func,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
