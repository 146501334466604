import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  Button,
  Select,
  Spin,
  // Input
} from 'antd';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { objectToFormData } from '../../utils/utils';
import { fetchPackages } from '../../api/payments';
import { showUserAgreement } from '../../api/userAgreement';
import { handleNotifications } from '../../utils/notifications';
import { onUpdateAgreement } from '../../redux/actions/userAgreement';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24
  },
};

const UserAgreementUpdate = ({ onUpdate }) => {

  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [agreement, setAgreement] = useState({});

  const { agreementId } = useParams();

  useEffect(() => {

    if (!agreementId) {
      return;
    }

    async function fetchAgreement(id) {
      let res = await showUserAgreement(id);
      if (res.data['success']) {
        setAgreement(res.data['data']);
      } else {
        handleNotifications('error', 'Could not fetch', res.data.message);
      }
    }

    async function getPackages() {
      let res = await fetchPackages()
      if (res.data['success']) {
        let data = res.data['data'];
        let packages = [];
        // eslint-disable-next-line
        data.map(p => {
          // eslint-disable-next-line
          p.package_type.map(pt => {
            let pack = { id: pt.id, name: p.name + " - " + pt.name }
            packages.push(pack)
          })
        })
        setPackages(packages);
        setLoading(false);
      } else {
        handleNotifications('error', 'Could not fetch', res.data.message);
      }
    }

    getPackages();
    fetchAgreement(agreementId);
    
    // eslint-disable-next-line
  }, [agreementId]);

  const onFinish = (values) => {
    console.log('Success:', values);
    values.user_agreement = agreement.user_agreement;
    onUpdate(agreement.id, objectToFormData(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  function onEditorChange(e) {
    setAgreement({
      ...agreement,
      ...{ user_agreement: e.editor.getData() }
    })
  }
  

  return (
    <>
    { loading ? <Spin /> :
      <Form
        {...layout}
        layout="vertical"
        initialValues={{
          package_type_id: agreement.package_type_id
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="package_type_id" label="Package Type">
          <Select
            disabled={true}
            options={packages.map(e => ({
              label: e.name,
              value: e.id
            }))}
          >
          </Select>
        </Form.Item>

        {/* <Form.Item name="price" label="Price">
          <Input placeholder="0.00" />
        </Form.Item> */}

        <div style={{ marginBottom: '10px' }}>
          <CKEditor data={agreement.user_agreement} onChange={onEditorChange} />
        </div> 

        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    }
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onUpdate: (id, payload) => dispatch(onUpdateAgreement(id, payload))
});

UserAgreementUpdate.propTypes = { config: array, onUpdate: func };

export default connect(null, mapDispatchToProps)(UserAgreementUpdate);