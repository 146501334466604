import React, { useEffect, useState } from 'react';
import { any, func, number, string } from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker, Radio, Space } from 'antd';
// import { NodeCollapseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const DateInput = ({
  valueProps,
  keyName,
  picker,
  showTime,
  mode,
  limit,
  parentHandler,
  allowPastDates
}) => {
  const [inputMode, setInputMode] = useState('single');
  const [dateMode, setDatesMode] = useState('single');
  const [dateValue, setDatesValue] = useState(null);

  useEffect(() => {
    if (mode) {
      setInputMode(mode);
    }
  }, [mode /* , value */]);

  useEffect(() => {
    console.log(valueProps);
    if (valueProps && valueProps.mode && valueProps.mode !== dateMode) {
      setDatesMode(valueProps.mode);
    }
    // eslint-disable-next-line
  }, [valueProps]);

  const onChangeMode = dateMode => {
    if (valueProps) {
      valueProps['value'] = null; // MomentJS Bug Fixed
    }
    setDatesMode(dateMode);
    setDatesValue(null);
    parentHandler(keyName, null);
  };

  const onSelectDate = value => {
    let selectedDate = dateValue;
    if (
      inputMode === 'range' ||
      (inputMode === 'dynamic' && dateMode === 'multi-day')
    ) {
      selectedDate = {
        [`${keyName}_start`]: value
          ? showTime
            ? value[0]?.utc()?.format('MM/DD/YYYY HH:mm:ss')
            : value[0]?.format('MM/DD/YYYY')
          : null,
        [`${keyName}_end`]: value
          ? showTime
            ? value[1]?.utc()?.format('MM/DD/YYYY HH:mm:ss')
            : value[1]?.format('MM/DD/YYYY')
          : null
      };
    }
    // else if (inputMode === 'dynamic' && dateMode === 'multiple') {
    // /* Multiple Day not implemented */
    // }
    else {
      selectedDate = {
        [keyName]: showTime
          ? value?.utc()?.format('MM/DD/YYYY HH:mm:ss')
          : value?.format('MM/DD/YYYY')
      };
    }
    // setDatesValue(selectedDate);
    parentHandler(keyName, selectedDate);
  };

  const disabledDate = current =>
    current && current < dayjs().subtract(limit, 'day').endOf('day');

  const createDateElement = inputMode => {
    switch (inputMode) {
      case 'dynamic':
        return (
          <>
            <Radio.Group
              value={dateMode}
              onChange={e => {
                onChangeMode(e.target.value);
              }}
            >
              <Radio value={'single'}>Single Day</Radio>
              <Radio value={'multi-day'}>Multi-Day</Radio>
            </Radio.Group>
            {dateMode === 'single' ? (
              <DatePicker
                showTime={showTime}
                picker={picker || 'date'}
                onChange={onSelectDate}
                defaultValue={valueProps['value']}
                disabledDate={disabledDate}
                format="YYYY-MM-DD HH:mm:ss"
              />
            ) : null}
            {dateMode === 'multi-day' ? (
              <RangePicker
                showTime={showTime}
                picker={picker || 'date'}
                onChange={onSelectDate}
                defaultValue={valueProps['value']}
                disabledDate={disabledDate}
              />
            ) : null}
            {/* {dateMode === 'multiple' ? '???' : null}) */}
          </>
        );
      case 'range':
        return (
          <RangePicker
            picker="date"
            onChange={onSelectDate}
            disabledDate={disabledDate}
          />
        );
      default:
        return (
          <DatePicker
            picker={picker || 'date'}
            onChange={onSelectDate}
            disabledDate={allowPastDates ? false : disabledDate}
            defaultValue={valueProps ? dayjs(valueProps) : null}
          />
        );
    }
  };
  const dateElement = createDateElement(inputMode);

  return (
    <>
      <Space direction="vertical">{dateElement}</Space>
    </>
  );
};

DateInput.propTypes = {
  name: string,
  valueProps: any,
  keyName: string,
  picker: string,
  mode: string,
  limit: number,
  parentHandler: func
};

export default DateInput;
