import React from 'react';
import { Spin } from 'antd';

const Spinner = () => (
  <Spin
    size="large"
    tip="Loading..."
    spinning={true}
    style={{
      position: 'fixed',
      height: '150vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 99999,
      backgroundColor: 'rgba(204,204,204,0.5)'
    }}
  />
);

export default Spinner;
