import React, { /* useEffect, */ useState } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { Button, Form, Space } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined
} from '@ant-design/icons';

import dynamicFormConfigs from '../../../configs/forms/dynamicFormFields.json';
import { objectToFormData } from '../../../../utils/utils';
import FormElement from '../FormElement';
import FileUpload from './FileUpload';

const DynamicForm = ({
  keyName,
  label,
  min,
  max,
  proof,
  microApiHandler,
  nestedHandler
}) => {
  const [data, setData] = useState([]);
  const [fieldsList, setFieldsList] = useState([]);
  // const [loading, setLoading] = useState([]);

  const changeHandler = /* e */ (key, index, value) => {
    const tempData = [...data];
    tempData[index] = { ...tempData[index], ...value };
    setData([...tempData]);
  };

  const saveFieldItem = fieldIndex => {
    if (!microApiHandler) {
      const tempFieldsList = [...fieldsList];
      setFieldsList([...tempFieldsList, data[fieldIndex]]);
      nestedHandler(
        keyName,
        data.map(item => item.certificate)
      );
    } else {
      let payload = { ...data[fieldIndex] };
      if (payload.address) {
        payload = { ...payload, ...payload.address };
        delete payload.address;
      }
      if (!proof) {
        delete payload.proof;
      }
      microApiHandler
        .add(keyName, objectToFormData(payload))
        .then(({ data }) => {
          if (data.success) {
            const tempFieldsList = [...fieldsList];
            tempFieldsList[fieldIndex] = data.data['id'];
            setFieldsList([...tempFieldsList]);
            nestedHandler(keyName, [...tempFieldsList]);
          } else {
            console.log('error', data.error);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const removeFieldItem = index => {
  };

  return (
    <Form.List name={keyName}>
      {(fields, { add, remove }) => (
        <>
          {fields && fields.length
            ? fields.map((field, fieldIndex) => (
                <Space
                  key={`${field.key}_${fieldIndex}`}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="start"
                >
                  <Space>
                    {dynamicFormConfigs[keyName].map(item => {
                      return (
                        <Form.Item
                          name={[field.name, item.key]}
                          fieldKey={[field.fieldKey, item.key]}
                          key={`${item.key}_${fieldIndex}`}
                        >
                          <FormElement
                            config={item}
                            nestedHandler={(key, value) => {
                              changeHandler(key, fieldIndex, value);
                            }}
                          />
                        </Form.Item>
                      );
                    })}
                  </Space>
                  <Space>
                    {proof ? (
                      <FileUpload
                        keyName={keyName}
                        nestedHandler={(key, value) =>
                          changeHandler(key, value, fieldIndex)
                        }
                      />
                    ) : null}
                  </Space>
                  <Space>
                    <MinusCircleOutlined
                      onClick={() => {
                        // console.log(fields);
                        remove(field.name);
                        removeFieldItem(fieldIndex);
                      }}
                    />
                    <SaveOutlined
                      hidden={!!fieldsList[fieldIndex]}
                      onClick={() => {
                        saveFieldItem(fieldIndex);
                      }}
                    />
                  </Space>
                </Space>
              ))
            : null}

          {fields.length <= min || fields.length < max ? (
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                block
              >
                <PlusOutlined /> Add {label}
              </Button>
            </Form.Item>
          ) : null}
        </>
      )}
    </Form.List>
  );
};

DynamicForm.propTypes = {
  keyName: string,
  label: string,
  min: number,
  max: number,
  proof: bool,
  microApiHandler: object,
  nestedHandler: func
};

export default DynamicForm;
