import connection from '../utils/api';
import { smallBusiness } from '../utils/apiStrings.json';

/* Add API for Field */
export const addField = (key, payload) =>
  connection.post(`small_business/${smallBusiness[key]}`, payload);

/* Update API for Field */
export const updateField = (key, id, payload) =>
  connection.put(`small_business/${smallBusiness[key]}/${id}`, payload);

/*  Delete API for Field */
export const deleteField = (key, id) =>
  connection.delete(`small_business/${smallBusiness[key]}/${id}`);
