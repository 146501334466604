import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Image,
  Space,
  Typography,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../redux/actions/ui';
import { createSubscription } from '../../api/payments';
import { getUser } from '../../api/addUsers';
import { fetchPermissions } from '../../redux/actions/auth';
import { MAKE_PAYMENT_ACTION } from '../../redux/actions/payments';
import { objectToFormData } from '../../utils/utils';
import brandLogo from '../../assets/images/brand-logo-light-no-tagline1.png';
import { handleNotifications } from '../../utils/notifications';
import {
  getcurrentDateTime,
  stringToDateObject
} from '../../utils/dateTimeHelper';

const SubscriptionStep = ({
  currentStep,
  handleStep,
  hideModal,
  fetchPermissions,
  data,
  MAKE_PAYMENT_ACTION
}) => {
  const [formValues, setFormValues] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const loginData = useSelector(state => state.auth);

  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    if (data) {
      setFormValues(prev => ({ ...prev, ...data }));
    }
  }, [data]);

  const fetchCards = async () => {
    const { data } = await getUser(loginData?.user?.id);
    setUserInfo(data.data);
  };

  const handleSubmit = async () => {
    let payload = {
      ...formValues,
      object_type:
      formValues.type ||
      (loginData?.role === 'prime'
        ? 'Primes'
        : loginData?.role === 'small-business'
        ? 'SmallBusiness'
        : 'Association'),
      object_id: formValues.company_id || loginData?.user?.businessId,
      profileId: userInfo.customer_profile_id,
      paymentProfileId: userInfo.customer_payment_id,
      customerAddressId: userInfo.customer_address_id
    };
    const currentDate = getcurrentDateTime();
    const startDate = currentDate.add(7, 'days').format('YYYY-MM-DD');
    payload['startDate'] = startDate;
    console.log('payload', payload);

    let res = await createSubscription(objectToFormData(payload));
    if (res.data?.success) {
      handleNotifications('success', 'BZ Connects', res.data?.message_text);
      const date = stringToDateObject(res.data?.account_expiry_date);
      MAKE_PAYMENT_ACTION(date);
      loginData?.user.id && fetchPermissions();
      handleStep(currentStep + 1);
      return;
    } else {
      handleNotifications('error', 'BZ Connects', res.data?.message_text);
    }
  };

  const submitFail = errors => {
    console.log(errors);
  };

  return (
    <React.Fragment>
      <div className="payment-form-container">
        <header>
          <span>
            <Image
              src={brandLogo}
              preview={false}
              width="180px"
              height="70px"
            />
          </span>
          <Typography.Title level={2}>Make Payment</Typography.Title>
        </header>
      </div>
      {'Your payment ID: ' + userInfo?.customer_payment_id}
      <div wrapperCol={{ offset: 6, span: 12 }} style={{ textAlign: 'center' }}>
        <Space direction="horizontal" align="center">
          <Button
            type="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              hideModal();
              handleStep && handleStep(0);
            }}
          >
            Cancel
          </Button>
        </Space>
      </div>
    </React.Fragment>
  );
};

SubscriptionStep.propTypes = {
  handleStep: PropTypes.func.isRequired,
  MAKE_PAYMENT_ACTION: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired
};

export default connect(null, {
  hideModal,
  fetchPermissions,
  MAKE_PAYMENT_ACTION
})(SubscriptionStep);
