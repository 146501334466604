import { CALENDAR_REFRESH, CALENDAR_SET_EVENTS, FETCH_EVENTS } from '../actionTypes';

const initialState = {
  date: null,
  events: [],
  refresh: true,
  fetchEvents: false
};

export const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CALENDAR_SET_EVENTS:
      return { ...state, events: [...action.payload] };
    case CALENDAR_REFRESH:
      return { ...state, refresh: action.payload };
    case FETCH_EVENTS:
      return { ...state, fetchEvents: action.payload }
    default:
      return { ...state };
  }
};
