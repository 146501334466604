import connection from '../utils/api';

/* Fetching the user agreement */
export const getUserAgreements = query => {
    let queryString = '';
    if(!query){
        queryString = '';
    }
    let queryParams = [];
    if(query.page) {
        queryParams.push(`page=${query.page}`);
    }
    if(query.limit) {
        queryParams.push(`limit=${query.limit}`);
    }
    queryString =`?` + queryParams.join('&');
    return connection.get(`user-agreement${queryString}`);
}

export const showUserAgreement = id => connection.get(`user-agreement/${id}`);
export const createUserAgreement = data => connection.post('user-agreement', data);
export const updateUserAgreement = (id, data) => connection.put(`user-agreement/${id}`, data);
export const deleteUserAgreement = id => connection.delete(`user-agreement/${id}`);

export const showTos = id => connection.get(`tos/${id}`);