/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Button, Space, Typography } from 'antd';

import { events } from '../../components/configs/forms/calendar.json';
import ModalComponent from '../../components/ui/ModalComponent';
import CalendarComponent from '../../components/common/Calendar/CalendarComponent';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { clearRedirect } from '../../redux/actions/redirect';
import { getCurrentPageFromLocation } from '../../utils/utils';
import { accessRules } from '../../utils/accessRules';
import { getcurrentDateTime } from '../../utils/dateTimeHelper';
import Add from './Add';
import Edit from './Edit';
import EventsList from './EventsList';
import Export from './Export';
import Import from './Import';
import View from './View';

const { Title } = Typography;

const Calendar = ({
  role,
  redirect,
  onShowModal,
  onClearRedirect
}) => {
  const [currentMonthYear, setCurrentMonthYear] = useState(
    getcurrentDateTime().format('MMMM, YYYY')
  );

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
  }, [redirect.path]);

  return (
    <>
      <ModalComponent goBack={history.location.state?.goBack}>
        <Switch>
          <Route
            path={`${match.path}/add`}
            render={() => <Add config={events} />}
          />
          <Route path={`${match.path}/import`} component={Import} />
          <Route path={`${match.path}/export`} component={Export} />
          <Route path={`${match.path}/list/:date`} component={EventsList} />
          <Route path={`${match.path}/view/:eventId`} component={View} />
          <Route
            path={`${match.path}/edit/:eventId`}
            render={() => <Edit config={events} />}
          />
        </Switch>
      </ModalComponent>

      <Space
        direction="horizontal"
        style={{
          width: '100%',
          display: 'flex',
          padding: '8px',
          flexDirection: 'row',
          justifyContent: 'space-between'
          // alignItems: 'center'
        }}
      >
        <Space id="current-month" style={{ alignSelf: 'flex-start' }}>
          <Title level={2}>{currentMonthYear}</Title>
        </Space>

        <Space style={{ alignSelf: 'flex-end' }}>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                onShowModal();
              }}
            >
              <Link to={`${match.path}/add`}>Add Event</Link>
            </Button>
          ) : null}

          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'import'
          ] ? (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                onShowModal();
              }}
            >
              <Link to={`${match.path}/import`}>Import Events</Link>
            </Button>
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'export'
          ] ? (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                onShowModal();
              }}
            >
              <Link to={`${match.path}/export`}>Export Events</Link>
            </Button>
          ) : null}
        </Space>
      </Space>
      <div className="divider"></div>

      <CalendarComponent
        setMonthYear={(month, year) => {
          setCurrentMonthYear(`${month}, ${year}`);
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect,
  refresh: state.calendar.refresh,
  searchParams: state.search.calendar
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect()),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading())
});

Calendar.propTypes = {
  role: string,
  redirect: object,
  onShowModal: func,
  onClearRedirect: func
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
