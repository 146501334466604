import connection from '../utils/api';

export const getDropdownOptions = (key, subkey) => {
  return key === 'type_event' ||
    key === 'type_rfp_rfq' ||
    key === 'type_of_work' ||
    key === 'first_year_revenue' ||
    key === 'second_year_revenue' ||
    key === 'third_year_revenue' ||
    key === 'no_of_employees' ||
    key === 'service' ||
    key === 'specializations' ||
    key === 'agencies'
    ? new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(getOptionsPromise(key));
        }, 0);
      })
    : connection.get(
        `options?type=["${key}"]${subkey ? '&subtype=' + subkey : ''}`
      );
};

const getOptionsPromise = key => {
  const response = { data: { data: {}, success: true } };
  switch (key) {
    case 'type_rfp_rfq':
      response['data']['data'][key] = [
        { rfp: 'RFP' },
        { rfq: 'RFQ' },
        { rfq: 'RFI' },
        { ifb: 'IFB' }
      ];
      break;
    case 'type_event':
      response['data']['data'][key] = [
        { virtual: 'Virtual' },
        { inPerson: 'In Person' }
      ];
      break;
    case 'type_of_work':
      response['data']['data'][key] = [
        { rfp: 'RFP' },
        { rfq: 'RFQ' },
        { rfq: 'RFI' },
        { ifb: 'IFB' }
      ];
      break;
    case 'first_year_revenue':
      response['data']['data'][key] = [
        { greaterThanOneMillion: '>$1,000,000' },
        { oneMillionToFiveMillion: '$1,000,000 - $5,000,000' },
        { greaterThanFiveMillion: '$5,000,000+' },
        { na: 'Not Applicable' }
      ];
      break;
    case 'second_year_revenue':
      response['data']['data'][key] = [
        { greaterThanOneMillion: '>$1,000,000' },
        { oneMillionToFiveMillion: '$1,000,000 - $5,000,000' },
        { greaterThanFiveMillion: '$5,000,000+' },
        { na: 'Not Applicable' }
      ];
      break;
    case 'third_year_revenue':
      response['data']['data'][key] = [
        { greaterThanOneMillion: '>$1,000,000' },
        { oneMillionToFiveMillion: '$1,000,000 - $5,000,000' },
        { greaterThanFiveMillion: '$5,000,000+' },
        { na: 'Not Applicable' }
      ];
      break;
    case 'no_of_employees':
      response['data']['data'][key] = [
        { zeroToTen: '0-10 ' },
        { elevenToTwentyFive: '11-25' },
        { twentySixToFifty: '26-50' },
        { fiftyOneToHundred: '51-100' },
        { greaterThanHundred: '100+' }
      ];
      break;
    case 'service':
      response['data']['data'][key] = [
        { Auditing: 'Auditing' },
        { civilEngineering: 'Civil Engineering' },
        { complianceMonitoring: 'Compliance/Monitoring' },
        { computerServices: 'Computer Services'},
        { conceptDesign: 'Conceptual Design'},
        { constructionManagement: 'Construction Management' },
        { designbuild: 'Design/Build'},
        { envImpactPlanning: 'Environmental Impact Planning'},
        { envRemediation: 'Environmental Remediation'},
        { fareCollection: 'Fare Collection'},
        { operationsMaintenance: 'Operations/Maintenance'},
        { permittingAndDesign: 'Permitting and Design' },
        { planningServices: 'Planning Services'},
        { programManagement: 'Program Management' },
        { projectManagement: 'Project Management' },
        { safetySecurity: 'Safety/Security' },
        { systemDesignEngineering: 'System Design Engineering'},
        { talentAcquisition: 'Talent Acquisition'},
        { trainingDevelopment: 'Training/Development' },
      ];
      break;
    case 'specializations':
      response['data']['data'][key] = [
        // { commercialRealEstate: 'Commercial/Real Estate' },
        // {
        //   educationK12HigherEducationLearningCenters:
        //     'Education/K-12/Higher Education/Learning Centers'
        // },
        // {PublicAffairs: 'Public Affairs/Government Relations'},
        // { governmentMilitary: 'Government/Military' },
        // { healthcareClinics: 'Healthcare/Clinics' },
        // { oilGasMining: 'Oil/Gas/Mining' },
        // { powerUtilities: 'Power/Utilities' },
        // { transportationAviationPorts: 'Transportation: Aviation/Ports' },
        // { transportationLightHeavyRail: 'Transportation: Light/Heavy Rail' },
        // { transportationRoadwaysHighways: 'Transportation: Roadways/Highways' },
        // { waterWasteWater: 'Water/Waste Water' },
        { '3D Modeling': '3D Modeling' },
        { AcousticalConsultingServices: 'Acoustical Consulting Services' },
        { AdministrativeSupportServices: 'Administrative Support Services' },
        { AerialPhotographyServices: 'Aerial Photography Services' },
        { AerospaceServices: 'Aerospace Services' },
        { ArchitecturalServices: 'Architectural Services' },
        { Auditing: 'Auditing' },
        { BrandingServices: 'Branding Services' },
        { Broadcasting: 'Broadcasting' },
        { BusinessDevelopmentServices: 'Business Development Services' },
        { civilEngineering: 'Civil Engineering' },
        { complianceMonitoring: 'Compliance/Monitoring' },
        { computerServices: 'Computer Services'},
        { CommercialPrinting: 'Commercial Printing' },
        { CommercialRealEstate: 'Commercial Real Estate' },
        { CommunityOutreach: 'Community Outreach' },
        { conceptDesign: 'Conceptual Design'},
        { constructionManagement: 'Construction Management' },
        { ConcreteContractor: 'Concrete Contractor' },
        { ConfigurationManagement: 'Configuration Management' },
        { ConstructionAuditsPerformanceCompliance: 'Construction Audits/Performance/Compliance' },
        { ConstructionContractor: 'Construction Contractor' },
        { ConstructionDesign: 'Construction Design' },
        { ConstructionInspectionandMaterialTestingServices: 'Construction Inspection and Material Testing Services' },
        { ConstructionManagement: 'Construction Management' },
        { ConstructionManagementMaterialsandSupplies: 'Construction Management - Materials and Supplies' },
        { ConstructionManagementSupportServices: 'Construction Management Support Services' },
        { ConstructionQAQC: 'Construction QA-QC' },
        { DataScienceServices: 'Data Science Services' },
        { DBECompliance: 'DBE Compliance' },
        { designbuild: 'Design/Build'},
        { DesignEngineeringServices: 'Design Engineering Services' },
        { DesignofCivilRoadwayandBridgeStructures: 'Design of Civil Roadway and Bridge Structures' },
        { DesignQAQC: 'Design QA/QC' },
        { DigitalPrinting: 'Digital Printing' },
        { DiversityandInclusionTraining: 'Diversity and Inclusion Training' },
        { DocumentManagement: 'Document Management' },
        { Drafting: 'Drafting' },
        { DronePhotography: 'Drone Photography' },
        { ElectricVehicleCharging: 'Electric Vehicle Charging' },
        { ElectricalContractor: 'Electrical Contractor' },
        { ElectricalEngineering: 'Electrical Engineering' },
        { EngineeringServices: 'Engineering Services' },
        { EnvironmentalServices: 'Environmental Services' },
        { envImpactPlanning: 'Environmental Impact Planning'},
        { envRemediation: 'Environmental Remediation'},
        { EstimatingServices: 'Estimating Services' },
        { FacilitiesManagement: 'Facilities Management' },
        { FacilitySupportServices: 'Facility Support Services' },
        { FeasibilityStudies: 'Feasibility Studies' },
        { FinancialServices: 'Financial Services' },
        { FreightTransportServices: 'Freight Transport Services' },
        { fareCollection: 'Fare Collection'},
        { operationsMaintenance: 'Operations/Maintenance'},
        { permittingAndDesign: 'Permitting and Design' },
        { planningServices: 'Planning Services'},
        { programManagement: 'Program Management' },
        { safetySecurity: 'Safety/Security' },
        { systemDesignEngineering: 'System Design Engineering'},
        { GeneralContractor: 'General Contractor' },
        { GeotechnicalServices: 'Geotechnical Services' },
        { GISConsultingServices: 'GIS Consulting Services' },
        { GISPhotogrammetricServices: 'GIS Photogrammetric Services' },
        { GovernmentAffairs: 'Government Affairs' },
        { HeavyEquipmentRentalServices: 'Heavy Equipment Rental Services' },
        { IndustrialandMaterialsSupply: 'Industrial and Materials Supply' },
        { InspectionServices: 'Inspection Services' },
        { ITSupportServices: 'IT Support Services' },
        { LaborCompliance: 'Labor Compliance' },
        { Landandrightofwayconsultingservices: 'Land and right-of-way consulting services' },
        { LandSurveying: 'Land Surveying' },
        { LandscapeDesignServices: 'Landscape Design Services' },
        { LandscapingServices: 'Landscaping Services' },
        { LEEDandSustainabilityServices: 'LEED and Sustainability Services' },
        { ManagementConsultant: 'Management Consultant' },
        { MarketingServices: 'Marketing Services ' },
        { OutreachServices: 'Outreach Services' },
        { PhotographyServices: 'Photography Services' },
        { Planningtrafficengineeringanddesign: 'Planning traffic engineering and design' },
        { PreConstructionSurveys: 'Pre-Construction Surveys' },
        { ProjectControls: 'Project Controls' },
        { ProjectManagement: 'Project Management' },
        { PromotionalItems: 'Promotional Items' },
        { PublicAffairs: 'Public Affairs' },
        { PublicRelations: 'Public Relations' },
        { RebarContractor: 'Rebar Contractor' },
        { SafetyAccessEquipmentSupplier: 'Safety Access Equipment Supplier' },
        { SafetyServices: 'Safety Services' },
        { SchedulingServices: 'Scheduling Services' },
        { SecurityGuardServices: 'Security Guard Services' },
        { SiteExcavationServices: 'Site Excavation Services' },
        { SocialResponsibility: 'Social Responsibility' },
        { SoftwareDevelopment: 'Software Development' },
        { SoftwareTraining: 'Software Training' },
        { SolarEnergyServices: 'Solar Energy Services' },
        { StaffAugmentation: 'Staff Augmentation' },
        { StrategicConsulting: 'Strategic Consulting' },
        { StructuralEngineering: 'Structural Engineering' },
        { SupplyChainManagement: 'Supply Chain Management' },
        { SurveyingandMappingServices: 'Surveying and Mapping Services' },
        { talentAcquisition: 'Talent Acquisition'},
        { trainingDevelopment: 'Training/Development' },
        { TechnicalAssistanceServices: 'Technical Assistance Services' },
        { TrafficAnalysisServices: 'Traffic Analysis Services' },
        { TrafficPlanningServices: 'Traffic Planning Services ' },
        { TransportationPlanningServices: 'Transportation Planning Services' },
        { TruckingServices: 'Trucking Services' },
        { TunnelingEngineeringServices: 'Tunneling Engineering Services' },
        { UtilitiesCoordination: 'Utilities Coordination' },
        { UtilityAndDrainageEngineering: 'Utility & Drainage Engineering' },
        { UtilityDesignServices: 'Utility Design Services' },
        { UtilityRelocationsServices: 'Utility Relocations Services' },
        { VibrationandNoiseServices: 'Vibration and Noise Services' },
        { VideoProduction: 'Video Production' },
        { WayfindingServices: 'Wayfinding Services' },
        { WebsiteDevelopmentServices: 'Website Development Services' }
      ];
      break;
    case 'agencies':
      response['data']['data'][key] = [
        { 'AC Transit': 'Alameda Contra Costa Transit District' },
        { BART: 'Bay Area Rapid Transit' },
        { HSR: 'California High Speed Rail Authority' },
        { CL: 'City of LA' },
        { CLB: 'City of Long Beach' },
        { RCTD: 'County of Riverside Transportation Department' },
        { WD: 'El Toro Water District' },
        { FT: 'Foothill Transit' },
        { IRWD: 'Irvine Ranch Water District ' },
        { LADWP: 'LA Department of Water & Power' },
        { 'LA Metro': 'Los Angeles Metro Rail' },
        { LAWA: 'Los Angeles World Airport' },
        { LGB: 'Long Beach Airport' },
        { LBT: 'Long Beach Transit' },
        { MTRL: 'Metrolink' },
        { MWD: 'Metropolitan Water District of Southern California' },
        { NCTD: 'North County Transit District' },
        { OCTA: 'Orange County Transportation Authority' },
        { OCWD: 'Orange County Water District ' },
        { PLA: 'Port of Los Angeles' },
        { PLB: 'Port of Long Beach' },
        { RCTC: 'Riverside County Transportation Commission' },
        { RSMWD: 'Rancho Santa Margarita Water District' },
        { SBCTA: 'San Bernardino County Transportation Authority' },
        { MTS: 'San Diego Metropolitan Transit System' },
        { SDGE: 'San Diego Gas & Electric ' },
        { SMCTD: 'San Mateo Transit District' },
        { VTA: 'Santa Clara VTA' },
        { SCE: 'Southern California Edison ' },
        { SoCalGas: 'Southern California Gas' },
      ]
      break;
    default:
      response['data']['data'][key] = [];
      break;
  }

  return response;
};