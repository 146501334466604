import React, { useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Button, Space } from 'antd';

import { showModal } from '../../redux/actions/ui';
import ModalComponent from '../../components/ui/ModalComponent';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation } from '../../utils/utils';
import List from './List';
import Delete from './Delete';
import { clearRedirect } from '../../redux/actions/redirect';
import PrimesAdd from '../../components/customForms/PrimesAdd';
import PrimesEdit from '../../components/customForms/PrimesEdit';
import PrimesView from '../../components/customForms/PrimesView';
import PrimesPrintView from '../../components/customForms/PrimesPrintView';
import SuperChat from '../MessageCenter/SuperChat';

const Primes = ({
  role,
  redirect,
  history,
  match,
  onShowModal,
  onClearRedirect
}) => {
  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect.path]);

  return (
    <>
      <ModalComponent goBack={true}>
        <Switch>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Route path={`${match.path}/add`} render={() => <PrimesAdd />} />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ] ? (
            <Route
              path={`${match.path}/view/:primeId`}
              render={() => <PrimesView />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'chat'
          ] ? (
            <Route
              path={`${match.path}/chat/:companyId/:companyType`}
              render={() => <SuperChat />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'print-view'
          ] ? (
            <Route
              path={`${match.path}/print-view/:primeId`}
              render={() => <PrimesPrintView />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'edit'
          ] ? (
            <Route
              path="/primes/edit/:primeId"
              render={() => <PrimesEdit selfMode={false} />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Route path="/primes/delete/:primeId" component={Delete} />
          ) : null}
        </Switch>
      </ModalComponent>
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h3 className="section-headers">Corporation</h3>
        {role === 'admin' &&
        accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'add'
        ] ? (
          <Button
            type="primary"
            size="small"
            onClick={e => {
              e.preventDefault();
              onShowModal();
            }}
          >
            {/* <Link to={`${match.path}/add`}>Add Medium/Large Business</Link> */}
            <Link to={`${match.path}/add`}>Add Corporation</Link>
          </Button>
        ) : null}
      </Space>
      <div className="divider"></div>
      <List />
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect())
});

Primes.propTypes = {
  role: string,
  redirect: object,
  match: object,
  history: object,
  onShowModal: func,
  onClearRedirect: func
};

export default connect(mapStateToProps, mapDispatchToProps)(Primes);
