export const SET_AXIO_HEADERS = 'SET_AXIOS_HEADERS';

export const UI_LOADING_SHOW = 'UI_LOADING_SHOW';
export const UI_LOADING_HIDE = 'UI_LOADING_HIDE';
export const UI_SHOW_MODAL = 'UI_SHOW_MODAL';
export const UI_HIDE_MODAL = 'UI_HIDE_MODAL';
export const UI_SET_MODAL_TITLE = 'UI_SET_MODAL_TITLE';
export const UI_SHOW_SIDEBAR = 'UI_SHOW_SIDEBAR';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const REDIRECT_SET = 'REDIRECT_SET';
export const REDIRECT_CLEAR = 'REDIRECT_CLEAR';

export const SMALL_BUSINESS_REFRESH = 'SMALL_BUSINESS_REFRESH';
export const DELETE_SMALL_BUSINESS_SUCCESS = 'DELETE_SMALL_BUSINESS_SUCCESS';

export const CALENDAR_SET_DATE = 'CALENDAR_SET_DATE';
export const CALENDAR_SET_EVENTS = 'CALENDAR_SET_EVENTS';
export const CALENDAR_REFRESH = 'CALENDAR_REFRESH';

export const RFP_RFQ_REFRESH = 'RFP_RFQ_REFRESH';
export const DELETE_RFPRFQ_SUCCESS = 'DELETE_RFPRFQ_SUCCESS';
export const IMPORT_RFPRFQ_SUCCESS = 'IMPORT_RFPRFQ_SUCCESS';

export const PRIMES_REFRESH = 'PRIMES_REFRESH';
export const DELETE_PRIMES_SUCCESS = 'DELETE_PRIMES_SUCCESS';
export const FETCH_PRIMES = 'FETCH_PRIMES';

export const ASSOCIATION_REFRESH = 'ASSOCIATION_REFRESH';
export const DELETE_ASSOCIATION_SUCCESS = 'DELETE_ASSOCIATION_SUCCESS';
export const FETCH_ASSOCIATION = 'FETCH_ASSOCIATION';

export const LOOK_AHEAD_REFRESH = 'LOOK_AHEAD_REFRESH';
export const DELETE_LOOK_AHEAD_SUCCESS = 'DELETE_LOOK_AHEAD_SUCCESS';
export const IMPORT_LOOK_AHEAD_SUCCESS = 'IMPORT_LOOK_AHEAD_SUCCESS';

export const COMMENTS_REFRESH = 'COMMENTS_REFRESH';
export const USER_REFRESH = 'USER_REFRESH'

export const SET_CONTACT_REQUESTS = 'SET_CONTACT_REQUESTS';
export const ADD_CONTACT_REQUEST = 'ADD_CONTACT_REQUEST';
export const UPDATE_CONTACT_REQUESTS = 'UPDATE_CONTACT_REQUESTS';
export const FILTER_CONTACT_REQUESTS = 'FILTER_CONTACT_REQUESTS';

export const SET_USER_AGREEMENTS = 'SET_USER_AGREEMENTS';
export const ADD_USER_AGREEMENT = 'ADD_USER_AGREEMENT';
export const UPDATE_USER_AGREEMENTS = 'UPDATE_USER_AGREEMENTS';
export const FILTER_USER_AGREEMENTS = 'FILTER_USER_AGREEMENTS';

export const SEARCH_SET_QUERY = 'SEARCH_SET_QUERY';
export const SEARCH_CLEAR_QUERY = 'SEARCH_CLEAR_QUERY';
export const SET_ACTIVE_ADVANCE_SEARCH = 'SET_ACTIVE_ADVANCE_SEARCH';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL';

export const SET_MESSAGE_CENTERS = 'SET_MESSAGE_CENTERS';
export const ADD_MESSAGE_CENTER = 'ADD_MESSAGE_CENTER';
export const FILTER_MESSAGE_CENTERS = 'FILTER_MESSAGE_CENTERS';
export const UPDATE_MESSAGE_CENTERS = 'UPDATE_MESSAGE_CENTERS';

export const SET_ACTIVE_COMP = 'SET_ACTIVE_COMP';

export const GET_PERMISSIONS = 'GET_PERMISSIONS';

export const SET_FEATURES = 'SET_FEATURES';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';

export const UPDATE_ALLOWED = 'UPDATE_ALLOWED';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';

export const CLEAR_ADVANCE_SEARCH = 'CLEAR_ADVANCE_SEARCH';
export const SAVE_ADVANCE_FORM_VALUES = 'SAVE_ADVANCE_FORM_VALUES';

export const REFRESH_VIDEOS = 'REFRESH_VIDEOS';

export const SET_VERIFICATION_STEPS = 'SET_VERIFICATION_STEPS'
export const ADD_VERIFICATION_STEP = 'ADD_VERIFICATION_STEP'
export const FILTER_VERIFICATION_STEPS = 'FILTER_VERIFICATION_STEPS'
export const UPDATE_VERIFICATION_STEP = 'UPDATE_VERIFICATION_STEP'