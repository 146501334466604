import { accessRules } from '../../../utils/accessRules';
import { getFromLocalStorage } from '../../../utils/localStorage';

export const menuItems = [
  {
    key: 1,
    id: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
    codename: 'custom_dashboard_perm',
    isPaid: true
  },
  {
    key: 2,
    id: 'dashboard',
    label: 'Organizations',
    path: '',
    codename: 'custom_dashboard_perm',
    isPaid: true,
    children: [
      {
        key: 2.1,
        id: 'association',
        label: 'Association',
        path: '/association',
        codename: 'association_perm',
        isPaid: true
      },
      {
        key: 2.2,
        id: 'small-business',
        label: 'Small Business',
        path: '/small-business',
        codename: 'small_business_perm',
        isPaid: true
      },
      {
        key: 2.3,
        id: 'primes',
        label: 'Corporation',
        path: '/primes',
        codename: 'primes_perm',
        isPaid: true
      }
    ]
  },
  {
    key: 3,
    id: 'calendar',
    label: 'Events',
    path: '',
    codename: 'calendar_perm',
    isPaid: true,
    children: [
      {
        key: 3.1,
        id: 'calendar',
        label: 'Calendar',
        path: '/calendar',
        codename: 'calendar_perm',
        isPaid: true
      },
      {
        key: 3.2,
        id: 'rfp-rfq',
        label: 'RFP/RFQ/RFI/IFB',
        path: '/rfp-rfq',
        codename: 'rfp_perm',
        isPaid: true
      },
      {
        key: 3.3,
        id: 'look-ahead',
        label: 'Look-Ahead',
        path: '/look-ahead',
        codename: 'look_ahead_schedule_perm',
        isPaid: true
      }
    ]
  },
  {
    key: 4,
    id: 'rfp-rfq',
    label: 'Administration',
    path: '',
    codename: 'rfp_perm',
    isPaid: true,
    children: [
      {
        key: 4.1,
        id: 'message-center',
        label: 'Message Center',
        path: '/message-center',
        codename: 'one_way_contact_perm',
        isPaid: true
      },
      {
        key: 4.2,
        id: 'contact',
        label: 'Contact Us',
        path: '/contact',
        codename: 'technical_support_perm',
        isPaid: true
      }
    ]
  },
  {
    key: 5,
    id: 'videos',
    label: 'Videos',
    path: '/videos',
    codename: 'videos_perm',
    isPaid: false
  }
];

const checkAccess = ({ item, role, permissions }) => {
  if (!!permissions[item.codename] || role === 'admin') {
    item.access = true;
  } else {
    item.access = false;
    item.path = '/payments';
  }
  return item;
};

export const getMenuItems = (role, free_user) => {
  const menu = [];
  const noAccess = [];
  const permissions = getFromLocalStorage('permissions');

  menuItems.forEach(item => {
    if (accessRules[role][item['id']]?.['menu'] && accessRules[role][item['id']]?.['free_user'] ? accessRules[role][item['id']]?.['free_user'] === free_user : true) {
      item = checkAccess({ item, permissions, role });
      if (item.children) {
        item.children.forEach(sub => {
          sub = checkAccess({ item: sub, permissions, role });
        });
      }
      role === 'admin' || !!permissions[item.codename] ? menu.push(item) : noAccess.push(item);
    }
  });

  return menu.concat(noAccess);
};
