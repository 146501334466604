import { SMALL_BUSINESS_REFRESH, DELETE_SMALL_BUSINESS_SUCCESS } from '../actionTypes';

const initialState = { refresh: true, deleteSuccess: false };

export const smallBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case SMALL_BUSINESS_REFRESH:
      return { ...state, refresh: action.payload };
    case DELETE_SMALL_BUSINESS_SUCCESS:
      return { ...state, deleteSuccess: true };
    default:
      return { ...state };
  }
};
