import React, { useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';
import { Button, Space } from 'antd';
import { Tabs } from 'antd';

import { showModal } from '../../redux/actions/ui';
import { clearRedirect } from '../../redux/actions/redirect';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation } from '../../utils/utils';
import ModalComponent from '../../components/ui/ModalComponent';
import List from './List';
import View from './View';
import SmallBusinessAdd from '../../components/customForms/SmallBusinessAdd';
import SmallBusinessEdit from '../../components/customForms/SmallBusinessEdit';
import SmallBusinessView from '../../components/customForms/SmallBusinessView';
// import SmallBusinessPrintView from '../../components/customForms/SmallBusinessPrintView';
import SuperChat from '../MessageCenter/SuperChat';
import Delete from './Delete';
import AdvanceSearch from '../../components/common/SearchSidebar/AdvanceSearch';
import { setActiveAdvanceSearch } from '../../redux/actions/search';

const { TabPane } = Tabs;

const SmallBusiness = ({
  role,
  redirect,
  history,
  match,
  advanceSearch,
  onShowModal,
  onClearRedirect,
  showAdvanceSearch
}) => {
  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect.path]);

  const closeAdvanceSearch = () => {
    showAdvanceSearch(null);
  };

  return (
    <div className="container">
      {advanceSearch ? (
        <ModalComponent onCloseModalSuccess={closeAdvanceSearch}>
          <AdvanceSearch title="Small Business Search" search="smallBusiness" />
        </ModalComponent>
      ) : (
        <ModalComponent goBack={true}>
          <Switch>
            {accessRules[role][getCurrentPageFromLocation(match.path)][
              'actions'
            ]['add'] ? (
              <Route
                path={`${match.path}/add`}
                render={() => <SmallBusinessAdd />}
              />
            ) : null}
            {accessRules[role][getCurrentPageFromLocation(match.path)][
              'actions'
            ]['view'] ? (
              <Route
                path={`${match.path}/view/:smallBusinessId`}
                render={() => <SmallBusinessView />}
              />
            ) : null}
            {accessRules[role][getCurrentPageFromLocation(match.path)][
              'actions'
            ]['chat'] ? (
              <Route
                path={`${match.path}/chat/:companyId/:companyType`}
                render={() => <SuperChat />}
              />
            ) : null}
            {accessRules[role][getCurrentPageFromLocation(match.path)][
              'actions'
            ]['print-view'] ? (
              <Route
                path={`${match.path}/print-view/:smallBusinessId`}
                render={() => <View />}
              />
            ) : null}
            {accessRules[role][getCurrentPageFromLocation(match.path)][
              'actions'
            ]['edit'] ? (
              <Route
                path={`${match.path}/edit/:smallBusinessId`}
                render={() => <SmallBusinessEdit selfMode={false} />}
              />
            ) : null}
            {accessRules[role][getCurrentPageFromLocation(match.path)][
              'actions'
            ]['delete'] ? (
              <Route
                path={`${match.path}/delete/:businessId`}
                component={Delete}
              />
            ) : null}
          </Switch>
        </ModalComponent>
      )}
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px'
        }}
      >
        <h3 className="section-headers">Small Business Companies</h3>
        <Space direction="horizontal">
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <>
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  onShowModal();
                }}
              >
                <Link to={`${match.path}/add`}>Add Small Business</Link>
              </Button>
            </>
          ) : null}
        </Space>
      </Space>
      <div className="divider" style={{ marginBottom: 0 }}></div>

      <Tabs defaultActiveKey="1">
        <TabPane tab={<span>Non-Vetted</span>} key="1">
          <List activeTab={'non-vetted'} />
        </TabPane>
        <TabPane tab={<span>Vetted</span>} key="2">
          <List activeTab={'vetted'} />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect,
  advanceSearch: state.search.activeAdvanceSearch
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect()),
  showAdvanceSearch: payload => dispatch(setActiveAdvanceSearch(payload))
});

SmallBusiness.propTypes = {
  role: string,
  redirect: object,
  match: object,
  history: object,
  editMode: bool,
  onShowModal: func,
  onClearRedirect: func
};

export default connect(mapStateToProps, mapDispatchToProps)(SmallBusiness);
