/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, number, object, string } from 'prop-types';
import { Space, Tooltip } from 'antd';
import { PrinterOutlined, EditOutlined, DeleteOutlined, MessageOutlined } from '@ant-design/icons';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { getPrimes } from '../../api/primes';
import { setPrimesRefresh, fetchPrimesAction } from '../../redux/actions/primes';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { columns } from '../../components/configs/tables/primes.json';
import TableComponent from '../../components/ui/TableComponent';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation, HeaderRenderer } from '../../utils/utils';
import { handleNotifications } from '../../utils/notifications';

const List = ({
  refresh,
  fetchPrimesSuccess,
  onDeleteSuccess,
  role,
  businessId,
  searchParams,
  onSetRefresh,
  onShowModal,
  onShowLoading,
  onHideLoading,
  fetchPrimesAction
}) => {
  const [primes, setPrimes] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentTableProps, setCurrentTableProps] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });
  const match = useRouteMatch();
  const history = useHistory();
  const innerWidth = window.innerWidth;

  useEffect(() => {
    if (refresh) {
      setFiltersFromSearchParams(searchParams);
    }
  }, [refresh]);

  useEffect(() => {
    if(fetchPrimesSuccess) {
      fetchPrimes({ currentTableProps, filters: [...currentFilters] });
      fetchPrimesAction(false);
    }
  }, [fetchPrimesSuccess]);

  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  useDeepCompareEffect(() => {
    fetchPrimes({ currentTableProps, filters: [...currentFilters] });
  }, [currentFilters, currentTableProps.pagination.current, onDeleteSuccess]);

  /* Cleanup Method */
  useEffect(
    () => () => {
      setCurrentFilters([]);
      onSetRefresh(true);
    },
    []
  );

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: searchParams['keyword']
        }))
      : [];
    setCurrentFilters(searchFilters);
  };

  const filterData = (arr) => {
    arr = arr
    .map(item => ({
      ...item,
      key: item.id,
      company_specialization: item.company_specialization || '-',
      county: item?.address?.length ? item['address'].map(address => address.county).join(', ') : '-',
      state: item?.address?.length ? item['address'].map(address => address.state).join(', ') : '-',
      key_projects: item?.key_projects?.length ? item['key_projects'].map(project => project.title).join(', ') : '-'
    }))
    return arr;
  }

  /* Render Actions Column */
  const renderActions = (text, record) => (
    <Space size="small">
      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'chat'
      ] ? (
        <Tooltip
          title="Chat"
          onClick={(e) => {
            e.stopPropagation();
            onShowModal();
          }}
        >
          <Link style={{ marginRight: '5px' }} to={`${match.path}/chat/${record.id}/Prime`}>
            <MessageOutlined style={{ fontSize: '18px' }} />
          </Link>
        </Tooltip>
      ) : null}

      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'print-view'
      ] ? (
        <Tooltip
          title="Print Profile"
          onClick={(e) => {
            e.stopPropagation();
            onShowModal();
          }}
        >
          <Link style={{ marginRight: '5px' }} to={`${match.path}/print-view/${record.id}`}>
            <PrinterOutlined style={{ fontSize: '18px' }} />
          </Link>
        </Tooltip>
      ) : null}

      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'edit'
      ] ? (
        <Tooltip
          title="Edit"
          onClick={(e) => {
            e.stopPropagation();
            onShowModal();
          }}
        >
          <Link style={{ marginRight: '5px' }} to={`${match.path}/edit/${record.id}`}>
            <EditOutlined style={{ fontSize: '18px' }} />
          </Link>
        </Tooltip>
      ) : null}

      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'delete'
      ] ? (
        <Tooltip
          title="Delete"
          onClick={(e) => {
            e.stopPropagation();
            onShowModal();
          }}
        >
          <Link
            style={{ marginRight: '5px' }}
            to={{
              pathname: `${match.path}/delete/${record.id}`,
              primeId: record.id
            }}
          >
            <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
          </Link>
        </Tooltip>
      ) : null}
    </Space>
  );

  const tableColumns = HeaderRenderer(columns, innerWidth)

  tableColumns.push({
    title: 'Action',
    key: 'action',
    displayName: 'action',
    render: renderActions
  });

  const fetchPrimes = ({ currentTableProps, filters }) => {
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = currentTableProps.pagination.size;
    }
    if (filters) {
      query['filters'] = filters;
    }

    onShowLoading();
    getPrimes(query)
      .then(({ data }) => {
        if (data['success']) {
          let arr = filterData(data.data);
          setPrimes(arr);
          setCurrentTableProps({
            ...currentTableProps,
            pagination: {
              ...currentTableProps.pagination,
              total: data?.pagination.total
            }
          });
        } else {
          setPrimes([]);
          handleNotifications('error', 'Failed', data['message']);
        }
        onHideLoading();
      })
      .catch(error => {
        onHideLoading();
        setPrimes([]);
        handleNotifications('error', 'Failed', error['message']);
        console.log(error);
      });
  };

  const manageDataSource = ({ current, pageSize }) => {
    let tableProps = { ...currentTableProps };
    if (current) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, current }
      };
    }
    if (pageSize) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, size: pageSize }
      };
    }
    setCurrentTableProps({ ...tableProps });
  };

  const onClickRow = (record) => {
    return {
      onClick: () => {
        if(accessRules[role][getCurrentPageFromLocation(match.path)]['actions']['view']){
          onShowModal();
          history.push(`${match.path}/view/${record.id}`)
        }
      },
    };
  }

  return (
    <TableComponent
      className="table-hoverable"
      columns={tableColumns}
      tableData={primes}
      onRow={onClickRow}
      pagination={{
        position: ['bottomCenter'],
        current: currentTableProps.pagination.current,
        pageSize: currentTableProps.pagination.size,
        total: currentTableProps.pagination.total,
        filters: currentTableProps.filters,
        hideOnSinglePage: true
      }}
      manageDataSource={manageDataSource}
    />
  );
};

const mapStateToProps = state => ({
  refresh: state.rfpRfq.refresh,
  onDeleteSuccess: state.primes.deleteSuccess,
  fetchPrimesSuccess: state.primes.fetchPrimes,
  role: state.auth.role,
  businessId: state.auth?.user?.businessId,
  searchParams: state.search.primes
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onSetRefresh: payload => dispatch(setPrimesRefresh(payload)),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading()),
  fetchPrimesAction: payload => dispatch(fetchPrimesAction(payload))
});

List.propTypes = {
  refresh: bool,
  fetchPrimesSuccess: bool,
  onDeleteSuccess: bool,
  role: string,
  businessId: number,
  searchParams: object,
  onSetRefresh: func,
  onShowModal: func,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
