import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Spin
} from 'antd';
import { showUserAgreement } from '../../api/userAgreement';
import { handleNotifications } from '../../utils/notifications';
import ReactHtmlParser from 'react-html-parser';

const UserAgreementView = () => {

  const [loading, setLoading] = useState(true);
  const [agreement, setAgreement] = useState({});

  const { agreementId } = useParams();

  useEffect(() => {
    if (!agreementId) {
      return;
    }

    async function fetchAgreement(id) {
      let res = await showUserAgreement(id);
      if (res.data['success']) {
        setAgreement(res.data['data']);
        setLoading(false);
      } else {
        handleNotifications('error', 'Could not fetch', res.data.message);
      }
    }
    fetchAgreement(agreementId);
    
    // eslint-disable-next-line
  }, [agreementId]);

  return (
    <>
    { loading ? <Spin /> :
      <div>{ ReactHtmlParser(agreement.user_agreement) }</div>
    }
    </>
  );
};

export default connect(null, null)(UserAgreementView);