import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Row,
  Image,
  Radio
} from 'antd';
import { fetchPackages, fetchFeatures } from '../../api/payments';
import jsonData from './payments.json';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, hideModal, showLoading, showModal } from '../../redux/actions/ui';
import { MAKE_PAYMENT_ACTION } from '../../redux/actions/payments';
import { objectToFormData } from '../../utils/utils';
import { fetchPermissions } from '../../redux/actions/auth';
import brandLogo from '../../assets/images/brand-logo.png';

import { makePayment } from '../../api/payments';
import { stringToDateObject } from '../../utils/dateTimeHelper';
import ContactUs from './contactUs';

const packageOrder = {
  "Silver Package": 1,
  "Association Package": 2,
  "Bronze Package": 3,
  "Gold Package": 4,
  "Platinum Package": 5
}

const ROLES = {
  SmallBusiness: "small-business",
  Primes: "prime",
  Association: 'association'
}

const PaymentPackages = ({
  MAKE_PAYMENT_ACTION,
  data,
  fetchPermissions,
  handleStep,
  parentHandler,
  hideLoading,
  showLoading,
  showModal
}) => {
  const [packages, setPackages] = useState([]);
  const [showContact, setShowContact] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [packType, setPackType] = useState({});
  const [loading, setLoading] = useState(false);
  const loginData = useSelector(state => state.auth);

  useEffect(() => {
    if (data) {
      setFormValues({ ...data });
    }
  }, [data]);
  
  useEffect(() => {
    (async () => {
      try {
        showLoading();
        const [res1, res2] = await Promise.all([
          fetchFeatures(),
          fetchPackages()
        ]);
        if (res1.data.success && res2.data.success) {
          const { data: result } = res2.data;
          let packs = result.filter(pack => jsonData.permissions[loginData.role || ROLES[data.type]]?.[pack.name])?.sort((curr, next) => packageOrder[curr.name] - packageOrder[next.name])
          setPackages(packs);
        }
        setLoading(false);
        hideLoading();
      } catch (error) {
        console.warn(error);
        hideLoading();
        handleNotifications(
          'error',
          'BZ Connects !!',
          'Something went wrong !'
        );
      }
    })();
    // eslint-disable-next-line
  }, [data]);

  const handlePackType = (pack, packageName) => {
    pack = {
      package_type_id: pack.id,
      amount: parseInt(pack.price),
      package_id: pack.package_id,
      order_desciptioin: packageName
    }
    setPackType(pack);
  };

  const handleFreePay = () => {
    let payload = {
      first_name: formValues.first_name || loginData?.user?.name,
      last_name: formValues.last_name || '',
      email: formValues.email || loginData?.user?.email,
      user_id: formValues.id || loginData?.user?.user_id,
      package_type_id: packType.package_type_id,
      package_id: packType.package_id,
      object_type:
        formValues.type ||
        (loginData?.role === 'prime' ? 'Primes' : 'SmallBusiness'),
      object_id: formValues.company_id || loginData?.user?.businessId,
      city: '',
      zip: '',
      country: '',
      state: '',
      address: '',
      expiration_date: '',
      card_number: '',
      amount: 0,
      order_desciptioin: '',
      card_code: ''
    };
    makePayment('payments', objectToFormData(payload))
      .then(res => {
        loginData?.user?.user_id && fetchPermissions();
        if (res.data.success) {
          handleNotifications('success', 'BZ Connects !!', res.data?.message);
          let date = stringToDateObject(res.data.data.account_expiry_date);
          MAKE_PAYMENT_ACTION(date);
          handleStep(3);
          return;
        }
        handleNotifications(
          'error',
          'BZ Connects !!',
          res.data?.message || 'Error Occured !!'
        );
      })
      .catch(err => {
        if (err && err.response && err.response.message) {
          console.warn(err.response.message);
          handleNotifications('error', 'BZ Connects !!', err.response.message);
        }
      });
  };

  const handlePay = () => {
    if (!packType.package_type_id) {
      handleNotifications(
        'warn',
        'BZ Connects !!',
        'please select package duration'
      );
      return;
    }
    if ([13].includes(packType.package_type_id)) {
      handleFreePay();
    } else {
      parentHandler(packType);
      handleStep(1);
    }
  };

  const toggleContact = () => {
    if(!showContact){
      setShowContact(true);
      showModal();
    }else{
      setShowContact(false);
      hideModal();
    }
  };
console.log(formValues)
  return (
    <>
      {showContact && <ContactUs toggleContact={toggleContact} />}
      <Image src={brandLogo} preview={false} className="payment-header-logo" />
      {!loading ? (
        <Row justify="center" style={{ padding: '0 20px' }}>
          {packages?.map((item, j) => (
            <Col key={item.id} className="package-item">
              <section>
                <Card className="package-card">
                  <header>
                    <p>{jsonData.title[item.name]}</p>
                    <p>PACKAGE</p>
                  </header>
                  <p id="pricing">
                    <span id="main">{jsonData.pricing[item.name].main?.display}</span>
                    <br />
                    <span id="sec">{jsonData.pricing[item.name].sec?.display}</span>
                  </p>
                  <main style={{ position: 'relative', padding: '0 24px' }}>
                    <div
                      className="custom-scroll"
                      style={{ height: 425, overflowY: 'auto', padding: 2 }}
                    >
                      {jsonData.features[item.name].map((field, i) => (
                        <div key={i}>
                          {i === jsonData.features[item.name].length - 1 ? (
                            <small>{field}</small>
                          ) : (
                              <p style={{ marginBottom: 10 }}>{field}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  </main>
                  <div className="dropup">
                      <span></span>
                      <div className="extra-details">
                        <p>
                          {jsonData.extra_details[item.name].desc}
                        </p>
                        {jsonData.extra_details[item.name].note && <hr />}
                        <p><b>{jsonData.extra_details[item.name].note}</b></p>
                        {/* <Button id='get-info'>GET MORE INFO</Button> */}
                      </div>
                    </div>
                </Card>
              </section>
              <section className="checkout-container">
                <h3>{`${jsonData.title[item.name]} Package`}</h3>
                {item.name !== "Platinum Package" && <article>
                <Radio.Group
                  value={packType.package_type_id}
                  >
                  {Object.values(jsonData.pricing[item.name])?.map(pack => (
                    <>
                      <Radio
                        style={{
                          display: 'block',
                          height: '30px',
                          lineHeight: '30px'
                        }}
                        onChange={() =>
                          handlePackType(
                            pack,
                            item.name
                          )
                        }
                        value={pack.id}
                      >
                        {pack.name}{' '}
                        <strong>
                          {!pack.price
                           ? '' : `: $ ${pack.price}`}
                        </strong>
                      </Radio>
                      <br />
                    </>
                  ))}
                </Radio.Group>
              </article>}
                <footer>
                  {item.name === 'Platinum Package' ? (
                    <Button
                      id="contact-us"
                      type="warning"
                      onClick={toggleContact}
                    >
                      Contact Us
                    </Button>
                  ) : (
                    <Button type="warning" onClick={handlePay}>
                      Checkout
                    </Button>
                  )}
                </footer>
              </section>
            </Col>
          ))}
        </Row>
      ) : null}
    </>
  );
};

PaymentPackages.propTypes = {
  handleStep: PropTypes.func.isRequired,
  parentHandler: PropTypes.func.isRequired,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func
};

export default connect(null, {
  fetchPackages,
  fetchFeatures,
  fetchPermissions,
  showLoading,
  showModal,
  hideModal,
  hideLoading,
  MAKE_PAYMENT_ACTION
})(PaymentPackages);
