import connection from '../utils/api';

/* POST Create Look-Ahead Event */
export const addLookAhead = eventPayload =>
  connection.post(`look_ahead/events`, eventPayload);

/* GET Fetch Look-Ahead Event */
export const getLookAheads = ({ page, limit, filters }) => {
  let queryString = '';
  const queryParams = [];

  if (page) {
    queryParams.push(`page=${page}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }
  if (filters?.length) {
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  }
  if (queryParams.length) {
    queryString = `?` + queryParams.join('&');
  }

  return connection.get(`look_ahead/events${queryString}`);
};

/* GET Fetch a Look-Ahead event by ID */
export const getLookAhead = lookAheadId =>
  connection.get(`look_ahead/events/${lookAheadId}`);

/* PUT Update Look-Ahead Event */
export const updateLookAhead = (lookAheadId, eventPayload) =>
  connection.put(`look_ahead/events/${lookAheadId}`, eventPayload);

/* Delete Look-Ahead Event */
export const deleteLookAhead = lookAheadId =>
  connection.delete(`look_ahead/events/${lookAheadId}`);

/* POST Import Look-Ahead Event */
export const importLookAhead = payload =>
  connection.post(`look_ahead/import`, payload);

/* GET Export Look-Ahead Event */
export const exportLookAhead = () => connection.get(`look_ahead/export`);
