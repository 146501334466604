import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image } from 'antd';

import { onLoginFromLocal } from '../../redux/actions/auth';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';
import brandLogo from '../../assets/images/brand-logo.png';

const ResetPassword = () => {
  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <div>
        <Image
          src={brandLogo}
          width="450px"
          alt="BZConnects Logo"
          preview={false}
        />
      </div>
      <ResetPasswordForm />
    </div>
  );
};

ResetPassword.propTypes = {
  loggedIn: PropTypes.bool,
  history: PropTypes.object,
  loginFromLocal: PropTypes.func
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn
});

const mapDispatchToProps = dispatch => ({
  loginFromLocal: user => dispatch(onLoginFromLocal(user))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
