import React from 'react';
import Branding from '../common/Navigation/Branding';
import UserControls from '../common/Navigation/UserControls';

const PageHeader = () => (
  <div className="header-section">
    <Branding />
    <UserControls />
  </div>
);

export default PageHeader;
