import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { func } from 'prop-types';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  AutoComplete,
  Radio
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { addField } from '../../api/smallBusinessFields';
import { hideModal } from '../../redux/actions/ui';
import { onAddSmallBusiness } from '../../redux/actions/smallBusiness';
import { debounce, objectToFormData } from '../../utils/utils';
import ImageUpload from '../common/Form/FormInputs/ImageUpload';
import Address from '../common/Form/FormInputs/Address';
import RadioComponent from '../common/Form/FormInputs/Radio';
import Dropdowns from '../common/Form/FormInputs/Dropdowns';
import FileUpload from '../common/Form/FormInputs/FileUpload';
import { handleNotifications } from '../../utils/notifications';
import {
  phoneUSPattern,
  websiteUrlPattern,
  NaicsCodes
} from '../../utils/constants';

const { Title } = Typography;
const { TextArea } = Input;

const SmallBusinessAdd = ({ addSmallBusiness, onCancel }) => {
  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: '',
    phone: '',
    company_website: '',
    address: [],
    company_contacts: [],
    unspsc_codes: [],
    company_profile: '',
    naics_codes: [],
    service: [],
    specialization: [],
    agencies: [],
    revenue: null,
    no_of_employees: null,
    certification: [],
    soq: null,
    linkedin_profile: '',
    owner_demographic: '',
    liability_insurance: null,
    compensation_insurance: null,
    disability_insurance: null,
    due_diligence: null,
    verification_step_id: 0
  });
  const role = useSelector(state => state.auth.role);

  const [revenue, setRevenue] = useState({
    first_year_revenue: null,
    second_year_revenue: null,
    third_year_revenue: null
  });

  const [certifications, setCertifications] = useState([]);

  const [isOtherDemographic, setIsOtherDemographic] = useState(false);
  const [otherDemographic, setOtherDemographic] = useState('');
  const [isRevenueSaved, setIsRevenueSaved] = useState(false);

  const inputChangedHandler = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const debouncedInput = debounce((e, key) => {
    if (key === 'other_demographic') {
      inputChangedHandler('owner_demographic', e.target.value);
      return;
    }
    inputChangedHandler(key, e.target.value);
  }, 300);

  const revenueHandler = (key, value) => {
    setRevenue({ ...revenue, [key]: value });
  };

  const certificationsHandler = (key, value, index) => {
    const newCertifications = [...certifications];
    if (newCertifications[index]) {
      newCertifications[index][key] = value;
    } else {
      newCertifications.push({ [key]: value });
    }
    setCertifications([...newCertifications]);
  };

  const microApiResponseHandler = (key, value) => {
    setFormValues({ ...formValues, [key]: [...formValues[key], value] });
  };

  const submit = () => {
    if (
      !formValues['address'] ||
      !formValues['company_name'] ||
      !formValues['no_of_employees'] ||
      !formValues['revenue']
    ) {
      handleNotifications(
        'warning',
        'Warning',
        'Please fill all required fields.'
      );
    }
    let payload = { ...formValues };
    Object.keys(payload).forEach(key => {
      if (
        [
          'address',
          'agencies',
          'service',
          'specialization',
          'company_contacts',
          'naics_codes',
          'projects',
          'unspsc_codes'
        ].includes(key)
      ) {
        payload[key] = JSON.stringify(payload[key]);
      }
    });
    addSmallBusiness(objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
    handleNotifications(
      'warning',
      'Warning',
      'Some fields require your attention.'
    );
  };

  return (
    <Row align="middle" justify="center">
      <Col span={24}>
        <Title level={2} className="heading-title">
          Create Small Business Profile
        </Title>
        <Form
          form={form}
          name="add-small-business"
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          onFinish={submit}
          onFinishFailed={submitFail}
        >
          {/* Company Logo */}
          <Form.Item
            key="logo"
            name="logo"
            hasFeedback
            style={{ textAlign: 'right' }}
            wrapperCol={{ md: { offset: 4 }, sm: { offset: 0 } }}
          >
            <ImageUpload
              keyName="logo"
              label="Company Logo"
              max={1}
              nestedHandler={(key, value) => {
                inputChangedHandler(key, value[key]);
              }}
            />
          </Form.Item>

          {/* Business Name */}
          <Form.Item
            key="company_name"
            name="company_name"
            label="Business Name"
            style={{
              border: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Company name is required.'
              }
            ]}
          >
            <Input
              name="company_name"
              placeholder="Business Name"
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              type="text"
              onChange={e => {
                e.persist();
                debouncedInput(e, 'company_name');
              }}
            />
          </Form.Item>

          {/* Business Phone */}
          <Form.Item
            key="phone"
            name="phone"
            label="Business Phone"
            style={{
              border: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Company Phone is required.'
              },
              {
                pattern: phoneUSPattern,
                message: 'Please enter a valid phone number.'
              }
            ]}
          >
            <Input
              name="phone"
              placeholder="Business Phone (10-digit)"
              type="tel"
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              maxLength={10}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'phone');
              }}
            />
          </Form.Item>

          {/* Website */}
          <Form.Item
            key="company_website"
            name="company_website"
            label="Website"
            style={{
              border: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Company Website is required.'
              },
              {
                pattern: websiteUrlPattern,
                message: 'Please enter a valid web url.'
              }
            ]}
          >
            <Input
              name="company_website"
              placeholder="Company Website"
              type="text"
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'company_website');
              }}
            />
          </Form.Item>

          {/* Business Addresses */}
          <Form.List
            name="address"
            rules={[
              {
                validator: async (_, address) => {
                  if (!address || address.length < 1) {
                    return Promise.reject(
                      new Error('At least 1 address is required.')
                    );
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{
                      md: { offset: index === 0 ? 0 : 4, span: 20 },
                      sm: { span: 24 }
                    }}
                    required={true}
                    key={field.key}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                    label={index === 0 ? 'Business Address' : ''}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          validator: async (_, address) => {
                            if (!formValues['address'][index]) {
                              return Promise.reject(
                                new Error(
                                  'Please input details and Save or delete this field.'
                                )
                              );
                            }
                          }
                        }
                      ]}
                      noStyle
                    >
                      <Address
                        keyName="address"
                        microApi={{ add: addField }}
                        parentHandler={microApiResponseHandler}
                        hasCity={true}
                        hasZip={true}
                      />
                    </Form.Item>
                    <Button
                      type="warning"
                      style={{
                        float: 'right',
                        marginRight: '3px',
                        marginTop: '0px',
                        backgroundColor: '#D26A31',
                        color: 'white'
                      }}
                      onClick={() => {
                        formValues['address'].splice(index, 1);
                        return remove(field.name);
                      }}
                    >
                      Remove
                    </Button>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '0px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 2 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Address
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Company Contacts */}
          <Form.List name="company_contacts">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{
                      md: { offset: index === 0 ? 0 : 4, span: 20 },
                      sm: { span: 24 }
                    }}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                    required={false}
                    key={`${field.key}_${index}`}
                    label={index === 0 ? 'Company Contacts' : ''}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`name_${index}`}
                    >
                      <Input
                        placeholder="Name"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'title']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`title_${index}`}
                    >
                      <Input
                        placeholder="Title"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'phone']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`phone_${index}`}
                    >
                      <Input
                        placeholder="Phone"
                        type="tel"
                        maxLength={10}
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`email_${index}`}
                    >
                      <Input
                        placeholder="Email"
                        type="email"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>

                    <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginRight: '-8px',
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['company_contacts'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                      {!formValues['company_contacts'][index] ? (
                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '0px',
                            marginTop: '0px',
                            backgroundColor: '#82CF8F',
                            color: 'white'
                          }}
                          onClick={() => {
                            addField(
                              'company_contacts',
                              objectToFormData(
                                form.getFieldValue('company_contacts')[index]
                              )
                            )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'company_contacts',
                                    data['data']['id']
                                  );
                                  handleNotifications(
                                    'success',
                                    'BZConnects',
                                    'Contact added successfully!'
                                  );
                                } else {
                                  // microApiResponseHandler(
                                  //   'company_contacts',
                                  //   null
                                  // );
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                          }}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '1px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 2 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Company Contact
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* UNSPSC Code */}
          <Form.List name="unspsc_codes">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{
                      md: { offset: index === 0 ? 0 : 4, span: 20 },
                      sm: { span: 24 }
                    }}
                    key={`${field.key}_${index}`}
                    label={index === 0 ? 'UNSPSC Codes' : ''}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'codes']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`codes_${index}`}
                    >
                      <Input
                        placeholder="UNSPSC Code"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      name={[field.name, 'description']}
                      key={`description_${index}`}
                    >
                      <Input
                        placeholder="UNSPSC Description"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>

                    <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginRight: '0px',
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['unspsc_codes'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                      {!formValues['unspsc_codes'][index] ? (
                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '0px',
                            marginTop: '0px',
                            backgroundColor: '#82CF8F',
                            color: 'white'
                          }}
                          onClick={() => {
                            let ind = index;
                            addField(
                              'unspsc',
                              objectToFormData(
                                form.getFieldValue('unspsc_codes')[index]
                              )
                            )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'unspsc_codes',
                                    data['data']['id'],
                                    ind
                                  );
                                } else {
                                  // microApiResponseHandler('naics_codes', null);
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                          }}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '1px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 2 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add UNSPSC Code
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Brief Company Profile */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px'
              }}
            >
              Brief Company Profile: (max 300 words)
            </div>
          </React.Fragment>
          <TextArea
            name="company_profile"
            placeholder="Brief Company Profile (max 300 words)"
            rows={3}
            maxLength={300}
            onChange={e => {
              e.persist();
              debouncedInput(e, 'company_profile');
            }}
          />

          {/* NAICS Code */}
          <Form.List name="naics_codes">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{
                      md: { offset: index === 0 ? 0 : 4, span: 20 },
                      sm: { span: 24 }
                    }}
                    key={`${field.key}_${index}`}
                    label={index === 0 ? 'NAICS Codes' : ''}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'codes']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`codes_${index}`}
                    >
                      <AutoComplete
                        style={{ marginLeft: '-7px', width: '101%' }}
                        options={NaicsCodes}
                        placeholder="Search Naics Codes"
                        filterOption={(inputValue, option) => {
                          let bool = !isNaN(inputValue)
                            ? option?.value.toString().includes(inputValue)
                            : option?.label
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1;
                          if (bool) {
                            let val = form.getFieldValue('naics_codes');
                            let obj = {
                              ...val?.[index],
                              description: option.label
                            };
                            val.splice(index, 1, obj);
                            form.setFieldsValue({ naics_codes: [...val] });
                          }
                          return bool;
                        }}
                        onBlur={() => console.log()}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      name={[field.name, 'description']}
                      key={`description_${index}`}
                    >
                      <Input
                        placeholder="NAICS Description"
                        style={{ marginLeft: '-7px', width: '101%' }}
                        disabled
                      />
                    </Form.Item>

                    <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginRight: '8px',
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['naics_codes'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                      {!formValues['naics_codes'][index] ? (
                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '0px',
                            marginTop: '0px',
                            backgroundColor: '#82CF8F',
                            color: 'white'
                          }}
                          onClick={() => {
                            addField(
                              'naics_codes',
                              objectToFormData(
                                form.getFieldValue('naics_codes')[index]
                              )
                            )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'naics_codes',
                                    data['data']['id']
                                  );
                                  handleNotifications(
                                    'success',
                                    'success',
                                    'Naics Code added successfully.'
                                  );
                                } else {
                                  // microApiResponseHandler('naics_codes', null);
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                          }}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '1px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 2 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add NAICS Code
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Company Specializations */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px'
              }}
            >
              Company Specialization:
            </div>
          </React.Fragment>
          <Dropdowns
            keyName="specializations"
            label="specializations"
            multiSelect={true}
            parentHandler={(key, value) => {
              inputChangedHandler('specialization', value[key]);
            }}
          />

          {/* Agency Experience */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px'
              }}
            >
              Agency Experience:
            </div>
          </React.Fragment>

          <Dropdowns
            keyName="agencies"
            label="agencies"
            multiSelect={true}
            parentHandler={(key, value) => {
              inputChangedHandler('agencies', value[key]);
            }}
          />

          {/* Previous Year Revenue */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px'
              }}
            >
              Previous Year Revenue:
            </div>
          </React.Fragment>
          <Form.Item
            key="first_year_revenue"
            name="first_year_revenue"
            label="Last Year"
            style={{
              border: '1px solid gray',
              marginBottom: '1px',
              paddingLeft: '7px'
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                validator: async (_ /* first_year_revenue */) => {
                  if (!formValues['revenue']) {
                    return Promise.reject(
                      new Error('Please select one option and save.')
                    );
                  }
                }
              }
            ]}
          >
            <RadioComponent
              keyName="first_year_revenue"
              parentHandler={(key, value) => {
                revenueHandler(key, value[key]);
              }}
              disabled={isRevenueSaved}
            />
          </Form.Item>
          <Form.Item
            key="second_year_revenue"
            name="second_year_revenue"
            label="2 Years Ago"
            style={{
              border: '1px solid gray',
              marginBottom: '1px',
              paddingLeft: '7px'
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                validator: async (_ /* second_year_revenue */) => {
                  if (!formValues['revenue']) {
                    return Promise.reject(
                      new Error('Please select one option and save.')
                    );
                  }
                }
              }
            ]}
          >
            <RadioComponent
              keyName="second_year_revenue"
              parentHandler={(key, value) => {
                revenueHandler(key, value[key]);
              }}
              disabled={isRevenueSaved}
            />
          </Form.Item>
          <Form.Item
            key="third_year_revenue"
            name="third_year_revenue"
            label="3 Years Ago"
            style={{
              border: '1px solid gray',
              marginBottom: '1px',
              paddingLeft: '7px'
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                validator: async (_ /* third_year_revenue */) => {
                  if (!formValues['revenue']) {
                    return Promise.reject(
                      new Error('Please select one option and save.')
                    );
                  }
                }
              }
            ]}
          >
            <RadioComponent
              keyName="third_year_revenue"
              parentHandler={(key, value) => {
                revenueHandler(key, value[key]);
              }}
              disabled={isRevenueSaved}
            />
          </Form.Item>
          <Space direction="horizontal">
            {!formValues['revenue'] ? (
              <Button
                type="warning"
                style={{
                  float: 'right',
                  marginRight: '0px',
                  marginTop: '0px',
                  backgroundColor: '#82CF8F',
                  color: 'white'
                }}
                onClick={() => {
                  addField(
                    'revenue',
                    objectToFormData({
                      first_year_revenue: revenue['first_year_revenue'],
                      second_year_revenue: revenue['second_year_revenue'],
                      third_year_revenue: revenue['third_year_revenue']
                    })
                  )
                    .then(({ data }) => {
                      if (data.success) {
                        inputChangedHandler('revenue', data['data']['id']);
                        setIsRevenueSaved(true);
                        form.validateFields([
                          'first_year_revenue',
                          'second_year_revenue',
                          'third_year_revenue'
                        ]);
                      } else {
                        inputChangedHandler('revenue', null);
                      }
                    })
                    .catch(error => {
                      console.log(error);
                    });
                }}
                disabled={
                  !(
                    revenue?.['first_year_revenue'] &&
                    revenue?.['second_year_revenue'] &&
                    revenue?.['third_year_revenue']
                  )
                }
              >
                Save
              </Button>
            ) : null}
          </Space>

          {/* Number of Employees */}
          <Form.Item
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                message: 'Please select one option.'
              }
            ]}
            noStyle
          >
            <React.Fragment>
              <div
                className="ant-row ant-form-item"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  padding: '7px',
                  backgroundColor: 'lightgray',
                  color: 'rgba(0, 0, 0, 0.85)',
                  fontSize: '14px'
                }}
              >
                Number of Employees:
              </div>
            </React.Fragment>
          </Form.Item>
          <RadioComponent
            keyName="no_of_employees"
            parentHandler={(key, value) => {
              inputChangedHandler('no_of_employees', value[key]);
            }}
          />

          {/* Certifications */}
          <Form.List name="certification">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    required={false}
                    key={`${field.key}_${index}`}
                    wrapperCol={{
                      md: { offset: index === 0 ? 0 : 4, span: 20 },
                      sm: { span: 24 }
                    }}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                    label={index === 0 ? 'Certifications' : ''}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'certification']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`certification_${index}`}
                    >
                      <Dropdowns
                        keyName="certifications"
                        label="Certification"
                        multiSelect={false}
                        parentHandler={(key, value) => {
                          certificationsHandler(
                            'certification',
                            value[key],
                            index
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'certification_agency']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`certification_agency_${index}`}
                    >
                      <Input
                        placeholder="Certification Agency"
                        onChange={e => {
                          certificationsHandler(
                            'certification_agency',
                            e.target.value,
                            index
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'certification_no']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`certification_no_${index}`}
                    >
                      <Input
                        placeholder="Certification Number"
                        onChange={e => {
                          certificationsHandler(
                            'certification_no',
                            e.target.value,
                            index
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'expiration_date']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`expiration_date_${index}`}
                    >
                      <Input
                        placeholder="Expiration Date (MM/DD/YYYY)"
                        onChange={e => {
                          certificationsHandler(
                            'expiration_date',
                            e.target.value,
                            index
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'certification_proof']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`certification_proof_${index}`}
                    >
                      <FileUpload
                        keyName="certification_proof"
                        label="Upload"
                        styleObj={{ marginLeft: '-7px', width: '101%' }}
                        parentHandler={(key, value) => {
                          certificationsHandler(
                            'certification_proof',
                            value[key],
                            index
                          );
                        }}
                      />
                    </Form.Item>

                    <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginRight: '0px',
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['certification'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                      {!formValues?.['certification']?.[index] ? (
                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '0px',
                            marginTop: '0px',
                            backgroundColor: '#82CF8F',
                            color: 'white'
                          }}
                          onClick={() => {
                            addField(
                              'certification',
                              objectToFormData(certifications[index])
                            )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'certification',
                                    data['data']['id']
                                  );
                                } else {
                                  // microApiResponseHandler(
                                  //   'certification',
                                  //   null
                                  // );
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                          }}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Space>
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '20px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 5 ? (
                    <Button
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Certification
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Additional Information */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px',
                fontWeight: 600
              }}
            >
              Additional Information:
            </div>
          </React.Fragment>

          {/* Statement of Qualification */}
          <Form.Item
            key="soq"
            name="soq"
            label="Statement of Qualification"
            hasFeedback
            labelCol={{ span: 6 }}
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
          >
            <FileUpload
              keyName="soq"
              styleObj={{ marginLeft: '-7px', width: '101%' }}
              parentHandler={(key, value) => {
                inputChangedHandler(key, value[key]);
              }}
            />
          </Form.Item>

          {/* Company LinkedIn Profile */}
          <Form.Item
            key="linkedin_profile"
            name="linkedin_profile"
            label="Company LinkedIn Profile"
            labelCol={{ span: 6 }}
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '20px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
          >
            <Input
              name="linkedin_profile"
              placeholder="LinkedIn Profile"
              style={{ marginLeft: '-7px', width: '101%' }}
              type="text"
              onChange={e => {
                e.persist();
                debouncedInput(e, 'linkedin_profile');
              }}
            />
          </Form.Item>

          {/* Optional Information */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px',
                fontWeight: 600
              }}
            >
              Optional Information:
            </div>
          </React.Fragment>

          {/* Owner Demographic */}
          <Form.Item
            key="owner_demographic"
            name="owner_demographic"
            label="Owner Demographic"
            labelCol={{ span: 6 }}
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
          >
            <Dropdowns
              keyName="demographics"
              label="Owner Demographic"
              multiSelect={false}
              parentHandler={(key, value) => {
                inputChangedHandler('owner_demographic', value[key]);
                if (value[key] === 'Other') {
                  setIsOtherDemographic(true);
                } else {
                  setIsOtherDemographic(false);
                  setOtherDemographic('');
                }
              }}
            />
          </Form.Item>
          {isOtherDemographic ? (
            <Form.Item
              key="other_demographic"
              name="other_demographic"
              label="Other Demographic"
              labelCol={{ span: 6 }}
              style={{
                borderBottom: '1px solid gray',
                borderLeft: '1px solid gray',
                borderRight: '1px solid gray',
                marginBottom: '0px',
                paddingLeft: '7px'
              }}
            >
              <Input
                name="other_demographic"
                placeholder="Please enter demographic."
                type="text"
                value={otherDemographic}
                onChange={e => {
                  e.persist();
                  debouncedInput(e, 'other_demographic');
                }}
              />
            </Form.Item>
          ) : null}

          <Form.Item
            key="liability_insurance"
            name="liability_insurance"
            label="Liability Insurance"
            labelCol={{ span: 6 }}
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
          >
            <FileUpload
              keyName="liability_insurance"
              label="Upload"
              styleObj={{ marginLeft: '-7px', width: '101%' }}
              parentHandler={(key, value) => {
                inputChangedHandler(key, value[key]);
              }}
            />
          </Form.Item>
          <Form.Item
            key="compensation_insurance"
            name="compensation_insurance"
            label="Compensation Insurance"
            labelCol={{ span: 6 }}
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
          >
            <FileUpload
              keyName="compensation_insurance"
              styleObj={{ marginLeft: '-7px', width: '101%' }}
              parentHandler={(key, value) => {
                inputChangedHandler(key, value[key]);
              }}
            />
          </Form.Item>
          <Form.Item
            key="disability_insurance"
            name="disability_insurance"
            label="Disablity Insurance"
            labelCol={{ span: 6 }}
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '30px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
          >
            <FileUpload
              keyName="disability_insurance"
              styleObj={{ marginLeft: '-7px', width: '101%' }}
              parentHandler={(key, value) => {
                inputChangedHandler(key, value[key]);
              }}
            />
          </Form.Item>

          {/* Action Buttons */}
          <Form.Item
            wrapperCol={{ md: { offset: 6, span: 12 }, sm: { span: 24 } }}
            style={{ textAlign: 'center' }}
          >
            <Space direction="horizontal" align="center">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

SmallBusinessAdd.propTypes = {
  addSmallBusiness: func,
  onCancel: func
};

const mapDispatchToProps = dispatch => ({
  addSmallBusiness: payload => dispatch(onAddSmallBusiness(payload)),
  onCancel: () => dispatch(hideModal())
});

export default connect(null, mapDispatchToProps)(SmallBusinessAdd);
