import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { emailPattern } from '../../utils/constants';
import { AddUser } from '../../api/addUsers';
import { handleNotifications } from '../../utils/notifications';
import { hideModal } from '../../redux/actions/ui';
import { objectToFormData } from '../../utils/utils';

const { Title } = Typography;

const AddUserForm = ({ getData }) => {
  const [form] = Form.useForm();
  const loginData = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    let payload = {
      ...values,
      object_id: loginData?.user?.businessId,
      object_type: loginData?.role === 'prime' ? 'Primes' : 'SmallBusiness',
    }
    console.log(payload)
    AddUser(objectToFormData(payload))
    .then(res => {
      if (res.data.success) {
        handleNotifications('success', 'BZ Connects !!', res.data?.message);
        getData();
        setTimeout(()=>{
          dispatch(hideModal());
        },1000)
        return
      }
      handleNotifications('error', 'BZ Connects !!', res.data?.message || 'Error Occured !!');
    })
    .catch(err=>{
      handleNotifications(
        'error',
        'BZ CONNECT!!',
        err.response?.data?.message || 'Something Bad Happened!'
      )
    })
  };

  const onFail = errors => {
    console.log(errors);
  };

  return (
    <Row align="middle" justify="center" className="addUsers-container">
      <Col span={24}>
        <Title level={2}>Add Users</Title>
        <Form
          form={form}
          layout="horizontal"
          name="addUser-form"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          onFinish={onFinish}
          onFinishFailed={onFail}
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: 'First Name is required' }]}
            hasFeedback
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Last Name is required' }]}
            hasFeedback
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Email is required.' },
              {
                pattern: emailPattern,
                message: 'Please enter a valid email.'
              }
            ]}
            hasFeedback
          >
            <Input placeholder="Email" type="email" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: 'Password is required' },
              { min: 6, message: 'Password too short' }
            ]}
            hasFeedback
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default AddUserForm;
