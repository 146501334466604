import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getFromLocalStorage } from '../utils/localStorage';

const nonPaidRoutes = [
  '/my-business', '/videos', '/change-password'
]

const ProtectedRoute = ({ component: Component, free_user, codename, isValidPayment, businessId, ...rest }) => {
  const permissions = getFromLocalStorage('permissions');
  const allowed = getFromLocalStorage('allowed');

  const renderComponent = () => {
    return (
      !free_user && businessId && !allowed && !["/partial-form"].includes(rest.path) ? 
      <Redirect to={`/partial-form`} /> :
      <Route {...rest} component={Component} />
    )
  }
  return (
    (nonPaidRoutes.includes(rest.path) ? true : isValidPayment && permissions?.[codename]) || !businessId ? 
    renderComponent() :
    <Redirect to="/payments" />
  );
};

const mapStateToProps = (state) => {
  return ({
    isValidPayment: state.auth.isValidPayment,
    businessId: state.auth.user?.businessId,
    free_user: state.auth.free_user,
  })
}

ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  isValidPayment: PropTypes.bool,
}
export default connect(mapStateToProps)(ProtectedRoute);
