import connection from '../utils/api';
import { associations } from '../utils/apiStrings.json';

/* Add API for field */
export const addField = (key, payload) =>
  connection.post(`${associations[key]}`, payload);

/* Update API for field */
export const updateField = (key, id, payload) =>
  connection.put(`${associations[key]}/${id}`, payload);

/* Delete API for field */
export const deleteField = (key, id) =>
  connection.delete(`${associations[key]}/${id}`);