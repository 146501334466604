import React from 'react'
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { onAddContact } from '../../redux/actions/contact';
import {
  Form,
  Input,
  Button,
  Select
} from 'antd';
import { objectToFormData } from '../../utils/utils';

const Add = ({ config, addContact }) => {

  const submit = values => {
    console.log(values);
    values['action'] = 'create';
    addContact(objectToFormData(values));
  }

  return(
    <>
      <h1>Contact Us</h1>
      <Form
        layout="vertical"
        onFinish={submit}
      >
        <Form.Item name="type_id" label="Request Type">
          <Select>
            <Select.Option value="1">General Query</Select.Option>
            <Select.Option value="2">Technical Difficulty</Select.Option>
          </Select>
        </Form.Item> 
        <Form.Item name="subject" label="Subject">
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item name="message" label="Message">
        <Input.TextArea showCount maxLength={500} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  addContact: (payload) => dispatch(onAddContact(payload))
});

Add.propTypes = { addContact: func };

export default connect(null, mapDispatchToProps)(Add);