import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import { hideLoading, showLoading } from '../../redux/actions/ui';

import SbPartial from './sbPartial';
import PrimesPartial from './primesPartial';
import AssociationEdit from '../../components/customForms/Assocation';
import { handleNotifications } from '../../utils/notifications';
import { getPrime } from '../../api/primes';
import { getSmallBusiness } from '../../api/smallBusiness';
import { getAssociationDetails } from '../../api/associations';
import { getFromLocalStorage, setToLocalStorage } from '../../utils/localStorage';
import { Spin } from 'antd';

const MyBusiness = ({ role, free_user, showLoading, hideLoading, businessId, history, updateAllowed }) => {
  const [loader, showLoader] = useState(false);
  const [details, setDetails] = useState(null);

  useEffect(()=>{
    const allowed = getFromLocalStorage('allowed');
    if(allowed){
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  },[updateAllowed])

  useEffect(()=>{
    if(businessId){
      showLoader(true);
      showLoading();
      const allowed = getFromLocalStorage('allowed');
      (async ()=>{
        try {
          if(role === "prime"){
            const res = await getPrime(businessId);
            if(res.data && !res.data['success']) throw new Error('Could not fetch details');
            if(res && res.data?.data && res.data.data.address?.length && res.data.data.company_specialization && !allowed){
              setToLocalStorage("allowed", {allowed: true})
              history.push('/dashboard')
            }else{
              setDetails(res.data.data)
            }
          }else if(role === "small-business" && !free_user){
            const res = await getSmallBusiness(businessId);
            if(res && !res.data['success']) throw new Error('Could not fetch details');
            if(res && res.data?.data && res.data.data.address?.length && res.data.data.industry_specialization && !allowed){
              setToLocalStorage("allowed", {allowed: true})
              history.push('/dashboard')
            }else{
              setDetails(res.data.data)
            }
          }else if(role === "small-business" && free_user){
            const res = await getSmallBusiness(businessId);
            if(res && !res.data['success']) throw new Error('Could not fetch details');
            if(res && res.data?.data && res.data.data.address?.length && !allowed){
              setToLocalStorage("allowed", {allowed: true})
            }
          }else{
            const res = await getAssociationDetails(businessId);
            if(res && !res.data['success']) throw new Error('Could not fetch details');
            if(res && res.data?.data && (res.data.data.name || res.data.data.company_name) && res.data.data.website && !allowed){
              setToLocalStorage("allowed", {allowed: true})
              history.push('/dashboard')
            }
          }
          showLoader(false);
          hideLoading();
        } catch (error) {
          hideLoading();
          handleNotifications('error', 'Failed', 'Could not fetch details');
        }
      })()
    }
    // eslint-disable-next-line
  }, [businessId])

  return (
    !loader ? (<div style={{paddingLeft: '15px'}}>
      {role === 'small-business' ? <SbPartial details={details} /> : null}
      {role === 'prime' ? <PrimesPartial details={details} /> : null}
      {role === 'association' ? <AssociationEdit partial={details} edit={true} selfMode={true} /> : null}
    </div>) : <Spin />
  );
};

const mapStateToProps = state => ({
  role: state.auth?.role,
  businessId: state.auth.user?.businessId,
  updateAllowed: state.auth.updateAllowed,
  free_user: state.auth?.free_user
});

MyBusiness.propTypes = {
  role: string,
};

export default connect(mapStateToProps, {showLoading, hideLoading})(MyBusiness);
