import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, number, string } from 'prop-types';
import Downloader from '../../utils/downloader';
// import { isEmptyObj } from '../../utils/utils';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  Select,
  Typography,
  DatePicker,
  InputNumber,
  Spin,
  AutoComplete,
  Radio
} from 'antd';
import {
  PlusOutlined,
  DownloadOutlined,
  EditOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';

import { addField, updateField } from '../../api/smallBusinessFields';
import { getSmallBusiness } from '../../api/smallBusiness';
import { getUserPackage, updateUserPackage } from '../../api/adminUpdates';
import { hideModal } from '../../redux/actions/ui';
import {
  onUpdateSmallBusiness,
  verifyCertificatesAction
} from '../../redux/actions/smallBusiness';
import { handleNotifications } from '../../utils/notifications';
import { debounce, objectToFormData } from '../../utils/utils';
import ImageUpload from '../common/Form/FormInputs/ImageUpload';
import Address from '../common/Form/FormInputs/Address';
import Dropdowns from '../common/Form/FormInputs/Dropdowns';
import FileUpload from '../common/Form/FormInputs/FileUpload';
import {
  NaicsCodes,
  phoneUSPattern,
  websiteUrlPattern
} from '../../utils/constants';
import RadioComponent from '../common/Form/FormInputs/Radio';
import dayjs from 'dayjs';
import moment from 'moment';

const { Title, Text } = Typography;
const { TextArea } = Input;

const downloadStyle = {
  width: 150,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '0 5px',
  backgroundColor: '#1890ff',
  color: 'white',
  border: 'none'
};

const SmallBusinessEdit = ({
  selfMode,
  businessId,
  updateSmallBusiness,
  verifyCertificates,
  onCancel,
  history,
  role,
  companyVerificationSteps
}) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState({});
  const [packages, setPackages] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [adminEdit, setAdminEdit] = useState(false);

  const params = useParams();
  const { Option } = Select;
  const smallBusinessId = selfMode ? businessId : params['smallBusinessId'];

  const dueDiligenceOptions = [
    { VERIFIED_CLEAR: 'Verified - Clear' },
    { VERIFIED_RECORD_FOUND: 'Verified - Record Found' },
    { NOT_VERIFIED: 'Not Verified' }
  ];

  useEffect(() => {
    if (smallBusinessId) {
      setLoading(true);
      getSmallBusiness(smallBusinessId)
        .then(({ data }) => {
          if (data['success']) {
            setDetails(data['data']);
            getFormValuesFromDetails(data['data']);
          } else {
            setDetails(null);
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          setLoading(false);
        })
        .catch(error => {
          setDetails(null);
          setLoading(false);
          console.log(error);
        });

      // getUserPackage(smallBusinessId, 'SmallBusiness')
      //   .then(({ data }) => {
      //     if (data['success']) {
      //       setSubscription(data['data']);
      //       setPackages(data['data']['options']);
      //     } else {
      //       // setDetails(null);
      //       handleNotifications('error', 'Failed', 'Could not fetch details');
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallBusinessId]);

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: null,
    phone: null,
    company_website: null,
    address: [],
    company_contacts: [],
    company_profile: null,
    naics_codes: [],
    company_services: [],
    industry_specialization: [],
    agencies: [],
    revenue: null,
    unspsc_codes: [],
    no_of_employees: null,
    certification: [],
    soq: null,
    linkedin_profile: null,
    owner_demographic: null,
    verification_step_id: 0,
    liability_insurance: null,
    compensation_insurance: null,
    disability_insurance: null,
    updated_on: '',
    due_diligence: null,
    si: null
  });
  // const [revenue, setRevenue] = useState({
  //   first_year_revenue: null,
  //   second_year_revenue: null,
  //   third_year_revenue: null
  // });

  const [certifications, setCertifications] = useState([]);
  const [disableBtns, setDisableBtns] = useState({});

  const [isOtherDemographic, setIsOtherDemographic] = useState(false);
  const [otherDemographic, setOtherDemographic] = useState('');
  // const [newValues, setNewValues] = useState(null);

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      logo_url: logoUrl,
      logo_name: logoName,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      address,
      company_contacts: companyContacts,
      company_profile: companyProfile,
      unspsc_codes,
      naics_codes: naicsCodes,
      company_services: companyServices,
      industry_specialization: industrySpecialization,
      agencies,
      previous_year_revenue: previousYearRevenue,
      no_of_employees: noOfEmployees,
      certification,
      soq_name: soqName,
      soq_url: soqUrl,
      linkedin_profile: linkedinProfile,
      owner_demographic: ownerDemographic,
      liability_insurance_proof_name: liabilityInsuranceProofName,
      liability_insurance_proof_url: liabilityInsuranceProofUrl,
      worker_compensation_insurance_proof_name:
        workerCompensationInsuranceProofName,
      worker_compensation_insurance_proof_url:
        workerCompensationInsuranceProofUrl,
      disability_insurance_proof_name: disabilityInsuranceProofName,
      disability_insurance_proof_url: disabilityInsuranceProofUrl,
      updated_on: updatedOn,
      due_diligence,
      verification_step_id,
      si_name: siName,
      si_url: siUrl
    } = details;

    if (logoUrl) {
      newFormValues['logo'] = {
        url: logoUrl,
        name: logoName
      };
    }
    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['address'],
        state: item['state'],
        county: item['county'],
        zipcode: item['zip_code'],
        city: item['city']
      }));
    }
    if (unspsc_codes?.length) {
      newFormValues['unspsc_codes'] = unspsc_codes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyContacts?.length) {
      newFormValues['company_contacts'] = companyContacts.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }
    if (naicsCodes?.length) {
      newFormValues['naics_codes'] = naicsCodes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyServices?.length) {
      newFormValues['company_services'] = [...companyServices];
    }
    if (industrySpecialization?.length) {
      newFormValues['industry_specialization'] = [...industrySpecialization];
    }
    if (previousYearRevenue) {
      newFormValues['revenue'] = {
        id: previousYearRevenue['id'],
        first_year_revenue: previousYearRevenue['first_year_revenue'],
        second_year_revenue: previousYearRevenue['second_year_revenue'],
        third_year_revenue: previousYearRevenue['third_year_revenue']
      };
    }
    if (noOfEmployees) {
      newFormValues['no_of_employees'] = noOfEmployees;
    }
    newFormValues['due_diligence'] = due_diligence;
    newFormValues['verification_step_id'] = verification_step_id;
    if (certification) {
      newFormValues['certification'] = certification.map(item => ({
        id: item['id'],
        certification: item['certification'],
        certification_agency: item['certification_agency'],
        // certification_no: item['certification_no'],
        certification_proof: {
          name: item['proof_name'],
          url: item['proof_url']
        },
        expiration_date: item['expiration_date']
      }));
      setCertifications(newFormValues['certification']);
    }
    if (soqUrl) {
      newFormValues['soq'] = { url: soqUrl, name: soqName };
    }
    if (siUrl) {
      newFormValues['si'] = { url: siUrl, name: siName };
    }
    if (liabilityInsuranceProofUrl) {
      newFormValues['liability_insurance'] = {
        url: liabilityInsuranceProofUrl,
        name: liabilityInsuranceProofName
      };
    }
    if (workerCompensationInsuranceProofUrl) {
      newFormValues['compensation_insurance'] = {
        url: workerCompensationInsuranceProofUrl,
        name: workerCompensationInsuranceProofName
      };
    }
    if (disabilityInsuranceProofUrl) {
      newFormValues['disability_insurance'] = {
        url: disabilityInsuranceProofUrl,
        name: disabilityInsuranceProofName
      };
    }

    setFormValues({
      ...newFormValues,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      company_profile: companyProfile,
      agencies: agencies || [],
      linkedin_profile: linkedinProfile,
      owner_demographic: ownerDemographic,
      updated_on: updatedOn
    });
  };

  const inputChangedHandler = (key, value) => {
    if (key === 'revenue') {
      setFormValues({
        ...formValues,
        [key]: { ...formValues[key], id: value }
      });
    } else {
      setFormValues({ ...formValues, [key]: value });
    }
  };

  const handleDownload = (e, url, name) => {
    e.stopPropagation();
    e.preventDefault();
    Downloader(url, name);
  };

  const debouncedInput = debounce((e, key) => {
    if (key === 'other_demographic') {
      inputChangedHandler('owner_demographic', e.target.value);
      return;
    }
    inputChangedHandler(key, e.target.value);
  }, 500);

  const revenueHandler = (key, value) => {
    // setRevenue({ ...revenue, [key]: value });
    setFormValues({
      ...formValues,
      revenue: { ...formValues['revenue'], [key]: value }
    });
  };

  const certificationsHandler = (key, value, index) => {
    const newCertifications = [...certifications];
    if (newCertifications[index]) {
      newCertifications[index][key] = value;
    } else {
      newCertifications.push({ [key]: value });
    }
    setCertifications([...newCertifications]);
  };

  const microApiResponseHandler = (key, value, index) => {
    // setFormValues({ ...formValues, [key]: [...formValues[key], value] });
    let cert = [...formValues[key]];
    cert[index] = value;
    setFormValues({ ...formValues, [key]: cert });
    setDisableBtns(prevBtns => ({ ...prevBtns, [key]: false }));
  };

  const onPackageSelect = id => {
    setLoading(true);
    // eslint-disable-next-line
    packages.map(e => {
      if (e.id === id) {
        setPackageTypes(e['package_type']);
      }
    });
    setLoading(false);
  };

  // Updates package details of this user
  const adminSubmit = values => {
    setLoading(true);
    const { expiry_date } = values;
    values['expiry_date'] = expiry_date.format('YYYY-MM-DD');
    values['entity_type'] = 'SmallBusiness';
    console.log('adminValues', values);
    updateUserPackage(smallBusinessId, objectToFormData(values)).then(
      ({ data }) => {
        if (data['success']) {
          console.log(data['data']);
          setLoading(false);
          setAdminEdit(false);
          handleNotifications('success', 'Success', data['message']);
          onCancel();
          history.push('/small-business');
        } else {
          handleNotifications('error', 'Failed', 'Could not update details');
          setLoading(false);
          setAdminEdit(false);
        }
      }
    );
  };

  const submit = values => {
    if (formValues.logo?.url) {
      delete formValues.logo;
    }

    let payload = {};
    for (let key in formValues) {
      if (formValues[key]) {
        if (Array.isArray(formValues[key]) && !formValues[key]?.length)
          continue;

        if (
          [
            'address',
            'company_contacts',
            'naics_codes',
            'certification',
            'unspsc_codes'
          ].includes(key)
        ) {
          payload[key] = JSON.stringify(
            formValues[key].map(item => item.id || item)
          );
        } else if (
          ['agencies', 'company_services', 'industry_specialization'].includes(
            key
          )
        ) {
          payload[key] = JSON.stringify(formValues[key]);
        } else if (
          [
            'soq',
            'liability_insurance',
            'disability_insurance',
            'compensation_insurance'
          ].includes(key)
        ) {
          formValues[key]?.url
            ? (payload[key] = JSON.stringify(formValues[key]))
            : (payload[key] = formValues[key]);
        } else if (key === 'revenue') {
          payload['revenue'] =
            formValues['revenue']?.id || formValues['revenue'];
        } else if (key === 'no_of_employees') {
          payload['no_of_employees'] = formValues['no_of_employees'];
        } else {
          payload[key] = formValues[key];
        }
      }
    }
    payload['due_diligence'] = formValues['due_diligence'];
    payload['verification_step_id'] = formValues['verification_step_id'];
    updateSmallBusiness(smallBusinessId, objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
    handleNotifications(
      'warning',
      'Warning',
      'Some fields require your attention.'
    );
  };

  const handleVerifyCertificate = (value = 'false') => {
    verifyCertificates(smallBusinessId, objectToFormData({ is_valid: value }));
    setFormValues(prev => ({
      ...prev,
      certification_proof_verified: value === 'True' ? 'True' : 'False'
    }));
  };

  const editImage = () => {
    setFormValues(prev => ({ ...prev, logo: null }));
  };

  return (
    <Card loading={loading}>
      {formValues && details ? (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Title level={2} className="heading-title">
              Edit Small Business Profile
            </Title>
            {subscription && subscription.package_id && (
              <Form
                layout="vertical"
                style={{
                  display: adminEdit ? 'block' : 'none'
                }}
                initialValues={{
                  package_id: subscription?.package_id,
                  package_type_id: subscription?.package_type_id,
                  expiry_date: details?.expiry_date
                    ? dayjs(details?.expiry_date)
                    : null,
                  additional_users: details?.additional_users
                    ? details.additional_users
                    : null
                }}
                onFinish={adminSubmit}
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="package_id"
                      label="Select a package"
                      rules={[
                        { required: true, message: 'Please select a package' }
                      ]}
                    >
                      <Select
                        onSelect={onPackageSelect}
                        options={packages.map(e => ({
                          label: e.name,
                          value: e.id
                        }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="package_type_id"
                      label="Select a package type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a package type'
                        }
                      ]}
                    >
                      <Select
                        options={packageTypes.map(e => ({
                          label: e.name,
                          value: e.id
                        }))}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="expiry_date" label="Expiry Date">
                      <DatePicker />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="additional_users" label="Additional Users">
                      <InputNumber min={1} max={10} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Form>
            )}
            <Form
              form={form}
              name="add-small-business"
              layout="horizontal"
              labelCol={{ /* offset: 4, */ span: 4 }}
              wrapperCol={{ /* offset: 4,  */ span: 20 }}
              labelAlign="left"
              onFinish={submit}
              validateTrigger={['']}
              onFinishFailed={submitFail}
              initialValues={{ ...formValues }}
            >
              <Row>
                <Col span={16}>
                  {/* <div
                    style={{
                      display: adminEdit ? 'none' : 'block'
                    }}
                  >
                    <Title level={5} style={{ textAlign: 'left' }}>
                      Package Name:{' '}
                      {subscription ? subscription.package_name : ''}
                    </Title>
                    <Title level={5} style={{ textAlign: 'left' }}>
                      Expiration Date: {details.expiry_date}
                    </Title>
                    <Title level={5} style={{ textAlign: 'left' }}>
                      No. of users: {details.additional_users}
                    </Title>
                    {role === 'admin' ? (
                      <Button
                        type="primary"
                        onClick={() => setAdminEdit(!adminEdit)}
                      >
                        Edit Package Details
                      </Button>
                    ) : null}
                  </div> */}
                </Col>
                <Col span={8}>
                  {/* Company Logo */}
                  <Form.Item
                    key="logo"
                    name="logo"
                    hasFeedback
                    style={{ textAlign: 'right' }}
                    wrapperCol={{ md: { offset: 4 }, sm: { offset: 0 } }}
                  >
                    {formValues['logo'] && formValues['logo']?.['url'] ? (
                      <React.Fragment>
                        <Image
                          src={
                            formValues['logo'] ? formValues['logo']['url'] : ''
                          }
                          alt={
                            formValues['logo']
                              ? formValues['logo']['name']
                              : 'No logo uploaded.'
                          }
                          preview={false}
                          width="100px"
                          height="100px"
                        />
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                          size="small"
                          style={{ transform: 'translate(-25px, -10px)' }}
                          onClick={editImage}
                        />
                      </React.Fragment>
                    ) : (
                      <ImageUpload
                        keyName="logo"
                        label="Company Logo"
                        max={1}
                        nestedHandler={(key, value) => {
                          inputChangedHandler(key, value[key]);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {/* Business Name */}
              <Form.Item
                key="company_name"
                name="company_name"
                label="Business Name"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company name is required.'
                  }
                ]}
              >
                <Input
                  name="company_name"
                  placeholder="Business Name"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  type="text"
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_name');
                  }}
                />
              </Form.Item>

              {/* Business Phone */}
              <Form.Item
                key="phone"
                name="phone"
                label="Business Phone"
                required={true}
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    validator: async (_, phone) => {
                      if (!phone) {
                        return Promise.reject(
                          new Error('Phone Number is required.')
                        );
                      }
                    }
                  },
                  {
                    pattern: phoneUSPattern,
                    message: 'Please enter a valid phone number.'
                  }
                ]}
              >
                <Input
                  name="phone"
                  placeholder="Business Phone (10-digit)"
                  type="tel"
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  maxLength={10}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'phone');
                  }}
                />
              </Form.Item>

              {/* Website */}
              <Form.Item
                key="company_website"
                name="company_website"
                label="Website"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company Website is required.'
                  },
                  {
                    pattern: websiteUrlPattern,
                    message: 'Please enter a valid web url.'
                  }
                ]}
              >
                <Input
                  name="company_website"
                  placeholder="Company Website"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_website');
                  }}
                />
              </Form.Item>

              {/* Business Addresses */}
              <Form.List
                name="address"
                rules={[
                  {
                    validator: async (_, address) => {
                      if (!address || address.length < 1) {
                        return Promise.reject(
                          new Error('At least 1 address is required.')
                        );
                      }
                    }
                  }
                ]}
              >
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          clear: 'both',
                          backgroundColor: 'lightgray'
                        }}
                        required={true}
                        key={field.key}
                        label={index === 0 ? 'Business Address' : ''}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              validator: async (_, address) => {
                                if (!formValues['address'][index]) {
                                  return Promise.reject(
                                    new Error(
                                      'Please input details and Save or delete this field.'
                                    )
                                  );
                                }
                              }
                            }
                          ]}
                          noStyle
                        >
                          <Address
                            keyName="address"
                            microApi={{ add: addField, update: updateField }}
                            parentHandler={microApiResponseHandler}
                            hasCity={true}
                            hasZip={true}
                            index={index}
                            valueProps={formValues['address'][index]}
                          />
                        </Form.Item>

                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '3px',
                            marginTop: '0px',
                            backgroundColor: '#D26A31',
                            color: 'white'
                          }}
                          onClick={() => {
                            formValues['address'].splice(index, 1);
                            return form.setFieldsValue({
                              address: formValues?.['address']
                            });
                            // return remove(field.name);
                          }}
                        >
                          Remove
                        </Button>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        float: 'left',
                        clear: 'both',
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Address
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Company Contacts */}
              <Form.List name="company_contacts">
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray',
                          clear: 'both'
                        }}
                        required={false}
                        key={`${field.key}_${index}`}
                        label={index === 0 ? 'Company Contacts' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          key={`name_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Name"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'title']}
                          key={`title_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Title"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'phone']}
                          key={`phone_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Phone"
                            type="tel"
                            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                            maxLength={10}
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          key={`email_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Email"
                            type="email"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>

                        <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['company_contacts'].splice(index, 1);
                              form.setFieldsValue({
                                company_contacts:
                                  formValues?.['company_contacts']
                              });
                              // return remove(field.name);
                            }}
                          >
                            Remove
                          </Button>
                          {/* {!formValues['company_contacts'][index] ? ( */}
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            disabled={disableBtns['company_contacts']}
                            onClick={() => {
                              let ind = index;
                              let id =
                                form.getFieldValue('company_contacts')[index]
                                  ?.id;
                              setDisableBtns(prevBtns => ({
                                ...prevBtns,
                                company_contacts: true
                              }));
                              if (id) {
                                updateField(
                                  'company_contacts',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('company_contacts')[
                                      index
                                    ]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'company_contacts',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Company contact updated successfully.'
                                      );
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              } else {
                                addField(
                                  'company_contacts',
                                  objectToFormData(
                                    form.getFieldValue('company_contacts')[
                                      index
                                    ]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'company_contacts',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Company contact updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler(
                                      //   'company_contacts',
                                      //   null
                                      // );
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                          {/* ) : null} */}
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '1px',
                        paddingLeft: '7px',
                        clear: 'both',
                        float: 'left'
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Company Contact
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* UNSPSC Code */}
              <Form.List name="unspsc_codes">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        key={`${field.key}_${index}`}
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          clear: 'both',
                          // float: 'left',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'UNSPSC Codes' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'codes']}
                          key={`codes_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Code"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          key={`description_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Description"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>

                        <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['unspsc_codes'].splice(index, 1);
                              form.setFieldsValue({
                                unspsc_codes: formValues?.['unspsc_codes']
                              });
                              // return remove(field.name);
                            }}
                          >
                            Remove
                          </Button>
                          {/* {!formValues['unspsc_codes'][index] ? ( */}
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            disabled={disableBtns['unspsc_codes']}
                            onClick={() => {
                              let ind = index;
                              let id =
                                form.getFieldValue('unspsc_codes')[index]?.id;
                              setDisableBtns(prevBtns => ({
                                ...prevBtns,
                                unspsc_codes: true
                              }));
                              if (id) {
                                updateField(
                                  'unspsc',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('unspsc_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'unspsc_codes',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'UNSPSC Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('unspsc_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      unspsc_codes: false
                                    }));
                                  });
                              } else {
                                addField(
                                  'unspsc',
                                  objectToFormData(
                                    form.getFieldValue('unspsc_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'unspsc_codes',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'UNSPSC Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('unspsc_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      unspsc_codes: false
                                    }));
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                          {/* ) : null} */}
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '1px',
                        clear: 'both',
                        float: 'left',
                        paddingLeft: '7px'
                      }}
                    >
                      {fields.length < 2 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add UNSPSC Code
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Brief Company Profile */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    clear: 'both'
                  }}
                >
                  Brief Company Profile: (max 300 words)
                </div>
              </React.Fragment>
              <TextArea
                name="company_profile"
                placeholder="Brief Company Profile (max 300 words)"
                rows={3}
                maxLength={300}
                // defaultValue={formValues['company_profile']}
                onChange={e => {
                  e.persist();
                  debouncedInput(e, 'company_profile');
                }}
              />

              {/* NAICS Code */}
              <Form.List name="naics_codes">
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        key={`${field.key}_${index}`}
                        label={index === 0 ? 'NAICS Codes' : ''}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'codes']}
                          key={`codes_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <AutoComplete
                            style={{ marginLeft: '-7px', width: '101%' }}
                            options={NaicsCodes}
                            placeholder="Search Naics Codes"
                            filterOption={(inputValue, option) => {
                              let bool = !isNaN(inputValue)
                                ? option?.value.toString().includes(inputValue)
                                : option?.label
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1;
                              if (bool) {
                                let val = form.getFieldValue('naics_codes');
                                let obj = {
                                  ...val?.[index],
                                  description: option.label
                                };
                                val.splice(index, 1, obj);
                                form.setFieldsValue({ naics_codes: [...val] });
                              }
                              return bool;
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          key={`description_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="NAICS Description"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled
                          />
                        </Form.Item>

                        <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['naics_codes'].splice(index, 1);
                              form.setFieldsValue({
                                naics_codes: formValues?.['naics_codes']
                              });
                              // return remove(field.name);
                            }}
                          >
                            Remove
                          </Button>
                          {/* {!formValues['naics_codes'][index] ? ( */}
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            disabled={disableBtns['naics_codes']}
                            onClick={() => {
                              let ind = index;
                              let id =
                                form.getFieldValue('naics_codes')[index]?.id;
                              setDisableBtns(prevBtns => ({
                                ...prevBtns,
                                naics_codes: true
                              }));
                              if (id) {
                                updateField(
                                  'naics_codes',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('naics_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'naics_codes',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Naics Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('naics_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              } else {
                                addField(
                                  'naics_codes',
                                  objectToFormData(
                                    form.getFieldValue('naics_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'naics_codes',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Naics Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('naics_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                          {/* ) : null} */}
                        </Space>
                      </Form.Item>
                    ))}
                    {fields.length < 5 ? (
                      <Form.Item
                        style={{
                          border: '1px dashed gray',
                          marginBottom: '1px',
                          paddingLeft: '7px',
                          float: 'left'
                        }}
                      >
                          <Button
                            type="primary"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Add NAICS Code
                          </Button>

                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>

              {/* Company Specializations */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Company Specialization:
                </div>
              </React.Fragment>
              <Dropdowns
                keyName="specializations"
                label="specializations"
                multiSelect={true}
                valueProps={formValues['industry_specialization']}
                parentHandler={(key, value) => {
                  inputChangedHandler('industry_specialization', [
                    ...value[key]
                  ]);
                }}
              />

              {role == 'admin' && (
                <>
                  <React.Fragment>
                    <div
                      className="ant-row ant-form-item"
                      style={{
                        border: '1px solid gray',
                        marginBottom: '0px',
                        padding: '7px',
                        backgroundColor: 'lightgray',
                        color: 'rgba(0, 0, 0, 0.85)',
                        fontSize: '14px'
                      }}
                    >
                      Structural Integrity:
                      <Select
                        placeholder="Select an option"
                        style={{
                          width: '100%',
                          borderBottom: '1px solid gray',
                          borderLeft: '1px solid gray',
                          marginBottom: '0px'
                        }}
                        onChange={value =>
                          inputChangedHandler('due_diligence', value)
                        }
                        defaultValue={formValues['due_diligence']}
                      >
                        {dueDiligenceOptions.map(item => {
                          let optionsItem = null;
                          for (const [key, value] of Object.entries(item)) {
                            optionsItem = (
                              <Option key={key} value={key}>
                                {value}
                              </Option>
                            );
                          }
                          return optionsItem;
                        })}
                      </Select>
                    </div>
                  </React.Fragment>

                  <Form.Item
                    key="si"
                    name="si"
                    label="Structural Integrity Doc"
                    hasFeedback
                    labelCol={{ span: 6 }}
                    style={{
                      borderBottom: '1px solid gray',
                      borderLeft: '1px solid gray',
                      borderRight: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray',
                      textAlign: 'left'
                    }}
                  >
                    {/* // TODO url vs upload */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <FileUpload
                        keyName="si"
                        styleObj={{ width: '101%' }}
                        label={formValues['si']?.url ? 'Re_upload' : ''}
                        parentHandler={(key, value) => {
                          inputChangedHandler(key, value ? value[key] : null);
                        }}
                      />
                      {formValues['si']?.['url'] ? (
                        <button
                          style={downloadStyle}
                          onClick={e =>
                            handleDownload(
                              e,
                              formValues?.['si']?.['url'],
                              formValues?.['si']?.['name']
                            )
                          }
                        >
                          <DownloadOutlined /> {formValues?.['si']?.['name']}
                        </button>
                      ) : null}
                    </div>
                  </Form.Item>
                </>
              )}

              {/* Agency Experience */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Agency Experience:
                </div>
              </React.Fragment>
              <Dropdowns
                keyName="agencies"
                label="agencies"
                multiSelect={true}
                valueProps={formValues['agencies']}
                parentHandler={(key, value) => {
                  inputChangedHandler(key, [...value[key]]);
                }}
              />

              {/* Average Annual Revenue */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Previous Years Revenue:
                </div>
              </React.Fragment>
              <Form.Item
                key="first_year_revenue"
                name="first_year_revenue"
                label="Last Year"
                style={{
                  border: '0.1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px'
                }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <RadioComponent
                  keyName="first_year_revenue"
                  valueProps={
                    formValues['revenue']?.['first_year_revenue']
                      ? formValues['revenue']?.['first_year_revenue']
                      : []
                  }
                  parentHandler={(key, value) => {
                    revenueHandler(key, value[key]);
                  }}
                />
              </Form.Item>
              <Form.Item
                key="second_year_revenue"
                name="second_year_revenue"
                label="2 Years Ago"
                style={{
                  border: '0.1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px'
                }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <RadioComponent
                  keyName="second_year_revenue"
                  valueProps={
                    formValues['revenue']?.['second_year_revenue']
                      ? formValues['revenue']['second_year_revenue']
                      : []
                  }
                  parentHandler={(key, value) => {
                    revenueHandler(key, value[key]);
                  }}
                />
              </Form.Item>
              <Form.Item
                key="third_year_revenue"
                name="third_year_revenue"
                label="3 Years Ago"
                style={{
                  border: '0.1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px'
                }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <RadioComponent
                  keyName="third_year_revenue"
                  valueProps={
                    formValues['revenue']?.['third_year_revenue']
                      ? formValues['revenue']?.['third_year_revenue']
                      : []
                  }
                  parentHandler={(key, value) => {
                    revenueHandler(key, value[key]);
                  }}
                />
              </Form.Item>
              <Space direction="horizontal" style={{ float: 'right' }}>
                {/* {!formValues['revenue'] ? ( */}
                <Button
                  type="warning"
                  style={{
                    float: 'right',
                    marginRight: '0px',
                    marginTop: '0px',
                    backgroundColor: '#82CF8F',
                    color: 'white'
                  }}
                  onClick={() => {
                    addField(
                      'revenue',
                      objectToFormData({
                        first_year_revenue:
                          formValues['revenue']?.['first_year_revenue'],
                        second_year_revenue:
                          formValues['revenue']?.['second_year_revenue'],
                        third_year_revenue:
                          formValues['revenue']?.['third_year_revenue']
                      })
                    )
                      .then(({ data }) => {
                        if (data.success) {
                          inputChangedHandler('revenue', data['data']['id']);
                          handleNotifications(
                            'success',
                            'success',
                            'Revenue Updated successfully.'
                          );
                        } else {
                          inputChangedHandler('revenue', null);
                          handleNotifications(
                            'success',
                            'success',
                            'Error Occured in Updating Revenue.'
                          );
                        }
                      })
                      .catch(error => {
                        console.log(error);
                      });
                  }}
                  disabled={
                    !(
                      formValues?.revenue?.['first_year_revenue'] &&
                      formValues?.revenue?.['second_year_revenue'] &&
                      formValues?.revenue?.['third_year_revenue']
                    )
                  }
                >
                  Save
                </Button>
                {/* ) : null} */}
              </Space>
              {/* </Form.Item> */}

              {/* Number of Employees */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    clear: 'both'
                  }}
                >
                  Number of Employees:
                </div>
              </React.Fragment>
              <RadioComponent
                keyName="no_of_employees"
                valueProps={formValues['no_of_employees']}
                parentHandler={(key, value) => {
                  inputChangedHandler('no_of_employees', value[key]);
                }}
              />

              {/* Certifications */}
              <Form.List name="certification">
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        required={false}
                        key={`${field.key}_${index}`}
                        label={
                          index === 0 ? (
                            <span>
                              Certifications{' '}
                              {false ? (
                                <CheckCircleOutlined
                                  style={{ color: 'green' }}
                                />
                              ) : (
                                <ExclamationCircleOutlined
                                  style={{ color: 'orange' }}
                                />
                              )}
                            </span>
                          ) : (
                            ''
                          )
                        }
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'certifications']}
                          key={`certification_name_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Dropdowns
                            keyName="certifications"
                            label="Certification"
                            multiSelect={false}
                            valueProps={
                              formValues['certification'][index]?.[
                                'certification'
                              ]
                            }
                            parentHandler={(key, value) => {
                              certificationsHandler(
                                'certification',
                                value[key],
                                index
                              );
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'certification_agency']}
                          key={`certification_agency_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Certification Agency"
                            onChange={e => {
                              certificationsHandler(
                                'certification_agency',
                                e.target.value,
                                index
                              );
                            }}
                          />
                        </Form.Item>
                        {/* <Form.Item
                          {...field}
                          name={[field.name, 'certification_no']}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                          key={`certification_no_${index}`}
                        >
                          <Input
                            placeholder="Certification Number"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            onChange={e => {
                              certificationsHandler(
                                'certification_no',
                                e.target.value,
                                index
                              );
                            }}
                          />
                        </Form.Item> */}
                        <Form.Item
                          {...field}
                          name={[field.name, 'expiration_date']}
                          key={`expiration_date_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Expiration Date (MM/DD/YYYY)"
                            onChange={e => {
                              certificationsHandler(
                                'expiration_date',
                                e.target.value,
                                index
                              );
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'certification_proof']}
                          key={`certification_proof_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          {/* // TODO url vs upload */}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <FileUpload
                              keyName="certification_proof"
                              label={
                                formValues?.['certification']?.[index]?.[
                                  'certification_proof'
                                ]?.['url']
                                  ? 'Re_upload'
                                  : ''
                              }
                              parentHandler={(key, value) => {
                                certificationsHandler(
                                  'certification_proof',
                                  value ? value[key] : null,
                                  index
                                );
                              }}
                            />
                            {formValues?.['certification']?.[index]?.[
                              'certification_proof'
                            ]?.['url'] ? (
                              <button
                                style={downloadStyle}
                                onClick={e =>
                                  handleDownload(
                                    e,
                                    formValues?.['certification']?.[index]?.[
                                      'certification_proof'
                                    ]?.['url'],
                                    formValues?.['certification']?.[index]?.[
                                      'certification_proof'
                                    ]?.['name']
                                  )
                                }
                              >
                                <DownloadOutlined />{' '}
                                {
                                  formValues?.['certification']?.[index]?.[
                                    'certification_proof'
                                  ]?.['name']
                                }
                              </button>
                            ) : null}
                          </div>
                        </Form.Item>

                        <Space direction="horizontal">
                          <span>
                            <Button
                              type="warning"
                              style={{
                                marginTop: '0px',
                                backgroundColor: '#D26A31',
                                color: 'white'
                              }}
                              onClick={() => {
                                formValues['certification'].splice(index, 1);
                                form.setFieldsValue({
                                  certification: formValues?.['certification']
                                });
                                // return remove(field.name);
                              }}
                            >
                              Remove
                            </Button>
                            {/* {!formValues?.['certification']?.length ? ( */}
                            <Button
                              type="warning"
                              style={{
                                float: 'right',
                                marginRight: '0px',
                                marginTop: '0px',
                                backgroundColor: '#82CF8F',
                                color: 'white'
                              }}
                              disabled={disableBtns['certification']}
                              onClick={() => {
                                let ind = index;
                                if (
                                  certifications[index]['certification_proof']
                                    ?.url
                                ) {
                                  return handleNotifications(
                                    'warning',
                                    'Warning',
                                    'Please Add New certification proof before saving.'
                                  );
                                }
                                setDisableBtns(prevBtns => ({
                                  ...prevBtns,
                                  certification: true
                                }));
                                if (certifications[index]?.id) {
                                  let id = certifications[index]?.id;
                                  updateField(
                                    'certification',
                                    certifications[index]?.id,
                                    objectToFormData(certifications[index])
                                  )
                                    .then(({ data }) => {
                                      if (data.success) {
                                        setFormValues(prev => ({
                                          ...prev,
                                          certification_proof_verified: 'False'
                                        }));
                                        microApiResponseHandler(
                                          'certification',
                                          id,
                                          ind
                                        );
                                        handleNotifications(
                                          'success',
                                          'success',
                                          'Certification Updated successfully.'
                                        );
                                      } else {
                                      }
                                    })
                                    .catch(error => {
                                      console.log(error);
                                      setDisableBtns(prevBtns => ({
                                        ...prevBtns,
                                        certification: false
                                      }));
                                    });
                                } else {
                                  addField(
                                    'certification',
                                    objectToFormData(certifications[index])
                                  )
                                    .then(({ data }) => {
                                      if (data.success) {
                                        microApiResponseHandler(
                                          'certification',
                                          data['data']['id'],
                                          ind
                                        );
                                        handleNotifications(
                                          'success',
                                          'success',
                                          'Certification Added successfully.'
                                        );
                                      } else {
                                        // microApiResponseHandler(
                                        //   'certification',
                                        //   null
                                        // );
                                      }
                                    })
                                    .catch(error => {
                                      console.log(error);
                                      setDisableBtns(prevBtns => ({
                                        ...prevBtns,
                                        certification: false
                                      }));
                                    });
                                }
                              }}
                            >
                              Save
                            </Button>
                          </span>
                          {/* ) : null} */}
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        float: 'left'
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Certification
                        </Button>
                      ) : null}
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                    {!formValues['certification_proof_verified'] &&
                    formValues['certification'].length ? (
                      <Form.Item
                        style={{
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          float: 'left'
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() => handleVerifyCertificate('True')}
                          icon={<CheckCircleOutlined />}
                        >
                          Verify Certification
                        </Button>
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>

              {/* Additional Information */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    fontWeight: 600,
                    clear: 'both'
                  }}
                >
                  Additional Information:
                </div>
              </React.Fragment>

              <Form.Item
                key="soq"
                name="soq"
                label="Statement of Qualification"
                hasFeedback
                labelCol={{ span: 6 }}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray',
                  textAlign: 'left'
                }}
              >
                {/* // TODO url vs upload */}
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FileUpload
                    keyName="soq"
                    styleObj={{ width: '101%' }}
                    label={formValues['soq']?.url ? 'Re_upload' : ''}
                    parentHandler={(key, value) => {
                      inputChangedHandler(key, value ? value[key] : null);
                    }}
                  />
                  {formValues['soq']?.['url'] ? (
                    <button
                      style={downloadStyle}
                      onClick={e =>
                        handleDownload(
                          e,
                          formValues?.['soq']?.['url'],
                          formValues?.['soq']?.['name']
                        )
                      }
                    >
                      <DownloadOutlined /> {formValues?.['soq']?.['name']}
                    </button>
                  ) : null}
                </div>
              </Form.Item>

              {/*  Company LinkedIn Profile*/}
              <Form.Item
                key="linkedin_profile"
                name="linkedin_profile"
                labelCol={{ span: 6 }}
                label="Company LinkedIn Profile"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '20px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <Input
                  name="linkedin_profile"
                  placeholder="LinkedIn Profile"
                  type="text"
                  // defaultValue={formValues['linkedin_profile']}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'linkedin_profile');
                  }}
                />
              </Form.Item>

              {/* Optional Information */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    fontWeight: 600
                  }}
                >
                  Optional Information:
                </div>
              </React.Fragment>

              {/* Owner Demographic */}
              <Form.Item
                key="owner_demographic"
                name="owner_demographic"
                label="Owner Demographic"
                labelCol={{ span: 6 }}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <Dropdowns
                  keyName="demographics"
                  label="Owner Demographic"
                  multiSelect={false}
                  valueProps={formValues['owner_demographic']}
                  parentHandler={(key, value) => {
                    inputChangedHandler('owner_demographic', value[key]);
                    if (value[key] === 'Other') {
                      setIsOtherDemographic(true);
                    } else {
                      setIsOtherDemographic(false);
                      setOtherDemographic('');
                    }
                  }}
                />
              </Form.Item>
              {isOtherDemographic ? (
                <Form.Item
                  key="other_demographic"
                  name="other_demographic"
                  label="Other Demographic"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ offset: 6, span: 18 }}
                >
                  <Input
                    name="other_demographic"
                    placeholder="Please enter demographic."
                    type="text"
                    value={otherDemographic}
                    onChange={e => {
                      e.persist();
                      debouncedInput(e, 'other_demographic');
                    }}
                  />
                </Form.Item>
              ) : null}

              <Form.Item
                key="liability_insurance"
                name="liability_insurance"
                label="Liability Insurance"
                labelCol={{ span: 6 }}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray',
                  textAlign: 'left'
                }}
              >
                {/* // TODO url vs upload */}
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FileUpload
                    keyName="liability_insurance"
                    label={
                      formValues['liability_insurance']?.url ? 'Re_upload' : ''
                    }
                    styleObj={{ marginLeft: '-7px', width: '101%' }}
                    parentHandler={(key, value) => {
                      inputChangedHandler(key, value ? value[key] : null);
                    }}
                  />
                  {formValues['liability_insurance']?.['url'] ? (
                    <button
                      style={downloadStyle}
                      onClick={e =>
                        handleDownload(
                          e,
                          formValues?.['liability_insurance']?.['url'],
                          formValues?.['liability_insurance']?.['name']
                        )
                      }
                    >
                      <DownloadOutlined />{' '}
                      {formValues?.['liability_insurance']?.['name']}
                    </button>
                  ) : null}
                </div>
              </Form.Item>
              <Form.Item
                key="compensation_insurance"
                name="compensation_insurance"
                label="Compensation Insurance"
                labelCol={{ span: 6 }}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray',
                  textAlign: 'left'
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FileUpload
                    keyName="compensation_insurance"
                    label={
                      formValues['compensation_insurance']?.url
                        ? 'Re_upload'
                        : ''
                    }
                    styleObj={{ marginLeft: '-7px', width: '101%' }}
                    parentHandler={(key, value) => {
                      inputChangedHandler(key, value ? value[key] : null);
                    }}
                  />
                  {formValues['compensation_insurance']?.['url'] ? (
                    <button
                      style={downloadStyle}
                      onClick={e =>
                        handleDownload(
                          e,
                          formValues?.['compensation_insurance']?.['url'],
                          formValues?.['compensation_insurance']?.['name']
                        )
                      }
                    >
                      <DownloadOutlined />{' '}
                      {formValues?.['compensation_insurance']?.['name']}
                    </button>
                  ) : null}
                </div>
              </Form.Item>
              <Form.Item
                key="disability_insurance"
                name="disability_insurance"
                label="Disablity Insurance"
                labelCol={{ span: 6 }}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray',
                  textAlign: 'left'
                }}
              >
                {/* // TODO url vs upload */}
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FileUpload
                    keyName="disability_insurance"
                    label={
                      formValues['disability_insurance']?.url ? 'Re_upload' : ''
                    }
                    styleObj={{ marginLeft: '-7px', width: '101%' }}
                    parentHandler={(key, value) => {
                      inputChangedHandler(key, value ? value[key] : null);
                    }}
                  />
                  {formValues['disability_insurance']?.['url'] ? (
                    <button
                      style={downloadStyle}
                      onClick={e =>
                        handleDownload(
                          e,
                          formValues?.['disability_insurance']?.['url'],
                          formValues?.['disability_insurance']?.['name']
                        )
                      }
                    >
                      <DownloadOutlined />{' '}
                      {formValues?.['disability_insurance']?.['name']}
                    </button>
                  ) : null}
                </div>
              </Form.Item>

              
              {/* Verification Step */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '30px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Verification Step:
                  <Select
                    placeholder="Select an option"
                    style={{
                      width: '100%',
                      borderBottom: '1px solid gray',
                      borderLeft: '1px solid gray',
                      marginBottom: '0px'
                    }}
                    onChange={value =>
                      inputChangedHandler('verification_step_id', value)
                    }
                    defaultValue={formValues['verification_step_id']}
                  >
                    <Option key="not-verified" value={0}>
                      Not Verified
                    </Option>
                    {companyVerificationSteps.map(item => {
                      let optionsItem = null;

                      optionsItem = (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );

                      return optionsItem;
                    })}
                  </Select>
                </div>
              </React.Fragment>

              {/* Profile Last Updated */}
              <strong>Profile Created : </strong>
              <Text>
                {moment
                  .utc(details['created_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text>
              <br />
              <strong>Profile Last Updated : </strong>
              <Text>
                {moment
                  .utc(details['updated_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text>

              {/* Action Buttons */}
              <Form.Item
                wrapperCol={{ md: { offset: 6, span: 12 }, sm: { span: 24 } }}
                style={{ textAlign: 'center' }}
              >
                <Space direction="horizontal" align="center">
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      onCancel();
                      history.push('/small-business');
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  businessId: state.auth?.user?.businessId,
  companyVerificationSteps: state.verificationSteps.verificationSteps
});

const mapDispatchToProps = dispatch => ({
  updateSmallBusiness: (smallBusinessId, payload) =>
    dispatch(onUpdateSmallBusiness(smallBusinessId, payload)),
  verifyCertificates: (smallBusinessId, payload) =>
    dispatch(verifyCertificatesAction(smallBusinessId, payload)),
  onCancel: () => dispatch(hideModal())
});

SmallBusinessEdit.propTypes = {
  role: string,
  businessId: number,
  selfMode: bool,
  updateSmallBusiness: func,
  onCancel: func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SmallBusinessEdit));
