import { notification } from 'antd';

notification.config({
  placement: 'bottomRight',
  duration: 3
});

export const handleNotifications = (type, message, description, duration) => {
  notification.config({
    duration: duration || 3
  });
  notification[type]({ message, description });
};
