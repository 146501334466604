import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { showVerificationStep } from '../../api/verificationSteps';
import { handleNotifications } from '../../utils/notifications';
// import ReactHtmlParser from 'react-html-parser';

const VerificationStepView = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState({});

  const { stepId } = useParams();

  useEffect(() => {
    if (!stepId) {
      return;
    }

    async function fetchStep(id) {
      let res = await showVerificationStep(id);
      if (res.data['success']) {
        setStep(res.data['data']);
        setLoading(false);
      } else {
        handleNotifications('error', 'Could not fetch', res.data.message);
      }
    }
    fetchStep(stepId);

    // eslint-disable-next-line
  }, [stepId]);

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <div>Name: {step.name}</div>
          <div>Description: {step.description}</div>
        </>
      )}
    </>
  );
};

export default connect(null, null)(VerificationStepView);
