import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

/**
 * @name stringToDateObject
 * @param {string} dateString - The date string that needs to be converted to dayjs object
 * @return {dayjs} dayjs object
 * @description Returns dayjs DateTimeObject from string
 */
export const stringToDateObject = dateString => dayjs(dateString);

/**
 * @name getUtcOffset
 * @return {number} minutes
 * @description Returns the offset from UTC in minutes
 */
export const getUtcOffset = () => dayjs().utcOffset();

/**
 * @name dateTimeUtcToLocal
 * @param {string} utcDateTime - time in utc
 * @return {dayjs} daysjs object
 * @description Converts dateTime string in utc to dayjs object in local time
 */
export const dateTimeUtcToLocal = utcDateTime => dayjs.utc(utcDateTime).local();

/**
 * @name getcurrentDateTime
 * @return {dayjs} dayjs object
 * @description Returns the current date-time in dayjs object form.
 */
export const getcurrentDateTime = () => dayjs();
