import { 
  SET_USER_AGREEMENTS,
  ADD_USER_AGREEMENT,
  FILTER_USER_AGREEMENTS,
  UPDATE_USER_AGREEMENTS,
  REFRESH_VIDEOS
 } from '../actionTypes';

const initialState = {
  agreements: [],
  refreshVideos: false
};

export const userAgreementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_AGREEMENTS:
      return { ...state, agreements: [...action.payload] };
    case ADD_USER_AGREEMENT:
      return { ...state, agreements: [...state.agreements, ...action.payload] };
    case UPDATE_USER_AGREEMENTS:
      let index = state.agreements.findIndex(e => e.id === action.payload.id );
      state.agreements[index] = action.payload;
      return { ...state, agreements: state.agreements };
    case FILTER_USER_AGREEMENTS:
        return { ...state, agreements: state.agreements.filter(c => c.id !== action.agreementId) };
    case REFRESH_VIDEOS:
      return { ...state, refreshVideos: action.payload };
    default:
      return { ...state };
  }
};