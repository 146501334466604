import React, { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { func, string } from 'prop-types';

import { getDropdownOptions } from '../../../../api/formOptions';
import { toHumanizedString } from '../../../../utils/utils';

const NestedMultiSelect = ({ keyName, label, nestedHandler }) => {
  const [treeData, setTreeData] = useState([]);
  const [currentValue, setCurrentValue] = useState(undefined);

  useEffect(() => {
    getDropdownOptions(keyName)
      .then(({ data: { data: options } }) => {
        setTreeData([...makeTreeData([...options[keyName]])]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [keyName]);

  const onChange = values => {
    const selectedValues = values.map(item => toHumanizedString(item));
    setCurrentValue(selectedValues);
    nestedHandler(keyName, selectedValues);
  };

  const makeTreeData = data => {
    const tree = [];

    for (const specialization of data) {
      let node = {};
      for (const [key, value] of Object.entries(specialization)) {
        if (Array.isArray(value)) {
          node['title'] = toHumanizedString(key);
          node['value'] = key;
          node['children'] = value.map(subItem => {
            const childNode = {};
            for (const [key, value] of Object.entries(subItem)) {
              childNode['title'] = value;
              childNode['value'] = key;
            }
            return childNode;
          });
        } else {
          node = { title: value, value: key };
        }
        tree.push(node);
      }
    }
    // console.log('Tree is -> ', tree);
    return tree;
  };

  return (
    <>
      <TreeSelect
        value={currentValue}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        placeholder={`Select ${label}`}
        multiple
        treeDefaultExpandAll
        onChange={onChange}
      />
    </>
  );
};

NestedMultiSelect.propTypes = {
  keyName: string,
  label: string,
  nestedHandler: func
};

export default NestedMultiSelect;
