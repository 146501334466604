import {
  addLookAhead,
  deleteLookAhead,
  importLookAhead,
  updateLookAhead
} from '../../api/lookAhead';
import { handleNotifications } from '../../utils/notifications';
import { LOOK_AHEAD_REFRESH, DELETE_LOOK_AHEAD_SUCCESS, IMPORT_LOOK_AHEAD_SUCCESS } from '../actionTypes';
import { redirect } from './redirect';
import { hideLoading, hideModal, showLoading } from './ui';

export const setLookAheadRefresh = payload => ({
  type: LOOK_AHEAD_REFRESH,
  payload
});

export const deleteLookHeadSuccess = () => ({
  type: DELETE_LOOK_AHEAD_SUCCESS
});

export const successImport = () => ({
  type: IMPORT_LOOK_AHEAD_SUCCESS
});


export const onAddLookAhead = payload => dispatch => {
  dispatch(showLoading());
  addLookAhead(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/look-ahead'));
        dispatch(setLookAheadRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateLookAhead = (lookAheadId, payload) => dispatch => {
  dispatch(showLoading());
  updateLookAhead(lookAheadId, payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        handleNotifications('success', 'Success', data.message);
        dispatch(redirect('/look-ahead'));
        dispatch(setLookAheadRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteLookAhead = lookAheadId => dispatch => {
  dispatch(showLoading());
  deleteLookAhead(lookAheadId)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        handleNotifications('success', 'Success', data.message);
        dispatch(redirect('/look-ahead'));
        dispatch(setLookAheadRefresh(true));
        dispatch(deleteLookHeadSuccess());
      } else {
        dispatch(hideLoading());
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onImportLookAhead = importPayload => dispatch => {
  dispatch(showLoading());
  importLookAhead(importPayload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/look-ahead'));
        dispatch(setLookAheadRefresh(true));
        dispatch(successImport());
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};
