import {
  createVideo,
  updateVideo,
  deleteVideo
} from '../../api/videos';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, hideModal, showLoading } from './ui';
import {
  SET_USER_AGREEMENTS,
  UPDATE_USER_AGREEMENTS,
  REFRESH_VIDEOS
} from '../actionTypes';

export const setUserAgreements = payload => dispatch => {
  dispatch({ type: SET_USER_AGREEMENTS, payload });
};

const updateVideos = payload => dispatch => {
  dispatch({ type: UPDATE_USER_AGREEMENTS, payload });
};

export const refreshVideos = (payload) => dispatch => {
  dispatch({ type: REFRESH_VIDEOS, payload})
}

export const onCreateVideo = (videoPayload, cb) => dispatch => {
  dispatch(showLoading);
  createVideo(videoPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(hideModal());
        dispatch(refreshVideos(true));
        handleNotifications('success', 'Success', data.message);
        cb && cb();
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateVideo = (id, videoPayload) => dispatch => {
  dispatch(showLoading);
  updateVideo(id, videoPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(updateVideos([data.data]));
        dispatch(hideModal());
        dispatch(refreshVideos(true));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteVideo = id => dispatch => {
  dispatch(showLoading);
  deleteVideo(id)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(refreshVideos(true));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};
