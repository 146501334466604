import {
  PRIMES_REFRESH,
  FETCH_PRIMES,
  DELETE_PRIMES_SUCCESS
} from '../actionTypes';

const initialState = {
  refresh: true,
  deleteSuccess: false,
  fetchPrimes: false
};

export const primesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRIMES_REFRESH:
      return { ...state, refresh: action.payload };
    case FETCH_PRIMES:
      return { ...state, fetchPrimes: action.payload };
    case DELETE_PRIMES_SUCCESS:
      return { ...state, deleteSuccess: true };
    default:
      return { ...state };
  }
};
