import { combineReducers } from 'redux';

import { authReducer } from './auth';
import { dashboardReducer } from './dashboard';
import { calendarReducer } from './calendar';
import { commentsReducer } from './comments';
import { lookAheadReducer } from './lookAhead';
import { primesReducer } from './primes';
import { associationReducer } from './association';
import { redirectReducer } from './redirect';
import { rfpRfqReducer } from './rfpRfq';
import { searchReducer } from './search';
import { smallBusinessReducer } from './smallBusiness';
import { messageCenterReducer } from './messageCenter';
import { uiReducer } from './ui';
import { contactReducer } from './contact';
import { userAgreementReducer } from './userAgreement';
import { verificationStepsReducer } from './verificationSteps';

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  comments: commentsReducer,
  calendar: calendarReducer,
  lookAhead: lookAheadReducer,
  primes: primesReducer,
  association: associationReducer,
  redirect: redirectReducer,
  rfpRfq: rfpRfqReducer,
  search: searchReducer,
  smallBusiness: smallBusinessReducer,
  ui: uiReducer,
  messageCenter: messageCenterReducer,
  contact: contactReducer,
  userAgreement: userAgreementReducer,
  verificationSteps: verificationStepsReducer
});

export default rootReducer;
