import connection from '../utils/api';

export const getUsers = params =>
  connection.get(`users?${params}`);

export const getUser = id =>
  connection.get(`users/${id}`)

export const getAdditionalUsers = params =>
  connection.get(`get_additional_user?${params}`);

export const AddUser = payload =>
  connection.post(`users`, payload);

export const EditUser = (payload, id) =>
  connection.put(`users/${id}`, payload);
