import React from 'react';
import { Drawer, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../../redux/actions/ui';
import brandLogo from '../../../assets/images/brand-logo-light-no-tagline.png';

const MobileSidebar = (props) => {
    const visible = useSelector(({ui})=>ui.showSidebar);
    const dispatch = useDispatch();

    const hide = () => {
        dispatch(closeDrawer(false))
    }
    
    return (
        <div>
            <Drawer
                title={
                    <div className="sidebar-header">
                        <Image
                            src={brandLogo}
                            alt="BZ Connects Logo"
                            preview={false}
                            width="170px"
                        />
                    </div>
                }
                placement={'left'}
                closable={false}
                visible={visible}
                width="200px"
                onClose={hide}
            >
                {props.children}
            </Drawer>
        </div>
    )
}

export default MobileSidebar;
