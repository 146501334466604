import connection from '../../utils/api';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, showLoading } from './ui';

export const addAnnouncement = (payload, cb) => dispatch => {
  dispatch(showLoading());
  return connection.post('/announcements', payload)
    .then(({ data }) => {
      if(data.success){
        handleNotifications('success', "Success", data.message);
        cb && cb();
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      handleNotifications('error', 'Failed', error.message);
    });
};

export const getAnnouncement = () => dispatch => {
  dispatch(showLoading());
  return connection.get('/announcements')
    .then(({ data }) => {
      dispatch(hideLoading());
      if(data.success){
        // handleNotifications('success', "Success", data.message);
        return data.data
      } else {
        handleNotifications('error', 'Failed', data.message);
        return [];
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
    });
};

export const updateAnnouncement = (id, payload, cb) => dispatch => {
  dispatch(showLoading());
  connection.put(`/announcements/${id}`, payload)
    .then(({ data }) => {
      if(data.success){
        handleNotifications('success', "Success", data.message);
        cb && cb();
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
      dispatch(hideLoading());
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
    });
};

export const deleteAnnouncement = (id) => dispatch => {
  return connection.delete(`/announcements/${id}`)
    .then(({ data }) => {
      if(data.success){
        handleNotifications('success', "Success", data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      handleNotifications('error', 'Failed', error.message);
    });
};
