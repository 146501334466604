/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes, { array, bool, func, object, string } from 'prop-types';
import { Table } from 'antd';

const TableComponent = ({
  tableData,
  columns,
  size,
  pagination,
  manageDataSource,
  onRow,
  className
}) => (
  <Table
    columns={columns}
    dataSource={tableData && tableData.length ? tableData : []}
    pagination={typeof pagination === "object" ? { ...pagination } : false}
    onChange={manageDataSource}
    size={size || "middle"}
    /* scroll-x should be proportional to search sidebar's width and sum to 100vw */
    scroll={{ x: '80%' }}
    onRow={onRow}
    className={`table-striped-rows ${className}`}
  />
);

TableComponent.propTypes = {
  columns: array,
  pagination: PropTypes.oneOfType([
    object,
    bool
  ]),
  tableData: array,
  className: string,
  manageDataSource: func,
  onRow: func
};

export default TableComponent;
