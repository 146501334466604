import React, { useEffect, useState } from 'react';
import { array, bool, func, string } from 'prop-types';
import { Radio, Col, Row } from 'antd';

import { getDropdownOptions } from '../../../../api/formOptions';

const RadioComponent = ({ valueProps, keyName, parentHandler, disabled }) => {
  const [options, setOptions] = useState(null);
  const innerWidth = window.innerWidth;
  
  useEffect(() => {
    getDropdownOptions(keyName)
      .then(({ data: { data: options } }) => {
        const optionsArray = options[keyName];
        let optionsList = [];
        optionsList = optionsArray.map(option => {
          let node = null;
          for (const value of Object.values(option)) {
            node = { label: value, value };
          }
          return node;
        });
        setOptions([...optionsList]);
      })
      .catch(error => {
        console.log(error);
      });
  }, [keyName]);

  const onCheckedValues = e => {
    parentHandler(keyName, { [keyName]: e.target.value });
  };
  return (
    <>
      {options ? (
        <Radio.Group
          style={{ border: '1px solid gray', width: '100%', textAlign: 'left' }}
          onChange={onCheckedValues}
          disabled={disabled}
          defaultValue={
            valueProps
              ? options
                  .filter(item => valueProps === item['label'])
                  .map(item => item['value'])?.[0]
              : null
          }
        >
          <Row>
            {options.map((option) => (
              <Col
                span={innerWidth > 556 ? 6 : 12}
                key={option.value}
                style={{
                  borderBottom: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  padding: '10px',
                }}
              >
                <Radio value={option.value}>{option.label}</Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      ) : null}
    </>
  );
};

RadioComponent.propTypes = {
  keyName: string,
  parentHandler: func,
  valueProps: array,
  disabled: bool
};

export default RadioComponent;
