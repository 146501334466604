import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';
import { objectToFormData } from '../../utils/utils';
import { handleNotifications } from '../../utils/notifications';
import { onCreateStep } from '../../redux/actions/verificationSteps';

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 24
  }
};

const VerificationStepCreate = ({ onCreate }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();

  const onFinish = values => {
    // setLoading(true);
    onCreate(objectToFormData(values));
    // form.resetFields();
    setFormValues({});
    // setLoading(false);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        form={form}
        {...layout}
        initialValues={formValues}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item key="name" name="name" hasFeedback>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item key="description" name="description" hasFeedback>
          <TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onCreate: payload => dispatch(onCreateStep(payload))
});

VerificationStepCreate.propTypes = { onCreate: func };

export default connect(null, mapDispatchToProps)(VerificationStepCreate);
