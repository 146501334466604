import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input, Button, Popconfirm, message, Comment, Avatar, Typography } from 'antd';
import { SendOutlined } from '@ant-design/icons';
// import moment from 'moment';
import { UserOutlined, DeleteOutlined  } from '@ant-design/icons';

import dayjs from 'dayjs';
import moment from 'moment';

import { findMessageCenter, createAndSend, sendMessage, deleteMessage, getConversation } from '../../api/messageCenter';
import { handleNotifications } from '../../utils/notifications';
import { objectToFormData } from '../../utils/utils';
import { getUser } from '../../utils/checkLogin';
import InfiniteScroll from 'react-infinite-scroll-component';

const { TextArea } = Input;
const { Title } = Typography;

const SuperChat = ({ config, onAdd }) => {
  const [loading, setLoading] = useState(true);
  // const chatContainer = useRef(null);

  const [me, setMe] = useState({});
  const [typedMessage, setTypedMessage] = useState("");
  const [center, setCenter] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const { companyId, companyType } = useParams();

  useEffect(() => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    setMe(getUser());

    findMessageCenter(companyId, companyType)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          setCenter(data['data']['center'][0])
          setMessageList(data['data']['messages']['data'])
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }, [companyId, companyType]);

  const handleChange = e => setTypedMessage(e.target.value);

  function deleteConfirm(message_id) {
    setLoading(true);
    deleteMessage(message_id)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          message.success(data.message);
          setMessageList(messageList.filter(e => e.id !== message_id));
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }
  
  function deleteCancel(e) {
    console.log(e);
    message.error('Click on No');
  }
  
  const send = () => {
    let payload = {
      'company_id': companyId,
      'company_type': companyType,
      'sender_id': me.user_id,
      'sender_name': me.name,
      'center_id': center.id ? center.id : null,
      'msg_text': typedMessage,
      'msg_file': null
    };
    if(center.id) {
      setLoading(true);
      sendMessage(objectToFormData(payload))
        .then(({ data }) => {
          setLoading(false);
          if (data.success) {
            // Append new message to chat history
            setMessageList([data['data'], ...messageList ]);
          } else {
            handleNotifications('error', 'Could not fetch', data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setLoading(true);
      createAndSend(objectToFormData(payload))
        .then(({ data }) => {
          setLoading(false);
          if (data.success) {
            // Set center and messageList
            setCenter(data['data']['center']);
            setMessageList(data['data']['messages']['data']);
          } else {
            handleNotifications('error', 'Could not fetch', data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }

    // Empty the chat form
    setTypedMessage("");
  }

  // useEffect(() => {
  //   // For chat scroll
  //   if (chatContainer) {
  //     chatContainer.current.addEventListener('DOMNodeInserted', event => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  //     });
  //   }

  //   // 
  // }, [])

  const fetchData = () => {
    getConversation(center.id, currentPage+1)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          // Append new message to chat history
          setMessageList([...messageList, ...data['data']]);
          setCurrentPage(data['pagination']['page'])
          if(messageList.length > data['pagination']['total']) {
            setHasMore(false);
          }
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }
 
  return (
    <>
    <Title level={3}>{center.title}</Title>
    <div
      id="scrollableDiv"
      style={{
        height: 300,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    >
      {/*Put the scroll bar always on the bottom*/}
      <InfiniteScroll
        dataLength={messageList.length}
        next={fetchData}
        style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
        inverse={true}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        { messageList.map((m, i) =>
          <Comment
            key={m.id}
            author={<span>{m.sender_name} | {moment.utc(m.created_on).utcOffset((dayjs().utcOffset())).format('MM-DD-YYYY hh:mm A')}</span>}
            avatar={
              <Avatar shape="square" icon={<UserOutlined />} />
            }
            content={
              <p>{m.msg_text}</p>
            }
            datetime={
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => deleteConfirm(m.id)}
                onCancel={deleteCancel}
                okText="Yes"
                cancelText="No"
              >
                <span>
                  { m.sender_id === me.user_id ? <DeleteOutlined style={{ color: 'crimson' }} /> : null }
                </span>
              </Popconfirm>
            }
          />
        )}
      </InfiniteScroll>
    </div>
    <div 
      className="footer"
      style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <TextArea
        style={{ width: '90%', marginTop: '20px' }}
        value={typedMessage}
        onChange={handleChange} 
        showCount 
        maxLength={500} 
        allowClear 
      />

      <Button 
        type="primary" 
        icon={<SendOutlined />} 
        size='large' 
        onClick={send}
        disabled={loading}
      >
        Send
      </Button>
    </div>

    </>
  );
};

export default connect(null, null)(SuperChat);