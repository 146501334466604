import {
  createUserAgreement,
  updateUserAgreement,
  deleteUserAgreement
} from '../../api/userAgreement';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, hideModal, showLoading } from './ui';
import { redirect } from './redirect';
import {
  SET_USER_AGREEMENTS,
  ADD_USER_AGREEMENT,
  FILTER_USER_AGREEMENTS,
  UPDATE_USER_AGREEMENTS
} from '../actionTypes';

export const setUserAgreements = payload => dispatch => {
  dispatch({ type: SET_USER_AGREEMENTS, payload });
};

const addUserAgreement = payload => dispatch => {
  dispatch({ type: ADD_USER_AGREEMENT, payload });
};

const updateUserAgreements = payload => dispatch => {
  dispatch({ type: UPDATE_USER_AGREEMENTS, payload });
};

export const onCreateAgreement = agreementPayload => dispatch => {
  dispatch(showLoading);
  createUserAgreement(agreementPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(addUserAgreement([data.data]));
        dispatch(hideModal());
        dispatch(redirect('/user-agreement'));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateAgreement = (agreementId, agreementPayload) => dispatch => {
  dispatch(showLoading);
  updateUserAgreement(agreementId, agreementPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(updateUserAgreements([data.data]));
        dispatch(hideModal());
        dispatch(redirect('/user-agreement'));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteAgreement = agreementId => dispatch => {
  dispatch(showLoading);
  deleteUserAgreement(agreementId)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch({ type: FILTER_USER_AGREEMENTS, agreementId });
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};
