import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool, func, node, object, string } from 'prop-types';
import { Modal } from 'antd';

import { hideModal, showModal } from '../../redux/actions/ui';

const WIDTH = {
  'sm': 500,
  'md': 800,
  'lg': 1000
}

const ModalComponent = ({
  history,
  title,
  showModal,
  onHideModal,
  onCloseModalSuccess,
  children,
  goBack,
  size
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(showModal);
  }, [showModal]);

  return (
    <Modal
      title={title ? title : ''}
      centered
      visible={visible}
      onOk={() => {
        goBack && history.goBack();
        setVisible(false);
        onHideModal();
        onCloseModalSuccess && onCloseModalSuccess();
      }}
      onCancel={() => {
        goBack && history.goBack();
        setVisible(false);
        onHideModal();
        onCloseModalSuccess && onCloseModalSuccess();
      }}
      style={size ? { width: WIDTH[size]} : { minWidth: '75vw', maxWidth: '90vw' }}
      footer={null}
    >
      {children}
    </Modal>
  );
};

const mapStateToProps = state => ({
  showModal: state.ui.showModal,
  title: state.ui.modalTitle ? state.ui.modalTitle : ''
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onHideModal: () => dispatch(hideModal())
});

ModalComponent.propTypes = {
  match: object,
  history: object,
  title: string,
  showModal: bool,
  hideModal: bool,
  onShowModal: func,
  onHideModal: func,
  children: node,
  onCloseModalSuccess: func,
  size: string
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalComponent)
);
