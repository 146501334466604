import { createMessageCenter, updateMessageCenter, deleteMessageCenter } from '../../api/messageCenter';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, hideModal, showLoading } from './ui';
import { redirect } from './redirect';
import { SET_MESSAGE_CENTERS, ADD_MESSAGE_CENTER, FILTER_MESSAGE_CENTERS, UPDATE_MESSAGE_CENTERS } from '../actionTypes';

export const setMessageCenters = payload => dispatch => {
  dispatch({type: SET_MESSAGE_CENTERS, payload});
}

const addMessageCenter = payload => dispatch => {
  dispatch({type: ADD_MESSAGE_CENTER, payload});
}

const updateMessageCenters = payload => dispatch => {
  dispatch({type: UPDATE_MESSAGE_CENTERS, payload});
}

export const onCreateCenter = centerPayload => dispatch => {
  dispatch(showLoading);
  createMessageCenter(centerPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(addMessageCenter([data.data]));
        dispatch(hideModal());
        dispatch(redirect('/message-center'));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateCenter = (centerId, centerPayload) => dispatch => {
  dispatch(showLoading);
  updateMessageCenter(centerId, centerPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(updateMessageCenters(data.data));
        dispatch(hideModal());
        dispatch(redirect('/message-center'));
        handleNotifications('success', 'Success', data.message);
        // dispatch(setCalendarRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteCenter = centerId => dispatch => {
  dispatch(showLoading);
  deleteMessageCenter(centerId)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch({type: FILTER_MESSAGE_CENTERS, centerId});
        handleNotifications('success', 'Success', data.message);
        // dispatch(hideModal());
        // dispatch(redirect('/message-center'));
        // dispatch(setCalendarRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};