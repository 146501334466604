import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Button, Form, Input, Image } from 'antd';
import { useSelector } from 'react-redux';
import brandLogo from '../../assets/images/brand-logo-light-no-tagline1.png';
import { EditFilled } from '@ant-design/icons';
import ImageUpload from '../../components/common/Form/FormInputs/ImageUpload';

const Profile = () => {
  const [formValues, setFormValues] = useState({ logo: null });
  const userDetails = useSelector(state => state.auth && state.auth.user);

  useEffect(() => {
    if (userDetails && userDetails.email) {
      setFormValues({ ...userDetails, firstName: userDetails.name });
    }
    // eslint-disable-next-line
  }, [userDetails && userDetails.email]);

  const handleChange = debounce(() => {});

  const onFinish = values => {
    console.log('Success:', values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const editImage = () => {
    setFormValues(prev => ({ ...prev, logo: null }));
  };

  return (
    <div className="profile-container">
      <div style={{ textAlign: 'right' }}>
        {formValues['logo'] && formValues['logo']?.['url'] ? (
          <React.Fragment>
            <Image
              src={brandLogo}
              alt="alt-img"
              preview={false}
              width="100px"
              height="100px"
            />
            <EditFilled onClick={editImage} />
          </React.Fragment>
        ) : (
          <ImageUpload
            keyName="logo"
            label="Company Logo"
            max={1}
            nestedHandler={(key, value) => {}}
          />
        )}
      </div>
      {formValues.email && (
        <Form
          name="profile"
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          initialValues={{
            ...formValues
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="FirstName"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please input your firstname!'
              }
            ]}
          >
            <Input onChange={handleChange} />
          </Form.Item>

          <Form.Item
            label="LastName"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input your lastname!'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: 'Please select your role'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default Profile;
