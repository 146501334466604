import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Image,
  Input,
  Row,
  Space,
  Typography
} from 'antd';

import { getAssociationDetails } from '../../api/associations';
import { handleNotifications } from '../../utils/notifications';
import dayjs from 'dayjs';
import brandLogo from '../../assets/images/brand-logo-light-no-tagline1.png';
import { getcurrentDateTime } from '../../utils/dateTimeHelper';
import { PrinterFilled } from '@ant-design/icons';
import moment from 'moment';

const { Title } = Typography;
const { TextArea } = Input;

const styles = {
  title: {
    padding: '5px',
    border: '0.1px solid gray',
    // height: '35px'
  },
  headerStyle: {
    border: '1px solid gray',
    marginBottom: '0px',
    padding: '7px',
    backgroundColor: '#E8E8E8',
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px'
  },
  company_name: {marginTop: 0, fontWeight: 500}
}

const PrimesPrintView = () => {
  const [loading, setLoading] = useState(false);

  const role = useSelector(({ auth }) => auth.role);
  const { associationId } = useParams();

  useEffect(() => {
    if (associationId) {
      setLoading(true);
      getAssociationDetails(associationId)
        .then(({ data }) => {
          if (data['success']) {
            getFormValuesFromDetails(data['data']);
          } else {
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associationId]);

  const [formValues, setFormValues] = useState({
    logo: null,
    name: null,
    phone: null,
    association_contact: [],
    association_event: [],
    description: null
  });

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      name,
      description,
      association_contact,
      association_event,
      logo_url,
      phone,
      logo_name,
      updated_on,
      website,
    } = details;

    if (logo_url) {
      newFormValues['logo'] = {
        url: logo_url,
        name: logo_name
      };
    }
    if (website) {
      newFormValues['website'] = website;
    }
    if (association_contact?.length) {
      newFormValues['association_contact'] = association_contact.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }
    if (association_event?.length) {
      newFormValues['association_event'] = association_event.map(item => ({
        id: item['id'],
        name: item['name'],
        date: moment(item['date']).format('MM/DD/YYYY'),
        description: item['description']
      }));
    }
    if (updated_on) {
      newFormValues['profile_last_updated'] = dayjs(updated_on).format(
        'MM/DD/YYYY'
      );
    }
    setFormValues({
      ...newFormValues,
      description,
      phone,
      name
    });
  };

  return (
    <Card loading={loading}>
      {formValues ? (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Title level={3} style={{ color: '#4A71A1', fontWeight: 'bold' }}>
                  BZ One Assocation Profile
                </Title>
                <Title level={2} style={styles.company_name}>
                  {formValues.companyName}
                </Title>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Space direction="vertical">
                  <Button
                    type="primary"
                    htmlType="button"
                    size="small"
                    icon={<PrinterFilled />}
                    // onClick={() => window.print()}
                  >
                    Print
                  </Button>
                  <Image
                    src={
                      formValues['logo']?.['url']
                        ? formValues['logo']['url']
                        : ''
                    }
                    preview={false}
                    width="100px"
                    height="100px"
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Business Phone</Title>
              </Col>
              <Col span={6} style={styles.title}>
                {role === 'small-business' ? '**********' : (formValues['phone'] || '')}
              </Col>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Website</Title>
              </Col>
              <Col span={6} style={styles.title}>
                {formValues['website'] || ''}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="ant-row" style={styles.headerStyle}>
                  Contact:
                </div>
                <div>
                  {formValues?.['association_contact']?.map((item, i) => (
                    <Row key={i}>
                      <Col span={6} style={styles.headerStyle}>
                        Contact Name:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {item.name}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Title:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {item.title}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Phone:
                      </Col>
                      <Col span={6} style={styles.title}>
                        {item.phone}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Email:
                      </Col>
                      <Col span={6} style={styles.title}>
                        {item.email}
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>
            
            <Row>
              <Col span={24}>
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Brief Company Profile: (max 300 words)
                </div>
                <TextArea
                  rows={3}
                  disabled
                  defaultValue={formValues.description}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div className="ant-row" style={styles.headerStyle}>
                  Assocation Event:
                </div>
                <div>
                  {formValues?.['association_event']?.map((item, i) => (
                    <Row key={i}>
                      <Col span={6} style={styles.headerStyle}>
                        Name:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {item.name}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Date:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {item.date}
                      </Col>
                      <Col span={6} style={styles.headerStyle}>
                        Description:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {item.description}
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12} style={{marginTop: 10}}>
                Printed On:{' '}
                {dayjs(getcurrentDateTime()).format('MM/DD/YYYY hh:mm A').toString()}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Image
                  src={brandLogo}
                  preview={false}
                  width="150px"
                  height="70px"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

export default PrimesPrintView;
