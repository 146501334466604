import React, { /* useEffect, */ useState } from 'react';
import { func, number, object, string } from 'prop-types';
import { Upload } from 'antd';
// import ImgCrop from 'antd-img-crop';

import { fileFormats } from '../../../../utils/constants';
import { objectToFormData } from '../../../../utils/utils';

const ImageUpload = ({
  keyName,
  label,
  max,
  value,
  disabled,
  microApiHandler,
  nestedHandler
}) => {
  const [fileList, setFileList] = useState([]);
  const [imageIds, setImageIds] = useState([]);

  // file, fileList
  const onChange = ({ fileList: newFileList }) => {
    setFileList([...newFileList]);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  // file, filename, onProgress(), onSuccess()
  const dummyRequest = ({ file, onSuccess }) => {
    if (!microApiHandler) {
      nestedHandler(keyName, { [keyName]: file });
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    } else {
      const payload = { file: file };
      microApiHandler
        .add(keyName, objectToFormData(payload))
        .then(({ data }) => {
          if (data.success) {
            setImageIds([...imageIds, data.data['id']]);
            nestedHandler(keyName, [...imageIds, data.data['id']]);
            onSuccess('ok');
          } else {
            // onSuccess('error');
            console.log(data.error);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    // <ImgCrop rotate>
    <Upload
      accept={fileFormats[keyName]}
      listType="picture-card"
      fileList={fileList}
      disabled={disabled}
      onChange={onChange}
      onPreview={onPreview}
      customRequest={dummyRequest}
      maxCount={max}
      onRemove={temp => {
        // console.log('temp', temp);
      }}
    >
      {fileList.length < max
        ? label
          ? `+ Upload ${label}`
          : '+ Upload'
        : null}
    </Upload>
    // </ImgCrop>
  );
};

ImageUpload.propTypes = {
  keyName: string,
  label: string,
  max: number,
  value: string,
  microApiHandler: object,
  nestedHandler: func
};

export default ImageUpload;
