import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import industryNewsLogo from '../../assets/images/Industry_news.png';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { getNews } from '../../redux/actions/generalIndustryNews';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import ModalComponent from '../../components/ui/ModalComponent';
import { showModal } from '../../redux/actions/ui';

const IndustryNews = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [view, setView] = useState({});

  const getData = async() => {
    const data = await dispatch(getNews())
    setList(data);
  }
  useEffect(()=>{
    getData();
//    eslint-disable-next-line
  },[])

  const handleView = (item) => {
    dispatch(showModal());
    setShow(true);
    setView(item);
  }

  const hideModal = () => {
    setShow(false);
  }

  return (
    <div className="float-chat">
      {show && <ModalComponent onCloseModalSuccess={hideModal}>
        <div>
          <h4><strong>{view.title}</strong></h4>
          <h4>{view.description}</h4>
          <h4><a target="_blank" rel="noopener noreferrer" href={view.source}>Link</a></h4>
        </div>
      </ModalComponent>}
      <div className="announcement-container">
        <div className="heading-block">
          <img src={industryNewsLogo} alt="icon" width="30" height="30" />
          <h3>Industry News</h3>
        
        </div>
        <div className="msgs-container custom-scroll">
          {list?.slice(0,3).map((item, i)=>(
            <div key={i}>
              <div className="msg">
                <div>
                  <strong>{dayjs(item.aired_on || item.created_on).format('MM/DD/YYYY')}</strong>{' - '}
                  <span className="title">{item.title}</span>
                  <br />
                  <span className="para">{item.description}</span>

              <br />
              <span className="para"><a target="_blank" rel="noopener noreferrer" href={item.source}>Link </a></span>
                </div>
                <button onClick={() => handleView(item)}>View</button>
              </div>
              <hr />
            </div>
          ))}
          <Tooltip title="View More">
            <Button size="small" style={{float: 'right'}}>
              <Link to="/industry-news"><DoubleRightOutlined style={{transform: 'rotate(90deg)'}}/></Link>
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default IndustryNews;
