/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import dayjs from 'dayjs';
import { Card, Typography } from 'antd';

import { getEvent } from '../../api/rfpRfq';
import { hideLoading, showLoading } from '../../redux/actions/ui';
import { rfpRfqTypes } from '../../utils/constants';
import { dateTimeUtcToLocal } from '../../utils/dateTimeHelper';
import { handleNotifications } from '../../utils/notifications';
import moment from 'moment';

const { Link: TextLink } = Typography;

const View = ({ match, onShowLoading, onHideLoading }) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!match.params) {
      return;
    } else {
      setLoading(true);
      onShowLoading();
      getEvent(match.params['eventId'])
        .then(({ data }) => {
          setLoading(false);
          onHideLoading();
          if (data.success) {
            setEventDetails(data.data);
          }
        })
        .catch(error => {
          setLoading(false);
          onHideLoading();
          handleNotifications('error', 'Failed', error.message);
        });
    }
  }, []);

  return (
    <>
      <h1 className="heading-title">RFP/RFQ/RFI/IFB Details</h1>
      <Card loading={loading}>
        {eventDetails ? (
          <>
            <h3>
              Number: <p>{eventDetails.number}</p>
            </h3>

            <h3>Issue Date</h3>
            <p>
              {dayjs(eventDetails.issue_date, 'YYYY-MM-DD').format(
                'MMMM DD, YYYY'
              )}
            </p>

            <h3>Agency</h3>
            <p>{eventDetails.agency}</p>

            <h3>County</h3>
            <p>{eventDetails.county}</p>

            <h3>Scope</h3>
            <p>{eventDetails.scope}</p>

            <h3>Due Date</h3>
            <p>
              {dateTimeUtcToLocal(eventDetails.due_date_time).format(
                'MMMM DD, YYYY'
              )}
            </p>

            <h3>Due Time</h3>
            <p>
              {dateTimeUtcToLocal(eventDetails.due_date_time).format('hh:mma')}
            </p>

            <h3>Site Link</h3>
              <TextLink href={`${eventDetails.site_link}`} target="_blank"> {eventDetails.site_link} </TextLink>
            <h3>Type</h3>
            <p>{rfpRfqTypes[eventDetails.type_rfp_rfq]}</p>

            <h3>Address</h3>
            <p>{eventDetails.street}</p>

            <h3>Description</h3>
            <p>{eventDetails.description}</p>

            {eventDetails?.created_on && (<><h3>Created On</h3>
            <p>{moment.utc(eventDetails?.created_on).utcOffset((dayjs().utcOffset())).format('MM/DD/YYYY hh:mm A')}</p></>)}

            {eventDetails?.updated_on && (<><h3>Updated On</h3>
            <p>{moment.utc(eventDetails?.updated_on).utcOffset((dayjs().utcOffset())).format('MM/DD/YYYY hh:mm A')}</p></>)}
          </>
        ) : null}
      </Card>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading())
});

View.propTypes = { match: object, onShowLoading: func, onHideLoading: func };

export default connect(null, mapDispatchToProps)(View);
