export { debounce } from 'lodash';

export const toHumanizedString = inputString => {
  if (typeof inputString !== 'string') {
    return;
  }
  return inputString
    .replaceAll('_', ' ')
    .split(' ')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const objectToFormData = payload => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key]);
  });
  return formData;
};

export const shortenText = text =>
  text && text.length > 30 ? ''.substring(0, 30).concat('...') : text;

export const getCurrentPageFromLocation = pathname => {
  const pathArray = pathname.split('/');
  pathArray.shift();
  return pathArray[0];
};

export const isEmptyObj = obj => obj && Object.keys(obj).length === 0 && obj.constructor === Object

export const HeaderRenderer = (columns, width) => width > 556 ? columns.filter(item => ({ ...item })) : [ columns[0]];