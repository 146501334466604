import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { showTos } from '../../api/userAgreement';
import { handleNotifications } from '../../utils/notifications';

const TermsNCondition = ({ handleStep, data }) => {
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [tos, setTos] = useState(false);

  useEffect(() => {
    setLoading(true);
    showTos(data.package_type_id)
      .then(({data}) => {
        if(data['success']) {
          setTos(data['data']);
          setLoading(false);
        }
      }).catch(error => {
        setLoading(false);
        console.error(error);
      })
    // eslint-disable-next-line
  }, [data?.package_type_id]);

  const handleChange = (e) => {
    setAccepted(e.target.value);
  }

  const handleSubmit = () => {
    if(!accepted){
      handleNotifications('warn', 'BZ Connects !!', 'Please Accept terms and condition');
      return
    }
    handleStep(2);
  };

  return (
    <>
    { loading ? <Spin /> : (
      <div className="tnc-container">
        <h1>TERMS AND CONDITIONS</h1>
        <div>{ ReactHtmlParser(tos.user_agreement) }</div>
        <input type="checkbox" id="checkbox" onChange={handleChange}/> &nbsp;
        <label htmlFor="checkbox">Accept Terms and Condition</label>
        <br />
        <div id="btn-container">
          <Button Button type="primary" size="small" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    )}
    </>
  );
};

TermsNCondition.propTypes = {
  handleStep: PropTypes.func.isRequired
};

export default TermsNCondition;
