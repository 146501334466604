/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import dayjs from 'dayjs';
import { Card } from 'antd';

import { getLookAhead } from '../../api/lookAhead';
import { hideLoading, showLoading } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';

const View = ({ match, onShowLoading, onHideLoading }) => {
  const [lookAheadDetails, setLookAheadDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!match.params) {
      return;
    } else {
      setLoading(true);
      onShowLoading();
      getLookAhead(match.params['lookAheadId'])
        .then(({ data }) => {
          setLoading(false);
          onHideLoading();
          if (data['success']) {
            setLookAheadDetails(data.data);
          }
        })
        .catch(error => {
          setLoading(false);
          onHideLoading();
          handleNotifications('error', 'Failed', error.message);
        });
    }
  }, []);

  return (
    <>
      <h1 className="heading-title">Look Ahead Details</h1>
      <Card loading={loading}>
        {lookAheadDetails ? (
          <>
            <h3>Number</h3>
            <p>{lookAheadDetails['number']}</p>

            <h3>Proposed Advertising Date</h3>
            <p>
              {dayjs(
                lookAheadDetails['proposed_advertising_date'],
                'YYYY-MM-DD'
              ).format('MMMM DD, YYYY')}
            </p>

            <h3>Agency</h3>
            <p>{lookAheadDetails['agency']}</p>

            <h3>County</h3>
            <p>{lookAheadDetails['county']}</p>

            <h3>State</h3>
            <p>{lookAheadDetails['state']}</p>

            <h3>Scope</h3>
            <p>{lookAheadDetails['scope']}</p>

            <h3>Type of Work</h3>
            <p>{lookAheadDetails['type_of_work']}</p>

            <h3>Site Link</h3>
            <p>{lookAheadDetails['site_link']}</p>

            <h3>Estimated Cost</h3>
            <p>{lookAheadDetails['estimated_cost'] || '-'}</p>

            <h3>Comment</h3>
            <p>{lookAheadDetails['comment'] || '-'}</p>
            
            {lookAheadDetails?.created_on && (<><h3>Created On</h3>
            <p>{dayjs(lookAheadDetails?.created_on).format('MM/DD/YYYY hh:mm A')}</p></>)}

            {lookAheadDetails?.updated_on && (<><h3>Updated On</h3>
            <p>{dayjs(lookAheadDetails?.updated_on).format('MM/DD/YYYY hh:mm A')}</p></>)}
          </>
        ) : null}
      </Card>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading())
});

View.propTypes = { match: object, onShowLoading: func, onHideLoading: func };

export default connect(null, mapDispatchToProps)(View);
