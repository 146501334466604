import * as actionTypes from '../actionTypes';

const initialState = {
  path: null
};

export const redirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REDIRECT_SET:
      return { ...state, path: action.payload };
    case actionTypes.REDIRECT_CLEAR:
      return { ...state, path: null };
    default:
      return { ...state };
  }
};
