import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import { Card } from 'antd';
import dayjs from 'dayjs';

import { getEvent } from '../../api/calendar';
import { handleNotifications } from '../../utils/notifications';
import moment from 'moment';
// import { utcToLocalString } from '../../utils/calendarHelper';

const View = ({ match, eventId }) => {
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!(match && match.params) && !eventId) {
      return setLoading(false);
    }
    setLoading(true);
    getEvent(match?.params?.eventId || eventId)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          setEventDetails(data.data);
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
      // eslint-disable-next-line
  }, [match?.params, eventId]);

  return (
    <Card loading={loading}>
      <h1 className="heading-title">{eventDetails?.title}</h1>
      <h3>Dates</h3>
      <p>
        {eventDetails?.date_start === eventDetails?.date_end ? (
          <span>
            {moment.utc(eventDetails?.date_start).utcOffset((dayjs().utcOffset())).format('MMMM DD, YYYY hh:mm A')}
          </span>
        ) : (
          <span>
            {moment.utc(eventDetails?.date_start).utcOffset((dayjs().utcOffset())).format('MMMM DD, YYYY hh:mm A')}
            &nbsp;-&nbsp;
            {moment.utc(eventDetails?.date_end).utcOffset((dayjs().utcOffset())).format('MMMM DD, YYYY hh:mm A')}
          </span>
        )}
      </p>
      <h3>Type</h3>
      <p>{eventDetails?.type_event}</p>
      <h3>Location</h3>
      <p>{eventDetails?.location}</p>
      <h3>Agency</h3>
      <p>{eventDetails?.agency}</p>
      <h3>Description</h3>
      <p>{eventDetails?.description}</p>
      
      {eventDetails?.created_on && (<><h3>Created On</h3>
      <p>{moment.utc(eventDetails?.created_on).utcOffset((dayjs().utcOffset())).format('MM/DD/YYYY hh:mm A')}</p></>)}
      
      {eventDetails?.updated_on && (<><h3>Updated On</h3>
      <p>{moment.utc(eventDetails?.updated_on).utcOffset((dayjs().utcOffset())).format('MM/DD/YYYY hh:mm A')}</p></>)}
    </Card>
  );
};

View.propTypes = { match: object };

export default View;
