import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ src }) => {
  console.log(src)
  return (<ReactPlayer
    url={src}
    controls
    width={'100%'}
    style={{
      minHeight: "500px"
    }}
    playIcon
    playing
  />
)};

export default VideoPlayer;
