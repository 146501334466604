import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, element, string } from 'prop-types';
import { Layout } from 'antd';

import MenuBar from '../components/common/Navigation/MenuBar';
import { accessRules } from '../utils/accessRules';
import { getCurrentPageFromLocation } from '../utils/utils';
import Calendar from './Calendar/Calendar';
// import BetaComments from './BetaComments/BetaComments';
import MessageCenter from './MessageCenter/MessageCenter';
import LookAhead from './LookAhead/LookAhead';
import Primes from './Primes/Primes';
import RfpRfq from './RfpRfq/RfpRfq';
import Contact from './Contact/Contact';
import SmallBusiness from './SmallBusiness/SmallBusiness';
import Association from './Association';
import UserDashboard from './UserDashboard/UserDashboard';
import DashboardSettings from './UserDashboard/DashboardSettings';
import NotFound from '../components/common/NotFound';
import SearchSidebar from '../components/common/SearchSidebar/SearchSidebar';
import MobileSidebar from '../components/common/SearchSidebar/mobileSidebar';
import MyBusiness from './MyBusiness/MyBusiness';
import ChangePassword from './ChangePassword/ChangePassword';
import PaymentStepper from '../components/payments/paymentStepper';
import GeneralAnnouncement from './GeneralAnnouncements';
import GeneralIndustryNews from './GeneralIndustryNews';
import Announcements from './Announcements';
import IndustryNews from './IndustryNews';
import Profile from './Profile';
import AddUsers from './AddUsers';
import NoAccess from './NoAccess';
import ProtectedRoute from './protectedRoute';
import SpecialRoute from './specialRoute';
import { getFromLocalStorage } from '../utils/localStorage';
import PartialForm from './PartialForm';
import Videos from './Videos';
import SettingsContainer from './SiteSettings';

const { Sider, Content } = Layout;

const RoutesComponent = ({ isLoggedIn, fromRegister, role, free_user, businessId }) => {
  const history = useHistory();
  const permissions = getFromLocalStorage('permissions');

  useEffect(() => {
    if (!isLoggedIn && !fromRegister) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, fromRegister]);

  const { pathname } = useLocation();

  return (
    <Layout
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <MenuBar />
      <Layout>
        {role ? (
          accessRules?.[role]?.[getCurrentPageFromLocation(pathname)]?.[
            'searchSidebar'
          ] ? (
            <>
            <Sider>
              <SearchSidebar pathname={getCurrentPageFromLocation(pathname)} />
            </Sider>
            <MobileSidebar>
              <SearchSidebar pathname={getCurrentPageFromLocation(pathname)} />
            </MobileSidebar>
            </>
          ) : null
        ) : null}
        <Content>
          <Switch>
            {(permissions && permissions['custom_dashboard_perm']) || !businessId ? 
              <Redirect exact from="/" to="/association" /> :
              <Redirect exact from="/dashboard" to={free_user ? "/my-business" : "/association"} />
            }

            <Route path="/payments" component={PaymentStepper} />
            {/* <Redirect exact from="/dashboard" to="/payments" /> */}
            <SpecialRoute path="/add-users" codename="extra_user_perm" component={AddUsers} />
            <ProtectedRoute path="/dashboard" codename="custom_dashboard_perm" component={UserDashboard} />
            <ProtectedRoute path="/association" codename="association_perm" component={Association} />
            <ProtectedRoute path="/small-business" codename="small_business_perm" component={SmallBusiness} />
            <ProtectedRoute path="/view-profile" codename="my_profile_perm" component={Profile} />
            <ProtectedRoute path="/primes" codename="primes_perm" component={Primes} />
            <ProtectedRoute path="/calendar" codename="calendar_perm" component={Calendar} />
            <ProtectedRoute path="/rfp-rfq" codename="rfp_perm" component={RfpRfq} />
            <ProtectedRoute path="/look-ahead" codename="look_ahead_schedule_perm" component={LookAhead} />
            {/* <ProtectedRoute path="/beta-comments" codename="viewProfile" component={BetaComments} /> */}
            <ProtectedRoute path="/my-business" codename="my_business_perm" component={MyBusiness} />
            <ProtectedRoute path="/partial-form" codename="partial_form_perm" component={PartialForm} />
            <ProtectedRoute path="/change-password" codename="change_password_perm" component={ChangePassword} />
            <ProtectedRoute path="/dashboard-settings" codename="dashboardSettings" component={DashboardSettings} />
            <ProtectedRoute path="/message-center" codename="one_way_contact_perm" component={MessageCenter} />
            <Route path="/contact" codename="technical_support_perm" component={Contact} />
            <ProtectedRoute path="/site-settings" codename="user_agreement_perm" component={SettingsContainer} />
            <ProtectedRoute path="/general-announcements" codename="general_Announcement_perm" component={GeneralAnnouncement} />
            <ProtectedRoute path="/general-industry-news" codename="general_Industry_news_perm" component={GeneralIndustryNews} />
            <ProtectedRoute path="/announcements" codename="announcements_perm" component={Announcements} />
            <ProtectedRoute path="/industry-news" codename="industry_news_perm" component={IndustryNews} />
            <ProtectedRoute path="/videos" codename="videos_perm" component={Videos} />
            <Route path="/noaccess" codename="noacccess" component={NoAccess} />
            <Route path="*" codename="notfound" component={NotFound} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: state.auth.loggedIn,
  fromRegister: state.auth.fromRegister,
  role: state.auth.role,
  free_user: state.auth.free_user,
  businessId: state.auth.user?.businessId,
});

RoutesComponent.propTypes = {
  isLoggedIn: bool,
  fromRegister: bool,
  role: string,
  children: element
};

export default connect(mapStateToProps)(RoutesComponent);
