export const emailPattern = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/);

export const phoneUSPattern = new RegExp(
  /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g
);

export const websiteUrlPattern = new RegExp(
  /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)+(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
);

// export const zipcodePattern = new RegExp()

export const fileFormats = {
  logo: '.png, .jpeg, .jpg',
  address: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  revenues: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  number_of_employees: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  licenses: '.pdf, .png, .jpeg, .jpg',
  certifications: '.pdf, .png, .jpeg, .jpg',
  recommendations: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  soq: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  team_photo: '.png, .jpeg, .jpg',
  import_events: '.csv',
  rfp_event_file: '.csv',
  look_ahead_file: '.csv'
};

export const rfpRfqTypes = {
  RFP: 'RFP',
  RFQ: 'RFQ',
  IFB: 'IFB'
};

export const NaicsCodes = [
    { value: '111110', label: 'Soybean Farming' },
    { value: '111120', label: 'Oilseed (except Soybean) Farming' },
    { value: '111130', label: 'Dry Pea and Bean Farming' },
    { value: '111140', label: 'Wheat Farming' },
    { value: '111150', label: 'Corn Farming' },
    { value: '111160', label: 'Rice Farming' },
    { value: '111191', label: 'Oilseed and Grain Combination Farming' },
    { value: '111199', label: 'All Other Grain Farming' },
    { value: '111211', label: 'Potato Farming' },
    {
      value: '111219',
      label: 'Other Vegetable (except Potato) and Melon Farming'
    },
    { value: '111310', label: 'Orange Groves' },
    { value: '111320', label: 'Citrus (except Orange) Groves' },
    { value: '111331', label: 'Apple Orchards' },
    { value: '111332', label: 'Grape Vineyards' },
    { value: '111333', label: 'Strawberry Farming' },
    { value: '111334', label: 'Berry (except Strawberry) Farming' },
    { value: '111335', label: 'Tree Nut Farming' },
    { value: '111336', label: 'Fruit and Tree Nut Combination Farming' },
    { value: '111339', label: 'Other Noncitrus Fruit Farming' },
    { value: '111411', label: 'Mushroom Production' },
    { value: '111419', label: 'Other Food Crops Grown Under Cover' },
    { value: '111421', label: 'Nursery and Tree Production' },
    { value: '111422', label: 'Floriculture Production' },
    { value: '111910', label: 'Tobacco Farming' },
    { value: '111920', label: 'Cotton Farming' },
    { value: '111930', label: 'Sugarcane Farming' },
    { value: '111940', label: 'Hay Farming' },
    { value: '111991', label: 'Sugar Beet Farming' },
    { value: '111992', label: 'Peanut Farming' },
    { value: '111998', label: 'All Other Miscellaneous Crop Farming' },
    { value: '112111', label: 'Beef Cattle Ranching and Farming' },
    { value: '112112', label: 'Cattle Feedlots' },
    { value: '112120', label: 'Dairy Cattle and Milk Production' },
    { value: '112130', label: 'Dual-Purpose Cattle Ranching and Farming' },
    { value: '112210', label: 'Hog and Pig Farming' },
    { value: '112310', label: 'Chicken Egg Production' },
    { value: '112320', label: 'Broilers and Other Meat Type Chicken Production' },
    { value: '112330', label: 'Turkey Production' },
    { value: '112340', label: 'Poultry Hatcheries' },
    { value: '112390', label: 'Other Poultry Production' },
    { value: '112410', label: 'Sheep Farming' },
    { value: '112420', label: 'Goat Farming' },
    { value: '112511', label: 'Finfish Farming and Fish Hatcheries' },
    { value: '112512', label: 'Shellfish Farming' },
    { value: '112519', label: 'Other Aquaculture' },
    { value: '112910', label: 'Apiculture' },
    { value: '112920', label: 'Horses and Other Equine Production' },
    { value: '112930', label: 'Fur-Bearing Animal and Rabbit Production' },
    { value: '112990', label: 'All Other Animal Production' },
    { value: '113110', label: 'Timber Tract Operations' },
    {
      value: '113210',
      label: 'Forest Nurseries and Gathering of Forest Products'
    },
    { value: '113310', label: 'Logging' },
    { value: '114111', label: 'Finfish Fishing' },
    { value: '114112', label: 'Shellfish Fishing' },
    { value: '114119', label: 'Other Marine Fishing' },
    { value: '114210', label: 'Hunting and Trapping' },
    { value: '115111', label: 'Cotton Ginning' },
    { value: '115112', label: 'Soil Preparation, Planting, and Cultivating' },
    { value: '115113', label: 'Crop Harvesting, Primarily by Machine' },
    {
      value: '115114',
      label: 'Postharvest Crop Activities (except Cotton Ginning)'
    },
    { value: '115115', label: 'Farm Labor Contractors and Crew Leaders' },
    { value: '115116', label: 'Farm Management Services' },
    { value: '115210', label: 'Support Activities for Animal Production' },
    { value: '115310', label: 'Support Activities for Forestry' },
    { value: '211120', label: 'Crude Petroleum Extraction' },
    { value: '211130', label: 'Natural Gas Extraction' },
    { value: '212114', label: 'Surface Coal Mining' },
    { value: '212115', label: 'Underground Coal Mining' },
    { value: '212210', label: 'Iron Ore Mining' },
    { value: '212220', label: 'Gold Ore and Silver Ore Mining' },
    { value: '212230', label: 'Copper, Nickel, Lead, and Zinc Mining' },
    { value: '212290', label: 'Other Metal Ore Mining' },
    { value: '212311', label: 'Dimension Stone Mining and Quarrying' },
    {
      value: '212312',
      label: 'Crushed and Broken Limestone Mining and Quarrying'
    },
    { value: '212313', label: 'Crushed and Broken Granite Mining and Quarrying' },
    {
      value: '212319',
      label: 'Other Crushed and Broken Stone Mining and Quarrying'
    },
    { value: '212321', label: 'Construction Sand and Gravel Mining' },
    { value: '212322', label: 'Industrial Sand Mining' },
    {
      value: '212323',
      label: 'Kaolin, Clay, and Ceramic and Refractory Minerals Mining'
    },
    { value: '212390', label: 'Other Nonmetallic Mineral Mining and Quarrying' },
    { value: '213111', label: 'Drilling Oil and Gas Wells' },
    { value: '213112', label: 'Support Activities for Oil and Gas Operations' },
    { value: '213113', label: 'Support Activities for Coal Mining' },
    { value: '213114', label: 'Support Activities for Metal Mining' },
    {
      value: '213115',
      label: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining'
    },
    { value: '221111', label: 'Hydroelectric Power Generation' },
    { value: '221112', label: 'Fossil Fuel Electric Power Generation' },
    { value: '221113', label: 'Nuclear Electric Power Generation' },
    { value: '221114', label: 'Solar Electric Power Generation' },
    { value: '221115', label: 'Wind Electric Power Generation' },
    { value: '221116', label: 'Geothermal Electric Power Generation' },
    { value: '221117', label: 'Biomass Electric Power Generation' },
    { value: '221118', label: 'Other Electric Power Generation' },
    { value: '221121', label: 'Electric Bulk Power Transmission and Control' },
    { value: '221122', label: 'Electric Power Distribution' },
    { value: '221210', label: 'Natural Gas Distribution' },
    { value: '221310', label: 'Water Supply and Irrigation Systems' },
    { value: '221320', label: 'Sewage Treatment Facilities' },
    { value: '221330', label: 'Steam and Air-Conditioning Supply' },
    {
      value: '236115',
      label: 'New Single-Family Housing Construction (except For-Sale Builders)'
    },
    {
      value: '236116',
      label: 'New Multifamily Housing Construction (except For-Sale Builders)'
    },
    { value: '236117', label: 'New Housing For-Sale Builders' },
    { value: '236118', label: 'Residential Remodelers' },
    { value: '236210', label: 'Industrial Building Construction' },
    {
      value: '236220',
      label: 'Commercial and Institutional Building Construction'
    },
    {
      value: '237110',
      label: 'Water and Sewer Line and Related Structures Construction'
    },
    {
      value: '237120',
      label: 'Oil and Gas Pipeline and Related Structures Construction'
    },
    {
      value: '237130',
      label: 'Power and Communication Line and Related Structures Construction'
    },
    { value: '237210', label: 'Land Subdivision' },
    { value: '237310', label: 'Highway, Street, and Bridge Construction' },
    { value: '237990', label: 'Other Heavy and Civil Engineering Construction' },
    {
      value: '238110',
      label: 'Poured Concrete Foundation and Structure Contractors'
    },
    {
      value: '238120',
      label: 'Structural Steel and Precast Concrete Contractors'
    },
    { value: '238130', label: 'Framing Contractors' },
    { value: '238140', label: 'Masonry Contractors' },
    { value: '238150', label: 'Glass and Glazing Contractors' },
    { value: '238160', label: 'Roofing Contractors' },
    { value: '238170', label: 'Siding Contractors' },
    {
      value: '238190',
      label: 'Other Foundation, Structure, and Building Exterior Contractors'
    },
    {
      value: '238210',
      label: 'Electrical Contractors and Other Wiring Installation Contractors'
    },
    {
      value: '238220',
      label: 'Plumbing, Heating, and Air-Conditioning Contractors'
    },
    { value: '238290', label: 'Other Building Equipment Contractors' },
    { value: '238310', label: 'Drywall and Insulation Contractors' },
    { value: '238320', label: 'Painting and Wall Covering Contractors' },
    { value: '238330', label: 'Flooring Contractors' },
    { value: '238340', label: 'Tile and Terrazzo Contractors' },
    { value: '238350', label: 'Finish Carpentry Contractors' },
    { value: '238390', label: 'Other Building Finishing Contractors' },
    { value: '238910', label: 'Site Preparation Contractors' },
    { value: '238990', label: 'All Other Specialty Trade Contractors' },
    { value: '311111', label: 'Dog and Cat Food Manufacturing' },
    { value: '311119', label: 'Other Animal Food Manufacturing' },
    { value: '311211', label: 'Flour Milling' },
    { value: '311212', label: 'Rice Milling' },
    { value: '311213', label: 'Malt Manufacturing' },
    { value: '311221', label: 'Wet Corn Milling and Starch Manufacturing' },
    { value: '311224', label: 'Soybean and Other Oilseed Processing' },
    { value: '311225', label: 'Fats and Oils Refining and Blending' },
    { value: '311230', label: 'Breakfast Cereal Manufacturing' },
    { value: '311313', label: 'Beet Sugar Manufacturing' },
    { value: '311314', label: 'Cane Sugar Manufacturing' },
    { value: '311340', label: 'Nonchocolate Confectionery Manufacturing' },
    {
      value: '311351',
      label: 'Chocolate and Confectionery Manufacturing from Cacao Beans'
    },
    {
      value: '311352',
      label: 'Confectionery Manufacturing from Purchased Chocolate'
    },
    {
      value: '311411',
      label: 'Frozen Fruit, Juice, and Vegetable Manufacturing'
    },
    { value: '311412', label: 'Frozen Specialty Food Manufacturing' },
    { value: '311421', label: 'Fruit and Vegetable Canning' },
    { value: '311422', label: 'Specialty Canning' },
    { value: '311423', label: 'Dried and Dehydrated Food Manufacturing' },
    { value: '311511', label: 'Fluid Milk Manufacturing' },
    { value: '311512', label: 'Creamery Butter Manufacturing' },
    { value: '311513', label: 'Cheese Manufacturing' },
    {
      value: '311514',
      label: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing'
    },
    { value: '311520', label: 'Ice Cream and Frozen Dessert Manufacturing' },
    { value: '311611', label: 'Animal (except Poultry) Slaughtering' },
    { value: '311612', label: 'Meat Processed from Carcasses' },
    { value: '311613', label: 'Rendering and Meat Byproduct Processing' },
    { value: '311615', label: 'Poultry Processing' },
    { value: '311710', label: 'Seafood Product Preparation and Packaging' },
    { value: '311811', label: 'Retail Bakeries' },
    { value: '311812', label: 'Commercial Bakeries' },
    {
      value: '311813',
      label: 'Frozen Cakes, Pies, and Other Pastries Manufacturing'
    },
    { value: '311821', label: 'Cookie and Cracker Manufacturing' },
    {
      value: '311824',
      label:
        'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour'
    },
    { value: '311830', label: 'Tortilla Manufacturing' },
    { value: '311911', label: 'Roasted Nuts and Peanut Butter Manufacturing' },
    { value: '311919', label: 'Other Snack Food Manufacturing' },
    { value: '311920', label: 'Coffee and Tea Manufacturing' },
    { value: '311930', label: 'Flavoring Syrup and Concentrate Manufacturing' },
    {
      value: '311941',
      label: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing'
    },
    { value: '311942', label: 'Spice and Extract Manufacturing' },
    { value: '311991', label: 'Perishable Prepared Food Manufacturing' },
    { value: '311999', label: 'All Other Miscellaneous Food Manufacturing' },
    { value: '312111', label: 'Soft Drink Manufacturing' },
    { value: '312112', label: 'Bottled Water Manufacturing' },
    { value: '312113', label: 'Ice Manufacturing' },
    { value: '312120', label: 'Breweries' },
    { value: '312130', label: 'Wineries' },
    { value: '312140', label: 'Distilleries' },
    { value: '312230', label: 'Tobacco Manufacturing' },
    { value: '313110', label: 'Fiber, Yarn, and Thread Mills' },
    { value: '313210', label: 'Broadwoven Fabric Mills' },
    {
      value: '313220',
      label: 'Narrow Fabric Mills and Schiffli Machine Embroidery'
    },
    { value: '313230', label: 'Nonwoven Fabric Mills' },
    { value: '313240', label: 'Knit Fabric Mills' },
    { value: '313310', label: 'Textile and Fabric Finishing Mills' },
    { value: '313320', label: 'Fabric Coating Mills' },
    { value: '314110', label: 'Carpet and Rug Mills' },
    { value: '314120', label: 'Curtain and Linen Mills' },
    { value: '314910', label: 'Textile Bag and Canvas Mills' },
    {
      value: '314994',
      label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills'
    },
    { value: '314999', label: 'All Other Miscellaneous Textile Product Mills' },
    { value: '315120', label: 'Apparel Knitting Mills' },
    { value: '315210', label: 'Cut and Sew Apparel Contractors' },
    {
      value: '315250',
      label: 'Cut and Sew Apparel Manufacturing (except Contractors)'
    },
    {
      value: '315990',
      label: 'Apparel Accessories and Other Apparel Manufacturing'
    },
    { value: '316110', label: 'Leather and Hide Tanning and Finishing' },
    { value: '316210', label: 'Footwear Manufacturing' },
    { value: '316990', label: 'Other Leather and Allied Product Manufacturing' },
    { value: '321113', label: 'Sawmills' },
    { value: '321114', label: 'Wood Preservation' },
    { value: '321211', label: 'Hardwood Veneer and Plywood Manufacturing' },
    { value: '321212', label: 'Softwood Veneer and Plywood Manufacturing' },
    { value: '321215', label: 'Engineered Wood Member Manufacturing' },
    { value: '321219', label: 'Reconstituted Wood Product Manufacturing' },
    { value: '321911', label: 'Wood Window and Door Manufacturing' },
    { value: '321912', label: 'Cut Stock, Resawing Lumber, and Planing' },
    { value: '321918', label: 'Other Millwork (including Flooring)' },
    { value: '321920', label: 'Wood Container and Pallet Manufacturing' },
    { value: '321991', label: 'Manufactured Home (Mobile Home) Manufacturing' },
    { value: '321992', label: 'Prefabricated Wood Building Manufacturing' },
    {
      value: '321999',
      label: 'All Other Miscellaneous Wood Product Manufacturing'
    },
    { value: '322110', label: 'Pulp Mills' },
    { value: '322120', label: 'Paper Mills' },
    { value: '322130', label: 'Paperboard Mills' },
    { value: '322211', label: 'Corrugated and Solid Fiber Box Manufacturing' },
    { value: '322212', label: 'Folding Paperboard Box Manufacturing' },
    { value: '322219', label: 'Other Paperboard Container Manufacturing' },
    {
      value: '322220',
      label: 'Paper Bag and Coated and Treated Paper Manufacturing'
    },
    { value: '322230', label: 'Stationery Product Manufacturing' },
    { value: '322291', label: 'Sanitary Paper Product Manufacturing' },
    { value: '322299', label: 'All Other Converted Paper Product Manufacturing' },
    { value: '323111', label: 'Commercial Printing (except Screen and Books)' },
    { value: '323113', label: 'Commercial Screen Printing' },
    { value: '323117', label: 'Books Printing' },
    { value: '323120', label: 'Support Activities for Printing' },
    { value: '324110', label: 'Petroleum Refineries' },
    { value: '324121', label: 'Asphalt Paving Mixture and Block Manufacturing' },
    {
      value: '324122',
      label: 'Asphalt Shingle and Coating Materials Manufacturing'
    },
    {
      value: '324191',
      label: 'Petroleum Lubricating Oil and Grease Manufacturing'
    },
    {
      value: '324199',
      label: 'All Other Petroleum and Coal Products Manufacturing'
    },
    { value: '325110', label: 'Petrochemical Manufacturing' },
    { value: '325120', label: 'Industrial Gas Manufacturing' },
    { value: '325130', label: 'Synthetic Dye and Pigment Manufacturing' },
    { value: '325180', label: 'Other Basic Inorganic Chemical Manufacturing' },
    { value: '325193', label: 'Ethyl Alcohol Manufacturing' },
    {
      value: '325194',
      label: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing'
    },
    { value: '325199', label: 'All Other Basic Organic Chemical Manufacturing' },
    { value: '325211', label: 'Plastics Material and Resin Manufacturing' },
    { value: '325212', label: 'Synthetic Rubber Manufacturing' },
    {
      value: '325220',
      label: 'Artificial and Synthetic Fibers and Filaments Manufacturing'
    },
    { value: '325311', label: 'Nitrogenous Fertilizer Manufacturing' },
    { value: '325312', label: 'Phosphatic Fertilizer Manufacturing' },
    { value: '325314', label: 'Fertilizer (Mixing Only) Manufacturing' },
    { value: '325315', label: 'Compost Manufacturing' },
    {
      value: '325320',
      label: 'Pesticide and Other Agricultural Chemical Manufacturing'
    },
    { value: '325411', label: 'Medicinal and Botanical Manufacturing' },
    { value: '325412', label: 'Pharmaceutical Preparation Manufacturing' },
    { value: '325413', label: 'In-Vitro Diagnostic Substance Manufacturing' },
    {
      value: '325414',
      label: 'Biological Product (except Diagnostic) Manufacturing'
    },
    { value: '325510', label: 'Paint and Coating Manufacturing' },
    { value: '325520', label: 'Adhesive Manufacturing' },
    { value: '325611', label: 'Soap and Other Detergent Manufacturing' },
    { value: '325612', label: 'Polish and Other Sanitation Good Manufacturing' },
    { value: '325613', label: 'Surface Active Agent Manufacturing' },
    { value: '325620', label: 'Toilet Preparation Manufacturing' },
    { value: '325910', label: 'Printing Ink Manufacturing' },
    { value: '325920', label: 'Explosives Manufacturing' },
    { value: '325991', label: 'Custom Compounding of Purchased Resins' },
    {
      value: '325992',
      label:
        'Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing'
    },
    {
      value: '325998',
      label:
        'All Other Miscellaneous Chemical Product and Preparation Manufacturing'
    },
    { value: '326111', label: 'Plastics Bag and Pouch Manufacturing' },
    {
      value: '326112',
      label:
        'Plastics Packaging Film and Sheet (including Laminated) Manufacturing'
    },
    {
      value: '326113',
      label:
        'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing'
    },
    {
      value: '326121',
      label: 'Unlaminated Plastics Profile Shape Manufacturing'
    },
    { value: '326122', label: 'Plastics Pipe and Pipe Fitting Manufacturing' },
    {
      value: '326130',
      label:
        'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing'
    },
    { value: '326140', label: 'Polystyrene Foam Product Manufacturing' },
    {
      value: '326150',
      label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing'
    },
    { value: '326160', label: 'Plastics Bottle Manufacturing' },
    { value: '326191', label: 'Plastics Plumbing Fixture Manufacturing' },
    { value: '326199', label: 'All Other Plastics Product Manufacturing' },
    { value: '326211', label: 'Tire Manufacturing (except Retreading)' },
    { value: '326212', label: 'Tire Retreading' },
    {
      value: '326220',
      label: 'Rubber and Plastics Hoses and Belting Manufacturing'
    },
    { value: '326291', label: 'Rubber Product Manufacturing for Mechanical Use' },
    { value: '326299', label: 'All Other Rubber Product Manufacturing' },
    {
      value: '327110',
      label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing'
    },
    {
      value: '327120',
      label: 'Clay Building Material and Refractories Manufacturing'
    },
    { value: '327211', label: 'Flat Glass Manufacturing' },
    {
      value: '327212',
      label: 'Other Pressed and Blown Glass and Glassware Manufacturing'
    },
    { value: '327213', label: 'Glass Container Manufacturing' },
    {
      value: '327215',
      label: 'Glass Product Manufacturing Made of Purchased Glass'
    },
    { value: '327310', label: 'Cement Manufacturing' },
    { value: '327320', label: 'Ready-Mix Concrete Manufacturing' },
    { value: '327331', label: 'Concrete Block and Brick Manufacturing' },
    { value: '327332', label: 'Concrete Pipe Manufacturing' },
    { value: '327390', label: 'Other Concrete Product Manufacturing' },
    { value: '327410', label: 'Lime Manufacturing' },
    { value: '327420', label: 'Gypsum Product Manufacturing' },
    { value: '327910', label: 'Abrasive Product Manufacturing' },
    { value: '327991', label: 'Cut Stone and Stone Product Manufacturing' },
    {
      value: '327992',
      label: 'Ground or Treated Mineral and Earth Manufacturing'
    },
    { value: '327993', label: 'Mineral Wool Manufacturing' },
    {
      value: '327999',
      label: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing'
    },
    {
      value: '331110',
      label: 'Iron and Steel Mills and Ferroalloy Manufacturing'
    },
    {
      value: '331210',
      label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel'
    },
    { value: '331221', label: 'Rolled Steel Shape Manufacturing' },
    { value: '331222', label: 'Steel Wire Drawing' },
    {
      value: '331313',
      label: 'Alumina Refining and Primary Aluminum Production'
    },
    { value: '331314', label: 'Secondary Smelting and Alloying of Aluminum' },
    { value: '331315', label: 'Aluminum Sheet, Plate, and Foil Manufacturing' },
    { value: '331318', label: 'Other Aluminum Rolling, Drawing, and Extruding' },
    {
      value: '331410',
      label: 'Nonferrous Metal (except Aluminum) Smelting and Refining'
    },
    {
      value: '331420',
      label: 'Copper Rolling, Drawing, Extruding, and Alloying'
    },
    {
      value: '331491',
      label:
        'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding'
    },
    {
      value: '331492',
      label:
        'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)'
    },
    { value: '331511', label: 'Iron Foundries' },
    { value: '331512', label: 'Steel Investment Foundries' },
    { value: '331513', label: 'Steel Foundries (except Investment)' },
    { value: '331523', label: 'Nonferrous Metal Die-Casting Foundries' },
    { value: '331524', label: 'Aluminum Foundries (except Die-Casting)' },
    {
      value: '331529',
      label: 'Other Nonferrous Metal Foundries (except Die-Casting)'
    },
    { value: '332111', label: 'Iron and Steel Forging' },
    { value: '332112', label: 'Nonferrous Forging' },
    { value: '332114', label: 'Custom Roll Forming' },
    { value: '332117', label: 'Powder Metallurgy Part Manufacturing' },
    {
      value: '332119',
      label: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)'
    },
    {
      value: '332215',
      label:
        'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing'
    },
    { value: '332216', label: 'Saw Blade and Handtool Manufacturing' },
    {
      value: '332311',
      label: 'Prefabricated Metal Building and Component Manufacturing'
    },
    { value: '332312', label: 'Fabricated Structural Metal Manufacturing' },
    { value: '332313', label: 'Plate Work Manufacturing' },
    { value: '332321', label: 'Metal Window and Door Manufacturing' },
    { value: '332322', label: 'Sheet Metal Work Manufacturing' },
    {
      value: '332323',
      label: 'Ornamental and Architectural Metal Work Manufacturing'
    },
    { value: '332410', label: 'Power Boiler and Heat Exchanger Manufacturing' },
    { value: '332420', label: 'Metal Tank (Heavy Gauge) Manufacturing' },
    { value: '332431', label: 'Metal Can Manufacturing' },
    { value: '332439', label: 'Other Metal Container Manufacturing' },
    { value: '332510', label: 'Hardware Manufacturing' },
    { value: '332613', label: 'Spring Manufacturing' },
    { value: '332618', label: 'Other Fabricated Wire Product Manufacturing' },
    { value: '332710', label: 'Machine Shops' },
    { value: '332721', label: 'Precision Turned Product Manufacturing' },
    {
      value: '332722',
      label: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing'
    },
    { value: '332811', label: 'Metal Heat Treating' },
    {
      value: '332812',
      label:
        'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers'
    },
    {
      value: '332813',
      label: 'Electroplating, Plating, Polishing, Anodizing, and Coloring'
    },
    { value: '332911', label: 'Industrial Valve Manufacturing' },
    {
      value: '332912',
      label: 'Fluid Power Valve and Hose Fitting Manufacturing'
    },
    { value: '332913', label: 'Plumbing Fixture Fitting and Trim Manufacturing' },
    {
      value: '332919',
      label: 'Other Metal Valve and Pipe Fitting Manufacturing'
    },
    { value: '332991', label: 'Ball and Roller Bearing Manufacturing' },
    { value: '332992', label: 'Small Arms Ammunition Manufacturing' },
    { value: '332993', label: 'Ammunition (except Small Arms) Manufacturing' },
    {
      value: '332994',
      label: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing'
    },
    { value: '332996', label: 'Fabricated Pipe and Pipe Fitting Manufacturing' },
    {
      value: '332999',
      label: 'All Other Miscellaneous Fabricated Metal Product Manufacturing'
    },
    { value: '333111', label: 'Farm Machinery and Equipment Manufacturing' },
    {
      value: '333112',
      label:
        'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing'
    },
    { value: '333120', label: 'Construction Machinery Manufacturing' },
    { value: '333131', label: 'Mining Machinery and Equipment Manufacturing' },
    {
      value: '333132',
      label: 'Oil and Gas Field Machinery and Equipment Manufacturing'
    },
    { value: '333241', label: 'Food Product Machinery Manufacturing' },
    { value: '333242', label: 'Semiconductor Machinery Manufacturing' },
    {
      value: '333243',
      label: 'Sawmill, Woodworking, and Paper Machinery Manufacturing'
    },
    { value: '333248', label: 'All Other Industrial Machinery Manufacturing' },
    {
      value: '333310',
      label: 'Commercial and Service Industry Machinery Manufacturing'
    },
    {
      value: '333413',
      label:
        'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing'
    },
    {
      value: '333414',
      label: 'Heating Equipment (except Warm Air Furnaces) Manufacturing'
    },
    {
      value: '333415',
      label:
        'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing'
    },
    { value: '333511', label: 'Industrial Mold Manufacturing' },
    {
      value: '333514',
      label: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing'
    },
    {
      value: '333515',
      label: 'Cutting Tool and Machine Tool Accessory Manufacturing'
    },
    { value: '333517', label: 'Machine Tool Manufacturing' },
    {
      value: '333519',
      label: 'Rolling Mill and Other Metalworking Machinery Manufacturing'
    },
    {
      value: '333611',
      label: 'Turbine and Turbine Generator Set Units Manufacturing'
    },
    {
      value: '333612',
      label: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing'
    },
    {
      value: '333613',
      label: 'Mechanical Power Transmission Equipment Manufacturing'
    },
    { value: '333618', label: 'Other Engine Equipment Manufacturing' },
    { value: '333912', label: 'Air and Gas Compressor Manufacturing' },
    {
      value: '333914',
      label: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing'
    },
    { value: '333921', label: 'Elevator and Moving Stairway Manufacturing' },
    { value: '333922', label: 'Conveyor and Conveying Equipment Manufacturing' },
    {
      value: '333923',
      label: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing'
    },
    {
      value: '333924',
      label:
        'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing'
    },
    { value: '333991', label: 'Power-Driven Handtool Manufacturing' },
    { value: '333992', label: 'Welding and Soldering Equipment Manufacturing' },
    { value: '333993', label: 'Packaging Machinery Manufacturing' },
    {
      value: '333994',
      label: 'Industrial Process Furnace and Oven Manufacturing'
    },
    { value: '333995', label: 'Fluid Power Cylinder and Actuator Manufacturing' },
    { value: '333996', label: 'Fluid Power Pump and Motor Manufacturing' },
    {
      value: '333998',
      label: 'All Other Miscellaneous General Purpose Machinery Manufacturing'
    },
    { value: '334111', label: 'Electronic Computer Manufacturing' },
    { value: '334112', label: 'Computer Storage Device Manufacturing' },
    {
      value: '334118',
      label:
        'Computer Terminal and Other Computer Peripheral Equipment Manufacturing'
    },
    { value: '334210', label: 'Telephone Apparatus Manufacturing' },
    {
      value: '334220',
      label:
        'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing'
    },
    { value: '334290', label: 'Other Communications Equipment Manufacturing' },
    { value: '334310', label: 'Audio and Video Equipment Manufacturing' },
    { value: '334412', label: 'Bare Printed Circuit Board Manufacturing' },
    { value: '334413', label: 'Semiconductor and Related Device Manufacturing' },
    {
      value: '334416',
      label:
        'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing'
    },
    { value: '334417', label: 'Electronic Connector Manufacturing' },
    {
      value: '334418',
      label: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing'
    },
    { value: '334419', label: 'Other Electronic Component Manufacturing' },
    {
      value: '334510',
      label: 'Electromedical and Electrotherapeutic Apparatus Manufacturing'
    },
    {
      value: '334511',
      label:
        'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing'
    },
    {
      value: '334512',
      label:
        'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use'
    },
    {
      value: '334513',
      label:
        'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables'
    },
    {
      value: '334514',
      label: 'Totalizing Fluid Meter and Counting Device Manufacturing'
    },
    {
      value: '334515',
      label:
        'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals'
    },
    { value: '334516', label: 'Analytical Laboratory Instrument Manufacturing' },
    { value: '334517', label: 'Irradiation Apparatus Manufacturing' },
    {
      value: '334519',
      label: 'Other Measuring and Controlling Device Manufacturing'
    },
    {
      value: '334610',
      label: 'Manufacturing and Reproducing Magnetic and Optical Media'
    },
    {
      value: '335131',
      label: 'Residential Electric Lighting Fixture Manufacturing'
    },
    {
      value: '335132',
      label:
        'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing'
    },
    {
      value: '335139',
      label: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing'
    },
    { value: '335210', label: 'Small Electrical Appliance Manufacturing' },
    { value: '335220', label: 'Major Household Appliance Manufacturing' },
    {
      value: '335311',
      label: 'Power, Distribution, and Specialty Transformer Manufacturing'
    },
    { value: '335312', label: 'Motor and Generator Manufacturing' },
    {
      value: '335313',
      label: 'Switchgear and Switchboard Apparatus Manufacturing'
    },
    { value: '335314', label: 'Relay and Industrial Control Manufacturing' },
    { value: '335910', label: 'Battery Manufacturing' },
    { value: '335921', label: 'Fiber Optic Cable Manufacturing' },
    {
      value: '335929',
      label: 'Other Communication and Energy Wire Manufacturing'
    },
    { value: '335931', label: 'Current-Carrying Wiring Device Manufacturing' },
    { value: '335932', label: 'Noncurrent-Carrying Wiring Device Manufacturing' },
    { value: '335991', label: 'Carbon and Graphite Product Manufacturing' },
    {
      value: '335999',
      label:
        'All Other Miscellaneous Electrical Equipment and Component Manufacturing'
    },
    {
      value: '336110',
      label: 'Automobile and Light Duty Motor Vehicle Manufacturing'
    },
    { value: '336120', label: 'Heavy Duty Truck Manufacturing' },
    { value: '336211', label: 'Motor Vehicle Body Manufacturing' },
    { value: '336212', label: 'Truck Trailer Manufacturing' },
    { value: '336213', label: 'Motor Home Manufacturing' },
    { value: '336214', label: 'Travel Trailer and Camper Manufacturing' },
    {
      value: '336310',
      label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing'
    },
    {
      value: '336320',
      label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing'
    },
    {
      value: '336330',
      label:
        'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing'
    },
    { value: '336340', label: 'Motor Vehicle Brake System Manufacturing' },
    {
      value: '336350',
      label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing'
    },
    {
      value: '336360',
      label: 'Motor Vehicle Seating and Interior Trim Manufacturing'
    },
    { value: '336370', label: 'Motor Vehicle Metal Stamping' },
    { value: '336390', label: 'Other Motor Vehicle Parts Manufacturing' },
    { value: '336411', label: 'Aircraft Manufacturing' },
    { value: '336412', label: 'Aircraft Engine and Engine Parts Manufacturing' },
    {
      value: '336413',
      label: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing'
    },
    { value: '336414', label: 'Guided Missile and Space Vehicle Manufacturing' },
    {
      value: '336415',
      label:
        'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing'
    },
    {
      value: '336419',
      label:
        'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing'
    },
    { value: '336510', label: 'Railroad Rolling Stock Manufacturing' },
    { value: '336611', label: 'Ship Building and Repairing' },
    { value: '336612', label: 'Boat Building' },
    { value: '336991', label: 'Motorcycle, Bicycle, and Parts Manufacturing' },
    {
      value: '336992',
      label: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing'
    },
    {
      value: '336999',
      label: 'All Other Transportation Equipment Manufacturing'
    },
    {
      value: '337110',
      label: 'Wood Kitchen Cabinet and Countertop Manufacturing'
    },
    { value: '337121', label: 'Upholstered Household Furniture Manufacturing' },
    {
      value: '337122',
      label: 'Nonupholstered Wood Household Furniture Manufacturing'
    },
    {
      value: '337126',
      label: 'Household Furniture (except Wood and Upholstered) Manufacturing'
    },
    { value: '337127', label: 'Institutional Furniture Manufacturing' },
    { value: '337211', label: 'Wood Office Furniture Manufacturing' },
    {
      value: '337212',
      label: 'Custom Architectural Woodwork and Millwork Manufacturing'
    },
    { value: '337214', label: 'Office Furniture (except Wood) Manufacturing' },
    {
      value: '337215',
      label: 'Showcase, Partition, Shelving, and Locker Manufacturing'
    },
    { value: '337910', label: 'Mattress Manufacturing' },
    { value: '337920', label: 'Blind and Shade Manufacturing' },
    { value: '339112', label: 'Surgical and Medical Instrument Manufacturing' },
    { value: '339113', label: 'Surgical Appliance and Supplies Manufacturing' },
    { value: '339114', label: 'Dental Equipment and Supplies Manufacturing' },
    { value: '339115', label: 'Ophthalmic Goods Manufacturing' },
    { value: '339116', label: 'Dental Laboratories' },
    { value: '339910', label: 'Jewelry and Silverware Manufacturing' },
    { value: '339920', label: 'Sporting and Athletic Goods Manufacturing' },
    { value: '339930', label: 'Doll, Toy, and Game Manufacturing' },
    { value: '339940', label: 'Office Supplies (except Paper) Manufacturing' },
    { value: '339950', label: 'Sign Manufacturing' },
    {
      value: '339991',
      label: 'Gasket, Packing, and Sealing Device Manufacturing'
    },
    { value: '339992', label: 'Musical Instrument Manufacturing' },
    { value: '339993', label: 'Fastener, Button, Needle, and Pin Manufacturing' },
    { value: '339994', label: 'Broom, Brush, and Mop Manufacturing' },
    { value: '339995', label: 'Burial Casket Manufacturing' },
    { value: '339999', label: 'All Other Miscellaneous Manufacturing' },
    {
      value: '423110',
      label: 'Automobile and Other Motor Vehicle Merchant Wholesalers'
    },
    {
      value: '423120',
      label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers'
    },
    { value: '423130', label: 'Tire and Tube Merchant Wholesalers' },
    { value: '423140', label: 'Motor Vehicle Parts (Used) Merchant Wholesalers' },
    { value: '423210', label: 'Furniture Merchant Wholesalers' },
    { value: '423220', label: 'Home Furnishing Merchant Wholesalers' },
    {
      value: '423310',
      label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers'
    },
    {
      value: '423320',
      label:
        'Brick, Stone, and Related Construction Material Merchant Wholesalers'
    },
    {
      value: '423330',
      label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers'
    },
    {
      value: '423390',
      label: 'Other Construction Material Merchant Wholesalers'
    },
    {
      value: '423410',
      label: 'Photographic Equipment and Supplies Merchant Wholesalers'
    },
    { value: '423420', label: 'Office Equipment Merchant Wholesalers' },
    {
      value: '423430',
      label:
        'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers'
    },
    { value: '423440', label: 'Other Commercial Equipment Merchant Wholesalers' },
    {
      value: '423450',
      label:
        'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers'
    },
    { value: '423460', label: 'Ophthalmic Goods Merchant Wholesalers' },
    {
      value: '423490',
      label: 'Other Professional Equipment and Supplies Merchant Wholesalers'
    },
    {
      value: '423510',
      label: 'Metal Service Centers and Other Metal Merchant Wholesalers'
    },
    {
      value: '423520',
      label: 'Coal and Other Mineral and Ore Merchant Wholesalers'
    },
    {
      value: '423610',
      label:
        'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers'
    },
    {
      value: '423620',
      label:
        'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers'
    },
    {
      value: '423690',
      label: 'Other Electronic Parts and Equipment Merchant Wholesalers'
    },
    { value: '423710', label: 'Hardware Merchant Wholesalers' },
    {
      value: '423720',
      label:
        'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers'
    },
    {
      value: '423730',
      label:
        'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers'
    },
    {
      value: '423740',
      label: 'Refrigeration Equipment and Supplies Merchant Wholesalers'
    },
    {
      value: '423810',
      label:
        'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers'
    },
    {
      value: '423820',
      label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers'
    },
    {
      value: '423830',
      label: 'Industrial Machinery and Equipment Merchant Wholesalers'
    },
    { value: '423840', label: 'Industrial Supplies Merchant Wholesalers' },
    {
      value: '423850',
      label: 'Service Establishment Equipment and Supplies Merchant Wholesalers'
    },
    {
      value: '423860',
      label:
        'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers'
    },
    {
      value: '423910',
      label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers'
    },
    {
      value: '423920',
      label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers'
    },
    { value: '423930', label: 'Recyclable Material Merchant Wholesalers' },
    {
      value: '423940',
      label:
        'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers'
    },
    {
      value: '423990',
      label: 'Other Miscellaneous Durable Goods Merchant Wholesalers'
    },
    { value: '424110', label: 'Printing and Writing Paper Merchant Wholesalers' },
    {
      value: '424120',
      label: 'Stationery and Office Supplies Merchant Wholesalers'
    },
    {
      value: '424130',
      label: 'Industrial and Personal Service Paper Merchant Wholesalers'
    },
    {
      value: '424210',
      label: "Drugs and Druggists' Sundries Merchant Wholesalers"
    },
    {
      value: '424310',
      label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers'
    },
    { value: '424340', label: 'Footwear Merchant Wholesalers' },
    {
      value: '424350',
      label: 'Clothing and Clothing Accessories Merchant Wholesalers'
    },
    { value: '424410', label: 'General Line Grocery Merchant Wholesalers' },
    { value: '424420', label: 'Packaged Frozen Food Merchant Wholesalers' },
    {
      value: '424430',
      label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers'
    },
    {
      value: '424440',
      label: 'Poultry and Poultry Product Merchant Wholesalers'
    },
    { value: '424450', label: 'Confectionery Merchant Wholesalers' },
    { value: '424460', label: 'Fish and Seafood Merchant Wholesalers' },
    { value: '424470', label: 'Meat and Meat Product Merchant Wholesalers' },
    { value: '424480', label: 'Fresh Fruit and Vegetable Merchant Wholesalers' },
    {
      value: '424490',
      label: 'Other Grocery and Related Products Merchant Wholesalers'
    },
    { value: '424510', label: 'Grain and Field Bean Merchant Wholesalers' },
    { value: '424520', label: 'Livestock Merchant Wholesalers' },
    {
      value: '424590',
      label: 'Other Farm Product Raw Material Merchant Wholesalers'
    },
    {
      value: '424610',
      label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers'
    },
    {
      value: '424690',
      label: 'Other Chemical and Allied Products Merchant Wholesalers'
    },
    { value: '424710', label: 'Petroleum Bulk Stations and Terminals' },
    {
      value: '424720',
      label:
        'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)'
    },
    { value: '424810', label: 'Beer and Ale Merchant Wholesalers' },
    {
      value: '424820',
      label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers'
    },
    { value: '424910', label: 'Farm Supplies Merchant Wholesalers' },
    {
      value: '424920',
      label: 'Book, Periodical, and Newspaper Merchant Wholesalers'
    },
    {
      value: '424930',
      label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
    },
    {
      value: '424940',
      label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers'
    },
    {
      value: '424950',
      label: 'Paint, Varnish, and Supplies Merchant Wholesalers'
    },
    {
      value: '424990',
      label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers'
    },
    { value: '425120', label: 'Wholesale Trade Agents and Brokers' },
    { value: '441110', label: 'New Car Dealers' },
    { value: '441120', label: 'Used Car Dealers' },
    { value: '441210', label: 'Recreational Vehicle Dealers' },
    { value: '441222', label: 'Boat Dealers' },
    {
      value: '441227',
      label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers'
    },
    { value: '441330', label: 'Automotive Parts and Accessories Retailers' },
    { value: '441340', label: 'Tire Dealers' },
    { value: '444110', label: 'Home Centers' },
    { value: '444120', label: 'Paint and Wallpaper Retailers' },
    { value: '444140', label: 'Hardware Retailers' },
    { value: '444180', label: 'Other Building Material Dealers' },
    { value: '444230', label: 'Outdoor Power Equipment Retailers' },
    {
      value: '444240',
      label: 'Nursery, Garden Center, and Farm Supply Retailers'
    },
    {
      value: '445110',
      label:
        'Supermarkets and Other Grocery Retailers (except Convenience Retailers)'
    },
    { value: '445131', label: 'Convenience Retailers' },
    { value: '445132', label: 'Vending Machine Operators' },
    { value: '445230', label: 'Fruit and Vegetable Retailers' },
    { value: '445240', label: 'Meat Retailers' },
    { value: '445250', label: 'Fish and Seafood Retailers' },
    { value: '445291', label: 'Baked Goods Retailers' },
    { value: '445292', label: 'Confectionery and Nut Retailers' },
    { value: '445298', label: 'All Other Specialty Food Retailers' },
    { value: '445320', label: 'Beer, Wine, and Liquor Retailers' },
    { value: '449110', label: 'Furniture Retailers' },
    { value: '449121', label: 'Floor Covering Retailers' },
    { value: '449122', label: 'Window Treatment Retailers' },
    { value: '449129', label: 'All Other Home Furnishings Retailers' },
    { value: '449210', label: 'Electronics and Appliance Retailers' },
    { value: '455110', label: 'Department Stores' },
    { value: '455211', label: 'Warehouse Clubs and Supercenters' },
    { value: '455219', label: 'All Other General Merchandise Retailers' },
    { value: '456110', label: 'Pharmacies and Drug Retailers' },
    {
      value: '456120',
      label: 'Cosmetics, Beauty Supplies, and Perfume Retailers'
    },
    { value: '456130', label: 'Optical Goods Retailers' },
    { value: '456191', label: 'Food (Health) Supplement Retailers' },
    { value: '456199', label: 'All Other Health and Personal Care Retailers' },
    { value: '457110', label: 'Gasoline Stations with Convenience Stores' },
    { value: '457120', label: 'Other Gasoline Stations' },
    { value: '457210', label: 'Fuel Dealers' },
    { value: '458110', label: 'Clothing and Clothing Accessories Retailers' },
    { value: '458210', label: 'Shoe Retailers' },
    { value: '458310', label: 'Jewelry Retailers' },
    { value: '458320', label: 'Luggage and Leather Goods Retailers' },
    { value: '459110', label: 'Sporting Goods Retailers' },
    { value: '459120', label: 'Hobby, Toy, and Game Retailers' },
    { value: '459130', label: 'Sewing, Needlework, and Piece Goods Retailers' },
    { value: '459140', label: 'Musical Instrument and Supplies Retailers' },
    { value: '459210', label: 'Book Retailers and News Dealers' },
    { value: '459310', label: 'Florists' },
    { value: '459410', label: 'Office Supplies and Stationery Retailers' },
    { value: '459420', label: 'Gift, Novelty, and Souvenir Retailers' },
    { value: '459510', label: 'Used Merchandise Retailers' },
    { value: '459910', label: 'Pet and Pet Supplies Retailers' },
    { value: '459920', label: 'Art Dealers' },
    { value: '459930', label: 'Manufactured (Mobile) Home Dealers' },
    {
      value: '459991',
      label: 'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers'
    },
    { value: '459999', label: 'All Other Miscellaneous Retailers' },
    { value: '481111', label: 'Scheduled Passenger Air Transportation' },
    { value: '481112', label: 'Scheduled Freight Air Transportation' },
    {
      value: '481211',
      label: 'Nonscheduled Chartered Passenger Air Transportation'
    },
    {
      value: '481212',
      label: 'Nonscheduled Chartered Freight Air Transportation'
    },
    { value: '481219', label: 'Other Nonscheduled Air Transportation' },
    { value: '482111', label: 'Line-Haul Railroads' },
    { value: '482112', label: 'Short Line Railroads' },
    { value: '483111', label: 'Deep Sea Freight Transportation' },
    { value: '483112', label: 'Deep Sea Passenger Transportation' },
    { value: '483113', label: 'Coastal and Great Lakes Freight Transportation' },
    {
      value: '483114',
      label: 'Coastal and Great Lakes Passenger Transportation'
    },
    { value: '483211', label: 'Inland Water Freight Transportation' },
    { value: '483212', label: 'Inland Water Passenger Transportation' },
    { value: '484110', label: 'General Freight Trucking, Local' },
    {
      value: '484121',
      label: 'General Freight Trucking, Long-Distance, Truckload'
    },
    {
      value: '484122',
      label: 'General Freight Trucking, Long-Distance, Less Than Truckload'
    },
    { value: '484210', label: 'Used Household and Office Goods Moving' },
    {
      value: '484220',
      label: 'Specialized Freight (except Used Goods) Trucking, Local'
    },
    {
      value: '484230',
      label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance'
    },
    { value: '485111', label: 'Mixed Mode Transit Systems' },
    { value: '485112', label: 'Commuter Rail Systems' },
    { value: '485113', label: 'Bus and Other Motor Vehicle Transit Systems' },
    { value: '485119', label: 'Other Urban Transit Systems' },
    { value: '485210', label: 'Interurban and Rural Bus Transportation' },
    { value: '485310', label: 'Taxi and Ridesharing Services' },
    { value: '485320', label: 'Limousine Service' },
    { value: '485410', label: 'School and Employee Bus Transportation' },
    { value: '485510', label: 'Charter Bus Industry' },
    { value: '485991', label: 'Special Needs Transportation' },
    {
      value: '485999',
      label: 'All Other Transit and Ground Passenger Transportation'
    },
    { value: '486110', label: 'Pipeline Transportation of Crude Oil' },
    { value: '486210', label: 'Pipeline Transportation of Natural Gas' },
    {
      value: '486910',
      label: 'Pipeline Transportation of Refined Petroleum Products'
    },
    { value: '486990', label: 'All Other Pipeline Transportation' },
    { value: '487110', label: 'Scenic and Sightseeing Transportation, Land' },
    { value: '487210', label: 'Scenic and Sightseeing Transportation, Water' },
    { value: '487990', label: 'Scenic and Sightseeing Transportation, Other' },
    { value: '488111', label: 'Air Traffic Control' },
    { value: '488119', label: 'Other Airport Operations' },
    { value: '488190', label: 'Other Support Activities for Air Transportation' },
    { value: '488210', label: 'Support Activities for Rail Transportation' },
    { value: '488310', label: 'Port and Harbor Operations' },
    { value: '488320', label: 'Marine Cargo Handling' },
    { value: '488330', label: 'Navigational Services to Shipping' },
    {
      value: '488390',
      label: 'Other Support Activities for Water Transportation'
    },
    { value: '488410', label: 'Motor Vehicle Towing' },
    {
      value: '488490',
      label: 'Other Support Activities for Road Transportation'
    },
    { value: '488510', label: 'Freight Transportation Arrangement' },
    { value: '488991', label: 'Packing and Crating' },
    { value: '488999', label: 'All Other Support Activities for Transportation' },
    { value: '491110', label: 'Postal Service' },
    { value: '492110', label: 'Couriers and Express Delivery Services' },
    { value: '492210', label: 'Local Messengers and Local Delivery' },
    { value: '493110', label: 'General Warehousing and Storage' },
    { value: '493120', label: 'Refrigerated Warehousing and Storage' },
    { value: '493130', label: 'Farm Product Warehousing and Storage' },
    { value: '493190', label: 'Other Warehousing and Storage' },
    { value: '512110', label: 'Motion Picture and Video Production' },
    { value: '512120', label: 'Motion Picture and Video Distribution' },
    { value: '512131', label: 'Motion Picture Theaters (except Drive-Ins)' },
    { value: '512132', label: 'Drive-In Motion Picture Theaters' },
    {
      value: '512191',
      label: 'Teleproduction and Other Postproduction Services'
    },
    { value: '512199', label: 'Other Motion Picture and Video Industries' },
    { value: '512230', label: 'Music Publishers' },
    { value: '512240', label: 'Sound Recording Studios' },
    { value: '512250', label: 'Record Production and Distribution' },
    { value: '512290', label: 'Other Sound Recording Industries' },
    { value: '513110', label: 'Newspaper Publishers' },
    { value: '513120', label: 'Periodical Publishers' },
    { value: '513130', label: 'Book Publishers' },
    { value: '513140', label: 'Directory and Mailing List Publishers' },
    { value: '513191', label: 'Greeting Card Publishers' },
    { value: '513199', label: 'All Other Publishers' },
    { value: '513210', label: 'Software Publishers' },
    { value: '516110', label: 'Radio Broadcasting Stations' },
    { value: '516120', label: 'Television Broadcasting Stations' },
    {
      value: '516210',
      label:
        'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers'
    },
    { value: '517111', label: 'Wired Telecommunications Carriers' },
    {
      value: '517112',
      label: 'Wireless Telecommunications Carriers (except Satellite)'
    },
    { value: '517121', label: 'Telecommunications Resellers' },
    { value: '517122', label: 'Agents for Wireless Telecommunications Services' },
    { value: '517410', label: 'Satellite Telecommunications' },
    { value: '517810', label: 'All Other Telecommunications' },
    {
      value: '518210',
      label:
        'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services'
    },
    { value: '519210', label: 'Libraries and Archives' },
    {
      value: '519290',
      label: 'Web Search Portals and All Other Information Services'
    },
    { value: '521110', label: 'Monetary Authorities-Central Bank' },
    { value: '522110', label: 'Commercial Banking' },
    { value: '522130', label: 'Credit Unions' },
    {
      value: '522180',
      label: 'Savings Institutions and Other Depository Credit Intermediation'
    },
    { value: '522210', label: 'Credit Card Issuing' },
    { value: '522220', label: 'Sales Financing' },
    { value: '522291', label: 'Consumer Lending' },
    { value: '522292', label: 'Real Estate Credit' },
    {
      value: '522299',
      label:
        'International, Secondary Market, and All Other Nondepository Credit Intermediation'
    },
    { value: '522310', label: 'Mortgage and Nonmortgage Loan Brokers' },
    {
      value: '522320',
      label:
        'Financial Transactions Processing, Reserve, and Clearinghouse Activities'
    },
    {
      value: '522390',
      label: 'Other Activities Related to Credit Intermediation'
    },
    {
      value: '523150',
      label: 'Investment Banking and Securities Intermediation'
    },
    { value: '523160', label: 'Commodity Contracts Intermediation' },
    { value: '523210', label: 'Securities and Commodity Exchanges' },
    { value: '523910', label: 'Miscellaneous Intermediation' },
    { value: '523940', label: 'Portfolio Management and Investment Advice' },
    { value: '523991', label: 'Trust, Fiduciary, and Custody Activities' },
    { value: '523999', label: 'Miscellaneous Financial Investment Activities' },
    { value: '524113', label: 'Direct Life Insurance Carriers' },
    { value: '524114', label: 'Direct Health and Medical Insurance Carriers' },
    { value: '524126', label: 'Direct Property and Casualty Insurance Carriers' },
    { value: '524127', label: 'Direct Title Insurance Carriers' },
    {
      value: '524128',
      label: 'Other Direct Insurance (except Life, Health, and Medical) Carriers'
    },
    { value: '524130', label: 'Reinsurance Carriers' },
    { value: '524210', label: 'Insurance Agencies and Brokerages' },
    { value: '524291', label: 'Claims Adjusting' },
    {
      value: '524292',
      label:
        'Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds'
    },
    { value: '524298', label: 'All Other Insurance Related Activities' },
    { value: '525110', label: 'Pension Funds' },
    { value: '525120', label: 'Health and Welfare Funds' },
    { value: '525190', label: 'Other Insurance Funds' },
    { value: '525910', label: 'Open-End Investment Funds' },
    { value: '525920', label: 'Trusts, Estates, and Agency Accounts' },
    { value: '525990', label: 'Other Financial Vehicles' },
    { value: '531110', label: 'Lessors of Residential Buildings and Dwellings' },
    {
      value: '531120',
      label: 'Lessors of Nonresidential Buildings (except Miniwarehouses)'
    },
    {
      value: '531130',
      label: 'Lessors of Miniwarehouses and Self-Storage Units'
    },
    { value: '531190', label: 'Lessors of Other Real Estate Property' },
    { value: '531210', label: 'Offices of Real Estate Agents and Brokers' },
    { value: '531311', label: 'Residential Property Managers' },
    { value: '531312', label: 'Nonresidential Property Managers' },
    { value: '531320', label: 'Offices of Real Estate Appraisers' },
    { value: '531390', label: 'Other Activities Related to Real Estate' },
    { value: '532111', label: 'Passenger Car Rental' },
    { value: '532112', label: 'Passenger Car Leasing' },
    {
      value: '532120',
      label:
        'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing'
    },
    { value: '532210', label: 'Consumer Electronics and Appliances Rental' },
    { value: '532281', label: 'Formal Wear and Costume Rental' },
    { value: '532282', label: 'Video Tape and Disc Rental' },
    { value: '532283', label: 'Home Health Equipment Rental' },
    { value: '532284', label: 'Recreational Goods Rental' },
    { value: '532289', label: 'All Other Consumer Goods Rental' },
    { value: '532310', label: 'General Rental Centers' },
    {
      value: '532411',
      label:
        'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing'
    },
    {
      value: '532412',
      label:
        'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing'
    },
    {
      value: '532420',
      label: 'Office Machinery and Equipment Rental and Leasing'
    },
    {
      value: '532490',
      label:
        'Other Commercial and Industrial Machinery and Equipment Rental and Leasing'
    },
    {
      value: '533110',
      label:
        'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
    },
    { value: '541110', label: 'Offices of Lawyers' },
    { value: '541120', label: 'Offices of Notaries' },
    { value: '541191', label: 'Title Abstract and Settlement Offices' },
    { value: '541199', label: 'All Other Legal Services' },
    { value: '541211', label: 'Offices of Certified Public Accountants' },
    { value: '541213', label: 'Tax Preparation Services' },
    { value: '541214', label: 'Payroll Services' },
    { value: '541219', label: 'Other Accounting Services' },
    { value: '541310', label: 'Architectural Services' },
    { value: '541320', label: 'Landscape Architectural Services' },
    { value: '541330', label: 'Engineering Services' },
    { value: '541340', label: 'Drafting Services' },
    { value: '541350', label: 'Building Inspection Services' },
    { value: '541360', label: 'Geophysical Surveying and Mapping Services' },
    {
      value: '541370',
      label: 'Surveying and Mapping (except Geophysical) Services'
    },
    { value: '541380', label: 'Testing Laboratories and Services' },
    { value: '541410', label: 'Interior Design Services' },
    { value: '541420', label: 'Industrial Design Services' },
    { value: '541430', label: 'Graphic Design Services' },
    { value: '541490', label: 'Other Specialized Design Services' },
    { value: '541511', label: 'Custom Computer Programming Services' },
    { value: '541512', label: 'Computer Systems Design Services' },
    { value: '541513', label: 'Computer Facilities Management Services' },
    { value: '541519', label: 'Other Computer Related Services' },
    {
      value: '541611',
      label:
        'Administrative Management and General Management Consulting Services'
    },
    { value: '541612', label: 'Human Resources Consulting Services' },
    { value: '541613', label: 'Marketing Consulting Services' },
    {
      value: '541614',
      label: 'Process, Physical Distribution, and Logistics Consulting Services'
    },
    { value: '541618', label: 'Other Management Consulting Services' },
    { value: '541620', label: 'Environmental Consulting Services' },
    {
      value: '541690',
      label: 'Other Scientific and Technical Consulting Services'
    },
    { value: '541713', label: 'Research and Development in Nanotechnology' },
    {
      value: '541714',
      label:
        'Research and Development in Biotechnology (except Nanobiotechnology)'
    },
    {
      value: '541715',
      label:
        'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)'
    },
    {
      value: '541720',
      label: 'Research and Development in the Social Sciences and Humanities'
    },
    { value: '541810', label: 'Advertising Agencies' },
    { value: '541820', label: 'Public Relations Agencies' },
    { value: '541830', label: 'Media Buying Agencies' },
    { value: '541840', label: 'Media Representatives' },
    { value: '541850', label: 'Indoor and Outdoor Display Advertising' },
    { value: '541860', label: 'Direct Mail Advertising' },
    { value: '541870', label: 'Advertising Material Distribution Services' },
    { value: '541890', label: 'Other Services Related to Advertising' },
    { value: '541910', label: 'Marketing Research and Public Opinion Polling' },
    { value: '541921', label: 'Photography Studios, Portrait' },
    { value: '541922', label: 'Commercial Photography' },
    { value: '541930', label: 'Translation and Interpretation Services' },
    { value: '541940', label: 'Veterinary Services' },
    {
      value: '541990',
      label: 'All Other Professional, Scientific, and Technical Services'
    },
    { value: '551111', label: 'Offices of Bank Holding Companies' },
    { value: '551112', label: 'Offices of Other Holding Companies' },
    {
      value: '551114',
      label: 'Corporate, Subsidiary, and Regional Managing Offices'
    },
    { value: '561110', label: 'Office Administrative Services' },
    { value: '561210', label: 'Facilities Support Services' },
    { value: '561311', label: 'Employment Placement Agencies' },
    { value: '561312', label: 'Executive Search Services' },
    { value: '561320', label: 'Temporary Help Services' },
    { value: '561330', label: 'Professional Employer Organizations' },
    { value: '561410', label: 'Document Preparation Services' },
    { value: '561421', label: 'Telephone Answering Services' },
    { value: '561422', label: 'Telemarketing Bureaus and Other Contact Centers' },
    { value: '561431', label: 'Private Mail Centers' },
    {
      value: '561439',
      label: 'Other Business Service Centers (including Copy Shops)'
    },
    { value: '561440', label: 'Collection Agencies' },
    { value: '561450', label: 'Credit Bureaus' },
    { value: '561491', label: 'Repossession Services' },
    { value: '561492', label: 'Court Reporting and Stenotype Services' },
    { value: '561499', label: 'All Other Business Support Services' },
    { value: '561510', label: 'Travel Agencies' },
    { value: '561520', label: 'Tour Operators' },
    { value: '561591', label: 'Convention and Visitors Bureaus' },
    {
      value: '561599',
      label: 'All Other Travel Arrangement and Reservation Services'
    },
    {
      value: '561611',
      label: 'Investigation and Personal Background Check Services'
    },
    { value: '561612', label: 'Security Guards and Patrol Services' },
    { value: '561613', label: 'Armored Car Services' },
    { value: '561621', label: 'Security Systems Services (except Locksmiths)' },
    { value: '561622', label: 'Locksmiths' },
    { value: '561710', label: 'Exterminating and Pest Control Services' },
    { value: '561720', label: 'Janitorial Services' },
    { value: '561730', label: 'Landscaping Services' },
    { value: '561740', label: 'Carpet and Upholstery Cleaning Services' },
    { value: '561790', label: 'Other Services to Buildings and Dwellings' },
    { value: '561910', label: 'Packaging and Labeling Services' },
    { value: '561920', label: 'Convention and Trade Show Organizers' },
    { value: '561990', label: 'All Other Support Services' },
    { value: '562111', label: 'Solid Waste Collection' },
    { value: '562112', label: 'Hazardous Waste Collection' },
    { value: '562119', label: 'Other Waste Collection' },
    { value: '562211', label: 'Hazardous Waste Treatment and Disposal' },
    { value: '562212', label: 'Solid Waste Landfill' },
    { value: '562213', label: 'Solid Waste Combustors and Incinerators' },
    { value: '562219', label: 'Other Nonhazardous Waste Treatment and Disposal' },
    { value: '562910', label: 'Remediation Services' },
    { value: '562920', label: 'Materials Recovery Facilities' },
    { value: '562991', label: 'Septic Tank and Related Services' },
    {
      value: '562998',
      label: 'All Other Miscellaneous Waste Management Services'
    },
    { value: '611110', label: 'Elementary and Secondary Schools' },
    { value: '611210', label: 'Junior Colleges' },
    {
      value: '611310',
      label: 'Colleges, Universities, and Professional Schools'
    },
    { value: '611410', label: 'Business and Secretarial Schools' },
    { value: '611420', label: 'Computer Training' },
    {
      value: '611430',
      label: 'Professional and Management Development Training'
    },
    { value: '611511', label: 'Cosmetology and Barber Schools' },
    { value: '611512', label: 'Flight Training' },
    { value: '611513', label: 'Apprenticeship Training' },
    { value: '611519', label: 'Other Technical and Trade Schools' },
    { value: '611610', label: 'Fine Arts Schools' },
    { value: '611620', label: 'Sports and Recreation Instruction' },
    { value: '611630', label: 'Language Schools' },
    { value: '611691', label: 'Exam Preparation and Tutoring' },
    { value: '611692', label: 'Automobile Driving Schools' },
    { value: '611699', label: 'All Other Miscellaneous Schools and Instruction' },
    { value: '611710', label: 'Educational Support Services' },
    {
      value: '621111',
      label: 'Offices of Physicians (except Mental Health Specialists)'
    },
    {
      value: '621112',
      label: 'Offices of Physicians, Mental Health Specialists'
    },
    { value: '621210', label: 'Offices of Dentists' },
    { value: '621310', label: 'Offices of Chiropractors' },
    { value: '621320', label: 'Offices of Optometrists' },
    {
      value: '621330',
      label: 'Offices of Mental Health Practitioners (except Physicians)'
    },
    {
      value: '621340',
      label:
        'Offices of Physical, Occupational and Speech Therapists, and Audiologists'
    },
    { value: '621391', label: 'Offices of Podiatrists' },
    {
      value: '621399',
      label: 'Offices of All Other Miscellaneous Health Practitioners'
    },
    { value: '621410', label: 'Family Planning Centers' },
    {
      value: '621420',
      label: 'Outpatient Mental Health and Substance Abuse Centers'
    },
    { value: '621491', label: 'HMO Medical Centers' },
    { value: '621492', label: 'Kidney Dialysis Centers' },
    {
      value: '621493',
      label: 'Freestanding Ambulatory Surgical and Emergency Centers'
    },
    { value: '621498', label: 'All Other Outpatient Care Centers' },
    { value: '621511', label: 'Medical Laboratories' },
    { value: '621512', label: 'Diagnostic Imaging Centers' },
    { value: '621610', label: 'Home Health Care Services' },
    { value: '621910', label: 'Ambulance Services' },
    { value: '621991', label: 'Blood and Organ Banks' },
    {
      value: '621999',
      label: 'All Other Miscellaneous Ambulatory Health Care Services'
    },
    { value: '622110', label: 'General Medical and Surgical Hospitals' },
    { value: '622210', label: 'Psychiatric and Substance Abuse Hospitals' },
    {
      value: '622310',
      label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals'
    },
    {
      value: '623110',
      label: 'Nursing Care Facilities (Skilled Nursing Facilities)'
    },
    {
      value: '623210',
      label: 'Residential Intellectual and Developmental Disability Facilities'
    },
    {
      value: '623220',
      label: 'Residential Mental Health and Substance Abuse Facilities'
    },
    { value: '623311', label: 'Continuing Care Retirement Communities' },
    { value: '623312', label: 'Assisted Living Facilities for the Elderly' },
    { value: '623990', label: 'Other Residential Care Facilities' },
    { value: '624110', label: 'Child and Youth Services' },
    {
      value: '624120',
      label: 'Services for the Elderly and Persons with Disabilities'
    },
    { value: '624190', label: 'Other Individual and Family Services' },
    { value: '624210', label: 'Community Food Services' },
    { value: '624221', label: 'Temporary Shelters' },
    { value: '624229', label: 'Other Community Housing Services' },
    { value: '624230', label: 'Emergency and Other Relief Services' },
    { value: '624310', label: 'Vocational Rehabilitation Services' },
    { value: '624410', label: 'Child Care Services' },
    { value: '711110', label: 'Theater Companies and Dinner Theaters' },
    { value: '711120', label: 'Dance Companies' },
    { value: '711130', label: 'Musical Groups and Artists' },
    { value: '711190', label: 'Other Performing Arts Companies' },
    { value: '711211', label: 'Sports Teams and Clubs' },
    { value: '711212', label: 'Racetracks' },
    { value: '711219', label: 'Other Spectator Sports' },
    {
      value: '711310',
      label:
        'Promoters of Performing Arts, Sports, and Similar Events with Facilities'
    },
    {
      value: '711320',
      label:
        'Promoters of Performing Arts, Sports, and Similar Events without Facilities'
    },
    {
      value: '711410',
      label:
        'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures'
    },
    { value: '711510', label: 'Independent Artists, Writers, and Performers' },
    { value: '712110', label: 'Museums' },
    { value: '712120', label: 'Historical Sites' },
    { value: '712130', label: 'Zoos and Botanical Gardens' },
    { value: '712190', label: 'Nature Parks and Other Similar Institutions' },
    { value: '713110', label: 'Amusement and Theme Parks' },
    { value: '713120', label: 'Amusement Arcades' },
    { value: '713210', label: 'Casinos (except Casino Hotels)' },
    { value: '713290', label: 'Other Gambling Industries' },
    { value: '713910', label: 'Golf Courses and Country Clubs' },
    { value: '713920', label: 'Skiing Facilities' },
    { value: '713930', label: 'Marinas' },
    { value: '713940', label: 'Fitness and Recreational Sports Centers' },
    { value: '713950', label: 'Bowling Centers' },
    { value: '713990', label: 'All Other Amusement and Recreation Industries' },
    { value: '721110', label: 'Hotels (except Casino Hotels) and Motels' },
    { value: '721120', label: 'Casino Hotels' },
    { value: '721191', label: 'Bed-and-Breakfast Inns' },
    { value: '721199', label: 'All Other Traveler Accommodation' },
    { value: '721211', label: 'RV (Recreational Vehicle) Parks and Campgrounds' },
    {
      value: '721214',
      label: 'Recreational and Vacation Camps (except Campgrounds)'
    },
    {
      value: '721310',
      label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
    },
    { value: '722310', label: 'Food Service Contractors' },
    { value: '722320', label: 'Caterers' },
    { value: '722330', label: 'Mobile Food Services' },
    { value: '722410', label: 'Drinking Places (Alcoholic Beverages)' },
    { value: '722511', label: 'Full-Service Restaurants' },
    { value: '722513', label: 'Limited-Service Restaurants' },
    { value: '722514', label: 'Cafeterias, Grill Buffets, and Buffets' },
    { value: '722515', label: 'Snack and Nonalcoholic Beverage Bars' },
    { value: '811111', label: 'General Automotive Repair' },
    { value: '811114', label: 'Specialized Automotive Repair' },
    {
      value: '811121',
      label: 'Automotive Body, Paint, and Interior Repair and Maintenance'
    },
    { value: '811122', label: 'Automotive Glass Replacement Shops' },
    { value: '811191', label: 'Automotive Oil Change and Lubrication Shops' },
    { value: '811192', label: 'Car Washes' },
    { value: '811198', label: 'All Other Automotive Repair and Maintenance' },
    {
      value: '811210',
      label: 'Electronic and Precision Equipment Repair and Maintenance'
    },
    {
      value: '811310',
      label:
        'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance'
    },
    {
      value: '811411',
      label: 'Home and Garden Equipment Repair and Maintenance'
    },
    { value: '811412', label: 'Appliance Repair and Maintenance' },
    { value: '811420', label: 'Reupholstery and Furniture Repair' },
    { value: '811430', label: 'Footwear and Leather Goods Repair' },
    {
      value: '811490',
      label: 'Other Personal and Household Goods Repair and Maintenance'
    },
    { value: '812111', label: 'Barber Shops' },
    { value: '812112', label: 'Beauty Salons' },
    { value: '812113', label: 'Nail Salons' },
    { value: '812191', label: 'Diet and Weight Reducing Centers' },
    { value: '812199', label: 'Other Personal Care Services' },
    { value: '812210', label: 'Funeral Homes and Funeral Services' },
    { value: '812220', label: 'Cemeteries and Crematories' },
    { value: '812310', label: 'Coin-Operated Laundries and Drycleaners' },
    {
      value: '812320',
      label: 'Drycleaning and Laundry Services (except Coin-Operated)'
    },
    { value: '812331', label: 'Linen Supply' },
    { value: '812332', label: 'Industrial Launderers' },
    { value: '812910', label: 'Pet Care (except Veterinary) Services' },
    { value: '812921', label: 'Photofinishing Laboratories (except One-Hour)' },
    { value: '812922', label: 'One-Hour Photofinishing' },
    { value: '812930', label: 'Parking Lots and Garages' },
    { value: '812990', label: 'All Other Personal Services' },
    { value: '813110', label: 'Religious Organizations' },
    { value: '813211', label: 'Grantmaking Foundations' },
    { value: '813212', label: 'Voluntary Health Organizations' },
    { value: '813219', label: 'Other Grantmaking and Giving Services' },
    { value: '813311', label: 'Human Rights Organizations' },
    {
      value: '813312',
      label: 'Environment, Conservation and Wildlife Organizations'
    },
    { value: '813319', label: 'Other Social Advocacy Organizations' },
    { value: '813410', label: 'Civic and Social Organizations' },
    { value: '813910', label: 'Business Associations' },
    { value: '813920', label: 'Professional Organizations' },
    { value: '813930', label: 'Labor Unions and Similar Labor Organizations' },
    { value: '813940', label: 'Political Organizations' },
    {
      value: '813990',
      label:
        'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)'
    },
    { value: '814110', label: 'Private Households' },
    { value: '921110', label: 'Executive Offices' },
    { value: '921120', label: 'Legislative Bodies' },
    { value: '921130', label: 'Public Finance Activities' },
    { value: '921140', label: 'Executive and Legislative Offices, Combined' },
    {
      value: '921150',
      label: 'American Indian and Alaska Native Tribal Governments'
    },
    { value: '921190', label: 'Other General Government Support' },
    { value: '922110', label: 'Courts' },
    { value: '922120', label: 'Police Protection' },
    { value: '922130', label: 'Legal Counsel and Prosecution' },
    { value: '922140', label: 'Correctional Institutions' },
    { value: '922150', label: 'Parole Offices and Probation Offices' },
    { value: '922160', label: 'Fire Protection' },
    {
      value: '922190',
      label: 'Other Justice, Public Order, and Safety Activities'
    },
    { value: '923110', label: 'Administration of Education Programs' },
    { value: '923120', label: 'Administration of Public Health Programs' },
    {
      value: '923130',
      label:
        "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
    },
    { value: '923140', label: "Administration of Veterans' Affairs" },
    {
      value: '924110',
      label:
        'Administration of Air and Water Resource and Solid Waste Management Programs'
    },
    { value: '924120', label: 'Administration of Conservation Programs' },
    { value: '925110', label: 'Administration of Housing Programs' },
    {
      value: '925120',
      label:
        'Administration of Urban Planning and Community and Rural Development'
    },
    { value: '926110', label: 'Administration of General Economic Programs' },
    {
      value: '926120',
      label: 'Regulation and Administration of Transportation Programs'
    },
    {
      value: '926130',
      label:
        'Regulation and Administration of Communications, Electric, Gas, and Other Utilities'
    },
    {
      value: '926140',
      label: 'Regulation of Agricultural Marketing and Commodities'
    },
    {
      value: '926150',
      label:
        'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors'
    },
    { value: '927110', label: 'Space Research and Technology' },
    { value: '928110', label: 'National Security' },
    { value: '928120', label: 'International Affairs' }
  ];

export const basePermission = {
    "free": {
        "videos_perm": {codename: "videos_perm"},
        "my_business_perm": {codename: "my_business_perm"},
        "change_password_perm": {codename: "change_password_perm"},
    },
    "non-free": {
        "association_perm": {codename: "association_perm" },
        "small_business_perm": {codename: "small_business_perm" },
        "primes_perm": {codename: "primes_perm"},
        "my_business_perm": {codename: "my_business_perm"},
        "my_profile_perm": {codename: "my_profile_perm"},
        "change_password_perm": {codename: "change_password_perm"},
        "one_way_contact_perm": {codename: "one_way_contact_perm"},
        "custom_dashboard_perm": {codename: "custom_dashboard_perm"},
        "announcements_perm": {codename: "announcements_perm"},
        "industry_news_perm": {codename: "industry_news_perm"},
        "partial_form_perm": {codename: "partial_form_perm"},
        "videos_perm": {codename: "videos_perm"},
    }
};

export const dueDiligenceOptions = {
    VERIFIED_CLEAR: 'Verified - Clear',
    VERIFIED_RECORD_FOUND: 'Verified - Record Found',
    NOT_VERIFIED: 'Not Verified'
};