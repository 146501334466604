import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Input,
  Image,
  Space,
  Typography,
  DatePicker
} from 'antd';
import { connect, useSelector } from 'react-redux';
import { hideModal } from '../../redux/actions/ui';
import Address from '../common/Form/FormInputs/Address';
import { createCustomerProfile } from '../../api/payments';
import { EditUser } from '../../api/addUsers';
import { fetchPermissions } from '../../redux/actions/auth';
import { MAKE_PAYMENT_ACTION } from '../../redux/actions/payments';
import { objectToFormData } from '../../utils/utils';
import valid from 'card-validator';
import brandLogo from '../../assets/images/brand-logo-light-no-tagline1.png';
import { getcurrentDateTime } from '../../utils/dateTimeHelper';
import dayjs from 'dayjs';

const PaymentForm = ({ currentStep, handleStep, hideModal, data }) => {
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const loginData = useSelector(state => state.auth);

  useEffect(() => {
    if (data) {
      setFormValues(prev => ({ ...prev, ...data }));
    }
  }, [data]);

  useEffect(() => {
    if (
      loginData?.user?.customer_profile_id &&
      loginData?.user?.customer_payment_id &&
      loginData?.user?.customer_address_id
    ) {
      handleStep(currentStep + 1);
    }
  }, []);

  const handleChange = e => {
    setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleDate = value => {
    setFormValues(prev => ({ ...prev, expiration_date: value }));
  };

  const handleAddress = (k, data) => {
    setFormValues(prev => ({ ...prev, address: data.address }));
  };

  const handleSubmit = async () => {
    let { city, zipcode, county, state, street } = formValues?.address;

    // removing address field from payload as destructured above
    let payload = {
      ...formValues,
      city,
      zip: zipcode,
      country: county,
      state,
      address: street,
      expiration_date: dayjs(formValues.expiration_date).format('YYYY-MM'),
      object_type:
        formValues.type ||
        (loginData?.role === 'prime'
          ? 'Primes'
          : loginData?.role === 'small-business'
          ? 'SmallBusiness'
          : 'Association'),
      object_id: formValues.company_id || loginData?.user?.businessId,
      email: formValues.email || loginData?.user?.email
    };
    payload['user_id'] = payload.id || loginData?.user?.id;
    payload['card_number'] = payload['card_number'].trim();
    Object.keys(payload).forEach(key => {
      if (
        [
          'role',
          'type',
          'company_id',
          'id',
          'company_name',
          'password'
        ].includes(key) ||
        !payload[key]
      ) {
        delete payload[key];
      }
    });

    const currentDate = getcurrentDateTime();
    const startDate = currentDate.add(7, 'days').format('YYYY-MM-DD');
    payload['startDate'] = startDate;
    console.log('payload', payload);
    let customerProfileId, customerPaymentProfileId, customerShippingAddressId;
    const res_1 = await createCustomerProfile(
      'profile_id',
      objectToFormData(payload)
    );
    customerProfileId = res_1.data?.success ? res_1.data?.data : null;
    if (customerProfileId) {
      let res_2 = await createCustomerProfile(
        'payment_id',
        objectToFormData({ ...payload, customerProfileId })
      );
      customerPaymentProfileId = res_2.data?.success ? res_2.data?.data : null;
      let res_3 = await createCustomerProfile(
        'shipping_id',
        objectToFormData({
          ...payload,
          customerProfileId
        })
      );
      customerShippingAddressId = res_3.data?.success
        ? res_3.data?.customer_address_id
        : null;
    }
    console.log(
      customerProfileId,
      customerPaymentProfileId,
      customerShippingAddressId
    );
    if (
      customerProfileId &&
      customerPaymentProfileId &&
      customerShippingAddressId
    ) {
      const userUpdatePayload = {
        customer_profile_id: customerProfileId,
        customer_payment_id: customerPaymentProfileId,
        customer_address_id: customerShippingAddressId
      };
      await EditUser(
        objectToFormData(userUpdatePayload),
        payload.id || loginData?.user?.id
      );
      handleStep(currentStep + 1);
      //   return;
      // } else {
      //   handleNotifications('error', 'BZ Connects', res_4.data?.message_text);
      // }
    }
  };

  const submitFail = errors => {
    console.log(errors);
  };

  return (
    <React.Fragment>
      <div className="payment-form-container">
        <header>
          <span>
            <Image
              src={brandLogo}
              preview={false}
              width="180px"
              height="70px"
            />
          </span>
          <Typography.Title level={2}>Make Payment</Typography.Title>
        </header>
      </div>

      {(formValues?.package_type_id || formValues.quantity) && (
        <Form
          form={form}
          name="add-small-business"
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          onFinish={handleSubmit}
          onFinishFailed={submitFail}
          initialValues={{ ...formValues }}
        >
          <Form.Item
            key="firstName"
            name="firstName"
            label="First Name"
            hasFeedback
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input
              name="first_name"
              placeholder="First Name"
              type="text"
              style={{
                border: '1px solid gray',
                marginBottom: '0px'
              }}
              bordered={false}
              onChange={e => {
                e.persist();
                handleChange(e);
              }}
            />
          </Form.Item>

          <Form.Item
            key="lastName"
            name="lastName"
            label="Last Name"
            hasFeedback
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: 'Last Name is required' }]}
          >
            <Input
              name="last_name"
              placeholder="Last Name"
              type="text"
              style={{
                border: '1px solid gray',
                marginBottom: '0px'
              }}
              bordered={false}
              onChange={e => {
                e.persist();
                handleChange(e);
              }}
            />
          </Form.Item>

          <Form.Item
            key="address"
            name="address"
            label="Address"
            hasFeedback
            required
            validateTrigger={['onBlur']}
            rules={[
              {
                validator: async () => {
                  if (!formValues['address']) {
                    return Promise.reject(
                      new Error(
                        'Please input details and Save or delete this field.'
                      )
                    );
                  }
                }
              }
            ]}
          >
            <Address
              valueProps={formValues['address']}
              parentHandler={handleAddress}
              hasCity={true}
              hasZip={true}
            />
          </Form.Item>

          <Form.Item
            key="amount"
            name="amount"
            label="Amount"
            hasFeedback
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: 'Amount is required' }]}
          >
            <Input
              name="amount"
              placeholder="100"
              type="number"
              value={formValues.amount}
              disabled
              style={{
                border: '1px solid gray',
                marginBottom: '0px'
              }}
              bordered={true}
              addonBefore={'$'}
              onChange={e => {
                e.persist();
                handleChange(e);
              }}
            />
          </Form.Item>

          <Form.Item
            key="cardNumber"
            name="cardNumber"
            label="Card Number"
            hasFeedback
            required
            validateTrigger={['onBlur']}
            rules={[
              {
                validator: async () => {
                  let numberValidation = valid.number(
                    formValues['card_number']
                  );
                  if (
                    !(
                      numberValidation.isPotentiallyValid &&
                      numberValidation.card
                    )
                  ) {
                    return Promise.reject(
                      new Error('Please enter correct Card Number.')
                    );
                  }
                }
              }
            ]}
          >
            <Input
              name="card_number"
              placeholder="xxxx-xxxx-xxxx-xxxx"
              type="text"
              style={{
                border: '1px solid gray',
                marginBottom: '0px'
              }}
              maxLength={18}
              bordered={true}
              onChange={e => {
                e.persist();
                handleChange(e);
              }}
            />
          </Form.Item>

          <Form.Item
            key="expiration_date"
            name="expiration_date"
            label="Expiration Date"
            hasFeedback
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: 'Expiration Date is required' }]}
          >
            <DatePicker
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                width: '100%'
              }}
              name="expiration_date"
              defaultValue={formValues.expiration_date}
              onChange={date => {
                handleDate(date);
              }}
              format={'YYYY/MM'}
              picker="month"
            />
          </Form.Item>

          <Form.Item
            key="cardCode"
            name="cardCode"
            label="Card Code"
            hasFeedback
            validateTrigger={['onBlur']}
            rules={[{ required: true, message: 'Card Code is required' }]}
          >
            <Input
              name="card_code"
              placeholder="xxx"
              type="text"
              maxLength={3}
              style={{
                border: '1px solid gray',
                marginBottom: '0px'
              }}
              bordered={true}
              onChange={e => {
                e.persist();
                handleChange(e);
              }}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 6, span: 12 }}
            style={{ textAlign: 'center' }}
          >
            <Space direction="horizontal" align="center">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  hideModal();
                  handleStep && handleStep(0);
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </React.Fragment>
  );
};

PaymentForm.propTypes = {
  handleStep: PropTypes.func.isRequired,
  MAKE_PAYMENT_ACTION: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired
};

export default connect(null, {
  hideModal,
  fetchPermissions,
  MAKE_PAYMENT_ACTION
})(PaymentForm);
