import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select, Row, Col } from 'antd';

import { emailPattern } from '../../utils/constants';
import { onRegister } from '../../redux/actions/auth';
import { handleNotifications } from '../../utils/notifications';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Option } = Select;

const RegisterForm = props => {
  if (props.path) {
    setTimeout(() => {
      return <Redirect to={props.path} />;
    }, 1000);
  }

  const onFinish = values => {
    props.onRegister(values);
  };

  const onFail = () => {
    handleNotifications(
      'error',
      'Invalid form',
      'Some fields may be missing or incorrect.'
    );
  };

  return (
    <Form
      layout="vertical"
      name="register-form"
      onFinish={onFinish}
      onFinishFailed={onFail}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={[8]}>
        <Col md={12} sm={24}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'First Name is required' }]}
            hasFeedback
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Last Name is required' }]}
            hasFeedback
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Email is required.' },
          {
            pattern: emailPattern,
            message: 'Please enter a valid email.'
          }
        ]}
        hasFeedback
      >
        <Input placeholder="Email" type="email" />
      </Form.Item>
      <Form.Item
        name="companyName"
        label="Company Name"
        rules={[{ required: true, message: 'Company Name is required.' }]}
        hasFeedback
      >
        <Input placeholder="Company Name" type="text" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Password is required' },
          { min: 6, message: 'Password too short' }
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords do not match!'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>
      <Form.Item
        name="accountType"
        label="Account Type"
        rules={[{ required: true, message: 'Account Type is required.' }]}
      >
        <Select placeholder="Select account type">
          <Option key="prime" value="prime">
            Corporation
          </Option>
          <Option key="small-business" value="small-business">
            Small Business
          </Option>
          <Option key="association" value="association">
            Association
          </Option>
        </Select>
      </Form.Item>

      <div id="submit-container">
        <Form.Item>
          <Link to="/">Already registered? Login</Link>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <ArrowRightOutlined />
          </Button>
        </Form.Item>
      </div>

    </Form>
  );
};

const mapStateToProps = state => ({
  path: state.redirect.path
});

const mapDispatchToProps = dispatch => ({
  onRegister: payload => dispatch(onRegister(payload))
});

RegisterForm.propTypes = {
  onRegister: PropTypes.func,
  path: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
