import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, string, number } from 'prop-types';
import { Button, Card, Col, Form, Input, Row, Typography, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { addField, updateField } from '../../api/smallBusinessFields';
import { onUpdateSmallBusiness } from '../../redux/actions/smallBusiness';
import { handleNotifications } from '../../utils/notifications';
import { debounce, objectToFormData } from '../../utils/utils';
import Address from '../../components/common/Form/FormInputs/Address';
import Dropdowns from '../../components/common/Form/FormInputs/Dropdowns';
import { phoneUSPattern, websiteUrlPattern } from '../../utils/constants';
import { hideLoading } from '../../redux/actions/ui';

const { Title } = Typography;

const SmallBusinessPartial = ({ updateSmallBusiness, details, businessId }) => {
  useEffect(() => {
    if (details) {
      getFormValuesFromDetails(details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(null);

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      address,
      industry_specialization: industrySpecialization
    } = details;

    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['address'],
        state: item['state'],
        county: item['county'],
        zipcode: item['zip_code'],
        city: item['city']
      }));
    }
    if (industrySpecialization?.length) {
      newFormValues['industry_specialization'] = [...industrySpecialization];
    }

    setFormValues({
      ...newFormValues,
      company_name: companyName,
      phone,
      company_website: companyWebsite
    });
  };

  const inputChangedHandler = (key, value) => {
    if (key === 'revenue') {
      setFormValues({
        ...formValues,
        [key]: { ...formValues[key], id: value }
      });
    } else {
      setFormValues({ ...formValues, [key]: value });
    }
  };

  const debouncedInput = debounce((e, key) => {
    if (key === 'other_demographic') {
      inputChangedHandler('owner_demographic', e.target.value);
      return;
    }
    inputChangedHandler(key, e.target.value);
  }, 500);

  const microApiResponseHandler = (key, value, index) => {
    let cert = formValues[key] ? [...formValues[key]] : [];
    cert[index] = value;
    setFormValues({ ...formValues, [key]: cert });
  };

  const submit = () => {
    let payload = {};
    for (let key in formValues) {
      if (formValues[key]) {
        if (Array.isArray(formValues[key]) && !formValues[key]?.length)
          continue;

        if (['address'].includes(key)) {
          payload[key] = JSON.stringify(
            formValues[key].map(item => item.id || item)
          );
        } else if (['industry_specialization'].includes(key)) {
          payload[key] = JSON.stringify(formValues[key]);
        } else {
          payload[key] = formValues[key];
        }
      }
    }
    console.log(payload);
    updateSmallBusiness(businessId, objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
    handleNotifications(
      'warning',
      'Warning',
      'Some fields require your attention.'
    );
  };

  return (
    <Card>
      {formValues ? (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Title level={2} className="heading-title">
              Small Business Profile
            </Title>
            <Form
              form={form}
              name="add-small-business"
              layout="horizontal"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              labelAlign="left"
              onFinish={submit}
              validateTrigger={['']}
              onFinishFailed={submitFail}
              initialValues={{ ...formValues }}
            >
              {/* Business Name */}
              <Form.Item
                key="company_name"
                name="company_name"
                label="Business Name"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company name is required.'
                  }
                ]}
              >
                <Input
                  name="company_name"
                  placeholder="Business Name"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  type="text"
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_name');
                  }}
                />
              </Form.Item>

              {/* Business Phone */}
              <Form.Item
                key="phone"
                name="phone"
                label="Business Phone"
                required={true}
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    validator: async (_, phone) => {
                      if (!phone) {
                        return Promise.reject(
                          new Error('Phone Number is required.')
                        );
                      }
                    }
                  },
                  {
                    pattern: phoneUSPattern,
                    message: 'Please enter a valid phone number.'
                  }
                ]}
              >
                <Input
                  name="phone"
                  placeholder="Business Phone (10-digit)"
                  type="tel"
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  maxLength={10}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'phone');
                  }}
                />
              </Form.Item>

              {/* Website */}
              <Form.Item
                key="company_website"
                name="company_website"
                label="Website"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company Website is required.'
                  },
                  {
                    pattern: websiteUrlPattern,
                    message: 'Please enter a valid web url.'
                  }
                ]}
              >
                <Input
                  name="company_website"
                  placeholder="Company Website"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_website');
                  }}
                />
              </Form.Item>

              {/* Business Addresses */}
              <Form.List
                name="address"
                rules={[
                  {
                    validator: async (_, address) => {
                      if (!address || address.length < 1) {
                        return Promise.reject(
                          new Error('At least 1 address is required.')
                        );
                      }
                    }
                  }
                ]}
              >
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{ offset: index === 0 ? 0 : 4, span: 20 }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        required={true}
                        key={field.key}
                        label={index === 0 ? 'Business Address' : ''}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              validator: async (_, address) => {
                                if (!formValues['address'][index]) {
                                  return Promise.reject(
                                    new Error(
                                      'Please input details and Save or delete this field.'
                                    )
                                  );
                                }
                              }
                            }
                          ]}
                          noStyle
                        >
                          <Address
                            keyName="address"
                            microApi={{ add: addField, update: updateField }}
                            parentHandler={microApiResponseHandler}
                            hasCity={true}
                            hasZip={true}
                            index={index}
                            valueProps={formValues['address']?.[index]}
                          />
                        </Form.Item>

                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '3px',
                            marginTop: '0px',
                            backgroundColor: '#D26A31',
                            color: 'white'
                          }}
                          onClick={() => {
                            formValues['address'].splice(index, 1);
                            return form.setFieldsValue({
                              address: formValues?.['address']
                            });
                            // return remove(field.name);
                          }}
                        >
                          Remove
                        </Button>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '0px',
                        paddingLeft: '7px'
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          style={{
                            float: 'left'
                          }}
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Address
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Company Specializations */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Company Specialization:
                </div>
              </React.Fragment>
              <Dropdowns
                keyName="specializations"
                label="specializations"
                multiSelect={true}
                valueProps={formValues['industry_specialization']}
                parentHandler={(key, value) => {
                  inputChangedHandler('industry_specialization', [
                    ...value[key]
                  ]);
                }}
              />

              {/* Action Buttons */}
              <Form.Item
                wrapperCol={{ offset: 6, span: 12 }}
                style={{ textAlign: 'center', marginTop: 10 }}
              >
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  businessId: state.auth?.user?.businessId
});

const mapDispatchToProps = dispatch => ({
  updateSmallBusiness: (smallBusinessId, payload) =>
    dispatch(onUpdateSmallBusiness(smallBusinessId, payload)),
  onHideLoading: dispatch(hideLoading())
});

SmallBusinessPartial.propTypes = {
  role: string,
  businessId: number,
  updateSmallBusiness: func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SmallBusinessPartial));
