import { getUser } from '../../utils/checkLogin';
import { setToLocalStorage } from '../../utils/localStorage';
import connection from '../../utils/api';
import * as actionTypes from '../actionTypes';
import { hideLoading, showLoading } from './ui';
import { handleNotifications } from '../../utils/notifications';

export const MAKE_PAYMENT_ACTION = exp_date => {
  let user = getUser();
  if(user){
    user['account_expiry_date'] = exp_date;
    setToLocalStorage('user', user);
  }
  return {
    type: actionTypes.PAYMENT_SUCCESSFUL,
    payload: user ? exp_date : null
  }
};

export const submitContactUsDetails = (payload, cb) => dispatch => {
  dispatch(showLoading());
  connection.post('platinum-package-enquiry', payload)
  .then(()=>{
    dispatch(hideLoading());
    handleNotifications('success', 'Details submitted successfully.', '');
    cb && cb(true);
  })
  .catch(err=>{
    console.error(err)
    dispatch(hideLoading());
    handleNotifications('error', 'Error!', err.message);
    cb && cb();
  })
};
