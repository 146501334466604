import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';

// import SmallBusiness from '../../components/common/Views/SmallBusiness';
import SmallBusinessPrintView from '../../components/customForms/SmallBusinessPrintView';
import PrintReady from '../../containers/PrintReady';

class View extends Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <ReactToPrint
          trigger={() => {
            // eslint-disable-next-line
            return <a href="#">Print</a>;
          }}
          content={() => this.componentRef}
        />
        <PrintReady ref={el => (this.componentRef = el)}>
          <SmallBusinessPrintView />
        </PrintReady>
      </div>
    );
  }
}

export default View;
