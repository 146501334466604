import { COMMENTS_REFRESH } from '../actionTypes';

const initialState = { refresh: true };

export const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_REFRESH:
      return { ...state, refresh: action.payload };
    default:
      return { ...state };
  }
};
