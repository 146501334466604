import { connect } from 'react-redux';
import connection from '../utils/api';

export const makePayment = (url, payload) => connection.post(url, payload);
export const createCustomerProfile = (param, payload) =>
  connection.post(`customer-profile?req_type=${param}`, payload);
export const createSubscription = payload =>
  connection.post('subscription', payload);
// export const fetchUserPaymentInfo = id => connection.get(`fetch-user-payment-info/${id}`)
export const fetchPackages = () => connection.get(`packages`);
export const fetchFeatures = () => connection.get(`features`);
