import React, { useEffect, useState } from 'react';
import { any, bool, func, string } from 'prop-types';
import { Select } from 'antd';

import { getDropdownOptions } from '../../../../api/formOptions';

const { Option } = Select;

const Dropdowns = ({
  keyName,
  label,
  useKey,
  multiSelect,
  valueProps,
  disabled,
  parentHandler,
  dropDownOptions
}) => {
  const [options, setOptions] = useState(null);
  useEffect(() => {
    if(dropDownOptions){
      return setOptions(dropDownOptions);
    }
    if (keyName) {
      getDropdownOptions(keyName)
        .then(({ data }) => {
          if (data['success']) {
            setOptions(
              data['data'][keyName].map(item => {
                const node = {};
                for (const [key, value] of Object.entries(item)) {
                  node['key'] = key;
                  node['value'] = value;
                }
                return node;
              })
            );
          } else {
            setOptions([]);
          }
        })
        .catch(error => {
          setOptions([]);
          console.log(error);
        });
    }
  }, [keyName, dropDownOptions]);

  const currentValueChangedHandler = selectedValue => {
    if (!multiSelect) {
      parentHandler(keyName, {
        [keyName]: selectedValue
      });
    } else {
      parentHandler(keyName, { [keyName]: selectedValue });
    }
  };
  return (
    <>
      {options ? (
        <Select
          placeholder={`Select ${label}`}
          onChange={currentValueChangedHandler}
          style={{ width: '100%' }}
          mode={multiSelect ? 'multiple' : null}
          defaultValue={valueProps}
          // value={valueProps}
          disabled={disabled}
        >
          {options.map(item => (
            <Option key={item['key']} value={useKey ? item['key'] : item['value']}>
              {item['value']}
            </Option>
          ))}
        </Select>
      ) : null}
    </>
  );
};

Dropdowns.propTypes = {
  keyName: string,
  label: string,
  value: any,
  multiSelect: bool,
  withInput: bool,
  parentHandler: func,
  disabled: bool,
  valueProps: string
};

export default Dropdowns;
