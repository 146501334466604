import React, { Component } from 'react';
import { element } from 'prop-types';

// const a4 = { width: '21cm', heigth: '29.7cm', margin: '30mm 45mm 30mm 45mm' };

class PrintReady extends Component {
  render() {
    return (
      <div style={{ margin: 'auto' }}>
        <div
          style={{
            // width: '21cm',
            // height: '29.7cm',
            // padding: '30mm 45mm 30mm 45mm',
            padding: '10mm 15mm 10mm 15mm',
            // border: '1px solid black',
            backgroundColor: 'white'
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

PrintReady.propTypes = {
  children: element
};

export default PrintReady;
