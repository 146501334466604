import React from 'react';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';

import PluggableForm from '../../components/common/Form/PluggableForm';
import { onAddEvent } from '../../redux/actions/calendar';
import { objectToFormData } from '../../utils/utils';
// import dayjs from 'dayjs';

const Add = ({ config, onAdd }) => {
  const onSubmit = values => {
    // console.log(values)
    let payload = {};
    payload = Object.keys(values).reduce(
      (accumulator, item) => ({ ...accumulator, ...values[item] }),
      {}
    );
    payload = {
      ...payload,
      date_start: payload.date_start || payload.date,
      date_end: payload.date_end || payload.date
    };
    delete payload.date;
    onAdd(objectToFormData(payload));
  };

  return (
    <>
      <h1 className="heading-title">Add Event Page</h1>
      <PluggableForm
        config={config}
        formConfig={{ layout: 'vertical', name: 'add-event' }}
        onFinish={onSubmit}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onAdd: payload => dispatch(onAddEvent(payload))
});

Add.propTypes = { config: array, onAdd: func };

export default connect(null, mapDispatchToProps)(Add);
