import connection from '../utils/api';

/* POST Create Prime */
export const addPrime = primePayload =>
  connection.post(`primes/manage`, primePayload);

/* GET List/Fetch Primes */
export const getPrimes = ({ page, limit, filters }) => {
  let queryString = '';
  const queryParams = [];

  if (page) {
    queryParams.push(`page=${page}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }
  if (filters?.length) {
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  }
  if (queryParams?.length) {
    queryString = `?` + queryParams.join('&');
  }

  return connection.get(`primes/manage${queryString}`);
};

/* GET Get Prime Details */
export const getPrime = primeId => connection.get(`primes/manage/${primeId}`);

/* PUT Update a Prime */
export const updatePrime = (primeId, primePayload) =>
  connection.put(`primes/manage/${primeId}`, primePayload);

/* DELETE Delete a Prime*/
export const deletePrime = primeId =>
  connection.delete(`primes/manage/${primeId}`);

/* POST Import Primes */
export const importPrimes = payload =>
  connection.post(`primes/import`, payload);

/* GET Export Primes */
export const exportPrimes = payload => connection.get(`primes/export`);
