import React from 'react';
import { Tabs } from 'antd';
import VideoList from './VideoList'
import VerificationSteps from './VerificationSteps';
import UserAgreement from './UserAgreement';
import ModalComponent from '../../components/ui/ModalComponent';
import { Route, Switch } from 'react-router-dom';
import VerificationStepCreate from './VerificationStepCreate';
import VerificationStepUpdate from './VerificationStepUpdate';
import VerificationStepView from './VerificationStepView';
import UserAgreementCreate from './UserAgreementCreate';
import UserAgreementUpdate from './UserAgreementUpdate';
import UserAgreementView from './UserAgreementView';
import VideoCreate from './VideoCreate';

const { TabPane } = Tabs;

const SettingsContainer = ({ match }) => {
  return (
    <div style={{ padding: 10 }}>
      <ModalComponent size="sm">
        <Switch>
          {/* Verification Steps Routes */}
          <Route exact path={`${match.path}/verification-step-create`} component={VerificationStepCreate} />
          <Route path={`${match.path}/verification-step-preview/:stepId`} component={VerificationStepView} />
          <Route path={`${match.path}/verification-step-edit/:stepId`} component={VerificationStepUpdate} />

          {/* Videos Routes */}
          <Route exact path={`${match.path}/video-create`} component={VideoCreate} />
          <Route exact path={`${match.path}/video-edit/:id`} component={VideoCreate} />

          {/* User Agreement Routes */}
          <Route exact path={`${match.path}/user-agreement-create`} component={UserAgreementCreate} />
          <Route path={`${match.path}/user-agreement-edit/:agreementId`} component={UserAgreementUpdate} />
          <Route path={`${match.path}/user-agreement-preview/:agreementId`} component={UserAgreementView} />
        </Switch>
      </ModalComponent>
      <Tabs defaultActiveKey="1">
        <TabPane tab={<span>User Agreements</span>} key="1">
          <UserAgreement />
        </TabPane>
        <TabPane tab={<span>Verification Steps</span>} key="2">
          <VerificationSteps />
        </TabPane>
        <TabPane tab={<span>Videos</span>} key="3">
          <VideoList />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SettingsContainer;
