import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PageLayout from './containers/PageLayout';
import RoutesComponent from './routes/RoutesComponent.js';
import Login from './routes/Login/Login';
import Register from './routes/Register/Register';
import ChangePassword from './routes/ChangePassword/ChangePassword';

import ForgotPassword from './routes/FogotPassword/ForgotPassword';
// import UpdatePasswordForm from './components/auth/UpdatePasswordForm';

const App = () => (
  <BrowserRouter>
    <PageLayout>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/update-password" component={ChangePassword} />
        <RoutesComponent />
      </Switch>
    </PageLayout>
  </BrowserRouter>
);

export default App;
