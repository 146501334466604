import * as actionTypes from '../actionTypes';

const initialState = {
  loading: false,
  modalTitle: '',
  showSidebar: false,
  showModal: false
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UI_LOADING_SHOW:
      return { ...state, loading: true };
    case actionTypes.UI_LOADING_HIDE:
      return { ...state, loading: false };
    case actionTypes.UI_SHOW_MODAL:
      return { ...state, showModal: true };
    case actionTypes.UI_HIDE_MODAL:
      return { ...state, showModal: false };
    case actionTypes.UI_SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case actionTypes.UI_SHOW_SIDEBAR:
      return { ...state, showSidebar: action.payload };
    default:
      return { ...state };
  }
};
