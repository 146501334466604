import React from 'react';
import { Button, Image } from 'antd';
import { Link } from 'react-router-dom';
import ErrorPic from '../../assets/images/404.png';

const NotFound = () => {
  return (
    <div className="err-content">
      <div className="grey-bg not-found">
        <div className="row">
          <div className="col-md-12">
            <div
              style={{
                textAlign: 'center',
                paddingTop: 5,
                paddingBottom: 5
              }}
            >
              <div className="top-icon">
                <Image
                  preview={false}
                  src={ErrorPic}
                  alt="alt-img"
                  width="500px"
                  height="200px"
                />
              </div>
              <div className="oops">
                <h2 className="oops-title">Ooops! Page not found</h2>
                <b>Unfortunately, This document is not available !</b>
                <p className="oops-details">
                  The page you are looking for might have moved, been renamed or
                  perhaps never existed.
                </p>
                <Link to="/dashboard">
                  <Button>Back Home</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
