import connection from '../utils/api';

export const getMessageCenters = ({ range, filters,  search }) => {
  let queryString = '';
  const queryParams = [];

  if (filters?.length) {
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  }
  if (range?.start_date && range?.end_date) {
    queryParams.push(
      `start_date=${range.start_date}&end_date=${range.end_date}`
    );
  }
  if(search){
    queryParams.push(search);
  }
  if (queryParams.length) {
    queryString = `?` + queryParams.join('&');
  }

  return connection.get(`message-center/manage${queryString}`);
};

export const createMessageCenter = (data) => connection.post('message-center/create', data);
export const updateMessageCenter = (id, data) => connection.put(`message-center/manage/${id}`, data);
export const getMessageCenter = (id) => connection.get(`message-center/manage/${id}`);
export const deleteMessageCenter = (id) => connection.delete(`message-center/manage/${id}`);

export const findMessageCenter = (id, type) => connection.get(`super-chat/${id}?company_type=${type}`);
export const createAndSend = (data) => connection.post('super-chat', data);

export const getMessagingEntities = () => connection.get('messaging-entities');

export const getConversation = (id, page) => connection.get(`/messages/manage/${id}?page=${page}`);
export const sendMessage = (data) => connection.post('/messages/create', data);
export const deleteMessage = (id) => connection.delete(`/messages/manage/${id}`);