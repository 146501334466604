import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import dayjs from 'dayjs';
import { Button, Row, Space } from 'antd';

import { onImportEvents } from '../../redux/actions/calendar';
import FileUpload from '../../components/common/Form/FormInputs/FileUpload';

const Import = ({ onImport }) => {
  const [upload, setUpload] = useState(null);

  const fileSelectHandler = (key, value) => {
    setUpload(value['import_events']);
  };

  const uploadFile = () => {
    const payload = {};
    payload['time_offset'] = dayjs().utcOffset();
    payload['event_file'] = upload;

    const formData = new FormData();
    formData.append('event_file', payload['event_file']);
    formData.append('time_offset', payload['time_offset']);

    onImport(formData);
  };

  return (
    <>
      <Row justify="center">
        <h2>Import Events</h2>
      </Row>
      <Row justify="center">
        <Space direction="vertical" align="center">
          <FileUpload
            keyName="import_events"
            label="Import Events"
            parentHandler={fileSelectHandler}
          />
          <Button type="primary" onClick={uploadFile} disabled={!upload}>
            Import
          </Button>
        </Space>
      </Row>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onImport: payload => dispatch(onImportEvents(payload))
});

Import.propTypes = {
  onImport: func
};

export default connect(null, mapDispatchToProps)(Import);
