import { 
  addContact,
  deleteContact
} from '../../api/contact';
import { handleNotifications } from '../../utils/notifications';
import { 
  SET_CONTACT_REQUESTS,
  ADD_CONTACT_REQUEST,
  UPDATE_CONTACT_REQUESTS,
  FILTER_CONTACT_REQUESTS,
} from '../actionTypes';
import { redirect } from './redirect';
import { hideLoading, hideModal, showLoading } from './ui';

export const setContactRequests = payload => dispatch => {
  dispatch({ type: SET_CONTACT_REQUESTS, payload });
};

const addContactRequest = payload => dispatch => {
  dispatch({ type: ADD_CONTACT_REQUEST, payload });
};

const updateContactRequests = payload => dispatch => {
  dispatch({ type: UPDATE_CONTACT_REQUESTS, payload });
};

export const onAddContact = payload => dispatch => {
  dispatch(showLoading());
  addContact(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if(data.success) {
        dispatch(addContactRequest([data.data]));
        handleNotifications('success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/contact'));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
    });
};

export const onUpdateContact = payload => dispatch => {
  dispatch(showLoading);
  addContact(payload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(updateContactRequests([data.data]));
        dispatch(hideModal());
        dispatch(redirect('/user-agreement'));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteContact = contactId => dispatch => {
  dispatch(showLoading());
  deleteContact(contactId)
    .then(({data}) => {
      dispatch(hideLoading());
      if(data.success){
        dispatch({ type: FILTER_CONTACT_REQUESTS, contactId });
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/contact'));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
    });
}