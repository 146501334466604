import React, { useState, useEffect } from 'react';
import { ShoppingFilled, StarFilled } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { showModal } from '../../redux/actions/ui';
import { connect } from 'react-redux';
import { Button, Card, Row, Col, Layout } from 'antd';
import {
  lookAhead,
  rfprfq,
  recent_bookmarks
} from '../../components/configs/tables/dashboard.json';
import { setActiveComponents } from '../../redux/actions/dashboard';
import ModalComponent from '../../components/ui/ModalComponent';
import TableComponent from '../../components/ui/TableComponent';
const { Content } = Layout;

const DashboardSettings = ({ showModal, setActiveComponents }) => {
  const [currentTableProps /*setCurrentTableProps */] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState('');
  const [comps, setComps] = useState({
    active: [
      'RFQ/RFP View',
      'Calendar Events',
      'Present Month Look Ahead',
      'Welcome New Members'
    ],
    inactive: [
      'Comp 1',
      'Comp 2',
      'Comp 3',
      'Comp 4',
      'Comp 5',
      'Comp 6',
      'Comp 7'
    ]
  });

  useEffect(() => {
      setActiveComponents(comps.active);
      // eslint-disable-next-line
  }, [comps.active]);

  const reOrder = (source, dest, interchange) => {
    let s =
      source.split('un').length > 1
        ? parseInt(source.split('un')[1])
        : parseInt(source);
    let d =
      dest.split('un').length > 1
        ? parseInt(dest.split('un')[1])
        : parseInt(dest);
    if (interchange) {
      let src_arr = source.includes('un')
        ? [...comps.inactive]
        : [...comps.active];
      let dest_arr = dest.includes('un')
        ? [...comps.inactive]
        : [...comps.active];
      let src_key = source.includes('un') ? 'inactive' : 'active';
      let dest_key = dest.includes('un') ? 'inactive' : 'active';
      if (
        (comps.active.length === 4 && !dest.includes('un')) ||
        (comps.active.length === 1 && dest.includes('un'))
      )
        return;
      const [removed] = src_arr.splice(s, 1);
      dest_arr.splice(d, 0, removed);
      setComps({ [src_key]: src_arr, [dest_key]: dest_arr });
    } else {
      let arr = source.includes('un') ? [...comps.inactive] : [...comps.active];
      let key = source.includes('un') ? 'inactive' : 'active';
      const [removed] = arr.splice(s, 1);
      arr.splice(d, 0, removed);
      setComps(prev => ({ ...prev, [key]: arr }));
    }
  };

  const onDragEnd = result => {
    // dropped outside the list
    const { source, destination } = result;
    let interchange = false;
    if (
      !destination ||
      (source.index === destination.index &&
        source.droppableId === destination.droppableId)
    ) {
      return;
    }
    if (source.droppableId !== destination.droppableId) {
      interchange = true;
    }
    let start =
      source.droppableId === 'droppable'
        ? source.index.toString()
        : 'un' + source.index;
    let end =
      destination.droppableId === 'droppable'
        ? destination.index.toString()
        : 'un' + destination.index;

    reOrder(start, end, interchange);
  };

  const tableColumns = columns.map(item => {
    return { ...item };
  });

  const getColumns = type => {
    switch (type) {
      case 'RFQ/RFP View Due for the next 7 days':
        return rfprfq.columns;
      case 'Present Month Look Ahead':
        return lookAhead.columns;
      case 'Welcome New Members':
      case 'Recent Companies Viewed':
        return recent_bookmarks.columns;
      default:
        return [];
    }
  };

  const handleViewDetails = (e, item) => {
    e.stopPropagation();
    showModal();
    setColumns(getColumns(item));
    setTitle(item);
  };

  return (
    <Layout className="dashboard-container">
    <Content>
      <ModalComponent>
        <h3 style={{ textAlign: 'center' }}>{title}</h3>
        <TableComponent
          columns={tableColumns}
          tableData={[]}
          pagination={{
            position: ['bottomCenter'],
            current: currentTableProps.pagination.current,
            pageSize: currentTableProps.pagination.size,
            total: currentTableProps.pagination.total,
            filters: currentTableProps.filters,
            hideOnSinglePage: true
          }}
          // manageDataSource={manageDataSource}
        />
      </ModalComponent>
      <h2>Edit Dashboard</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <Row
              {...provided.droppableProps}
              className="active-comp-container"
              ref={provided.innerRef}
            >
              {comps.active?.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {provided => (
                    <Col
                      span={6}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="active-comp-card">
                        <Card className="active-comp-item">
                          <p>
                            {index + 1} : <StarFilled /> {item}
                          </p>
                          <Button
                            type="primary"
                            htmlType="button"
                            onClick={e => handleViewDetails(e, item)}
                          >
                            View Details
                          </Button>
                        </Card>
                      </div>
                    </Col>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
        <Droppable droppableId="unuseddroppable" direction="horizontal">
          {provided => (
            <>
              <p>In active Components</p>
              <div
                {...provided.droppableProps}
                className="active-comp-container"
                ref={provided.innerRef}
              >
                {comps.inactive?.map((item, index) => (
                  <Draggable
                    key={index}
                    draggableId={'un' + index.toString()}
                    // index={'un'+index}
                    index={index}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="unused-comp-card">
                          <Card className="comp-item">
                            <p>
                              {index + 1} : <ShoppingFilled /> {item}
                            </p>
                            <Button
                              type="primary"
                              htmlType="button"
                              onClick={e => handleViewDetails(e, item)}
                            >
                              View Details
                            </Button>
                          </Card>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </>
          )}
        </Droppable>
      </DragDropContext>
      </Content>
    </Layout>
  );
};

export default connect(null, { showModal, setActiveComponents })(DashboardSettings);
