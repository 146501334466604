import connection from '../utils/api';

/* POST CREATE Events API */
export const addEvent = eventPayload =>
  connection.post(`calendar/events`, eventPayload);

/* GET FETCH Events API, returns complete JSON of events for displaying on calendar. */
export const getEvents = ({ range, filters }) => {
  let queryString = '';
  const queryParams = [];

  if (filters?.length) {
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  }
  if (range?.start_date && range?.end_date) {
    queryParams.push(
      `start_date=${range.start_date}&end_date=${range.end_date}`
    );
  }
  if (queryParams.length) {
    queryString = `?` + queryParams.join('&');
  }

  return connection.get(`calendar/events${queryString}`);
};

/* POST IMPORT Events from CSV file-upload */
export const importEvents = payload =>
  connection.post(`calendar/import`, payload);

/* GET EXPORT Events API, returns CSV of events */
export const exportEvents = (range, offset) => {
  let query = '';
  if (range && range.start_date && range.end_date) {
    query = `?start_date=${range.start_date}&end_date=${range.end_date}&time_offset=${offset}`;
  }
  return connection.get(`calendar/export${query}`);
};

/* GET FETCH Single Event by eventId */
export const getEvent = eventId => connection.get(`calendar/events/${eventId}`);

/* PUT UPDATE Single Event by eventId */
export const updateEvent = (eventId, eventPayload) =>
  connection.put(`calendar/events/${eventId}`, eventPayload);

/* DELETE DELETE Single Event by EventId */
export const deleteEvent = eventId =>
  connection.delete(`calendar/events/${eventId}`);
