import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { string, func, object } from 'prop-types';
import { Button, Checkbox, Form, Input, Space } from 'antd';

import { 
  smallBusinessSearchFields, 
  associationSearchFields, 
  primeSearchFields,
  rfpRfqSearchFields,
  MessageCenterSearchFields,
  lookAheadSearchFields,
  calendarSearchFields
} from '../../utils/searchFormFields';
import { clearSearchQuery, setActiveAdvanceSearch, setSearchQuery, resetSearchResults } from '../../redux/actions/search';
import { CheckCircleFilled } from '@ant-design/icons';
import { closeDrawer, showModal } from '../../redux/actions/ui';

const fields = {
  'smallBusiness': smallBusinessSearchFields,
  'primes': primeSearchFields,
  'association': associationSearchFields,
  'calendar': calendarSearchFields,
  'message-center': MessageCenterSearchFields,
  'lookAhead': lookAheadSearchFields,
  'rfpRfq': rfpRfqSearchFields
}

const Search = ({ name, searchParams, setSearch, clearSearch, role, showAdvanceSearch, onShowModal, clearSearchResults }) => {
  const [form] = Form.useForm();
  const [searchFields, setSearchFields] = useState([]);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  
  useEffect(()=>{
    form.resetFields();
    setSelected([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  useEffect(() => {
    let arr = [...fields[name]]
    if (role === "prime" && name === "smallBusiness") {
      [
        { demographic: 'Owner Demographic' },
        { certification: 'Certifications' },
        { company_services: 'Company Core Services' },
        { agencies: 'Agency Experience' }
      ].forEach(item => {
        arr.push(item);
      });
    }
    setSearchFields(arr);
  }, [role, name])

  useEffect(() => {
    if (!searchParams) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        keyword: searchParams.keyword,
        fields: searchParams.fields
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onSubmit = values => {
    const keyword = values['keyword'];
    const fields = values['fields'];
    setSearch(name, keyword, [...fields]);
    hide();
  };

  const onReset = () => {
    clearSearch(name);
    form.resetFields();
    hide();
  };
  
  const hide = () => {
    dispatch(closeDrawer(false))
  }

  const onChange = (value) => {
    setSelected(value)
  }

  const handleReset = () => {
    setSelected([])
    clearSearchResults({ clearAdvanceSearch: true });
  }

  return (
    <Form
      form={form}
      name="search"
      labelCol={{ offset: 4, span: 16 }}
      wrapperCol={{ offset: 2, span: 20 }}
      labelAlign="left"
      style={{ height: '100%' }}
      onFinish={onSubmit}
      onReset={onReset}
    >
      <Form.Item
        name="keyword"
        label="Enter Keyword"
        rules={[
          {
            required: true,
            message: 'Required.'
          }
        ]}
        hasFeedback
      >
        <Input name="keyword" type="text" />
      </Form.Item>
      <Form.Item
        name="fields"
        rules={[
          {
            required: true,
            message: 'Required.'
          }
        ]}
        style={{ alignItems: 'flex-start', justifyContent: 'center' }}
      >
        <Checkbox.Group
          className="sidebar-search"
          style={{ width: '100%', textAlign: 'left' }}
          onChange={e=>onChange(e)}
          options={searchFields.map(option => {
            let node = null;
            for (const [key, value] of Object.entries(option)) {
              node = { 
                label: <span id="label"><div className="checkbox-label">{value}</div>{' '}{selected.includes(key) && <CheckCircleFilled style={{color: '#52C41A'}} />}</span>, 
                value: key 
              };
            }
            return node;
          })}
        />
      </Form.Item>
      <Form.Item>
        <Space direction="horizontal">
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Space>
      </Form.Item>
      <Form.Item>
        <Space direction="horizontal">
          <Button type="primary" htmlType="reset" onClick={handleReset}>
            Reset
          </Button>
        </Space>
      </Form.Item>
      {['primes', 'admin'].includes(role) && name === "smallBusiness" && (<Form.Item>
        <Space direction="horizontal">
          <Button type="primary" onClick={()=> {showAdvanceSearch(name); onShowModal()}}>
            Advance Search
          </Button>
        </Space>
      </Form.Item>)}
    </Form>
  );
};

const mapStateToProps = (state, props) => {
  return {
    searchParams: state.search[props.name],
    role: state.auth?.role
  }
};

const mapDispatchToProps = dispatch => ({
  setSearch: (component, keyword, fields) =>
    dispatch(setSearchQuery(component, keyword, fields)),
  clearSearch: component => dispatch(clearSearchQuery(component)),
  onShowModal: () => dispatch(showModal()),
  showAdvanceSearch: (payload) => dispatch(setActiveAdvanceSearch(payload)),
  clearSearchResults: payload => dispatch(resetSearchResults(payload))
});

Search.propTypes = {
  searchParams: object,
  role: string,
  setSearch: func,
  clearSearch: func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
