/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Button, Space } from 'antd';

import { rfpRfq } from '../../components/configs/forms/rfpRfq.json';
import ModalComponent from '../../components/ui/ModalComponent';
import { clearRedirect } from '../../redux/actions/redirect';
import { showModal } from '../../redux/actions/ui';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation } from '../../utils/utils';
import Add from './Add';
import Delete from './Delete';
import Edit from './Edit';
import Export from './Export';
import Import from './Import';
import List from './List';
import View from './View';

const RfpRfq = ({
  history,
  match,
  role,
  redirect,
  onClearRedirect,
  onShowModal
}) => {
  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
  }, [redirect.path]);

  return (
    <>
      <ModalComponent goBack={true}>
        <Switch>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Route
              path={`${match.path}/add`}
              render={() => <Add config={rfpRfq} />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ] ? (
            <Route path={`${match.path}/view/:eventId`} component={View} />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'edit'
          ] ? (
            <Route
              path={`${match.path}/edit/:eventId`}
              render={() => <Edit config={rfpRfq} />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Route path={`${match.path}/delete/:eventId`} component={Delete} />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'import'
          ] ? (
            <Route path={`${match.path}/import`} component={Import} />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'export'
          ] ? (
            <Route path={`${match.path}/export`} component={Export} />
          ) : null}
        </Switch>
      </ModalComponent>

      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h3 className="section-headers">RFP/RFQ/RFI/IFB</h3>
        <div>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <>
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  onShowModal();
                }}
              >
                <Link to={`${match.path}/add`}>Add Event</Link>
              </Button>{' '}
            </>
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'import'
          ] ? (
            <>
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  onShowModal();
                }}
              >
                <Link to={`${match.path}/import`}>Import Events</Link>
              </Button>{' '}
            </>
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'export'
          ] ? (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                onShowModal();
              }}
            >
              <Link to={`${match.path}/export`}>Export Events</Link>
            </Button>
          ) : null}
        </div>
      </Space>
      <div className="divider"></div>

      <List />
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect())
});

RfpRfq.propTypes = {
  role: string,
  redirect: object,
  history: object,
  match: object,
  onShowModal: func,
  onClearRedirect: func
};

export default connect(mapStateToProps, mapDispatchToProps)(RfpRfq);
