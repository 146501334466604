import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Table, Button, Space, Popconfirm, Tooltip } from 'antd';
import { MessageOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { getMessageCenters } from '../../api/messageCenter';
import { handleNotifications } from '../../utils/notifications';

import ModalComponent from '../../components/ui/ModalComponent';

import { setMessageCenters, onDeleteCenter } from '../../redux/actions/messageCenter';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { clearRedirect } from '../../redux/actions/redirect';
import { getCurrentPageFromLocation, HeaderRenderer } from '../../utils/utils';
import { accessRules } from '../../utils/accessRules';
import dayjs from 'dayjs';
import moment from 'moment';

import Create from './Create';
import Update from './Update';
import Chat from './Chat';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: false,
    width: '20%',
  },
  {
    title: 'Created By',
    dataIndex: ['user', 'company_name'],
    sorter: false,
    width: '20%',
  },
  {
    title: 'Role',
    dataIndex: ['user', 'role'],
    sorter: false,
    width: '20%',
  },
  {
    title: 'Created On',
    dataIndex: 'created_on',
    sorter: false,
    width: '20%',
  },
];

const MessageCenter = ({
  role,
  centers,
  searchParams,
  redirect,
  onShowModal,
  onClearRedirect,
  setMessageCenters,
  onDelete
}) => { 

  const [loading, setLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10})
  const innerWidth = window.innerWidth;

  const match = useRouteMatch();
  const history = useHistory();
  
  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: searchParams['keyword']
        }))
      : [];
    setCurrentFilters(searchFilters);
  };

  useDeepCompareEffect(()=>{
    fetchMsgs({ filters: [...currentFilters] });
  }, [currentFilters])

  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line
  }, [redirect.path]);

  const handleTableChange = (pagination, filters, sorter) => {
    fetchMsgs({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  const fetchMsgs = (params = {}) => {
    setLoading(true);
    getMessageCenters(params)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          setMessageCenters(data['data']);
        } else {
          handleNotifications('error', 'Could not fetch Messages', data.message);
        }
        setPagination({...params.pagination, total: data.pagination.total })
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  /* Render Actions Column */
  const renderActions = (text, record) => (
    <Space size="small">
      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'view'
      ] ? (
        // <Button
        //   type="link"
        //   onClick={() => {
        //     onShowModal();
        //   }}
        // >
        <Tooltip
          title="Chat"
          onClick={(e) => {
            e.stopPropagation();
            onShowModal();
          }}
        >
          <Link style={{ marginRight: '5px' }} to={`${match.path}/chat/${record.id}`}>
            <MessageOutlined style={{ fontSize: '18px' }} />
          </Link>
        </Tooltip>
        // </Button>
      ) : null}
      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'edit'
      ] ? (
        // <Button
        //   type="link"
        //   onClick={() => {
        //     onShowModal();
        //   }}
        // >
        <Tooltip 
          title="Edit"
          onClick={(e) => {
            e.stopPropagation();
            onShowModal();
          }}
        >
          <Link style={{ marginRight: '5px' }} to={`${match.path}/edit/${record.id}`}>
            <EditOutlined style={{ fontSize: '18px' }} />
          </Link>
        </Tooltip>
        // </Button>
      ) : null}
      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'delete'
      ] ? (
        <Popconfirm
          title="Are you sure to delete this center?"
          onConfirm={(e) => {e.stopPropagation(); onDelete(record.id)}}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <span style={{ marginRight: '5px' }}>
              <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
            </span>
          </Tooltip>
        </Popconfirm>
      ) : null}
    </Space>
  );

  const tableColumns = HeaderRenderer(columns, innerWidth)

  tableColumns.push({
    title: 'Actions',
    key: 'action',
    displayName: 'action',
    render: renderActions
  });

  return (
    <div style={{marginLeft: '10px'}}>
      <ModalComponent>
        <Switch>
          <Route 
            path={`${match.path}/create`} 
            render={() => <Create role={role} />}
          />
          <Route path={`${match.path}/chat/:centerId`} component={Chat} />
          <Route 
            path={`${match.path}/edit/:centerId`} 
            render={() => <Update role={role} />}
          />
        </Switch>
      </ModalComponent>

      <Space
        direction="horizontal"
        style={{
          width: '100%',
          display: 'flex',
          padding: '8px',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Space style={{ alignSelf: 'flex-start' }} className="section-headers">
          My Message Centers
        </Space>

        <Space style={{ alignSelf: 'flex-end' }}>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <>
              <Button
                type="primary"
                // size="small"
                onClick={e => {
                  e.preventDefault();
                  onShowModal();
                }}
              >
                <Link to={`${match.path}/create`}>Create New</Link>
              </Button>
            </>
          ) : null}
        </Space>
      </Space>
      <div className="divider"></div>

      <Table
        className="table-striped-rows"
        columns={tableColumns}
        rowKey={record => record.id}
        dataSource={centers.map(item => (
          { ...item, created_on: moment.utc(item['created_on']).utcOffset((dayjs().utcOffset())).format('MM/DD/YYYY hh:mm A') }
        ))}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};


const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect,
  centers: state.messageCenter.centers,
  searchParams: state.search['message-center']
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect()),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading()),
  setMessageCenters: (payload) => dispatch(setMessageCenters(payload)),
  onDelete: (id) => dispatch(onDeleteCenter(id)),
});

MessageCenter.propTypes = {
  role: string,
  redirect: object,
  onShowModal: func,
  searchParams: object,
  onClearRedirect: func,
  onDelete: func,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(MessageCenter);