import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ModalComponent from '../../components/ui/ModalComponent';
import { emailPattern } from '../../utils/constants';
import { submitContactUsDetails } from '../../redux/actions/payments';
import { phoneUSPattern } from '../../utils/constants';

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 24
  }
};

const ContactUs = ({ toggleContact }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = values => {
    setLoading(true);
    const {
      firstName: first_name,
      lastName: last_name,
      email,
      phoneNumber: phone,
      description,
      companyName: company_name
    } = values;
    let payload = {
      first_name,
      last_name,
      email,
      company_name,
      phone,
      description: description || ''
    };
    dispatch(submitContactUsDetails(payload, onCompletion));
  };

  const onCompletion = success => {
    if (success) {
      toggleContact();
    }
    setLoading(false);
  };

  return (
    <ModalComponent>
      <Typography.Title level="h1" style={{ textAlign: 'center' }}>
        Contact Us
      </Typography.Title>
      <Form
        form={form}
        {...layout}
        name="contact-form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={[8]}>
          <Col md={12} sm={24}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: 'First Name is required' }]}
              hasFeedback
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: 'Last Name is required' }]}
              hasFeedback
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Email is required.' },
            {
              pattern: emailPattern,
              message: 'Please enter a valid email.'
            }
          ]}
          hasFeedback
        >
          <Input placeholder="Email" type="email" />
        </Form.Item>
        <Form.Item
          name="companyName"
          label="Company Name"
          rules={[{ required: true, message: 'Company Name is required.' }]}
          hasFeedback
        >
          <Input placeholder="Company Name" type="text" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            { required: true, message: 'Phone Number is required.' },
            {
              pattern: phoneUSPattern,
              message: 'Please enter a valid phone number.'
            }
          ]}
          hasFeedback
        >
          <Input placeholder="Phone Number" type="text" />
        </Form.Item>
        <Form.Item name="description" label="Description" hasFeedback>
          <TextArea placeholder="Description" type="text" />
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </ModalComponent>
  );
};

export default ContactUs;
