import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { array, bool, string } from 'prop-types';
import { Menu } from 'antd';

import { StarFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import SubMenu from 'antd/lib/menu/SubMenu';

const menuItemStyle = {
  marginTop: '10px',
  paddingLeft: '15px',
  paddingRight: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  borderRadius: '5px'
};

const NavMenu = ({ items, role, isValidPayment }) => {
  const [current, setCurrent] = useState(['1']);

  const renderMenuItem = item => {
    return (
      <Menu.Item
        key={item.key}
        style={{
          ...menuItemStyle,
          backgroundColor: item.access ? 'rgba(255,255,255, 0.2)' : '#43b243'
        }}
        disabled={(item.isPaid ? !isValidPayment : false) && role !== 'admin'}
      >
        <Link to={`${item.path}`}>
          {item.label}{' '}
          {!item.access ? <StarFilled style={{ marginRight: 0 }} /> : null}
        </Link>
      </Menu.Item>
    );
  };

  const navMenu = items.map(item => {
    return item.children ? (
      <SubMenu
        key={item.key}
        title={item.label}
        style={{
          ...menuItemStyle,
          backgroundColor: item.access ? 'rgba(255,255,255, 0.2)' : '#43b243'
        }}
      >
        {item.children.map(sub => renderMenuItem(sub))}
      </SubMenu>
    ) : (
      renderMenuItem(item)
    );
  });

  return (
    <div className="header-container">
      <Menu
        mode="horizontal"
        selectedKeys={current}
        onClick={({ key }) => setCurrent(key)}
        style={{ backgroundColor: 'var(--brandBlueLight)', fontSize: '18px' }}
      >
        {navMenu}
      </Menu>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  isValidPayment: state.auth.isValidPayment
});

NavMenu.propTypes = {
  items: array,
  role: string,
  isValidPayment: bool
};

export default connect(mapStateToProps)(NavMenu);
