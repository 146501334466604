import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
} from 'antd';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';

import { objectToFormData } from '../../utils/utils';
import { getUser } from '../../utils/checkLogin';
import { getMessagingEntities } from '../../api/messageCenter';
import { handleNotifications } from '../../utils/notifications';
import { onCreateCenter } from '../../redux/actions/messageCenter';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Create = ({ role, onCreate }) => {

  const [loading, setLoading] = useState(true);
  const [showAssociation, setShowAssociation] = useState(false);
  const [showPrimes, setShowPrimes] = useState(false);
  const [showSmalles, setShowSmalles] = useState(false);
  const [primeList, setPrimeList] = useState([]);
  const [sbList, setSbList] = useState([]);
  const [associationList,setAssociationList] = useState([]);

  useEffect(() => {
    switch(role) {
      
      case 'admin':
        setShowPrimes(true);
        setShowSmalles(true);
        setShowAssociation(true);
        break;
      case 'prime':
        setShowPrimes(true);
        setShowSmalles(false);
        setShowAssociation(true);
        break;
      case 'small-business':
      case 'association':
        setShowPrimes(false);
        setShowSmalles(true);
        setShowAssociation(true);
        break;
      default:
        setShowPrimes(false);
        setShowSmalles(true);
        setShowAssociation(true);
    }
    getMessagingEntities()
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          setPrimeList(data['data']['primes']);
          setSbList(data['data']['small_businesses']);
          setAssociationList(data['data']['associations'] || []);
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });  
    // eslint-disable-next-line
  }, []);
  const onFinish = (values) => {
    values.initiator_id = getUser().user_id;
    console.log('Success:', values);
    onCreate(objectToFormData(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Form
        {...layout}
        layout="vertical"
        initialValues={{
          'title': '',
          'primes': [],
          'smalles': [],
          'association': []
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
          
      {showPrimes && primeList.length > 0 ? (
        <Form.Item name="primes" label="Corporations">
          <Select
            showSearch
            mode={role === 'admin' ? 'tags' : false}
            options={primeList.map(e => ({
              label: e.company_name,
              value: e.userid
            }))}
          >
          </Select>
        </Form.Item>
      ) : null}

      {showSmalles && sbList.length > 0 ? (
        <Form.Item name="smalles" label="Small Businesses">
          <Select
            showSearch
            mode={role === 'admin' ? 'tags' : false}
            options={sbList.map(e => ({
              label: e.company_name,
              value: e.userid
            }))}
          >
          </Select>
        </Form.Item>
      ) : null}

      {showAssociation && associationList.length > 0 ? (
        <Form.Item name="associations" label="Associations">
          <Select
            showSearch
            mode={role === 'admin' ? 'tags' : false}
            options={associationList.map(e => ({
              label: e.company_name,
              value: e.userid
            }))}
          >
          </Select>
        </Form.Item>
      ) : null}

        <Form.Item {...tailLayout}>
          <Button disabled={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onCreate: payload => dispatch(onCreateCenter(payload))
});

Create.propTypes = { config: array, onCreate: func };

export default connect(null, mapDispatchToProps)(Create);