import {
    createVerificationStep,
    updateVerificationStep,
    deleteVerificationStep
} from '../../api/verificationSteps';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, hideModal, showLoading } from './ui';
import { redirect } from './redirect';
import {
    SET_VERIFICATION_STEPS,
    ADD_VERIFICATION_STEP,
    FILTER_VERIFICATION_STEPS,
    UPDATE_VERIFICATION_STEP
} from '../actionTypes';

export const setSteps = payload => dispatch => {
    dispatch({ type: SET_VERIFICATION_STEPS, payload });
};

const addStep = payload => dispatch => {
    dispatch({ type: ADD_VERIFICATION_STEP, payload });
};

const updateStep = payload => dispatch => {
    dispatch({ type: UPDATE_VERIFICATION_STEP, payload });
};

export const onCreateStep = stepPayload => dispatch => {
    dispatch(showLoading);
    createVerificationStep(stepPayload)
        .then(({ data }) => {
            dispatch(hideLoading);
            if (data.success) {
                dispatch(addStep(data.data));
                dispatch(hideModal());
                dispatch(redirect('/site-settings'));
                handleNotifications('success', 'Success', data.message);
            } else {
                handleNotifications('error', 'Failed', data.message);
            }
        })
        .catch(error => {
            dispatch(hideLoading);
            handleNotifications('error', 'Failed', error.message);
        });
};

export const onUpdateStep = (stepId, stepPayload) => dispatch => {
    dispatch(showLoading);
    updateVerificationStep(stepId, stepPayload)
        .then(({ data }) => {
            dispatch(hideLoading);
            if (data.success) {
                dispatch(updateStep(data.data));
                dispatch(hideModal());
                dispatch(redirect('/site-settings'));
                handleNotifications('success', 'Success', data.message);
            } else {
                handleNotifications('error', 'Failed', data.message);
            }
        })
        .catch(error => {
            dispatch(hideLoading);
            handleNotifications('error', 'Failed', error.message);
        });
};

export const onDeleteStep = stepId => dispatch => {
    dispatch(showLoading);
    deleteVerificationStep(stepId)
        .then(({ data }) => {
            dispatch(hideLoading);
            if (data.success) {
                dispatch({ type: FILTER_VERIFICATION_STEPS, stepId });
                handleNotifications('success', 'Success', data.message);
            } else {
                handleNotifications('error', 'Failed', data.message);
            }
        })
        .catch(error => {
            dispatch(hideLoading);
            handleNotifications('error', 'Failed', error.message);
        });
};
