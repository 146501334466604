import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
// import dayjs from 'dayjs';
import { Button, Row, Space } from 'antd';

import FileUpload from '../../components/common/Form/FormInputs/FileUpload';
import { onImportLookAhead } from '../../redux/actions/lookAhead';

const Import = ({ onImport }) => {
  const [upload, setUpload] = useState(null);

  const fileSelectorHandler = (key, value) => {
    if (!value) {
      setUpload(null);
    }
    setUpload(value['look_ahead_event_file']);
  };

  const uploadFile = () => {
    const payload = {};
    // payload['time_offset'] = dayjs().utcOffset();
    payload['event_file'] = upload;

    const formData = new FormData();
    formData.append('look_ahead_event_file', payload['event_file']);
    // formData.append('time_offset', payload['time_offset']);

    onImport(formData);
  };

  return (
    <>
      <Row justify="center">
        <h2>Import Look Ahead</h2>
      </Row>
      <Row justify="center">
        <Space direction="vertical" align="center">
          <FileUpload
            keyName="look_ahead_event_file"
            label="Import Look Ahead"
            parentHandler={fileSelectorHandler}
          />
          <Button type="primary" onClick={uploadFile} disabled={!upload}>
            Import
          </Button>
        </Space>
      </Row>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onImport: payload => dispatch(onImportLookAhead(payload))
});

Import.propTypes = { onImport: func };

export default connect(null, mapDispatchToProps)(Import);
