import connection from '../utils/api';

/* POST Create Association */
export const addAssociation = primePayload =>
  connection.post(`associations`, primePayload);

/* GET List/Fetch associations */
export const getAssociation = ({ page, limit, filters }) => {
  let queryString = '';
  const queryParams = [];

  if (page) {
    queryParams.push(`page=${page}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }
  if (filters?.length) {
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  }
  if (queryParams?.length) {
    queryString = `?` + queryParams.join('&');
  }

  return connection.get(`associations${queryString}`);
};

/* GET Get Association Details */
export const getAssociationDetails = id => connection.get(`associations/${id}`);

/* PUT Update a Association */
export const updateAssociation = (id, primePayload) =>
  connection.put(`associations/${id}`, primePayload);

/* DELETE Delete a Association*/
export const deleteAssociation = id =>
  connection.delete(`associations/${id}`);

/* POST Import associations */
export const importAssociation = payload =>
  connection.post(`associations/import`, payload);

/* GET Export associations */
export const exportAssociation = payload => connection.get(`associations/export`);
