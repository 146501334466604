import {
  addPrime,
  updatePrime,
  deletePrime,
  importPrimes
} from '../../api/primes';
import { handleNotifications } from '../../utils/notifications';
import {
  PRIMES_REFRESH,
  DELETE_PRIMES_SUCCESS,
  FETCH_PRIMES
} from '../actionTypes';
import { hideLoading, hideModal, showLoading } from './ui';
import { redirect } from './redirect';
import { getFromLocalStorage, setToLocalStorage } from '../../utils/localStorage';
import { updateAllowed } from './auth';

export const setPrimesRefresh = payload => ({ type: PRIMES_REFRESH, payload });

export const deletePrimesSuccess = () => ({
  type: DELETE_PRIMES_SUCCESS
});

export const onAddPrime = payload => dispatch => {
  dispatch(showLoading());
  addPrime(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/primes'));
        dispatch(setPrimesRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdatePrime = (primeId, primePayload) => dispatch => {
  dispatch(showLoading());
  let allowed = getFromLocalStorage('allowed');
  updatePrime(primeId, primePayload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        if(!allowed){
          setToLocalStorage("allowed", {allowed: true});
          dispatch(updateAllowed(true))
        }
        handleNotifications('success', 'Success', data.message);
        dispatch(setPrimesRefresh(true));
        dispatch(fetchPrimesAction(true));
        dispatch(redirect('/primes'));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeletePrime = primeId => dispatch => {
  dispatch(showLoading());
  deletePrime(primeId)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        handleNotifications('success', 'Success', data.message);
        dispatch(redirect('/primes'));
        dispatch(setPrimesRefresh(true));
        dispatch(deletePrimesSuccess());
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onImportPrimes = importPayload => dispatch => {
  dispatch(showLoading());
  importPrimes(importPayload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/primes'));
        dispatch(setPrimesRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const fetchPrimesAction = payload => dispatch => {
  dispatch({ type: FETCH_PRIMES, payload });
};