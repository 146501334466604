import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Select,
  Spin,
  // Input
} from 'antd';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { objectToFormData } from '../../utils/utils';
import { fetchPackages } from '../../api/payments';
import { handleNotifications } from '../../utils/notifications';
import { onCreateAgreement } from '../../redux/actions/userAgreement';


const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24
  },
};

const UserAgreementCreate = ({ onCreate }) => {

  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [text, setText] = useState('');

  const [form] = Form.useForm();
  useEffect(() => {

    fetchPackages()
      .then(({ data }) => {
        if (data.success) {
          let res = data['data'];
          let packages = [];
          // eslint-disable-next-line
          res.map(p => {
            // eslint-disable-next-line
            p.package_type.map(pt => {
              let pack = { id: pt.id, name: p.name + " - " + pt.name }
              packages.push(pack)
            })
          })
          setPackages(packages);
          setLoading(false);
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });  
    // eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
    values.user_agreement = text;
    onCreate(objectToFormData(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  function onEditorChange(e) {
    setText(e.editor.getData())
  }

  const getPrice = (value) => {
    const sel = packages.filter(item=>item.id === value)[0]
    return sel?.price;
  }

  return (
    <>
    { loading ? <Spin /> :
      <Form
        form={form}
        {...layout}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="package_type_id" label="Package Type">
          <Select
            options={packages.map(e => ({
              label: e.name,
              value: e.id
            }))}
            onChange={value=> form.setFieldsValue({"price": getPrice(value)})}
          >
          </Select>
        </Form.Item>

        {/* <Form.Item name="price" label="Price">
          <Input placeholder="0.00" />
        </Form.Item> */}

        <div style={{ marginBottom: '10px' }}>
          <CKEditor data={text} onChange={onEditorChange} />
        </div> 

        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    }
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onCreate: payload => dispatch(onCreateAgreement(payload))
});

UserAgreementCreate.propTypes = { config: array, onCreate: func };

export default connect(null, mapDispatchToProps)(UserAgreementCreate);