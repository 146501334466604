/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';
import { Space, Tooltip } from 'antd';

import { columns } from '../../components/configs/tables/lookAhead.json';
import TableComponent from '../../components/ui/TableComponent';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { getLookAheads } from '../../api/lookAhead';
import { setLookAheadRefresh } from '../../redux/actions/lookAhead';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation, HeaderRenderer } from '../../utils/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleNotifications } from '../../utils/notifications';
import { stringToDateObject } from '../../utils/dateTimeHelper';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const List = ({
  refresh,
  onDeleteSuccess,
  importSuccess,
  role,
  searchParams,
  onSetRefresh,
  onShowModal,
  onShowLoading,
  onHideLoading
}) => {
  const [events, setEvents] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentTableProps, setCurrentTableProps] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });
  const innerWidth = window.innerWidth;

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (refresh) {
      onSetRefresh(false);
      fetchLookAhead({ currentTableProps, filters: [...currentFilters] });
      setFiltersFromSearchParams(searchParams);
    }
  }, [refresh]);

  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  useDeepCompareEffect(() => {
    fetchLookAhead({ currentTableProps, filters: [...currentFilters] });
  }, [currentFilters, currentTableProps.pagination.current, importSuccess, onDeleteSuccess]);

  useEffect(
    () => () => {
      setCurrentFilters([]);
      onSetRefresh(true);
    },
    []
  );

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: searchParams['keyword']
        }))
      : [];
    setCurrentFilters(searchFilters);
  };

  /* Render Actions Column */
  const renderMethod = (text, record) => {
    return (
      <>
        <Space size="small">
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'edit'
          ] ? (
            <Tooltip 
              onClick={(e) => {
                e.stopPropagation();
                onShowModal();
              }}
              title="Edit"
            >
              <Link style={{ marginRight: '5px' }} to={`${match.path}/edit/${record?.id}`}>
                <EditOutlined style={{ fontSize: '18px' }} />
              </Link>
            </Tooltip>
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Tooltip 
              onClick={(e) => {
                e.stopPropagation();
                onShowModal();
              }}
              title="Delete"
            >
              <Link
                style={{ marginRight: '5px' }}
                to={{
                  pathname: `${match.path}/delete/${record?.id}`,
                  lookAheadId: record?.id
                }}
              >
                <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      </>
    );
  };

  const tableColumns = HeaderRenderer(columns, innerWidth)
  
  if(accessRules[role][getCurrentPageFromLocation(match.path)]['actions']['edit']){
    tableColumns.push({
      title: 'Action',
      key: 'action',
      displayName: 'action',
      render: renderMethod
    });
  }
    
  const fetchLookAhead = ({ currentTableProps, filters }) => {
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = currentTableProps.pagination.size;
    }
    if (filters) {
      query['filters'] = filters;
    }

    onShowLoading();
    getLookAheads(query)
      .then(({ data }) => {
        if (data['success']) {
          setEvents(
            data['data'].map(item => ({
              ...item,
              estimated_cost: item['estimated_cost'] ? `${item['estimated_cost']}` : '-',
              key: item['id'],
              proposed_advertising_date: item['proposed_advertising_date']
                ? stringToDateObject(item?.['proposed_advertising_date']).format('MMM, YYYY')
                : ''
            }))
          );
          setCurrentTableProps({
            ...currentTableProps,
            pagination: {
              ...currentTableProps.pagination,
              total: data['pagination'].total
            }
          });
        } else {
          setEvents([]);
          handleNotifications('error', 'Failed', data.message);
        }
        onHideLoading();
      })
      .catch(error => {
        onHideLoading();
        setEvents([]);
        handleNotifications('error', 'Failed', error.message);
        console.log(error);
      });
  };

  const manageDataSource = ({ current, pageSize }) => {
    let tableProps = { ...currentTableProps };
    if (current) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, current }
      };
    }
    if (pageSize) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, size: pageSize }
      };
    }

    setCurrentTableProps({ ...tableProps });
  };

  const onClickRow = (record) => {
    return {
      onClick: () => {
        if(accessRules[role][getCurrentPageFromLocation(match.path)]['actions']['view']){
          onShowModal();
          history.push(`${match.path}/view/${record?.id}`);
        }
      }
    };
  }

  return (
    <TableComponent
      className="table-hoverable"
      onRow={onClickRow}
      columns={tableColumns}
      tableData={events}
      pagination={{
        position: ['bottomCenter'],
        hideOnSinglePage: true,
        ...currentTableProps.pagination
      }}
      manageDataSource={manageDataSource}
    />
  );
};

const mapStateToProps = state => ({
  refresh: state.lookAhead.refresh,
  onDeleteSuccess: state.lookAhead.deleteSuccess,
  importSuccess: state.lookAhead.importSuccess,
  role: state.auth.role,
  searchParams: state.search.lookAhead
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onSetRefresh: payload => dispatch(setLookAheadRefresh(payload)),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading())
});

List.propTypes = {
  refresh: bool,
  onDeleteSuccess: bool,
  importSuccess: bool,
  role: string,
  searchParams: object,
  onSetRefresh: func,
  onShowModal: func,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
