import {
  ASSOCIATION_REFRESH,
  FETCH_ASSOCIATION,
  DELETE_ASSOCIATION_SUCCESS
} from '../actionTypes';

const initialState = {
  refresh: true,
  deleteSuccess: false,
  fetchAssociation: false
};

export const associationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSOCIATION_REFRESH:
      return { ...state, refresh: action.payload };
    case FETCH_ASSOCIATION:
      return { ...state, fetchAssociation: action.payload };
    case DELETE_ASSOCIATION_SUCCESS:
      return { ...state, deleteSuccess: true };
    default:
      return { ...state };
  }
};
