import dayjs from 'dayjs';

const { getFromLocalStorage } = require('./localStorage');

export const getUser = () => {
  const user = getFromLocalStorage('user');
  return user ? user : null;
};

export const checkTokenExpired = exp => dayjs().isAfter(dayjs.unix(+exp));
