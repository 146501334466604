import React, { useState, useEffect } from 'react';
import { getContact } from '../../api/contact';
import { handleNotifications } from '../../utils/notifications';
import { Button, Card, Row, Col, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { object, string, func } from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentPageFromLocation } from '../../utils/utils';
import { accessRules } from '../../utils/accessRules';
import { objectToFormData } from '../../utils/utils';
import { onAddContact } from '../../redux/actions/contact';

const { Title } = Typography;

const View = ({role, match, updateContact}) => {
  const [contactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const innerWidth = window.innerWidth;

  useEffect(() => {
    if(!match.params){
      return;
    } else {
      setLoading(true);
      getContact(match.params['contactId'])
        .then(({ data }) => {
          setLoading(false);
          if(data.success){
            setContactDetails(data.data);
          }
        })
        .catch(error => {
          setLoading(false);
          handleNotifications('error', 'Failed', error.message);
        })
    }
    // eslint-disable-next-line
  }, []);

  const markAsCompleted = () => {
    updateContact(objectToFormData({
      action: 'complete',
      obj_id: match.params['contactId']
    }));
  }

	return (
    <>
      <Card loading={loading}>
        {contactDetails ? (
          <>
            <Title level={3}>{contactDetails.created_by}</Title>
            <Row>
              <Col span={24}>
                <h3>Subject: </h3>
                <p>{contactDetails.subject}</p>
              </Col>
              <Col span={24}>
                <h3>Message: </h3>
                <p>{contactDetails.message}</p>
              </Col>
              <Col span={innerWidth < 567 ? 24 : 8}>
                <h3>Created On: </h3>
                <p>{contactDetails.created_on}</p>
              </Col>
              <Col span={innerWidth < 567 ? 24 : 8}>
                <h3>Responded On: </h3>
                <p>{contactDetails.updated_on ? contactDetails.updated_on : ""}</p>
              </Col>
              <Col span={innerWidth < 567 ? 24 : 8}>
                <h3>Responded by: </h3>
                <p>{contactDetails.responded_by ? contactDetails.responded_by : ""}</p>
              </Col>
              {accessRules[role][getCurrentPageFromLocation(match.path)]['actions']['complete'] ? (
                <Col span={24}>
                  <Button
                    onClick={markAsCompleted} 
                    hidden={contactDetails.updated_on && contactDetails.responder_id} 
                    type="primary" icon={<CheckOutlined />}
                  >
                    Mark as completed
                  </Button>
                </Col> ) : null
              }
            </Row>
          </>
        ): null}
      </Card>
    </>
	);
};

const mapStateToProps = state => ({
  role: state.auth.role,
  refresh: state.contact.refresh
});

const mapDispatchToProps = dispatch => ({
  updateContact: (payload) => dispatch(onAddContact(payload))
});

View.propTypes = { role: string, match: object, updateContact: func };

export default connect(mapStateToProps, mapDispatchToProps)(View);
