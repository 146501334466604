import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { array, object, string, func } from 'prop-types';
import { Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { calendar } from '../../components/configs/tables/calendar.json';
import { onDeleteEvent } from '../../redux/actions/calendar';

import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation } from '../../utils/utils';
import moment from 'moment';
import TableComponent from '../../components/ui/TableComponent';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Dropdowns from '../../components/common/Form/FormInputs/Dropdowns';
import View from './View';

const EventsList = ({ role, events, match, onDelete }) => {
  const [eventsList, setEventsList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const history = useHistory();
  const innerWidth = window.innerWidth;

  useEffect(() => {
    if (events?.length) {
      if(innerWidth <= 556){
        setEventsList([events[0]]);
        setSelectedEvent(events[0].title)
      }else{
        setEventsList([...events]);
      }
    }
  }, [events, innerWidth]);

  const getData = () => {
    return eventsList.map(item=>({
      id: item.id,
      name: item.name || '-',
      date: `${moment.utc(item?.date_start).utcOffset((dayjs().utcOffset())).format('MMMM DD, YYYY')}
              'to'
            ${moment.utc(item?.date_end).utcOffset((dayjs().utcOffset())).format('MMMM DD, YYYY')}`,
      time: `${moment.utc(item?.date_start).utcOffset((dayjs().utcOffset())).format('hh:mm A')}
              'to'
              ${moment.utc(item?.date_end).utcOffset((dayjs().utcOffset())).format('hh:mm A')}`,
      type_event: item.type_event || '-',
      agency: item.agency || '-'
    }))
  }

  const renderActions = (record) => (
    <Space size="small">
      {accessRules[role][getCurrentPageFromLocation(match.path)][
        'actions'
      ]['edit'] ? (
        <Link 
          onClick={(e) => e.stopPropagation()}
          to={`/calendar/edit/${record.id}`}
        >
          <EditOutlined style={{ fontSize: '18px' }} />
        </Link>
      ) : null}
      {accessRules[role][getCurrentPageFromLocation(match.path)][
        'actions'
      ]['delete'] ? (
        <span onClick={(e) => { e.stopPropagation(); onDelete(record.id) }}>
          <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
        </span>
      ) : null}
    </Space>
  )
  
  let tableColumns = calendar?.columns.map(item=>({ ...item, width: 100}))
  
  if(accessRules[role][getCurrentPageFromLocation(match.path)]['actions']['edit']){
    tableColumns[5] = {
      title: 'Action',
      key: 'action',
      displayName: 'action',
      width: 100,
      render: renderActions
    }
  }

  useEffect(()=>{
    tableColumns[1] = {
      ...tableColumns[1],
      render: date => (
        <Tooltip placement="topLeft" title={date}>
          {date}
        </Tooltip>
      ),
    }
    tableColumns[2] = {
      ...tableColumns[2],
      render: time => (
        <Tooltip placement="topLeft" title={time}>
          {time}
        </Tooltip>
      ),
    }
    // eslint-disable-next-line
  }, [])

  const onClickRow = (record) => {
    return {
      onClick: () => {
        history.push(`/calendar/view/${record.id}`)
      },
    };
  }

  const handleSelect = (key, value) => {
    setSelectedEvent(value[key])
    setEventsList(events.filter(item=>item.title === value[key]))
  }

  const getOptions = useCallback(() => {
    return events?.map(item=> ({key: item.title, value: item.title, id: item.id}))
  },[events])

  return (
    <>
      {/* <Row> */}
        {!eventsList.length ? (
          <p>No Events for this day</p>
        ) : (
          <>
            <h1 className="section-headers" style={{ marginBottom: 10}}>
              Events on {dayjs(match.params.date).format('MMMM DD, YYYY')}
            </h1>
            {innerWidth < 557 && (
              <>
                <Dropdowns 
                  keyName="events"
                  dropDownOptions={getOptions()}
                  label="Events"
                  parentHandler={handleSelect}
                  valueProps={selectedEvent}
                />
                <div className="calendar-action-container">
                  {renderActions({id: eventsList?.[0]?.id})}
                </div>
              </>
            )}
            {
              innerWidth < 557 ? (
                <View eventId={eventsList?.[0]?.id} />
              ) : (
                <TableComponent
                  className="table-hoverable"
                  columns={tableColumns}
                  tableData={getData()}
                  size="small"
                  onRow={onClickRow}
                />
              )
            }
          </>
        )}
      {/* </Row> */}
    </>
  );
};

const mapStateToProps = state => ({
  events: state.calendar.events,
  role: state.auth.role
});

const mapDispatchToProps = dispatch => ({
  onDelete: id => dispatch(onDeleteEvent(id))
});

EventsList.propTypes = { role: string, events: array, match: object, onDelete: func };

export default connect(mapStateToProps, mapDispatchToProps)(EventsList);
