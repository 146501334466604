import { 
  SET_MESSAGE_CENTERS,
  ADD_MESSAGE_CENTER,
  FILTER_MESSAGE_CENTERS, 
  UPDATE_MESSAGE_CENTERS
 } from '../actionTypes';

const initialState = {
  centers: []
};

export const messageCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE_CENTERS:
      return { ...state, centers: [...action.payload] };
    case ADD_MESSAGE_CENTER:
      return { ...state, centers: [...state.centers, ...action.payload] };
    case UPDATE_MESSAGE_CENTERS:
      let index = state.centers.findIndex(e => e.id === action.payload.id );
      state.centers[index] = action.payload;
      return { ...state, centers: state.centers };
    case FILTER_MESSAGE_CENTERS:
        return { ...state, centers: state.centers.filter(c => c.id !== action.centerId) };
    default:
      return { ...state };
  }
};