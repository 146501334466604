import React from 'react';
import { Button, Image } from 'antd';
import { Link } from 'react-router-dom';
import noaccessLogo from '../../assets/images/noaccess.png';

const NoAccess = () => {
  return (
      <div className="err-content">
        <div className="grey-bg not-found">
          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 5,
                  paddingBottom: 5
                }}
              >
                <div className="top-icon">
                  <Image
                    preview={false}
                    src={noaccessLogo}
                    alt="alt-img"
                    width="200px"
                    height="200px"
                  />
                </div>
                <div className="oops">
                  <h2 className="oops-title">Less Privilages</h2>
                  <p className="oops-details">
                    You don't have access to this component with your current plan. Click below to view or upgrade your plan!
                  </p>
                  <Link to="/payments">
                    <Button>Upgrade</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NoAccess;