/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, object } from 'prop-types';
import { Badge, Calendar } from 'antd';
import { getcurrentDateTime, stringToDateObject } from '../../../utils/dateTimeHelper';
import { hideLoading, showLoading, showModal } from '../../../redux/actions/ui';
import { eventsArrayToJson } from '../../../utils/calendarHelper';
import { setEvents } from '../../../redux/actions/calendar';
import Classes from './CalendarComponent.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getEvents } from '../../../api/calendar';
import { setCalendarRefresh } from '../../../redux/actions/calendar';
import { handleNotifications } from '../../../utils/notifications';
import moment from 'moment';

const getInitialDateRange = (date) => {
  return ({
    start_date: date.subtract(parseInt(date.get('date')) + 10, 'days').format('YYYY-MM-DD'), 
    end_date: date.add(42 - parseInt(date.get('date')) , 'days').format('YYYY-MM-DD')
  });
}

const CalendarComponent = ({
  refresh,
  searchParams,
  setMonthYear,
  onSetRefresh,
  onSetEvents,
  onShowModal,
  onShowLoading,
  onHideLoading,
  fetchEvents
}) => {
  const [eventsData, setEventsData] = useState({});
  const [currentDate, setCurrentDate] = useState(null);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [dateRange, setDateRange] = useState(getInitialDateRange(getcurrentDateTime()));

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (refresh) {
      setFiltersFromSearchParams(searchParams);
    }
  }, [refresh]);

  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  useDeepCompareEffect(() => {
    if(dateRange?.start_date && dateRange?.end_date){
      fetchCalendarEvents({ filters: [...currentFilters], range: {...dateRange} });
    }
  }, [currentFilters, dateRange]);

  useEffect(() => {
    if(fetchEvents) {
      fetchCalendarEvents({ range: {...dateRange} });
    }
  }, [fetchEvents]);

  useEffect(() => () => {
    onSetRefresh(true);
  }, []);

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: searchParams['keyword']
        }))
      : [];
    setCurrentFilters(searchFilters);
  };

  const generateEventsDataFromArray = eventsArray => {
    const eventsJson = eventsArray?.length
      ? eventsArrayToJson(eventsArray)
      : {};
    setEventsData(eventsJson);
  };

  const fetchCalendarEvents = ({ filters, range }) => {
    const query = {};
    if (filters) {
      query['filters'] = filters;
    }
    if(range){
      query['range'] = range;
    }

    onShowLoading();
    getEvents(query)
      .then(({ data }) => {
        if (data.success) {
          generateEventsDataFromArray(data.data);
          onHideLoading();
        } else {
          generateEventsDataFromArray([]);
          onHideLoading();
          handleNotifications('error', 'Unable to fetch.', data.message);
        }
      })
      .catch(error => {
        generateEventsDataFromArray([]);
        handleNotifications('error', 'Error', error.message);
        console.log(error);
        onHideLoading();
      });
    // onSetRefresh(false);
  };

  // const onPanelChange = (value, mode) => {
  // console.log('Panel Changed | value', value, ' | mode', mode);
  // };

  const onSelect = value => {
    setMonthYear(value.format('MMMM'), value.format('YYYY'));
    setDateRange(getInitialDateRange(stringToDateObject(value)));

    if (!currentDate) {
      setCurrentDate(value);
      onSetEvents(
        eventsData[value.get('year')]?.[value.get('month')]?.[value.get('date')]
          ? eventsData[value.get('year')][value.get('month')][value.get('date')]
          : []
      );
      onShowModal();
      history.push(`${match.path}/list/${value.format('YYYY-MM-DD')}`);
    } else if (currentDate === value) {
      return;
    } else if (
      currentDate.get('month') !== value.get('month') ||
      currentDate.get('year') !== value.get('year')
    ) {
      setCurrentDate(value);
    } else {
      onSetEvents(
        eventsData[value.get('year')]?.[value.get('month')]?.[value.get('date')]
          ? eventsData[value.get('year')][value.get('month')][value.get('date')]
          : []
      );
      onShowModal();
      history.push(`${match.path}/list/${value.format('YYYY-MM-DD')}`);
    }
  };

  const getType = (item) => {
    let _d = moment(item.date_end).diff(moment(), 'd');
    if(_d<=0){
      return "error"
    }else if(_d>0 && _d<8){
      return "warning"
    }else{
      return "success"
    }
  }

  const dateCellRender = value => {
    const eventsList = generateEventsList(value);
    return (
      <ul className={Classes.events}>
        {eventsList.map(item => (
          <li key={item.id}>
            <Badge status={getType(item)} text={item.name} />
          </li>
        ))}
      </ul>
    );
  };

  const generateEventsList = dateObj => {
    const year = dateObj.year();
    const month = dateObj.month();
    const date = dateObj.date();

    return eventsData?.[year]?.[month]?.[date]
      ? eventsData[year][month][date]
      : [];
  };

  return (
    <div className="calendar_container">
      <Calendar
        dateCellRender={dateCellRender}
        // onPanelChange={onPanelChange}
        onSelect={onSelect}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  refresh: state.calendar.refresh,
  searchParams: state.search.calendar,
  fetchEvents: state.calendar.fetchEvents
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onSetEvents: events => dispatch(setEvents(events)),
  onHideLoading: () => dispatch(hideLoading()),
  onSetRefresh: payload => dispatch(setCalendarRefresh(payload)),
  onShowLoading: () => dispatch(showLoading())
});

CalendarComponent.propTypes = {
  refresh: bool,
  searchParams: object,
  onShowLoading: func,
  onHideLoading: func,
  onSetRefresh: func,
  setMonthYear: func,
  onShowModal: func,
  onSetEvents: func
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
