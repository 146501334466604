import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { SendOutlined } from '@ant-design/icons';
import { EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Tooltip, Input, Popconfirm, Space, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import {addAnnouncement, getAnnouncement, updateAnnouncement, deleteAnnouncement} from '../../redux/actions/generalAnnoucement';
import TableComponent from '../../components/ui/TableComponent';
import { objectToFormData } from '../../utils/utils';
import dayjs from 'dayjs';

const { TextArea } = Input;

const MsgForm = ({editMsg, setEditMsg}) => {
  return (
    <>
      <Input 
        name="title"
        placeholder="title"
        defaultValue=""
        value={editMsg.title}
        onChange={e => {
          e.persist();
          setEditMsg(prev => ({...prev, title: e.target.value}));
        }}
      />
      <TextArea 
        type="text"
        name="description"
        rows="3" 
        value={editMsg.description} 
        style={{ width: '100%' }} 
        onChange={e => setEditMsg(prev => ({...prev, description: e.target.value}))}
      />
    </>
  )
}

const Announcement = () => {
  const [msg, setMsg] = useState({});
  const [list, setList] = useState([]);
  const [editMsg, setEditMsg] = useState({});
  const [editIndex, setEdit] = useState(false);
  const dispatch = useDispatch();

  const getData = async() => {
    const data = await dispatch(getAnnouncement())
    setList(data);
  }
  useEffect(()=>{
    getData();
    // eslint-disable-next-line
  },[])

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);

  const handleSubmit = async e => {
    e && e.preventDefault();
    let payload = {
      title: msg.title,
      description: msg.description,
      aired_on: today.toISOString()
    }
    await dispatch(addAnnouncement(objectToFormData(payload)));
    setMsg({});
    getData();
  };

  const handleUpdate = async (e) => {
    e && e.preventDefault();
    let payload = {
      aired_on: dayjs(editMsg.aired_on).format('MM/DD/YYYY hh:mm:ss'),
      title: editMsg.title,
      description: editMsg.description,
    }
    await dispatch(updateAnnouncement(editMsg.id, objectToFormData(payload)))
    setList(prev => prev.map(item => item.id === editMsg.id ? {...item, aired_on: editMsg.aired_on, title: editMsg.title, description: editMsg.description} : item))
    setEdit(false);
  };

  const handleChange = debounce((key, value) => {
    setMsg(prev => ({...prev, [key]: value}));
  });

  const handleEdit = (data) => {
    setEdit(data.index)
    setEditMsg({ ...data.item, aired_on: dayjs(data.item?.aired_on || data.item?.created_on)});
  }

  const handleDelete = async (e, id) => {
    e && e.stopPropagation();
    await dispatch(deleteAnnouncement(id))
    getData();
  }

  const renderActions = data => (
    <Space size="small">
      <Tooltip
        onClick={(e) => {
          editIndex === data.index ? handleUpdate(e) : handleEdit(data)
        }}
      >
        {editIndex === data.index ? 
          <SaveOutlined className="cursor-pointer" style={{ fontSize: '18px', color: '#58eb34' }} />
          : <EditOutlined style={{ fontSize: '18px', color: '#1d4e89' }} />}
      </Tooltip>
      {editIndex !== data.index && (
        <Popconfirm
          placement="topRight"
          title={'Are you sure to delete this Announcement?'}
          onConfirm={e =>handleDelete(e, data.item.id)}
          okText="Yes"
          cancelText="No"
        >
          {<DeleteOutlined style={{ fontSize: '18px', color: 'red' }} />}
        </Popconfirm>
      )}
    </Space>
  );

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      searchFilter: true,
      sortable: false,
      width: 150
    },
    {
      title: 'Announcement',
      dataIndex: 'field',
      key: 'field',
      searchFilter: true,
      sortable: false
    }
  ];

  const handleDateChange = (date) => {
    setEditMsg(prev => ({...prev, aired_on: date}));
  }

  tableColumns.push({
    title: 'Action',
    key: 'action',
    displayName: 'action',
    render: renderActions
  });

  return (
    <div style={{ padding: 10 }}>
      <div className="general-announcement-container">
        <h3>General Announcement</h3>
        <form onSubmit={handleSubmit}>
          <Input 
            name="title"
            placeholder="Title"
            defaultValue=""
            value={msg.title}
            onChange={e => {
              e.persist();
              handleChange(e.target.name, e.target.value);
            }}
          /><br /><br />  
          <TextArea
            name={'description'}
            placeholder="Message..."
            rows={4}
            value={msg.description}
            maxLength={500}
            defaultValue={''}
            onChange={e => {
              e.persist();
              handleChange(e.target.name, e.target.value);
            }}
          />  
          <div className="send-btn">
            <button type="sumbit" onClick={handleSubmit}>
              <SendOutlined />
            </button>
          </div>
        </form>
        <div className="announcement-list">
          <TableComponent
            columns={tableColumns}
            tableData={list?.map((item, i) => ({
              index: i,
              date: editIndex === i ? <DatePicker value={editMsg.aired_on} onChange={handleDateChange} /> : dayjs(item.aired_on || item.created_on).format('MM/DD'),
              item: item,
              field: editIndex === i ? <MsgForm editMsg={editMsg} setEditMsg={setEditMsg} /> 
              : 
              <>
                <h4><strong>{item.title}</strong></h4>
                <h4>{item.description}</h4>
              </>
            }))}
            // manageDataSource={manageDataSource}
          />
        </div>
      </div>
    </div>
  );
};

export default Announcement;
