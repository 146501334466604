import React /* useState */ from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { Form, Input, Button, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { emailPattern } from '../../utils/constants';
import { onResetPassword } from '../../redux/actions/auth';
// import { objectToFormData } from '../../utils/utils';

const ResetPasswordForm = ({ onResetPassword }) => {
  const onSubmit = values => {
    const payload = values;
    onResetPassword(payload);
  };

  // const [hasError, setHasError] = useState(null);

  return (
    <Form
      layout="vertical"
      name="login-form"
      className="login-form"
      onFinish={onSubmit}
      wrapperCol={{ offset: 8, span: 8 }}
      labelCol={{ offset: 8, span: 8 }}
    >
      <Form.Item
        name="email"
        label="Enter your email."
        rules={[
          { required: true, message: 'Email is required.' },
          {
            pattern: emailPattern,
            message: 'Please enter a valid email.'
          }
        ]}
        hasFeedback
      >
        <Input prefix={<UserOutlined />} type="email" placeholder="Email" />
      </Form.Item>

      <Form.Item>
        <Space direction="vertical">
          <Button type="primary" htmlType="submit">
            Reset Password
          </Button>
          OR
          <Link to="/">Return to Login</Link>
        </Space>
      </Form.Item>
    </Form>
  );
};

const mapDispatchToProps = dispatch => ({
  onResetPassword: payload => dispatch(onResetPassword(payload))
});

ResetPasswordForm.propTypes = {
  onResetPassword: func
};

export default withRouter(
  connect(/* mapStateToProps */ null, mapDispatchToProps)(ResetPasswordForm)
);
