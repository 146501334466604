import React, { useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Button, Space } from 'antd';

import { showModal } from '../../redux/actions/ui';
import ModalComponent from '../../components/ui/ModalComponent';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation } from '../../utils/utils';
import List from './List';
import Delete from './Delete';
import { clearRedirect } from '../../redux/actions/redirect';
import Association from '../../components/customForms/Assocation';
import AssociationPrintView from '../../components/customForms/AssociationPrintView';
import AdvanceSearch from '../../components/common/SearchSidebar/AdvanceSearch';

const Associations = ({
  role,
  redirect,
  history,
  advanceSearch,
  match,
  onShowModal,
  onClearRedirect
}) => {
  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect.path]);

  return (
    <>
      <ModalComponent goBack={true}>
        {advanceSearch && (
          <AdvanceSearch title="Association Search" search="association" />
        )}
        <Switch>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Route path={`${match.path}/add`} render={() => <Association />} />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ] ? (
            <Route
              path={`${match.path}/view/:associationId`}
              render={() => <Association disabled={true} />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'print-view'
          ] ? (
            <Route
              path={`${match.path}/print-view/:associationId`}
              render={() => <AssociationPrintView />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'edit'
          ] ? (
            <Route
              path={`${match.path}/edit/:associationId`}
              render={() => <Association edit={true} selfMode={false} />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Route
              path={`${match.path}/delete/:associationId`}
              component={Delete}
            />
          ) : null}
        </Switch>
      </ModalComponent>
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h3 className="section-headers">Association</h3>
        {role === 'admin' &&
        accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'add'
        ] ? (
          <Button
            type="primary"
            size="small"
            onClick={e => {
              e.preventDefault();
              onShowModal();
            }}
          >
            <Link to={`${match.path}/add`}>Add Association</Link>
          </Button>
        ) : null}
      </Space>
      <div className="divider"></div>
      <List />
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect,
  advanceSearch: state.search.activeAdvanceSearch
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect())
});

Associations.propTypes = {
  role: string,
  redirect: object,
  match: object,
  history: object,
  onShowModal: func,
  onClearRedirect: func
};

export default connect(mapStateToProps, mapDispatchToProps)(Associations);
