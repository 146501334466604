import * as actionTypes from '../actionTypes';

export const redirect = path => ({
  type: actionTypes.REDIRECT_SET,
  payload: path
});

export const clearRedirect = () => ({
  type: actionTypes.REDIRECT_CLEAR
});
