import React /* , { useEffect } */ from 'react';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';

import { onAddEvent } from '../../redux/actions/rfpRfq';
import PluggableForm from '../../components/common/Form/PluggableForm';
import { objectToFormData } from '../../utils/utils';

const Add = ({ config, addEvent }) => {
  const onSubmit = values => {
    values = { ...values, address: values.address.address };
    const payload = Object.keys(values).reduce(
      (accumulator, item) => ({ ...accumulator, ...values[item] }),
      {}
      );
    delete payload.id;
    delete payload.proof;
    delete payload.city;
    delete payload.zipcode;

    addEvent(objectToFormData(payload));
  };

  return (
    <>
      <h1 className="heading-title">Add RFP/RFQ/RFI/IFB Page</h1>
      <PluggableForm
        config={config}
        formConfig={{ layout: 'vertical', name: 'add-rfp-rfq' }}
        onFinish={onSubmit}
        editMode={false}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  addEvent: payload => dispatch(onAddEvent(payload))
});

Add.propTypes = { config: array, addEvent: func };

export default connect(null, mapDispatchToProps)(Add);
