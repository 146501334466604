import connection from '../utils/api';

/* POST Create RFP-RFQ Event */
export const addEvent = eventPayload =>
  connection.post(`rfp_rfq/events`, eventPayload);

/* GET Fetch RFP-RFQ Event */
export const getEvents = ({ page, limit, filters }) => {
  let queryString = '';
  const queryParams = [];

  if (page) {
    queryParams.push(`page=${page}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }
  if (filters?.length) {
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  }
  if (queryParams?.length) {
    queryString = `?` + queryParams.join('&');
  }

  return connection.get(`rfp_rfq/events${queryString}`);
};

/* GET Fetch an event by ID */
export const getEvent = eventId => connection.get(`rfp_rfq/events/${eventId}`);

/* PUT Update RFP-RFQ Event */
export const updateEvent = (eventId, eventPayload) =>
  connection.put(`rfp_rfq/events/${eventId}`, eventPayload);

/* POST Import RFP-RFQ Event */
export const importEvents = payload =>
  connection.post(`rfp_rfq/import`, payload);

/* GET Export RFP-RFQ Event */
export const exportEvents = (/* filters, */ timeOffset) =>
  connection.get(`rfp_rfq/export?time_offset=${timeOffset}`);

/* Delete RFP-RFQ Event */
export const deleteEvent = eventId =>
  connection.delete(`rfp_rfq/events/${eventId}`);
