import {
  addAssociation,
  updateAssociation,
  deleteAssociation,
  importAssociation
} from '../../api/associations';
import { handleNotifications } from '../../utils/notifications';
import {
  ASSOCIATION_REFRESH,
  DELETE_ASSOCIATION_SUCCESS,
  FETCH_ASSOCIATION
} from '../actionTypes';
import { hideLoading, hideModal, showLoading } from './ui';
import { redirect } from './redirect';
import { getFromLocalStorage, setToLocalStorage } from '../../utils/localStorage';
import { updateAllowed } from './auth';

export const setAssociationsRefresh = payload => ({ type: ASSOCIATION_REFRESH, payload });

export const deleteAssociationSuccess = () => ({
  type: DELETE_ASSOCIATION_SUCCESS
});

export const onAddAssociation = payload => dispatch => {
  dispatch(showLoading());
  addAssociation(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/association'));
        dispatch(setAssociationsRefresh(true));
        dispatch(fetchAssociationAction(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateAssociation = (primeId, primePayload) => dispatch => {
  dispatch(showLoading());
  let allowed = getFromLocalStorage('allowed');
  updateAssociation(primeId, primePayload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        if(!allowed){
          setToLocalStorage("allowed", {allowed: true});
          dispatch(updateAllowed(true))
        }
        handleNotifications('success', 'Success', data.message);
        setToLocalStorage("allowed", {allowed: true});
        dispatch(redirect('/association'));
        dispatch(setAssociationsRefresh(true));
        dispatch(fetchAssociationAction(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteAssociation = primeId => dispatch => {
  dispatch(showLoading());
  deleteAssociation(primeId)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        handleNotifications('success', 'Success', data.message);
        dispatch(redirect('/association'));
        dispatch(setAssociationsRefresh(true));
        dispatch(deleteAssociationSuccess());
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onImportAssociation = importPayload => dispatch => {
  dispatch(showLoading());
  importAssociation(importPayload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/association'));
        dispatch(setAssociationsRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const fetchAssociationAction = payload => dispatch => {
  dispatch({ type: FETCH_ASSOCIATION, payload });
};