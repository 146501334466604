import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, number, string } from 'prop-types';
import Downloader from '../../utils/downloader';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  Select,
  Typography,
  DatePicker,
  InputNumber,
  Spin
} from 'antd';
import {
  PlusOutlined,
  DownloadOutlined,
  EditOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { getPrime } from '../../api/primes';
import { getUserPackage, updateUserPackage } from '../../api/adminUpdates';
import { addField, updateField } from '../../api/primesFields';
import { hideModal } from '../../redux/actions/ui';
import { onUpdatePrime } from '../../redux/actions/primes';
import { handleNotifications } from '../../utils/notifications';
import { debounce, objectToFormData } from '../../utils/utils';
import ImageUpload from '../common/Form/FormInputs/ImageUpload';
import Address from '../common/Form/FormInputs/Address';
import Dropdowns from '../common/Form/FormInputs/Dropdowns';
import FileUpload from '../common/Form/FormInputs/FileUpload';
import { phoneUSPattern, websiteUrlPattern } from '../../utils/constants';
import UpcomingProjects from './upcomingProjects';
import moment from 'moment';

const { Title, Text } = Typography;
const { TextArea } = Input;

const downloadStyle = {
  width: 150,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '0 5px',
  backgroundColor: '#1890ff',
  color: 'white',
  border: 'none'
};

const PrimesEdit = ({
  selfMode,
  businessId,
  updatePrime,
  onCancel,
  history,
  role
}) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableBtns, setDisableBtns] = useState({});

  const [subscription, setSubscription] = useState({});
  const [packages, setPackages] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [adminEdit, setAdminEdit] = useState(false);

  const params = useParams();

  const primeId = selfMode ? businessId : params['primeId'];

  useEffect(() => {
    if (primeId) {
      setLoading(true);
      getPrime(primeId)
        .then(({ data }) => {
          if (data['success']) {
            setDetails(data['data']);
            getFormValuesFromDetails(data['data']);
          } else {
            setDetails(null);
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          setLoading(false);
        })
        .catch(error => {
          setDetails(null);
          setLoading(false);
          console.log(error);
        });
      
      // getUserPackage(primeId, 'Primes')
      //   .then(({ data }) => {
      //     if (data['success']) {
      //       setSubscription(data['data']);
      //       setPackages(data['data']['options']);
      //     } else {
      //       // setDetails(null);
      //       handleNotifications('error', 'Failed', 'Could not fetch details');
      //     }
      //     setLoading(false);
      //   })
      //   .catch(error => {
      //     setLoading(false);
      //     console.log(error);
      //   });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primeId]);
  const [form] = Form.useForm();
  
  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: null,
    phone: null,
    website_info: null,
    address: [],
    unspsc_codes: [],
    company_contacts: [],
    company_profile: null,
    company_specialization: [],
    certificates: [],
    soq: null,
    linkedin_profile: null,
    updated_on: ''
  });
  
  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      address,
      // certificates,
      company_contacts: companyContacts,
      company_name,
      company_profile,
      company_specialization: companySpecialization,
      linkedin_profile,
      logo_url: logoUrl,
      logo_name: logoName,
      phone,
      unspsc_codes,
      soq_url: soqUrl,
      soq_name: soqName,
      website_info: websiteInfo,
      updated_on: updatedOn,
      projects,
      updated_on
    } = details;

    if (logoUrl) {
      newFormValues['logo'] = {
        url: logoUrl,
        name: logoName
      };
    }
    if (websiteInfo) {
      newFormValues['website_info'] = websiteInfo;
    }
    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['street'],
        state: item['state'],
        county: item['county'],
        city: item['city'],
        zipcode: item['zip_code']
      }));
    }
    if (unspsc_codes?.length) {
      newFormValues['unspsc_codes'] = unspsc_codes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyContacts?.length) {
      newFormValues['company_contacts'] = companyContacts.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }

    if (companySpecialization?.length) {
      newFormValues['company_specialization'] = [...companySpecialization];
    }
    if (soqUrl) {
      newFormValues['soq'] = { url: soqUrl, name: soqName };
    }
    if (updated_on) {
      newFormValues['profile_last_updated'] = dayjs(updated_on).format(
        'MM/DD/YYYY'
      );
    }

    setFormValues({
      ...newFormValues,
      company_profile,
      linkedin_profile,
      company_name,
      phone,
      projects,
      updatedOn
    });
  };

  const inputChangedHandler = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleDownload = (e, url, name) => {
    e.stopPropagation();
    e.preventDefault();
    Downloader(url, name);
  };

  const debouncedInput = debounce((e, key) => {
    inputChangedHandler(key, e.target.value);
  }, 500);

  const microApiResponseHandler = (key, value, index) => {
    let arr = formValues[key] ? [...formValues[key]] : [];
    arr[index] = value;
    setFormValues({ ...formValues, [key]: arr });
  };

  const onPackageSelect = id => {
    setLoading(true);
    // eslint-disable-next-line
    packages.map(e => {
      if (e.id === id) {
        setPackageTypes(e['package_type']);
      }
    });
    setLoading(false);
  };

  const adminSubmit = values => {
    setLoading(true);
    const { expiry_date } = values;
    values['expiry_date'] = expiry_date.format('YYYY-MM-DD');
    values['entity_type'] = 'Primes';
    console.log('adminValues', values);
    updateUserPackage(primeId, objectToFormData(values)).then(({ data }) => {
      if (data['success']) {
        console.log(data['data']);
        setLoading(false);
        setAdminEdit(false);
        handleNotifications('success', 'Success', data['message']);
        onCancel();
        history.push('/primes');
      } else {
        handleNotifications('error', 'Failed', 'Could not update details');
        setLoading(false);
        setAdminEdit(false);
      }
    });
  };

  const submit = () => {
    // console.log(formValues);

    if (formValues.logo?.url) {
      delete formValues.logo;
    }

    let payload = {};
    for (let key in formValues) {
      if (formValues[key]) {
        if (Array.isArray(formValues[key]) && !formValues[key]?.length)
          continue;

        if (
          ['address', 'company_contacts', 'certificates', 'projects', 'unspsc_codes'].includes(
            key
          )
        ) {
          payload[key] = JSON.stringify(
            formValues[key].map(item => item.id || item)
          );
        } else if (['company_specialization'].includes(key)) {
          payload[key] = JSON.stringify(formValues[key]);
        } else if (['soq'].includes(key)) {
          formValues[key]?.url
            ? (payload[key] = JSON.stringify(formValues[key]))
            : (payload[key] = formValues[key]);
        } else {
          payload[key] = formValues[key];
        }
      }
    }
    updatePrime(primeId, objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
  };

  const editImage = () => {
    setFormValues(prev => ({ ...prev, logo: null }));
  };

  return (
    <Card loading={loading}>
      {details && formValues && formValues.company_name ? (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Title level={2} className="heading-title">
              Corporation Profile
            </Title>
            {subscription && subscription.package_id && <Form
              layout="vertical"
              style={{
                display: adminEdit ? 'block' : 'none'
              }}
              initialValues={{
                package_id: subscription?.package_id,
                package_type_id: subscription?.package_type_id,
                expiry_date: details?.expiry_date
                  ? dayjs(details.expiry_date)
                  : null,
                additional_users: details?.additional_users
                  ? details.additional_users
                  : null
              }}
              onFinish={adminSubmit}
            >
              <Row>
                <Col span={12}>
                  <Form.Item name="package_id" label="Select a package" rules={[{ required: true, message: 'Please select a package' }]}>
                    <Select
                      onSelect={onPackageSelect}
                      options={packages.map(e => ({
                        label: e.name,
                        value: e.id
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="package_type_id"
                    label="Select a package type"
                    rules={[{ required: true, message: 'Please select a package type' }]}
                  >
                    <Select
                      options={packageTypes.map(e => ({
                        label: e.name,
                        value: e.id
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="expiry_date" label="Expiry Date">
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="additional_users" label="Additional Users">
                    <InputNumber min={1} max={10} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
            }
            <Form
              form={form}
              name="edit-primes"
              layout="horizontal"
              labelCol={{ /* offset: 4, */ span: 4 }}
              wrapperCol={{ /* offset: 4,  */ span: 20 }}
              labelAlign="left"
              onFinish={submit}
              onFinishFailed={submitFail}
              initialValues={{ ...formValues }}
            >
              {/* Company Logo */}
              <Row>
                <Col span={16}>
                  {/* <div
                    style={{
                      display: adminEdit ? 'none' : 'block'
                    }}
                  >
                    <Title level={5} style={{ textAlign: 'left' }}>
                      Package Name:{' '}
                      {subscription ? subscription.package_name : ''}
                    </Title>
                    <Title level={5} style={{ textAlign: 'left' }}>
                      Expiration Date: {details?.expiry_date}
                    </Title>
                    <Title level={5} style={{ textAlign: 'left' }}>
                      No. of users: {details?.additional_users}
                    </Title>
                    {role === 'admin' ? (
                      <Button
                        type="primary"
                        onClick={() => setAdminEdit(!adminEdit)}
                      >
                        Edit Package Details
                      </Button>
                    ) : null}
                  </div> */}
                </Col>
                <Col span={8}>
                  <Form.Item
                    key="logo"
                    name="logo"
                    hasFeedback
                    style={{ textAlign: 'right' }}
                    wrapperCol={{ md: { offset: 4 }, sm: { offset: 0 } }}
                  >
                    {formValues['logo'] && formValues['logo']?.['url'] ? (
                      <React.Fragment>
                        <Image
                          src={ formValues['logo'] ? formValues['logo']['url'] : '' }
                          alt={ formValues['logo'] ? formValues['logo']['name'] : 'No logo uploaded.'}
                          preview={false}
                          width="120px"
                          height="120px"
                        />
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                          size="small"
                          style={{ transform: 'translate(-25px, -10px)' }}
                          onClick={editImage}
                        />
                      </React.Fragment>
                    ) : (
                      <ImageUpload
                        keyName="logo"
                        label="Company Logo"
                        max={1}
                        nestedHandler={(key, value) => {
                          inputChangedHandler(key, value[key]);
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {/* Business Name */}
              <Form.Item
                key="company_name"
                name="company_name"
                label="Business Name"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company name is required.'
                  }
                ]}
              >
                <Input
                  name="company_name"
                  placeholder="Business Name"
                  type="text"
                  bordered={false}
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_name');
                  }}
                />
              </Form.Item>

              {/* Business Phone */}
              <Form.Item
                key="phone"
                name="phone"
                label="Business Phone"
                required={true}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    validator: async (_, phone) => {
                      if (!phone) {
                        return Promise.reject(
                          new Error('Phone Number is required.')
                        );
                      }
                    }
                  },
                  {
                    pattern: phoneUSPattern,
                    message: 'Please enter a valid phone number.'
                  }
                ]}
              >
                <Input
                  name="phone"
                  placeholder="Business Phone (10-digit)"
                  type="tel"
                  maxLength={10}
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'phone');
                  }}
                />
              </Form.Item>

              {/* Website */}
              <Form.Item
                key="website_info"
                name="website_info"
                label="Website"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company Website is required.'
                  },
                  {
                    pattern: websiteUrlPattern,
                    message: 'Please enter a valid web url.'
                  }
                ]}
              >
                <Input
                  name="website_info"
                  placeholder="Company Website"
                  // type="url"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'website_info');
                  }}
                />
              </Form.Item>

              {/* Business Addresses */}
              <Form.List
                name="address"
                rules={[
                  {
                    validator: async (_, address) => {
                      if (!address || address.length < 1) {
                        return Promise.reject(
                          new Error('At least 1 address is required.')
                        );
                      }
                    }
                  }
                ]}
              >
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                        required={true}
                        key={field.key}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'Business Address' : ''}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          name={[field.name, 'address']}
                          style={{ marginLeft: 7 }}
                          rules={[
                            {
                              validator: async (_, address) => {
                                if (!formValues['address'][index]) {
                                  return Promise.reject(
                                    new Error(
                                      'Please input details and Save or delete this field.'
                                    )
                                  );
                                }
                              }
                            }
                          ]}
                          noStyle
                        >
                          <Address
                            keyName="address"
                            microApi={{ add: addField, update: updateField }}
                            parentHandler={microApiResponseHandler}
                            hasCity={true}
                            hasZip={true}
                            index={index}
                            valueProps={formValues['address']?.[index]}
                          />
                        </Form.Item>

                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '3px',
                            marginTop: '0px',
                            backgroundColor: '#D26A31',
                            color: 'white'
                          }}
                          onClick={() => {
                            formValues['address'].splice(index, 1);
                            return form.setFieldsValue({
                              address: formValues?.['address']
                            });
                          }}
                        >
                          Remove
                        </Button>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        float: 'left'
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Address
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Company Contacts */}
              <Form.List name="company_contacts">
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray',
                          clear: 'both'
                        }}
                        required={false}
                        key={`${field.key}_${index}`}
                        label={index === 0 ? 'Company Contacts' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          key={`name_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Name"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'title']}
                          key={`title_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Title"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'phone']}
                          key={`phone_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Phone"
                            type="tel"
                            maxLength={10}
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          key={`email_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Email"
                            type="email"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>

                        <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['company_contacts'].splice(index, 1);
                              form.setFieldsValue({
                                company_contacts:
                                  formValues?.['company_contacts']
                              });
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            onClick={() => {
                              let ind = index;
                              let id = form.getFieldValue('company_contacts')[
                                index
                              ]?.id;
                              if (id) {
                                updateField(
                                  'company_contacts',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('company_contacts')[
                                      index
                                    ]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'company_contacts',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Company contact updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler(
                                      //   'company_contacts',
                                      //   null
                                      // );
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                  });
                              } else {
                                addField(
                                  'company_contacts',
                                  objectToFormData(
                                    form.getFieldValue('company_contacts')[
                                      index
                                    ]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'company_contacts',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Company contact added successfully.'
                                      );
                                    } else {
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '1px',
                        paddingLeft: '7px',
                        clear: 'both',
                        float: 'left'
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Company Contact
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Brief Company Profile */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    clear: 'both'
                  }}
                >
                  Brief Company Profile: (max 300 words)
                </div>
              </React.Fragment>
              <TextArea
                name="company_profile"
                placeholder="Brief Company Profile (max 300 words)"
                rows={3}
                maxLength={300}
                defaultValue={formValues['company_profile']}
                onChange={e => {
                  e.persist();
                  debouncedInput(e, 'company_profile');
                }}
              />
              {/* UNSPSC Code */}
              <Form.List name="unspsc_codes">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        key={`${field.key}_${index}`}
                        wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'UNSPSC Codes' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'codes']}
                          key={`codes_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Code"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          key={`description_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Description"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>

                        <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['unspsc_codes'].splice(index, 1);
                              form.setFieldsValue({
                                unspsc_codes: formValues?.['unspsc_codes']
                              });
                              // return remove(field.name);
                            }}
                          >
                            Remove
                          </Button>
                          {/* {!formValues['unspsc_codes'][index] ? ( */}
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            disabled={disableBtns['unspsc_codes']}
                            onClick={() => {
                              let ind = index;
                              let id = form.getFieldValue('unspsc_codes')[index]
                                ?.id;
                              setDisableBtns(prevBtns => ({
                                ...prevBtns,
                                unspsc_codes: true
                              }));
                              if (id) {
                                updateField(
                                  'unspsc',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('unspsc_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'unspsc_codes',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'UNSPSC Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('unspsc_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      unspsc_codes: false
                                    }));
                                  });
                              } else {
                                addField(
                                  'unspsc',
                                  objectToFormData(
                                    form.getFieldValue('unspsc_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'unspsc_codes',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'UNSPSC Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('unspsc_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      unspsc_codes: false
                                    }));
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                          {/* ) : null} */}
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '1px',
                        paddingLeft: '7px'
                      }}
                    >
                      {fields.length < 2 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          style={{
                            float: 'left',
                            clear: 'both'
                          }}
                        >
                          Add UNSPSC Code
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Company Specializations */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Company Specialization: (Select all that applies)
                </div>
              </React.Fragment>
              <div>
                <Dropdowns
                  keyName="specializations"
                  label="specializations"
                  multiSelect={true}
                  valueProps={formValues['company_specialization']}
                  parentHandler={(key, value) => {
                    inputChangedHandler('company_specialization', [
                      ...value[key]
                    ]);
                  }}
                />
              </div>

              {/* Upcoming Projects */}
              <Form.List
                name="projects"
                // rules={[
                //   {
                //     validator: async (_, project) => {
                //       if (!address || address.length < 1) {
                //         return Promise.reject(
                //           new Error('At least 1 project is required.')
                //         );
                //       }
                //     }
                //   }
                // ]}
              >
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                        key={field.key}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'Project Details' : ''}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          name={[field.name, 'projects']}
                          style={{ marginLeft: 7 }}
                          // rules={[
                          //   {
                          //     validator: async (_, address) => {
                          //       if (!formValues['address'][index]) {
                          //         return Promise.reject(
                          //           new Error(
                          //             'Please input details and Save or delete this field.'
                          //           )
                          //         );
                          //       }
                          //     }
                          //   }
                          // ]}
                          noStyle
                        >
                          <UpcomingProjects
                            parentHandler={microApiResponseHandler}
                            index={index}
                            valueProps={
                              formValues['projects']
                                ? formValues['projects'][index]
                                : null
                            }
                          />
                        </Form.Item>

                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '3px',
                            marginTop: '0px',
                            backgroundColor: '#D26A31',
                            color: 'white'
                          }}
                          onClick={() => {
                            formValues['upcoming_project'] &&
                              formValues['upcoming_project'].splice(index, 1);
                          }}
                        >
                          Remove
                        </Button>
                      </Form.Item>
                    ))}

                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        float: 'left'
                      }}
                    >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Upcoming Teaming Opportunities
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Additional Information */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    fontWeight: 600,
                    clear: 'both'
                  }}
                >
                  Additional Information:
                </div>
              </React.Fragment>

              {/* Statement of Qualification */}
              <Form.Item
                key="soq"
                name="soq"
                label="Statement of Qualification"
                hasFeedback
                labelCol={{ span: 6 }}
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FileUpload
                    keyName="soq"
                    styleObj={{ marginLeft: '-7px', width: '101%' }}
                    label={formValues['soq']?.url ? 'Re_upload' : ''}
                    parentHandler={(key, value) => {
                      inputChangedHandler(key, value ? value[key] : null);
                    }}
                  />
                  {formValues['soq']?.['url'] ? (
                    <button
                      style={downloadStyle}
                      onClick={e =>
                        handleDownload(
                          e,
                          formValues?.['soq']?.['url'],
                          formValues?.['soq']?.['name']
                        )
                      }
                    >
                      <DownloadOutlined /> {formValues?.['soq']?.['name']}
                    </button>
                  ) : null}
                </div>
              </Form.Item>

              {/*  Company LinkedIn Profile*/}
              <Form.Item
                key="linkedin_profile"
                name="linkedin_profile"
                label="Company LinkedIn Profile"
                labelCol={{ span: 6 }}
                style={{
                  // border: '1px solid gray',

                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '20px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
              >
                <Input
                  name="linkedin_profile"
                  placeholder="LinkedIn Profile"
                  type="text"
                  defaultValue={formValues['linkedin_profile']}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'linkedin_profile');
                  }}
                />
              </Form.Item>

              {/* Profile Last Updated */}
              <strong>Profile Created : </strong>
              <Text>
                {moment
                  .utc(details?.['created_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text>
              <br />
              <strong>Profile Last Updated : </strong>
              <Text>
                {moment
                  .utc(details?.['updated_on'] || details?.['created_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text>
              {/* Action Buttons */}
              <Form.Item
                wrapperCol={{ md: { offset: 6, span: 12 }, sm: { span: 24 }}}
                style={{ textAlign: 'center' }}
              >
                <Space direction="horizontal" align="center">
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      onCancel();
                      history.push('/primes');
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : <Spin />}
    </Card>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  businessId: state.auth?.user?.businessId
});

const mapDispatchToProps = dispatch => ({
  updatePrime: (primeId, payload) => dispatch(onUpdatePrime(primeId, payload)),
  onCancel: () => dispatch(hideModal())
});

PrimesEdit.propTypes = {
  role: string,
  selfMode: bool,
  businessId: number,
  updatePrime: func,
  onCancel: func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrimesEdit));
