export default (url, name='sample')=>{
  fetch(url)
  .then(res=>{
    return res.blob()})
  .then(blob=>{
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  })
  .catch(err=>{
    console.log(err)
  })
}