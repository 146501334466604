import { addEvent, importEvents, updateEvent, deleteEvent } from '../../api/calendar';
import { handleNotifications } from '../../utils/notifications';
import { CALENDAR_REFRESH, CALENDAR_SET_EVENTS, FETCH_EVENTS } from '../actionTypes';
import { hideLoading, hideModal, showLoading } from './ui';
import { redirect } from './redirect';

export const setEvents = events => ({
  type: CALENDAR_SET_EVENTS,
  payload: events
});

export const setCalendarRefresh = payload => ({
  type: CALENDAR_REFRESH,
  payload
});

export const fetchEvents = (payload) => ({
  type: FETCH_EVENTS,
  payload
});


export const onAddEvent = eventPayload => dispatch => {
  dispatch(showLoading);
  dispatch(fetchEvents(false));
  addEvent(eventPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/calendar'));
        dispatch(setCalendarRefresh(true));
        dispatch(fetchEvents(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateEvent = (eventId, eventPayload) => dispatch => {
  dispatch(showLoading);
  dispatch(fetchEvents(false));
  updateEvent(eventId, eventPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(setCalendarRefresh(true));
        dispatch(fetchEvents(true));
        dispatch(redirect('/calendar'));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onDeleteEvent = (eventId) => dispatch => {
  dispatch(showLoading);
  dispatch(fetchEvents(false));
  deleteEvent(eventId)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(setCalendarRefresh(true));
        dispatch(fetchEvents(true));
        dispatch(redirect('/calendar'));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onImportEvents = importPayload => dispatch => {
  dispatch(showLoading);
  importEvents(importPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/calendar'));
        dispatch(setCalendarRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};
