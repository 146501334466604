import { LOOK_AHEAD_REFRESH, DELETE_LOOK_AHEAD_SUCCESS, IMPORT_LOOK_AHEAD_SUCCESS } from '../actionTypes';

const initialState = { refresh: false, deleteSuccess: false, importSuccess: false };

export const lookAheadReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOOK_AHEAD_REFRESH:
      return { ...state, refresh: action.payload };
    case DELETE_LOOK_AHEAD_SUCCESS:
      return { ...state, deleteSuccess: true };
    case IMPORT_LOOK_AHEAD_SUCCESS:
      return { ...state, importSuccess: true };
    default:
      return { ...state };
  }
};
