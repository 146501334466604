import React, { useEffect, useState } from 'react';
import { any, bool, func, object, string } from 'prop-types';
// import { debounce } from 'lodash';
import { Button, Input, Select } from 'antd';

import { getDropdownOptions } from '../../../../api/formOptions';
import { objectToFormData } from '../../../../utils/utils';
import { handleNotifications } from '../../../../utils/notifications';

const { Option } = Select;

const Address = ({
  keyName,
  valueProps,
  microApi,
  hasCity,
  hasZip,
  hideStreet,
  index,
  // viewMode,
  disabled,
  parentHandler
}) => {
  const [address, setAddress] = useState({
    id: null,
    street: '',
    city: '',
    county: '',
    state: '',
    zipcode: ''
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [countyOptions, setcountyOptions] = useState([]);
  const [isSaved, setIsSaved] = useState(false);

  /* Fetch State Options; called only first time */
  useEffect(() => {
    getDropdownOptions('states')
      .then(
        ({
          data: {
            data: { states }
          }
        }) => {
          setStateOptions(states);
        }
      )
      .catch(error => {
        console.log(error);
      });
  }, []);

  /* Fetch County Options for the selected State; called every time a different state is selected */
  useEffect(() => {
    if (address.state && stateOptions?.length) {
      let stateKey = null;
      // eslint-disable-next-line no-unused-vars
      stateOptions.findIndex(item => {
        let found = false;
        for (const [key, value] of Object.entries(item)) {
          if (value === address.state) {
            found = true;
            stateKey = key;
            break;
          }
        }
        return found;
      });
      if(stateKey){
        getDropdownOptions('counties', stateKey)
          .then(
            ({
              data: {
                data: { counties }
              }
            }) => {
              setcountyOptions(counties);
            }
          )
          .catch(error => {
            console.log(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.state, stateOptions]);

  useEffect(() => {
    // console.log('address ValueProps | ', valueProps);
    // cityChangedHandler(valueProps?.['city'] ?? '');
    // zipcodeChangedHandler(valueProps?.['zipcode'] ?? '');
    // streetChangedHandler(valueProps?.['street'] ?? '');
    if(valueProps && valueProps !== address){
      setAddress({...valueProps});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProps]);

  const debouncedInput = (e, key) => {
    // setAddress(prev => ({ ...prev, [key]: e.target.value }));
    // if (!microApi) {
    //   parentHandler(keyName, {
    //     address: { ...address, [key]:e.target.value }
    //   });
    // }
    if (key === 'street') {
      streetChangedHandler(e.target.value);
    }
    if (key === 'city') {
      cityChangedHandler(e.target.value);
    }
    if (key === 'zipcode') {
      zipcodeChangedHandler(e.target.value);
    }
  };

  const streetChangedHandler = street => {
    setAddress(prev => ({ ...prev, street }));
    if (!microApi) {
      parentHandler(keyName, {
        address: { ...address, street }
      });
    }
  };

  const cityChangedHandler = city => {
    setAddress(prev => ({ ...prev, city }));
    if (!microApi) {
      parentHandler(keyName, {
        address: { ...address, city }
      });
    }
  };

  const zipcodeChangedHandler = zipcode => {
    setAddress(prev => ({ ...prev, zipcode }));
    if (!microApi) {
      parentHandler(keyName, {
        address: { ...address, zipcode }
      });
    }
  };

  const stateChangedHandler = state => {
    setAddress(prev => ({ ...prev, state, county: '' }));
    if (!microApi) {
      parentHandler(keyName, {
        address: { ...address, state, county: '' }
      });
    }
  };

  const countyChangedHandler = county => {
    setAddress(prev => ({ ...prev, county }));
    if (!microApi) {
      parentHandler(keyName, { address: { ...address, county } });
    }
  };

  const saveAddress = () => {
    if (address.street && address.city && address.state && address.county && address.zipcode) {
      if(!address.id){
        microApi
        .add(
          'address',
          objectToFormData({
            street: address.street,
            city: address.city,
            state: address.state,
            county: address.county,
            zip_code: address.zipcode
          })
        )
        .then(({ data }) => {
          if (data.success) {
            parentHandler(keyName, data['data']['id'], index);
            setIsSaved(true);
            handleNotifications('success', 'success', 'Address added successfully.');
          } else {
            parentHandler(keyName, null, index);
          }
        })
        .catch(error => {
          console.log(error);
          parentHandler(keyName, null, index);
        });
      }else{
        let ind = index;
        let id = address.id;
        microApi.update(
          'address',
          id,
          objectToFormData({
            street: address.street,
            city: address.city,
            state: address.state,
            county: address.county,
            zip_code: address.zipcode
          })
        )
        .then(({ data }) => {
          if (data.success) {
            parentHandler(keyName, id, ind);
            setIsSaved(true);
            handleNotifications('success', 'success', 'Address updated successfully.');
          } else {
            parentHandler(keyName, null, ind);
          }
        })
        .catch(error => {
          console.log(error);
          parentHandler(keyName, null, ind);
        });
      }
    } else {
      handleNotifications(
        'warning',
        'Warning',
        'Please fill all fields before saving.'
      );
    }
  };

  // const updateAddress = () => {
  //   if (address.street && address.state && address.county && address.id) {
  //     microApi
  //       .update(
  //         'address',
  //         address.id,
  //         objectToFormData({
  //           street: address.street,
  //           state: address.state,
  //           county: address.county
  //         })
  //       )
  //       .then(({ data }) => {
  //         if (data.success) {
  //           parentHandler(keyName, data['data']['id']);
  //           setIsSaved(true);
  //         } else {
  //           parentHandler(keyName, null);
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error);
  //         parentHandler(keyName, null);
  //       });
  //   } else {
  //     handleNotifications(
  //       'warning',
  //       'Warning',
  //       'Please fill all fields before saving.'
  //     );
  //   }
  // };
  return (
    <React.Fragment>
      {!hideStreet && <Input
        // name="street_address"
        placeholder="Street Address"
        type="text"
        style={{
          borderBottom: '1px solid gray',
          borderLeft: '1px solid gray',
          marginBottom: '0px',
          backgroundColor: 'white'
        }}
        onChange={e => {
          e.persist();
          debouncedInput(e, 'street');
        }}
        disabled={disabled}
        defaultValue={valueProps?.['street']}
      />}

      {hasCity ? (
        <Input
          name="city"
          placeholder="City"
          type="text"
          style={{
            borderBottom: '1px solid gray',
            borderLeft: '1px solid gray',
            marginBottom: '0px',
            backgroundColor: 'white'
          }}
          onChange={e => {
            e.persist();
            debouncedInput(e, 'city');
          }}
          disabled={disabled}
          defaultValue={valueProps?.['city']}
        />
      ) : null}
      {hasZip ? (
        <Input
          name="zipcode"
          placeholder="Zipcode"
          type="text"
          maxLength={6}
          style={{
            borderBottom: '1px solid gray',
            borderLeft: '1px solid gray',
            marginBottom: '0px',
            backgroundColor: 'white'
          }}
          onChange={e => {
            e.persist();
            debouncedInput(e, 'zipcode');
          }}
          disabled={disabled}
          defaultValue={valueProps?.['zipcode']}
        />
      ) : null}

      <Select
        placeholder="Select State"
        style={{
          borderBottom: '1px solid gray',
          borderLeft: '1px solid gray',
          marginBottom: '0px'
        }}
        onChange={stateChangedHandler}
        disabled={disabled}
        defaultValue={valueProps?.['state']}
      >
        {stateOptions.map(item => {
          let optionsItem = null;
          for (const [key, value] of Object.entries(item)) {
            optionsItem = (
              <Option key={key} value={value}>
                {value}
              </Option>
            );
          }
          return optionsItem;
        })}
      </Select>

      <Select
        placeholder="Select County"
        style={{
          borderBottom: '1px solid gray',
          borderLeft: '1px solid gray',
          marginBottom: '0px'
        }}
        onChange={countyChangedHandler}
        disabled={disabled}
        defaultValue={valueProps?.['county']}
      >
        <Option key={-1} value="">
          Select County
        </Option>
        {countyOptions.map((item, index) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))}
      </Select>

      {!!microApi && !isSaved && !disabled ? (
        <Button
          type="warning"
          style={{
            float: 'right',
            marginRight: '0px',
            marginTop: '0px',
            backgroundColor: '#82CF8F',
            color: 'white'
          }}
          onClick={() => {
            saveAddress();
          }}
        >
          Save
        </Button>
      ) : null}
    </React.Fragment>
  );
};

Address.propTypes = {
  valueProps: any,
  keyName: string,
  hasCity: bool,
  hasZip: bool,
  microApi: object,
  disabled: bool,
  viewMode: bool,
  index: string,
  parentHandler: func
};

export default Address;
